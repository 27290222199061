import { Avatar, Typography, Box, useMediaQuery, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import { CalendarMonthOutlined } from '@mui/icons-material';

import { truncateString } from '../../../../../utils';
import { Highlight } from './assets';

function Header({ tripTitle, dateAndDuration }) {
  const { palette, breakpoints } = useTheme();
  const { user } = useSelector((state) => state.Auth.userData);
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <>
      <Avatar
        alt={user?.firstName}
        style={{
          borderColor: palette.primary?.light,
          borderWidth: 2.5,
          borderStyle: 'solid',
        }}
        src={user?.profilePicture}
      />
      <Typography
        mt="12px"
        fontSize="1.56rem"
        color={palette?.text?.secondary}
        fontFamily="Clearface-Italic">
        You&apos;re invited to plan
      </Typography>

      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Highlight width={isMobile ? 320 : 437} />
        <Typography
          sx={{
            transform: 'rotate(-2.75deg)',
            position: 'absolute',
          }}
          color="primary"
          fontSize={isMobile ? '30px' : '40px'}
          fontFamily="Clearface-Italic">
          {truncateString(tripTitle, 19)}
        </Typography>
      </Box>
      {isMobile && (
        <Stack
          mt="10px"
          gap="5px"
          alignItems="center"
          flexDirection="row"
          justifyContent="center">
          <CalendarMonthOutlined
            height="16px"
            width="16px"
            sx={{ marginTop: '8px' }}
          />
          <Typography fontSize="16px" pt="8px" fontFamily="Clearface">
            {dateAndDuration}
          </Typography>
        </Stack>
      )}
    </>
  );
}

export default Header;
