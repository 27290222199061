import { createSlice } from '@reduxjs/toolkit';
import QUERY from '../../V3/graphql/queries';
import { getAsyncThunkV2 } from '../helpers';
import { updateAccommodation, deleteAccommodation } from './Accommodation';
import { deleteActivity } from './Activity';
import { deleteLocation, updateLocation } from './Location';
import {
  clearMapPinsFromSection,
  clearMapPinsFromLocation,
} from './sharedThunks';
import { deleteTripUsingAtc, getCompleteTripUsingAtc } from './TripV2';
import { deleteItem, ItemActions } from './Item';

export const createMapPin = getAsyncThunkV2(
  'MAP_V2/createMapPin',
  QUERY.CREATE_MAP_PIN
);

export const updateMapPin = getAsyncThunkV2(
  'MAP_V2/updateMapPin',
  QUERY.UPDATE_MAP_PIN
);

export const deleteMapPin = getAsyncThunkV2(
  'MAP_V2/deleteMapPin',
  QUERY.DELETE_MAP_PIN,
  ItemActions.deleteMapPinFromItem,
  (payload) => ({ mapPinId: payload.variables.id })
);

const initialState = {
  mapPins: {},
  directionsView: false,
  directionsPins: [{ id: 'stop_1' }, { id: 'stop_2' }],
  directionsRoutes: {
    routeGeoJson: {},
    travelMode: 'CAR',
    error: null,
  },
  hoveredPin: null,
};

const MapV2Slice = createSlice({
  name: 'MAP_V2',
  initialState,
  reducers: {
    resetDirections: (state) => {
      state.directionsView = false;
      state.directionsPins = [{ id: 'stop_1' }, { id: 'stop_2' }];
      state.directionsRoutes = {
        routeGeoJson: {},
        travelMode: 'CAR',
      };
    },
    setDirectionsView: (state, { payload: { activateDirections = false } }) => {
      state.directionsView = activateDirections;
    },
    setDirectionsPins: (state, { payload = [] }) => {
      state.directionsPins = payload;
    },
    changeTravelMode: (state, { payload: travelMode = 'CAR' }) => {
      state.directionsRoutes.travelMode = travelMode;
    },
    setRoutes: (state, { payload: { tripLegs = null } }) => {
      if (!tripLegs) {
        state.directionsRoutes.tripLegs = [];
        state.directionsRoutes.routeGeoJson = {};
        return;
      }
      // GeoJSON format
      state.directionsRoutes.tripLegs = tripLegs;
      state.directionsRoutes.error = false;
      state.directionsRoutes.routeGeoJson = {
        type: 'FeatureCollection',
        features: tripLegs?.map((leg) => ({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: leg.points.map((point) => [
              point.longitude,
              point.latitude,
            ]),
          },
        })),
      };
    },
    setRoutesError: (state) => {
      state.directionsRoutes.error = true;
      state.directionsRoutes.tripLegs = [];
      state.directionsRoutes.routeGeoJson = {};
    },
    setHoveredPin: (state, { payload: { pinId = null } }) => {
      state.hoveredPin = pinId;
    },
    removeMappins: (state, { payload: { deletedMapPins, tripId } }) => {
      if (!tripId) return;
      deletedMapPins?.forEach((mapPinId) => {
        delete state.mapPins[tripId][mapPinId];
      });
    },
    removeMappinTemp: (state, { payload: { mapPinId, tripId } }) => {
      if (!tripId) return;
      // set tempRemoveFlag to true
      if (state.mapPins[tripId] && state.mapPins[tripId][mapPinId]) {
        state.mapPins[tripId][mapPinId].tempRemove = true;
      }
    },
    addMappinTemp: (state, { payload: { mapPinId, tripId } }) => {
      if (!tripId) return;
      // set tempRemoveFlag to false
      if (state.mapPins[tripId] && state.mapPins[tripId][mapPinId]) {
        state.mapPins[tripId][mapPinId].tempRemove = false;
      }
    },
  },
  extraReducers: {
    [getCompleteTripUsingAtc.fulfilled]: (
      state,
      {
        meta: {
          arg: { tripId },
        },
        payload: { mapPinList },
      }
    ) => {
      state.mapPins[tripId] =
        mapPinList?.reduce(
          (mapPins, mapPin) => ({ ...mapPins, [mapPin?.id]: mapPin }),
          {}
        ) || {};
    },

    ...([
      clearMapPinsFromLocation.fulfilled,
      clearMapPinsFromSection.fulfilled,
    ].reduce(
      (clearMapPinsReducers, action) => ({
        ...clearMapPinsReducers,
        [action]: (
          state,
          {
            payload: mapPinIds = [],
            meta: {
              arg: { tripId },
            },
          }
        ) => {
          if (!tripId) return;
          mapPinIds?.forEach((mapPinId) => {
            delete state.mapPins[tripId][mapPinId];
          });
        },
      }),
      {}
    ) || {}),

    [createMapPin.fulfilled]: (
      state,
      {
        payload: { createMapPin: mapPin },
        meta: {
          arg: {
            variables: { tripId },
          },
        },
      }
    ) => {
      if (!tripId) return;
      state.mapPins = {
        ...state.mapPins,
        [tripId]: {
          ...(state.mapPins[tripId] || {}),
          [mapPin.id]: mapPin,
        },
      };
    },
    [updateMapPin.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: mapPin,
            context: { tripId },
          },
        },
      }
    ) => {
      state.mapPins = {
        ...state.mapPins,
        [tripId]: {
          ...(state.mapPins[tripId] || {}),
          [mapPin.id]: {
            ...(state.mapPins[tripId][mapPin.id] || {}),
            ...mapPin,
          },
        },
      };
    },
    [deleteMapPin.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { id },
            context: { tripId },
          },
        },
      }
    ) => {
      delete state.mapPins[tripId][id];
    },
    [deleteTripUsingAtc.fulfilled]: (state, { meta }) => {
      delete state.mapPins[meta.arg];
    },
    [deleteItem.fulfilled]: (state, { payload }) => {
      if (payload) {
        const { mapPinsToDelete = [], tripId = null } = payload;
        if (!tripId) return;
        mapPinsToDelete?.forEach(({ mapPinId }) => {
          delete state.mapPins[tripId][mapPinId];
        });
      }
    },
    // creating and deleting mapPins in real time, this setup to reduce repetitive code.
    ...([
      deleteActivity.fulfilled,
      deleteAccommodation.fulfilled,
      deleteLocation.fulfilled,
    ].reduce(
      (deletePinReducers, action) => ({
        ...deletePinReducers,
        [action]: (
          state,
          {
            meta: {
              arg: { mapPin },
            },
          }
        ) => {
          if (mapPin) {
            Object.keys(state.mapPins).forEach((tripId) => {
              if (
                Object.prototype.hasOwnProperty.call(
                  state.mapPins[tripId] || {},
                  mapPin
                )
              ) {
                delete state.mapPins[tripId][mapPin];
                return null;
              }
              return null;
            });
          }
        },
      }),
      {}
    ) || {}),

    ...([updateLocation.fulfilled, updateAccommodation.fulfilled].reduce(
      (updatePinReducers, action) => ({
        ...updatePinReducers,
        [action]: (
          state,
          {
            meta: {
              arg: {
                variables: { oldMapPin },
              },
            },
          }
        ) => {
          if (oldMapPin) {
            Object.keys(state.mapPins || {}).forEach((tripId) => {
              if (
                Object.prototype.hasOwnProperty.call(
                  state.mapPins[tripId] || {},
                  oldMapPin
                )
              ) {
                delete state.mapPins[tripId][oldMapPin];
                return null;
              }
              return null;
            });
          }
        },
      }),
      {}
    ) || {}),
  },
});

export const MapV2Actions = MapV2Slice.actions;
export const MapV2Reducer = MapV2Slice.reducer;
