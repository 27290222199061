import getCfConnector from '../../../../../cfConnector';

const generateSuggestions = async ({
  destinationName,
  coordinates,
  userPrompt,
  action = 'Activities',
  existingResults = [],
  interests = [],
  chatContext,
}) => {
  try {
    const res = await (
      await getCfConnector()
    ).post(process.env.REACT_APP_AI_AISUGGESTIONS_API, {
      destinationName,
      coordinates,
      userPrompt,
      action,
      existingResults,
      interests,
      chatContext,
    });

    if (res?.data?.success && res?.data?.data?.length > 0) {
      return [res?.data?.data, res?.data?.messages];
    }
  } catch (err) {
    // handle error
  }
  return false;
};

export default generateSuggestions;
