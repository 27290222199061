import React, { useState, useEffect, useRef, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BookmarkRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { useLocation, useParams } from 'react-router-dom';
import HotelImageContainer from '../V2/ImageContainer';
import HotelNavbar from '../V2/Navbar';
import HotelHeading from '../V2/Heading';
import HotelDealsContainer from '../V2/DealsContainer';
// import HotelReviewSection from '../ReviewSection';
import HotelPerksSection from '../V2/PerksSection';
import HotelLocationSection from '../V2/LocationSection';
// import HotelAmenities from '../Amenities';
import { getInternalHotel, getPrices } from '../api';
import actions from '../../../../../../redux/actions';
import LoadingLayout from '../../../../../templates/LoadingLayout';
import { getNumberOfNights } from '../utils';
import { OutlinedButton } from '../../../../../atoms/Button/index';
import {
  addSavedItemV2,
  deleteSavedItemV2,
} from '../../../../../../redux/slices/Bookings';
import TripSelectorStays from '../../TripSelectorStays';
import { MapProvider, useMapUtils } from '../../../Map';
import NotificationPopper from '../../NotificationPopper';
import CartReviewBottomBar from '../V2/CartReviewBottomBar';
import { ONE_DAY_MS } from '../../../../../../utils';
import {
  getRoomSplit,
  guestToStringV2,
  stringToGuestV2,
} from '../../bookingsUtils';
import { getCompleteTripUsingAtc } from '../../../../../../redux/slices/TripV2';
import { createItem, deleteItem } from '../../../../../../redux/slices/Item';
import ITEM_TYPES, {
  COST_TYPES,
  SAVED_ITEM_TYPES,
} from '../../../../../../const';

const useStyles = makeStyles(({ breakpoints }) => ({
  page: {
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    paddingBottom: 120,
  },
  controlledWidth: {
    width: 1032,
    margin: '0 auto',
    [breakpoints.down('lg')]: {
      width: '100%',
      padding: '0 48px',
    },
    [breakpoints.down('sm')]: {
      padding: '0 24px',
    },
  },
  saveIconContainer: (saved) => ({
    display: 'flex',
    marginRight: 8,
    marginLeft: 'auto',
    alignItems: 'center',
    cursor: 'pointer',
    color: saved ? '#ED702E' : '#8A8A8A',
    '&:hover': {
      color: '#FFA766',
      '& > svg > path': {
        stroke: '#FFA766',
      },
    },
  }),
  saveIcon: (saved) => ({
    color: saved ? '#ED702E' : 'transparent',
    marginRight: 4,
    '& > path': {
      stroke: saved ? '#ED702E' : '#8A8A8A',
      strokeWidth: '1',
    },
  }),
}));

function HotelDetailsPage() {
  const [loading, setLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const prevTripId = window.localStorage.getItem('prevTripId');
  const [isTripSelectorOpen, setIsTripSelectorOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [activeActionProps, setActiveActionProps] = useState(null);
  const [addToTripLoader, setAddToTripLoader] = useState(false);
  const [popperNotification, setPopperNotification] = useState(null);
  const [priceFetched, setPriceFetched] = useState(false);
  const isNewDestination = useRef(false);

  const { slug: hotelKey } = useParams();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const city = queryParams.get('city');

  const dispatch = useDispatch();

  const hotelDetails =
    useSelector((state) => state.Bookings.hotelDetailsPage) || null;

  // saved list logic
  const [saved, setSaved] = useState(false);
  const [allowSave, setAllowSave] = useState(true);
  const savedItemList = useSelector((state) =>
    Object.values(state.Bookings.saved[prevTripId] || {})?.filter(
      (savedItem) =>
        savedItem?.referenceId === hotelDetails?.data[hotelKey]?.id?.toString()
    )
  );
  const { cityLocationDetails } = useSelector((state) => state.Bookings);

  const {
    checkInDate = null,
    checkOutDate = null,
    rooms: guestRooms = [{ adults: 2, children: 0 }],
  } = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.searchProps || {}
  );

  const savedItem = savedItemList?.length > 0 && savedItemList[0];
  const isSaved = Boolean(savedItem);

  const { createMapPinForPlace, createMapPinForPlaceId } = useMapUtils();

  const mapPins = useSelector((state) => state.MapV2.mapPins[prevTripId] || {});
  const tripDestinations = useSelector((state) =>
    Object.values(state.Item.items || {})?.filter(
      (item) =>
        item.type === ITEM_TYPES.DESTINATION && item.tripId === prevTripId
    )
  );
  const trip = useSelector((state) => state.tripsAtc.tripsAtc[prevTripId]);

  const tripItems = trip?.children || [];

  const validLocations = useMemo(() => {
    return tripDestinations
      .filter((loc) => loc?.title !== '')
      .map((loc) => {
        const mapPin = loc?.mapPin ? mapPins[loc.mapPin] : null;
        return {
          id: loc?.id,
          title: loc?.title,
          mapPin,
          hotelsCount: loc.children?.length || 0,
          fromDate: loc?.content?.startDate,
          toDate: loc?.content?.endDate,
        };
      })
      .filter((tag) => Boolean(tag));
  }, [tripDestinations, mapPins]);

  const classes = useStyles(saved);

  const locationNameList = validLocations?.map((locationItem) =>
    locationItem?.title?.toLowerCase().trim()
  );

  const locationDetails = validLocations?.filter(
    (locationItem) =>
      locationItem?.title?.toLowerCase().trim() === city?.toLowerCase().trim()
  );

  const updateSearchUrl = (params) => {
    const defaultProps = {
      checkInDate: format(new Date(Date.now() + 2 * ONE_DAY_MS), 'yyyy-MM-dd'),
      checkOutDate: format(new Date(Date.now() + 4 * ONE_DAY_MS), 'yyyy-MM-dd'),
      rooms: '2|0|1',
      city,
      hotelName: hotelDetails?.data[hotelKey]?.name,
      starRating: hotelDetails?.data[hotelKey]?.starRating,
      price: hotelDetails?.data[hotelKey]?.packages?.[0]?.totalPrice?.price,
      address: hotelDetails?.data[hotelKey]?.address,
    };
    const urlParams = new URLSearchParams(params);
    const updatedSearchProps = {};

    Object.keys(defaultProps)?.forEach((searchProp) => {
      if (!urlParams.get(searchProp))
        urlParams.set(searchProp, defaultProps[searchProp]);
      updatedSearchProps[searchProp] = urlParams.get(searchProp);
    });

    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${urlParams.toString()}`
    );
    return updatedSearchProps;
  };

  // eslint-disable-next-line
  const refreshHotel = async () => {
    // get checkin date from url
    const searchParams = new URLSearchParams(window.location.search);
    const requiredSearchProps = ['checkInDate', 'checkOutDate', 'rooms'];

    if (
      !requiredSearchProps?.filter((prop) => Boolean(searchParams.get(prop)))
        ?.length === 3
    ) {
      return;
    }

    const searchProps = requiredSearchProps?.reduce(
      (acc, prop) => ({
        ...acc,
        [prop]: searchParams.get(prop),
      }),
      {}
    );

    searchProps.rooms = stringToGuestV2(searchProps.rooms);

    await dispatch(
      actions.Bookings.setHotelDetailsSearchProps({
        ...searchProps,
      })
    );
    setLoading(true);
    try {
      const {
        content: hotel,
        facilities,
        photos,
        descriptions,
      } = await getInternalHotel({
        hotelId: hotelKey,
        checkInDate,
        checkOutDate,
        rooms: getRoomSplit(searchProps?.rooms),
      });

      setLoading(false);

      if (hotel === null) return;

      dispatch(
        actions.Bookings.setHotelDetails({
          ...(hotel || {}),
          key: hotelKey,
          price: hotelDetails?.price,
          numberOfNights: getNumberOfNights(
            parse(
              hotelDetails?.searchProps?.checkInDate,
              'yyyy-MM-dd',
              new Date()
            ),
            parse(
              hotelDetails?.searchProps?.checkOutDate,
              'yyyy-MM-dd',
              new Date()
            )
          ),
          additional: {
            facilities,
            photos,
            descriptions,
          },
        })
      );
    } catch (err) {
      // error
    }
  };

  const handleSaveClick = async (e) => {
    if (!prevTripId || !allowSave) return;

    setAllowSave(false);
    e.stopPropagation();
    if (isSaved) {
      setSaved(false);
      await dispatch(
        deleteSavedItemV2({
          variables: {
            id: savedItem?.id,
          },
          tripId: prevTripId,
        })
      ).catch(() => setSaved(true));
    } else {
      setSaved(true);
      await dispatch(
        addSavedItemV2({
          variables: {
            itemToSave: {
              type: SAVED_ITEM_TYPES.HOTEL,
              tripId: prevTripId,
              referenceId: hotelDetails?.data[hotelKey]?.id?.toString(),
              savedData: {
                hotelKey,
                title: hotelDetails?.data[hotelKey]?.name,
                hotelName: hotelDetails?.data[hotelKey]?.name,
                starRating: hotelDetails?.data[hotelKey]?.starRating,
                guestRating:
                  hotelDetails?.data[hotelKey]?.reviews?.guestRatings?.OVERALL,
                numberOfReviews:
                  hotelDetails?.data[hotelKey]?.reviews?.numberOfReviews,
                guestRatingSentiment:
                  hotelDetails?.data[hotelKey]?.reviews?.sentiment,
                rates: hotelDetails?.data[hotelKey]?.reviews?.results,
                images: hotelDetails?.data[hotelKey]?.images,
                referenceId: hotelDetails?.data[hotelKey]?.id?.toString(),
                providers: hotelDetails?.data[hotelKey]?.providers,
                currencyCode: hotelDetails?.data[hotelKey]?.currencyCode,
                propertyType: hotelDetails?.data[hotelKey]?.propertyType,
              },
            },
          },
        })
      ).catch(() => setSaved(false));
    }
    setAllowSave(true);
  };

  const createMapPinForAccomodation = async () => {
    const hotel = hotelDetails?.data[hotelKey];
    const mapPinDetails = {
      title: hotel?.name,
      photo: hotel?.additional?.photos?.[0],
      website: hotel?.website,
      lat: hotel?.lat,
      long: hotel?.lng,
      types: 'ACCOMMODATION',
      hotelId: hotel?.id?.toString(),
    };

    const pinDetails = createMapPinForPlace(
      mapPinDetails,
      'ACCOMMODATION',
      {},
      prevTripId
    );
    return pinDetails;
  };

  const getStayLocationInfo = (
    controlledSelectedLocation = selectedLocation
  ) => {
    try {
      if (controlledSelectedLocation) {
        // This location is selected form trip selector execute this
        return [
          controlledSelectedLocation?.locationId,
          0,
          controlledSelectedLocation?.title,
        ];
      }
      if (
        locationNameList
          ?.map((loc) => loc?.toLowerCase().trim())
          ?.includes(city?.toLowerCase().trim()) &&
        locationDetails?.length === 1
      ) {
        return [locationDetails[0]?.id, 0, city];
      }
      return [null, 0, city];
    } catch (err) {
      return [null, 0, city];
    }
  };

  const handleUndoAction = async (undoActionProps = activeActionProps) => {
    if (!undoActionProps) return;

    const {
      parentId = null,
      parentMapPinId = null,
      itemId = null,
      itemMapPinId = null,
    } = undoActionProps;

    if (itemId === null) return;

    if (isNewDestination.current) {
      await Promise.all([
        dispatch(
          deleteItem({
            variables: {
              id: parentId,
              parentId: prevTripId,
              tripId: prevTripId,
            },
            mapPin: parentMapPinId,
          })
        ),
        dispatch(
          deleteItem({
            variables: {
              id: itemId,
              parentId,
              tripId: prevTripId,
            },
            tripId: prevTripId,
            parentId,
            mapPin: parentMapPinId,
          })
        ),
      ]);
    } else {
      await dispatch(
        deleteItem({
          variables: {
            id: itemId,
            parentId,
            tripId: prevTripId,
            mapPin: itemMapPinId,
          },
          tripId: prevTripId,
          parentId,
          mapPin: itemMapPinId,
        })
      );
    }
    setActiveActionProps(null);
  };

  const handleAddLocation = async () => {
    const placeDetails = {
      lat: cityLocationDetails?.latitude,
      long: cityLocationDetails?.longitude,
      placeId: cityLocationDetails?.placeId,
      title: cityLocationDetails?.title,
    };
    let mapPinId = null;
    if (placeDetails?.placeId) {
      mapPinId = await createMapPinForPlaceId(
        placeDetails?.placeId,
        'LOCATION'
      );
    }

    const locationObj = await dispatch(
      createItem({
        variables: {
          title: placeDetails.title || city || 'New destination',
          tripId: prevTripId,
          mapPin: mapPinId,
          type: ITEM_TYPES.DESTINATION,
          index: tripItems?.length || 0,
          parentId: prevTripId,
        },
        parentId: prevTripId,
        tripId: prevTripId,
        index: tripItems?.length || 0,
      })
    ).then((data) => data.payload.createItem);

    return { ...locationObj, mapPin: mapPinId };
  };

  const handleAddAccommodationToTripClick = async (
    controlledSelectedLocation = selectedLocation
  ) => {
    setAddToTripLoader(true);
    try {
      const stayLocationInfo = getStayLocationInfo(controlledSelectedLocation);
      let [locationId] = stayLocationInfo;
      const [stayIndex, locationTitle] = stayLocationInfo.slice(1);
      let locationMapPinId = null;

      if (!locationId) {
        const locationObj = await handleAddLocation();
        locationId = locationObj?.id;
        locationMapPinId = locationObj?.mapPin;
      }

      if (locationId) {
        const mapPinDetails = await createMapPinForAccomodation();
        const hotel = (
          await dispatch(
            createItem({
              variables: {
                content: {
                  expense: {
                    amount:
                      hotelDetails?.data[hotelKey]?.packages?.[0]?.totalPrice
                        ?.price,
                    costType: COST_TYPES[1],
                    currency:
                      hotelDetails?.data[hotelKey]?.packages?.[0]?.totalPrice
                        ?.currency,
                  },
                  checkInDate,
                  checkOutDate,
                  address: {
                    streetAddress: city,
                    city,
                  },
                },
                type: ITEM_TYPES.ACCOMMODATION,
                title: hotelDetails?.data[hotelKey]?.name,
                tripId: prevTripId,
                index: stayIndex,
                mapPin: mapPinDetails?.id,
                parentId: locationId,
              },
              tripId: prevTripId,
              parentId: locationId,
              index: stayIndex,
            })
          )
        )?.payload?.createItem;
        isNewDestination.current = Boolean(locationMapPinId);
        // Set action props to handle undo action if needed
        setActiveActionProps({
          ...activeActionProps,
          locationId,
          locationMapPinId,
          hotelId: hotel?.id,
          hotelMapPinId: mapPinDetails?.id,
        });
      }
      setSelectedLocation(null);
      setPopperNotification(locationTitle);
    } catch (err) {
      // handle error
      setIsTripSelectorOpen(true);
    }
    setAddToTripLoader(false);
  };

  const handleAddToTripButtonClick = async (e) => {
    e.stopPropagation();

    // if duplicate location exist then open the tripSelector else open notification popup
    if (locationDetails?.length > 1 || saved) {
      setIsTripSelectorOpen(true);
    } else {
      await handleAddAccommodationToTripClick();
    }
  };

  useEffect(() => {
    if (saved !== isSaved) setSaved(isSaved);
  }, [isSaved]);

  // load trip for saved items if prevItem is defined
  useEffect(() => {
    if (prevTripId) {
      try {
        dispatch(
          getCompleteTripUsingAtc({
            tripId: prevTripId,
            ignoreRejection: true,
          })
        );
      } catch (err) {
        // handle error
      }
    }
  }, []);

  const refreshHotelBookables = async () => {
    setPriceLoading(true);
    const { packages = null, rooms = null } = await getPrices({
      hotelId: hotelKey,
      checkInDate,
      checkOutDate,
      rooms: getRoomSplit(guestRooms),
      refetch: priceFetched ? 1 : 0,
    });
    dispatch(
      actions.Bookings.setHotelDetails({
        key: hotelKey,
        packages,
        rooms,
      })
    );

    setPriceLoading(false);
    if (!priceFetched) setPriceFetched(true);
  };

  useEffect(() => {
    refreshHotel();
  }, []);

  useEffect(() => {
    if (
      hotelDetails?.searchProps?.checkInDate &&
      hotelDetails?.searchProps?.checkOutDate &&
      hotelDetails?.searchProps?.rooms &&
      !loading
    ) {
      refreshHotelBookables({ hotelId: hotelKey });
      updateSearchUrl({
        ...hotelDetails?.searchProps,
        rooms: guestToStringV2(hotelDetails?.searchProps?.rooms),
      });
    }
  }, [hotelDetails?.searchProps, loading]);

  return (
    <MapProvider>
      <div className={classes.page} id="hotel-details-page">
        {loading ? (
          <LoadingLayout />
        ) : (
          hotelDetails?.data[hotelKey] && (
            <>
              <HotelNavbar
                saved={saved}
                handleSaveClick={handleSaveClick}
                handleAddToTripButtonClick={handleAddToTripButtonClick}
              />
              <div style={{ marginTop: 16 }} />

              <div className={classes.controlledWidth}>
                <div
                  style={{
                    display: isMd ? 'flex' : 'block',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: isMobile ? '10px' : '0px',
                    marginBottom: isMobile ? '10px' : '0px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '20px',
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}>
                    {!isMd && prevTripId && (
                      <Typography
                        variant="h5"
                        className={classes.saveIconContainer}
                        onClick={handleSaveClick}>
                        <BookmarkRounded className={classes.saveIcon} />
                        {saved ? 'Saved' : 'Save'}
                      </Typography>
                    )}
                    <div style={{ position: 'relative' }}>
                      {!isMobile && (
                        <OutlinedButton onClick={handleAddToTripButtonClick}>
                          {addToTripLoader ? (
                            <CircularProgress size="19px" />
                          ) : (
                            'Add to trip'
                          )}
                        </OutlinedButton>
                      )}
                      {isTripSelectorOpen && (
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 100000,
                            left: isMobile ? '-295px' : '-195px',
                            top: isMd ? (isMobile ? '-70px' : '30px') : '50px',
                            width: '300px',
                          }}>
                          <TripSelectorStays
                            setIsTripSelectorOpen={setIsTripSelectorOpen}
                            handleLocationUpdate={async (locationProps) => {
                              if (
                                locationProps?.locationId ===
                                activeActionProps?.locationId
                              )
                                return;
                              setSelectedLocation(locationProps);
                              await handleUndoAction();
                              await handleAddAccommodationToTripClick(
                                locationProps
                              );
                            }}
                            defaultLocationId={getStayLocationInfo()[0] || null}
                            locationsList={validLocations}
                          />
                        </div>
                      )}

                      <NotificationPopper
                        open={Boolean(popperNotification)}
                        onClose={(e, reason) => {
                          if (reason === 'timeout') {
                            setActiveActionProps(null);
                          }
                          setPopperNotification(null);
                        }}
                        onCancel={async () => {
                          setPopperNotification(null);
                          if (validLocations?.length > 1) {
                            setIsTripSelectorOpen(true);
                          } else {
                            setAddToTripLoader(true);
                            await handleUndoAction();
                            setAddToTripLoader(false);
                          }
                        }}
                        destinationName={popperNotification}
                        isNewDestination={isNewDestination?.current}
                        cancelButtonLabel={
                          validLocations?.length <= 1 ? 'Undo' : 'Change'
                        }
                        paperStyle={{
                          marginLeft: isMobile ? '-180px' : '0',
                          marginTop: isMobile ? '-100px' : '0',
                        }}
                      />
                    </div>
                  </div>
                </div>

                <HotelImageContainer />
                <HotelHeading saved={saved} handleSaveClick={handleSaveClick} />
                <div style={{ marginTop: 48 }} />
                <HotelDealsContainer loading={priceLoading} />
                <div
                  style={{
                    marginTop: 48,
                    borderTop: '1px solid #D9D9D9',
                    paddingTop: 32,
                  }}>
                  <HotelPerksSection />
                </div>
              </div>

              {/* <HotelReviewSection /> */}
              <div className={classes.controlledWidth}>
                <div
                  style={{
                    paddingTop: 48,
                    marginTop: 48,
                    borderTop: '1px solid #D9D9D9',
                  }}
                />
                <HotelLocationSection />
                <div
                  style={{ marginTop: 48, borderTop: '1px solid #D9D9D9' }}
                />
              </div>
              {!priceLoading && <CartReviewBottomBar />}
            </>
          )
        )}
      </div>
    </MapProvider>
  );
}

export default HotelDetailsPage;
