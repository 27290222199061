import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import LoadingLayout from '../../templates/LoadingLayout';
import TripNotFoundError from './TripNotFound';
import TripInvitation from './TripInvitation';
import queries from '../../../graphql/queries';
import graphqlClient from '../../../graphql';

function JoinTrip() {
  const navigate = useNavigate();
  const { slug: tripId } = useParams();

  const { data, loading } = useQuery(queries.GET_TRIP_BASIC, {
    variables: {
      id: tripId,
    },
    client: graphqlClient,
  });

  useEffect(() => {
    let timeout;
    if (!loading && !data?.getTripMeta) {
      timeout = setTimeout(() => {
        navigate('/trips');
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [loading, data]);

  if (loading) {
    return <LoadingLayout />;
  }

  if (!loading && !data?.getTripMeta) {
    return <TripNotFoundError />;
  }

  return <TripInvitation trip={data?.getTripMeta} />;
}

export default JoinTrip;
