import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Box,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import TripCard from '../../../molecules/CreateATripFlow/TripCard';
import { updateTripUsingAtc } from '../../../../redux/slices/TripV2';

function Step2Page({
  setStep,
  tripId,
  tripImageURL,
  tripName,
  setTripName,
  dateRange,
  tripUsers,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCreateTrip = async () => {
    if (!tripName.length) return;

    dispatch(
      updateTripUsingAtc({
        variables: {
          id: tripId,
          title: tripName,
        },
      })
    );
    if (isMobile) {
      setStep('2.5');
    } else {
      setStep((prev) => prev + 1);
    }
  };
  return (
    <Box
      sx={{
        height: '80vh',
        display: 'flex',
        alignItems: { xs: 'start', sm: 'center' },
      }}>
      <Grid
        container
        height="450px"
        sx={{ paddingX: { xs: '20px', sm: '100px' } }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Stack maxWidth="400px" spacing={3} mt="30px">
            <Box
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
              sx={{
                width: 'fit-content',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: '#8A8A8A',
              }}>
              <ChevronLeftRoundedIcon sx={{ marginTop: '-2px' }} />
              Back
            </Box>

            <Typography sx={{ fontSize: '37px', fontWeight: 500 }}>
              Let&apos;s give your trip a name!
            </Typography>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCreateTrip();
              }}>
              <TextField
                label="Enter a fun name! eg. Girls Euro Trip"
                variant="standard"
                sx={{ display: 'block', mb: 2 }}
                fullWidth
                value={tripName}
                onChange={(e) => {
                  setTripName(e.target.value);
                }}
              />
            </form>

            {isMobile && (
              <Button fullWidth onClick={handleCreateTrip}>
                Next
              </Button>
            )}
          </Stack>
        </Grid>

        {!isMobile && (
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Box
              sx={{
                maxWidth: '400px',
              }}>
              <Box mb={8}>
                <TripCard
                  tripImage={
                    tripImageURL || '/images/blank-trip-placeholder.png'
                  }
                  tripName={tripName}
                  dateRange={dateRange}
                  tripUsers={tripUsers}
                />
              </Box>

              <Button disabled={!tripName} fullWidth onClick={handleCreateTrip}>
                Next
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Step2Page;
