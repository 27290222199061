import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import FileRow from '../../../V2/organisms/FilesTab/FileRowV2';
import { MapProvider } from '../../../organisms/MapUtils';
import { handleFileDeleteV2 } from '../../../../utils';
import { ViewActions } from '../../../../redux/slices/View';

function ImportsList() {
  const tripId = useParams().slug;
  const files = useSelector(
    (state) => state?.Auth?.userData?.user?.forwardedFiles || []
  );
  const fileRelations = useSelector(
    (state) => state.FilesV2?.fileRelations[tripId]
  );
  const importOpenId = useSelector((state) => state.View.importOpenId);
  const isFilesTabOpen = useSelector((state) => state.View.isFilesTabOpen);
  const dispatch = useDispatch();
  const handleTriggerDeleteFile = (file) => {
    handleFileDeleteV2(file, fileRelations, dispatch);
  };

  const tripFiles = useSelector(
    (state) =>
      Object.values(state.FilesV2.files).filter((file) => file?.import?.status),
    shallowEqual
  );

  useEffect(() => {
    if (importOpenId) {
      setTimeout(() => {
        document
          .getElementById(`import-history-list-item-${importOpenId}`)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
  }, [importOpenId, isFilesTabOpen]);
  return (
    <MapProvider>
      <Stack id="import-history-list" direction="column" width="100%" px="24px">
        {[...files, ...tripFiles].length > 0 ? (
          [...files, ...tripFiles]

            .reduce((acc, current) => {
              const x = acc.find((item) => item.id === current.id);
              if (!x) {
                return acc.concat([current]);
              }
              return acc;
            }, [])
            .sort(
              (a, b) =>
                new Date(b?.import?.updatedAt || Date.now()) -
                new Date(a?.import?.updatedAt || Date.now())
            )
            .map((file, index) => (
              <FileRow
                containerId={`import-history-list-item-${file.id}`}
                collapsible
                isCollapsed={file.id !== importOpenId}
                onCollapseChange={(isCollapsed) => {
                  dispatch(
                    ViewActions.setImportOpenId(isCollapsed ? null : file.id)
                  );
                }}
                showOnlyFileAndImports
                isForwardedFile={file.type === 'EmailAttachment'}
                isModalView
                file={file}
                fileIndex={index}
                key={file.id}
                handleTriggerDeleteFile={handleTriggerDeleteFile}
                tripId={tripId}
                tripFiles={[]}
              />
            ))
        ) : (
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Stack direction="column" alignItems="center">
              <Typography>No imports found</Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </MapProvider>
  );
}

export default ImportsList;
