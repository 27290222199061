import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Typography, Slider, Box, Button } from '@mui/material';
import { styled } from '@mui/styles';
import { useSelector } from 'react-redux';
import { intervalToDuration } from 'date-fns';
import { BasicButton } from '../../../../atoms/Button/index';

const AirbnbSlider = styled(Slider)(() => ({
  color: '#1877F2',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#FFFFFF',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'red',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: '#D9D9D9',
    opacity: 1,
    height: 3,
  },
  '& > .MuiSlider-mark': {
    backgroundColor: 'transparent',
  },
}));

const roundOffPrice = (price, roundDown) => {
  const priceRegex = /^(\d+(\.\d{1,2})?|\.\d{1,2})$/;

  if (!price || !priceRegex.test(price)) return null;

  const roundFn = roundDown ? Math.floor : Math.ceil;
  return roundFn(price / 10) * 10;
};

const getNumberOfNights = (from, to) => {
  if (from && to) {
    return intervalToDuration({
      start: from,
      end: to,
    }).days;
  }
  return 1;
};

function PriceRangeSlider({ handleAction = () => {} }) {
  const { slug: tripId } = useParams();
  const { otherDetails, search } = useSelector(
    (state) => state.Bookings[tripId] || {}
  );
  const currencyCode = useSelector(
    (state) => state.Bookings?.searchLocale?.currencyCode
  );
  const nightlyStays = getNumberOfNights(search?.date?.from, search?.date?.to);

  const [value, setValue] = useState([
    roundOffPrice(otherDetails?.lowestTotalRate, true),
    roundOffPrice(otherDetails?.highestTotalRate),
  ]);

  const handleChange = (event, newValue) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    setValue([newValue[0], newValue[1]]);
  };

  const handleMouseUp = () => {
    handleAction({
      filterId: `price-${value[0]},${value[1]}`,
      action: 'UPDATE',
    });
  };

  const currencyFormatter = new Intl.NumberFormat(undefined, {
    currency: currencyCode || 'USD',
    style: 'currency',
    maximumFractionDigits: 0,
  });

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ margin: '0 5px' }}>
        <Typography
          pt={1}
          sx={{
            fontFamily: 'Inter',
            fontWeight: '500',
            fontSize: '14px',
            // TODO: underline once change currency allowed
            // textDecoration: 'underline',
          }}>
          {currencyCode || 'USD'}
        </Typography>

        <Button
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            color: '#222222',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#222222',
              textDecoration: 'underline',
            },
          }}
          onClick={() => {
            const minPrice = roundOffPrice(otherDetails?.lowestTotalRate, true);
            const maxPrice = roundOffPrice(otherDetails?.highestTotalRate);
            setValue([minPrice, maxPrice]);
            handleAction({
              filterId: `price-${minPrice},${maxPrice}`,
              action: 'UPDATE',
            });
          }}>
          Reset
        </Button>
      </Box>
      <AirbnbSlider
        getAriaLabel={() => 'Minimum distance shift'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(price) => {
          return (price / nightlyStays).toFixed(0);
        }}
        min={roundOffPrice(otherDetails?.lowestTotalRate, true)}
        max={roundOffPrice(otherDetails?.highestTotalRate)}
        onChangeCommitted={handleMouseUp}
      />
      <Box display="flex" justifyContent="space-between" pt={2}>
        <BasicButton
          disableRipple
          // startIcon="$"
          // id={`recommendations-filter-pill-${subCategoryId}`}
          className="recommendations-filter-pill">
          {currencyFormatter.format(value[0] / nightlyStays)}
        </BasicButton>
        <BasicButton
          disableRipple
          // startIcon="$"
          // id={`recommendations-filter-pill-${subCategoryId}`}
          className="recommendations-filter-pill">
          {currencyFormatter.format(value[1] / nightlyStays)}
        </BasicButton>
      </Box>
    </Box>
  );
}

export default PriceRangeSlider;
