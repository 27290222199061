/*

    This is a date range picker component over react-day-picker.
    With time we use this as our primary package and build over it.

*/

import {
  Button,
  Fade,
  Popper,
  ClickAwayListener,
  Paper,
  Select,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { useState, useRef } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { DayPicker, Day, useDayRender } from 'react-day-picker';
import { format, isSameDay } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { useDateUtils } from './DateUtils';
import { useItineraryDnd } from '../../organisms/ItineraryDndProvider';
import { parseISODate } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  overlayFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  resetBtn: {
    marginRight: 4,
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  limit: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '100%',
    color: '#FFF !important',
  },
  rangeMiddle: {
    borderRadius: '100%',
    backgroundColor: `${theme.palette.primary.extraLight} !important`,
  },
  dayContainerMiddle: {
    backgroundColor: `${theme.palette.primary.extraLight} !important`,
  },
  dayContainerStart: {
    background: `linear-gradient(to right, #FFF 50%, ${theme.palette.primary.extraLight} 50%)`,
  },
  dayContainerEnd: {
    background: `linear-gradient(to left, #FFF 50%, ${theme.palette.primary.extraLight} 50%)`,
  },
  calendarOverlay: {
    zIndex: 1121,
    backgroundColor: 'white',
    borderRadius: '4px',
  },
  calendarPaper: {
    padding: '16px 12px 8px 16px',
    '& .rdp': {
      margin: 0,
    },
  },
  cell: {
    color: '#000',
    fontSize: '0.75rem',
    height: 40,
    width: 40,
    padding: 0,
  },
}));

function CustomDropDown({ children, ...props }) {
  return (
    <div>
      <Select
        {...props}
        labelId={props['aria-label']}
        variant="standard"
        disableUnderline
        style={{
          padding: 4,
          position: 'relative',
        }}
        MenuProps={{
          anchorEl: this,
          disablePortal: true,
          style: {
            zIndex: 1221,
            height: 300,
            width: 200,
            position: 'absolute',
            marginTop: 16,
          },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}>
        {children?.map((option) => (
          <MenuItem key={option?.key} value={option?.props?.value}>
            {option?.props?.children}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

function DateRangePicker({
  open,
  anchorEl,
  handleClose,
  defaultRange,
  placement = 'bottom-start',
  // check to toggle disabled min-max ranges
  shouldDisableRangeContext = false,
  popperProps = {},
  numberOfMonths = 1,
  isBookings = false,
  onDateUpdate,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [range, setRange] = useState(defaultRange);
  const [firstClick, setFirstClick] = useState(true);

  const dateUtils = !isBookings && useDateUtils();

  const { tripStartDate = null } =
    isBookings || shouldDisableRangeContext ? {} : useItineraryDnd();

  const sameDay = range?.from && range?.to && isSameDay(range?.to, range?.from);
  const today = new Date();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDayClick = (date) => {
    if (firstClick) {
      setRange({
        from: date,
        to: null,
      });
      onDateUpdate({
        from: date,
        to: null,
      });
      setFirstClick(false);
    } else if (date < range.from) {
      setRange({
        from: date,
        to: null,
      });
      onDateUpdate({
        from: date,
        to: null,
      });
      setFirstClick(false);
    } else if (date > range.from) {
      setRange({
        ...range,
        to: date,
      });
      onDateUpdate({
        ...range,
        to: date,
      });
      setFirstClick(true);
    } else {
      setRange({ from: date, to: null });
      onDateUpdate({ from: date, to: null });
      setFirstClick(false);
    }
  };

  return (
    open && (
      <Popper
        role="presentation"
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ]}
        className={classes.calendarOverlay}
        transition
        {...popperProps}>
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={(e) => {
              if (anchorEl?.contains(e.target)) {
                return;
              }
              handleClose(range);
            }}>
            <Fade {...TransitionProps} timeout={600} in>
              <Paper elevation={8} className={classes.calendarPaper}>
                <DayPicker
                  numberOfMonths={isMobile ? 1 : numberOfMonths}
                  disabled={isBookings ? { before: today } : false}
                  captionLayout="dropdown"
                  selected={range}
                  mode="range"
                  defaultMonth={
                    defaultRange?.from
                      ? parseISODate(defaultRange?.from)
                      : isBookings
                      ? range.from
                      : dateUtils?.arrivalDate
                      ? parseISODate(dateUtils?.arrivalDate)
                      : tripStartDate
                      ? parseISODate(tripStartDate)
                      : new Date()
                  }
                  toYear={2050}
                  onDayClick={handleDayClick}
                  components={{
                    Dropdown: CustomDropDown,
                    Day: (props) => {
                      const dayRender = useDayRender(
                        props.date,
                        props.displayMonth,
                        useRef()
                      );
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          className={
                            dayRender?.activeModifiers?.range_middle
                              ? classes.dayContainerMiddle
                              : dayRender?.activeModifiers?.range_start
                              ? (range?.to &&
                                  !sameDay &&
                                  classes.dayContainerStart) ||
                                ''
                              : dayRender?.activeModifiers?.range_end
                              ? (!sameDay && classes.dayContainerEnd) || ''
                              : ''
                          }>
                          <Day {...props} />
                        </div>
                      );
                    },
                  }}
                  modifiersClassNames={{
                    selected: classes.selected,
                    range_start: classes.limit,
                    range_end: classes.limit,
                    range_middle: classes.rangeMiddle,
                  }}
                  formatters={{
                    formatWeekdayName: (weekday, options) => {
                      return format(weekday, 'ccccc', options);
                    },
                  }}
                  classNames={{
                    cell: classes.cell,
                  }}
                  styles={{
                    caption_label: {
                      fontSize: 16,
                      fontWeight: 400,
                      border: 0,
                      padding: '0px 8px',
                    },
                    dropdown_year: {
                      cursor: 'pointer',
                    },
                    dropdown_month: {
                      cursor: 'pointer',
                    },
                    caption: {
                      marginBottom: 8,
                    },
                    table: {
                      minHeight: 276,
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0px 4px',
                    },
                    head_row: {
                      display: 'flex',
                    },
                    day: {
                      height: 36,
                      width: 36,
                    },
                    head_cell: {
                      width: 36,
                      height: 36,
                      margin: '0px 2px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#4E4E4E',
                      opacity: 0.6,
                      fontWeight: 100,
                    },
                    nav_button_previous: {
                      height: 24,
                      width: 24,
                      padding: 4,
                      marginRight: 24,
                    },
                    nav_button_next: {
                      height: 24,
                      width: 24,
                      padding: 4,
                    },
                  }}
                />
                <div className={classes.overlayFooter}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      const newRange = {
                        from: isBookings
                          ? new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)
                          : null,
                        to: isBookings
                          ? new Date(Date.now() + 4 * 24 * 60 * 60 * 1000)
                          : null,
                      };
                      setRange(newRange);
                      onDateUpdate(newRange);
                    }}
                    className={classes.resetBtn}>
                    Reset
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose(range);
                    }}>
                    {isBookings ? 'Update' : 'Done'}
                  </Button>
                </div>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    )
  );
}

export default DateRangePicker;
