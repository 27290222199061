import { makeStyles, styled } from '@mui/styles';
import { ChevronDownIcon, ChevronUpIcon } from '@iconicicons/react';
import { Box, Grid, Typography, TextField, Collapse } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '../../../../redux/actions';
import { EVENTS, phTrackEvent } from '../../../../analytics';

const useStyles = makeStyles(() => ({
  box: {
    border: '1px solidrgb(116, 93, 93)',
    borderRadius: 8,
    overflow: 'hidden',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    color: '#8A8A8A',
    fontSize: 14,
  },
  '& .MuiInputLabel-shrink': {
    color: theme.palette.text.secondary,
    fontSize: 16,
  },
  '& .MuiInputLabel-shrink.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .MuiInputBase-input': {
    padding: '14px 16px',
  },
  '& .MuiInputBase-root': {
    '& fieldset': {
      borderColor: '#D9D9D9',
    },
  },
}));

function BasicInfo({ guestDetails, handleUpdateGuestInfo }) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <StyledTextField
          fullWidth
          label="First Name"
          variant="outlined"
          placeholder="John"
          name="firstName"
          value={guestDetails.firstName}
          onChange={handleUpdateGuestInfo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StyledTextField
          fullWidth
          label="Last Name"
          variant="outlined"
          placeholder="Doe"
          name="lastName"
          value={guestDetails.lastName}
          onChange={handleUpdateGuestInfo}
        />
      </Grid>
    </>
  );
}

function PrimaryGuestInfo({ captureAllInfo, title, index }) {
  const classes = useStyles();
  const user = useSelector((state) => state.Auth.userData?.user);
  const [isCollapsed, setIsCollapsed] = useState(index !== 0);

  const { slug: hotelId } = useParams();
  const dispatch = useDispatch();

  const selectedPackage = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelId]?.packages?.find(
      (p) => p?.packageId === state.Bookings.cart.packages[0]
    )
  );
  const roomId = selectedPackage?.rooms[0]?.id;
  const cartGuests = useSelector((state) =>
    Object.values(state.Bookings.cart.guests)
  );

  const guestDetails = cartGuests[index] || {
    id: index === 0 ? user?.id : `${user?.id}-${index}`,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
  };

  const events = {
    firstName: EVENTS.CHECKOUT_GUEST_INFO.FIRST_NAME_UPDATE,
    lastName: EVENTS.CHECKOUT_GUEST_INFO.LAST_NAME_UPDATE,
    email: EVENTS.CHECKOUT_GUEST_INFO.EMAIL_UPDATE,
    phoneNumber: EVENTS.CHECKOUT_GUEST_INFO.PHONE_NUMBER_UPDATE,
  };

  const handleUpdateGuestInfo = (e) => {
    phTrackEvent({
      event: events[e.target.name],
    });
    dispatch(
      actions.Bookings.updateCartGuest({
        ...guestDetails,
        [e.target.name]: e.target.value,
        assignedRoomId: roomId,
        isLeadUser: index === 0,
        type: 0,
      })
    );
  };

  const handleUpdatePhoneNumber = (e) => {
    const updatedPhoneNumber = e.target.value;
    const regex = /^[+]*[0-9]*$/;

    if (regex.test(updatedPhoneNumber)) {
      phTrackEvent({
        event: EVENTS.CHECKOUT_GUEST_INFO.PHONE_NUMBER_UPDATE,
      });
      dispatch(
        actions.Bookings.setDetailToAllGuests({
          phoneNumber: updatedPhoneNumber,
        })
      );
    }
  };

  const CollapseIcon = isCollapsed ? ChevronDownIcon : ChevronUpIcon;
  return (
    <Box className={classes.box} sx={{ p: 3, width: '100%' }}>
      <Box
        sx={{ mb: isCollapsed ? 0 : 2, display: 'flex', alignItems: 'center' }}>
        <Typography variant="h2" flexGrow={1}>
          {title} <span style={{ color: 'red' }}>*</span>
        </Typography>
        <CollapseIcon
          style={{
            cursor: 'pointer',
            height: 32,
            width: 32,
          }}
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </Box>
      <Collapse in={!isCollapsed}>
        <Grid container spacing={2}>
          <BasicInfo
            guestDetails={guestDetails}
            handleUpdateGuestInfo={handleUpdateGuestInfo}
          />
          {captureAllInfo && (
            <>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  placeholder="johndoe@pilotplans.com"
                  name="email"
                  value={guestDetails.email}
                  onChange={handleUpdateGuestInfo}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  label="Phone number"
                  variant="outlined"
                  placeholder="+1 123 4567"
                  name="phoneNumber"
                  type="tel"
                  autoFocus
                  value={guestDetails.phoneNumber}
                  onChange={handleUpdatePhoneNumber}
                  inputProps={{
                    maxLength: 15,
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      '& fieldset': {
                        borderColor:
                          guestDetails.phoneNumber === '' ||
                          !guestDetails.phoneNumber
                            ? '#ED702E !important'
                            : '#D9D9D9',
                      },
                    },
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Collapse>
    </Box>
  );
}

export default PrimaryGuestInfo;
