import React, { useState, useEffect, Fragment } from 'react';

import {
  Tooltip,
  Fade,
  IconButton,
  Box,
  Divider,
  Popper,
  ClickAwayListener,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import {
  ArrowDropDownRounded,
  CheckRounded,
  DeleteOutline,
} from '@mui/icons-material';
import {
  updateMapPin,
  deleteMapPin,
  createMapPin,
} from '../../../redux/slices/MapV2';
import { useMapUtils } from '../organisms/Map';
import { PIN_ICONS, COLORS, getCategoryIdFromTypes } from '../../../utils';
import { useSounds, SOUNDS } from '../../../sounds';

const usePinToMapIconStyles = makeStyles(() => ({
  pinIconWrapper: {
    backgroundColor: '#FFF1E0',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px #FFA766',
    },
  },
  iconButton: {
    padding: '4px 8px',
    color: '#ED702E',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
  tooltip: {
    backgroundColor: 'rgba(78, 78, 78, 1)',
    border: 'none',
    fontSize: 12,
  },
  tooltipArrow: {
    border: 'none',
    color: 'rgba(78, 78, 78, 1)',
  },
  selectorPins: {
    background: '#FFF',
    borderRadius: 4,
    boxShadow: '0 0 0 1px #FFA766',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '6px 8px',
  },
  selectorPinButton: {
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
    padding: '6px 8px',
  },
}));

function ColorSelector({
  selectedColor = null,
  handlePinColorSelect = () => {},
  isCommandBarEnabled = false,
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        paddingTop: 3,
        paddingBottom: isCommandBarEnabled ? 3 : 0,
      }}>
      {COLORS.map((color) => (
        <div
          onClick={() => {
            handlePinColorSelect(color);
          }}
          style={{
            height: 16,
            width: 16,
            borderRadius: '50%',
            backgroundColor: color,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {selectedColor && selectedColor === color ? (
            <CheckRounded sx={{ height: 10, width: 12, fill: '#FFF' }} />
          ) : null}
        </div>
      ))}
    </div>
  );
}

function DeleteMapPinButton({
  placePinOnMap,
  handleDeleteMapPin,
  locationId,
  setDeleteModalOpen = () => {},
  isCommandBarEnabled,
  setAnchorEl,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#ED702E',
      }}
      onClick={() => {
        setAnchorEl(null);
        if (isCommandBarEnabled) {
          if (locationId) {
            setDeleteModalOpen(true);
          } else {
            handleDeleteMapPin();
          }
        } else {
          placePinOnMap();
        }
      }}>
      <DeleteOutline sx={{ marginRight: '0.5px', fontSize: '20px' }} />
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: '500',
        }}>
        Delete map pin
      </Typography>
    </Box>
  );
}

function MapPinCustomizerMenu({
  anchorEl,
  handleUpdate,
  handleClose = () => {},
  selectedColor = null,
  selectedIcon = null,
  isCommandBarEnabled = false,
  placePinOnMap = () => {},
  handleDeleteMapPin = () => {},
  locationId,
  setDeleteModalOpen = () => {},
  setAnchorEl = () => {},
}) {
  const classes = usePinToMapIconStyles();

  const handlePinIconSelect = async (pinIconId) => {
    handleUpdate({
      categoryId: pinIconId,
    });
  };

  function CategoryRow({ data }) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {data.map((pinIcon, i) => (
          <Fragment key={i}>
            {i !== 0 && (
              <Divider
                orientation="vertical"
                textAlign="center"
                sx={{ borderColor: '#F4F4F4', height: 12, width: '1px' }}
              />
            )}
            <IconButton
              disableFocusRipple
              disableRipple
              className={classes.selectorPinButton}
              onClick={() => handlePinIconSelect(pinIcon.id)}
              id={`map-pin-${pinIcon.id}`}>
              <pinIcon.icon
                sx={{
                  height: 16,
                  width: 16,
                  fill: selectedIcon === pinIcon.id ? selectedColor : '#8A8A8A',
                }}
              />
            </IconButton>
          </Fragment>
        ))}
      </div>
    );
  }

  const pinIcons = Object.values(PIN_ICONS);
  return (
    <Popper
      id="map-pins-popper"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      sx={{ zIndex: 9999 }}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box className={classes.selectorPins}>
          <CategoryRow data={pinIcons.slice(0, 5)} />
          <CategoryRow data={pinIcons.slice(5)} />
          <Divider
            sx={{ width: '100%', borderColor: '#F4F4F4', margin: '2px' }}
          />
          <ColorSelector
            selectedColor={selectedColor}
            handlePinColorSelect={(color) => handleUpdate({ pinColor: color })}
            isCommandBarEnabled={isCommandBarEnabled}
          />
          {isCommandBarEnabled && (
            <>
              <Divider
                sx={{ width: '100%', borderColor: '#F4F4F4', margin: '2px' }}
              />
              <DeleteMapPinButton
                placePinOnMap={placePinOnMap}
                handleDeleteMapPin={handleDeleteMapPin}
                locationId={locationId}
                setDeleteModalOpen={setDeleteModalOpen}
                isCommandBarEnabled={isCommandBarEnabled}
                setAnchorEl={setAnchorEl}
              />
            </>
          )}
        </Box>
      </ClickAwayListener>
    </Popper>
  );
}

function PinToMapIcon({
  mapPinDetails,
  pinned = false,
  tripId,
  pins,
  isAdded = false,
  isCommandBarEnabled = false,
  handleDeleteMapPin = () => {},
  locationId,
  setDeleteModalOpen = () => {},
}) {
  const classes = usePinToMapIconStyles();
  const { playSound } = useSounds();

  const dispatch = useDispatch();
  const [active, setActive] = useState(pinned);
  const [currPin, setCurrPin] = useState(mapPinDetails?.id);
  const [anchorEl, setAnchorEl] = useState(null);

  const { setMarker, setPlace } = useMapUtils();

  useEffect(() => {
    if (active !== pinned || currPin !== mapPinDetails?.id) {
      setActive(pinned);
      setCurrPin(mapPinDetails?.id);
    }
  }, [pinned]);

  // function to check for pins that exist with the same properties.
  const findPin = ({ lat, long, type = 'Custom' }) => {
    let id;
    pins.forEach((pin) => {
      if (pin?.lat === lat && pin?.long === long && pin?.type === type)
        id = pin?.id;
    });
    return id;
  };

  const createNewTripMapPin = async (pinDetails = {}) => {
    const checkId = findPin(mapPinDetails);
    if (checkId) {
      return;
    }
    playSound(SOUNDS.softPop);
    const mapPinData = await dispatch(
      createMapPin({
        variables: {
          lat: mapPinDetails?.lat,
          long: mapPinDetails?.long,
          type: mapPinDetails?.type,
          placeId: mapPinDetails?.placeId,
          pinColor: mapPinDetails?.pinColor,
          tripId,
          parentId: tripId,
          categoryId:
            mapPinDetails?.categoryId ||
            (mapPinDetails?.type === 'ACCOMMODATION'
              ? 'stay'
              : getCategoryIdFromTypes(mapPinDetails?.pinDetails?.types)),
          ...pinDetails,
          pinDetails: {
            title: mapPinDetails?.pinDetails?.title,
            photo: mapPinDetails?.pinDetails?.photo || '',
            rating: mapPinDetails?.pinDetails?.rating,
            ratingCount: mapPinDetails?.pinDetails?.ratingCount,
            types: mapPinDetails?.pinDetails?.types,
            website: mapPinDetails?.pinDetails?.website,
            mapUrl: mapPinDetails?.pinDetails?.maps,
          },
        },
      })
    );
    setMarker(null);
    setActive(true);
    setPlace((val) => ({
      ...val,
      pinned: true,
      pinId: mapPinData?.payload?.createMapPin?.id,
      categoryId: mapPinData?.payload?.createMapPin?.categoryId,
    }));
    setCurrPin(mapPinData?.payload?.createMapPin?.id);
  };

  const placePinOnMap = async () => {
    // add logic to Pin to the Map
    if (active) {
      let deleteId;
      if (mapPinDetails.id && currPin === mapPinDetails?.id) {
        deleteId = mapPinDetails.id;
      } else {
        deleteId = findPin(mapPinDetails);
      }
      if (deleteId) {
        dispatch(
          deleteMapPin({
            context: {
              tripId,
            },
            variables: {
              id: deleteId,
            },
          })
        );
      }
      setActive(false);
      setCurrPin(null);
      setPlace((val) => ({
        ...val,
        pinId: null,
        categoryId: null,
      }));
    } else {
      await createNewTripMapPin();
    }
  };

  const handlePinUpdate = async (pinProps = {}) => {
    if (Object.keys(pinProps || {}).length === 0) return;

    // if pin already exists
    if (active) {
      setPlace((val) => ({
        ...val,
        ...pinProps,
      }));
      await dispatch(
        updateMapPin({
          context: {
            tripId,
          },
          variables: {
            id: mapPinDetails.id,
            ...pinProps,
          },
        })
      );
    } else {
      await createNewTripMapPin({
        ...pinProps,
      });
    }
  };

  function CustomMapPinIcon() {
    const CustomIcon = active
      ? PIN_ICONS[mapPinDetails?.categoryId || 'default']?.icon
      : PIN_ICONS[mapPinDetails?.categoryId || 'default']?.outlinedIcon;
    return (
      <CustomIcon
        sx={{
          height: 16,
          width: 16,
          fill: 'currentColor',
          '& > path': {
            strokeWidth: 1.5,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          },
        }}
      />
    );
  }

  return (
    <Box className={classes.pinIconWrapper} id="map-pins">
      {isCommandBarEnabled ? (
        isAdded && (
          <>
            <IconButton
              disableFocusRipple
              disableRipple
              className={classes.iconButton}
              sx={{ borderRadius: '0 4px 4px 0' }}
              onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
              id="map-pins-selector">
              <CustomMapPinIcon />
              <ArrowDropDownRounded
                sx={{
                  height: 20,
                  width: 20,
                  margin: '0 4% 0 4%',
                }}
              />
            </IconButton>
            {anchorEl && (
              <MapPinCustomizerMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleUpdate={handlePinUpdate}
                handleClose={() => setAnchorEl(null)}
                selectedIcon={mapPinDetails?.categoryId || 'default'}
                selectedColor={mapPinDetails?.pinColor || '#ED702E'}
                placePinOnMap={placePinOnMap}
                handleDeleteMapPin={handleDeleteMapPin}
                locationId={locationId}
                setDeleteModalOpen={setDeleteModalOpen}
                isCommandBarEnabled={isCommandBarEnabled}
              />
            )}
          </>
        )
      ) : (
        <>
          <Tooltip
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
            placement="top"
            arrow
            title={active ? 'Delete pin' : 'Pin to the map'}
            enterDelay={500}
            disableInteractive
            TransitionComponent={Fade}>
            <IconButton
              onClick={placePinOnMap}
              className={classes.iconButton}
              disableFocusRipple
              disableRipple
              size="large"
              sx={{ borderRadius: '4px 0 0 4px' }}
              id="add-pin-button">
              <CustomMapPinIcon />
            </IconButton>
          </Tooltip>

          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ background: '#FFA766' }}
          />
          <IconButton
            disableFocusRipple
            disableRipple
            className={classes.iconButton}
            sx={{ borderRadius: '0 4px 4px 0' }}
            onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
            id="map-pins-selector">
            <ArrowDropDownRounded
              sx={{
                height: 20,
                width: 20,
                margin: '0 4% 0 0%',
              }}
            />
          </IconButton>
          <MapPinCustomizerMenu
            anchorEl={anchorEl}
            handleUpdate={handlePinUpdate}
            handleClose={() => setAnchorEl(null)}
            selectedIcon={mapPinDetails?.categoryId || 'default'}
            selectedColor={mapPinDetails?.pinColor || '#ED702E'}
          />
        </>
      )}
    </Box>
  );
}

export default PinToMapIcon;
