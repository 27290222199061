import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid, InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OutlinedFlagRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useMapUtils } from '../Map';
import { PlacesSearchBar } from '../../molecules/SearchBar';
import { useSounds, SOUNDS } from '../../../../sounds';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import { createItem } from '../../../../redux/slices/Item';
import ITEM_TYPES from '../../../../const';
import { getLocalItemId } from '../../../../utils';

const useTodoItemStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D9D9D9',
    borderRadius: 4,
    display: 'flex',
    padding: '3px 0.5%',
    margin: '0px 0% 0% 1%',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    cursor: 'pointer',
  },
  activityIcon: ({
    isActivityHovered,
    isActivityFocused,
    isActivityHighlight,
  }) => ({
    color: isActivityFocused
      ? palette.primary.main
      : isActivityHovered || isActivityHighlight
      ? palette.primary.light
      : '#222',
  }),
  inputContainer: {
    display: 'flex',
    flex: 1,
    marginLeft: '8px',
  },
  hovered: {
    border: `1px solid ${palette.primary.light}`,
  },
  focused: {
    border: `1px solid ${palette.primary.main}`,
  },
  newTodoItem: {
    marginLeft: 'calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px + 8px)',
    },
  },
}));

export function EmptyActivity({ parentId, shouldCreateParent }) {
  const [title, setTitle] = useState('');
  const { slug: tripId } = useParams();
  const { playSound } = useSounds();
  const loading = useSelector((state) => state.Activity.placeholderLoading);
  const [enterTrigger, setEnterTrigger] = useState(false);
  const [isActivityFocused, setActivityFocused] = useState(false);
  const [isActivityHovered, setActivityHovered] = useState(false);
  const inputRef = useRef();
  const buttonRef = useRef();
  const { handleLocationSelect } = useMapUtils();
  const classes = useTodoItemStyles({ isActivityHovered, isActivityFocused });
  const dispatch = useDispatch();

  const INTERACTION_TYPES = {
    SELECT: 'Select',
    BLUR: 'Blur',
    ENTER: 'Enter',
  };
  const delayRef = useRef();
  const timeRef = useRef(null);
  useEffect(() => {
    return () => {
      clearTimeout(timeRef.current);
    };
  }, []);

  const createActivityInSection = async (
    { title: activityTitle, mapPin, ...activityProps },
    interactionType
  ) => {
    if (!activityTitle) {
      return;
    }
    let headingId = parentId;
    if (shouldCreateParent) {
      const heading = await dispatch(
        createItem({
          variables: {
            title: '',
            type: ITEM_TYPES.HEADING,
            parentId,
            tripId,
            index: 0,
          },
          tripId,
          parentId,
          index: 0,
        })
      );
      headingId = heading?.payload?.createItem?.id;
    }
    if (activityTitle === '') return;
    const localActivity = {
      id: getLocalItemId(ITEM_TYPES.ACTIVITY),
      title: '',
      type: ITEM_TYPES.ACTIVITY,
    };
    dispatch(
      createItem({
        variables: {
          title: activityTitle,
          type: ITEM_TYPES.ACTIVITY,
          parentId: headingId,
          tripId,
          mapPin: mapPin?.id,
          index: 0,
          content: {
            ...(activityProps?.city && {
              address: {
                streetAddress: activityProps?.streetAddress,
                city: activityProps?.city,
                state: activityProps?.state,
                country: activityProps?.country,
                zipCode: activityProps?.zipCode,
              },
            }),
          },
        },
        tripId,
        parentId: headingId,
        index: 0,
        shouldAppendItem:
          interactionType === INTERACTION_TYPES.ENTER ||
          interactionType === INTERACTION_TYPES.SELECT,
        localItem: localActivity,
      })
    );
    phTrackEvent({
      event: EVENTS.PLAN_ACTIVITY.ADD_START,
    });
  };

  return (
    <div className={classes.newTodoItem}>
      <Grid
        className={`
      ${classes.root}
      ${
        isActivityFocused
          ? classes.focused
          : isActivityHovered
          ? classes.hovered
          : null
      }
      `}
        onMouseEnter={() => setActivityHovered(true)}
        onMouseLeave={() => setActivityHovered(false)}>
        <OutlinedFlagRounded
          fontSize="small"
          className={classes.activityIcon}
        />
        <Grid item className={classes.inputContainer}>
          <PlacesSearchBar
            isActive={isActivityFocused && title?.length > 2}
            tripLocation={false}
            popperPlacement="bottom"
            CustomSearchBar={InputBase}
            inputRef={inputRef}
            handleSelect={async (option) => {
              playSound(SOUNDS.softPop);
              const location = await handleLocationSelect(
                option.place_id,
                undefined,
                'ACTIVITY'
              );
              setEnterTrigger(true);
              if (!delayRef.current) {
                await createActivityInSection(
                  location,
                  INTERACTION_TYPES.SELECT
                );
              } else {
                delayRef.current = null;
              }
              inputRef?.current?.blur();
            }}
            value={title}
            onChange={(newTitle) => setTitle(newTitle)}
            searchBarProps={{
              autoComplete: 'off',
              placeholder: 'new activity',
              onFocus: () => {
                setActivityFocused(true);
              },
              onBlur: async (e) => {
                if (
                  buttonRef.current &&
                  buttonRef.current.contains(e.relatedTarget)
                ) {
                  return;
                }
                timeRef.current = setTimeout(async () => {
                  if (!enterTrigger) {
                    setActivityFocused(false);
                    setActivityHovered(false);
                    createActivityInSection({ title }, INTERACTION_TYPES.BLUR);
                  } else {
                    setEnterTrigger(false);
                  }
                }, 250);
              },
              onKeyUp: async (e) => {
                if (e.key === 'Enter') {
                  setEnterTrigger(true);
                  setActivityFocused(false);
                  setActivityHovered(false);
                  delayRef.current = true;
                  createActivityInSection({ title }, INTERACTION_TYPES.ENTER);
                }
              },
              onClick: () => {
                if (title !== '') {
                  setActivityFocused(false);
                  setActivityHovered(false);
                }
              },
              inputProps: {
                style: { padding: 3, cursor: 'pointer' },
                sx: {
                  '&::placeholder': {
                    color: '#8A8A8A',
                    opacity: isActivityFocused ? 'auto' : 1,
                  },
                },
              },
            }}
            name="activityTitle"
            {...(loading === parentId
              ? {
                  endAdornment: (
                    <CircularProgress size={16} style={{ marginRight: 4 }} />
                  ),
                }
              : {})}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default EmptyActivity;
