import { useEffect } from 'react';
import { Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, getUserV2 } from '../../redux/slices/Auth';
import { ChatWindow } from '../pages/Messages';

const useMessengerBladeStyles = makeStyles((theme) => ({
  messengerBlade: {
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
  },
  chatContainer: {
    position: 'absolute',
    height: '50%',
    maxHeight: '520px',

    // intercom bubble has a zIndex just lower than this
    zIndex: 2147483003,
    bottom: 0,
    right: 24,
    transition: 'all 300ms ease-in-out',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px 8px 0px 0px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      right: 0,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '35%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '28%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '24%',
    },
  },
}));

function MessengerBlade({
  openMessenger,
  setOpenMessenger,
  chatId,
  tripName,
  tripUsers,
}) {
  const classes = useMessengerBladeStyles();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.Auth.firebaseUser.uid);
  const userObj = useSelector((state) => state.Auth.userData);

  useEffect(() => {
    if (userObj.status === 'IDLE') {
      dispatch(getUser({ id: userId }));
      dispatch(getUserV2({ id: userId }));
    }
  }, []);

  if (userObj.status === 'LOADING') return <p>Loading..</p>;
  if (userObj.error) return <p>some error occured..</p>;

  return (
    <div style={{ display: openMessenger ? 'block' : 'none' }}>
      <Fade in={openMessenger}>
        <div className={classes.chatContainer}>
          <ChatWindow
            user={userObj.user}
            chat={{
              id: chatId,
              chatName: tripName || 'Messenger',
              isTrip: true,
              members: tripUsers,
            }}
            handleClose={() => setOpenMessenger(false)}
          />
        </div>
      </Fade>
    </div>
  );
}

export default MessengerBlade;
