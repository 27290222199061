import TransportationItem from '../Transportation';

function Transportation({ itemId, parentId, index, ...props }) {
  return (
    <TransportationItem
      update
      transportId={itemId}
      parentId={parentId}
      index={index}
      {...props}
      // dragHandleProps={draggableProvided.dragHandleProps}
      // handleAddTransportation={(type) => {
      //   ref.current.transportationType = type;
      //   setOpenFlightBlade(index);
      // }}
      // isDragging={draggableSnapshot.isDragging}
    />
  );
}

export default Transportation;
