import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ITEM_TYPES from '../../../../const';

function Spacer({ itemId, prevItemId, isTripItem, isFirstIndex }) {
  const { slug: tripId } = useParams();
  const { itemType, prevItemType } = useSelector((state) => ({
    itemType: state.Item.items[itemId]?.type,
    prevItemType: state.Item.items[prevItemId]?.type,
  }));

  const getItemTopSpace = () => {
    if (prevItemId === tripId) {
      return 0;
    }

    switch (itemType) {
      case ITEM_TYPES.ACCOMMODATION:
        return prevItemType === ITEM_TYPES.ACCOMMODATION ? 8 : 28;
      case ITEM_TYPES.ACTIVITY:
        return isTripItem
          ? 24
          : [ITEM_TYPES.ACTIVITY, ITEM_TYPES.HEADING]?.includes(prevItemType)
          ? 8
          : 28;
      case ITEM_TYPES.DESTINATION:
        return isTripItem ? 36 : prevItemType === ITEM_TYPES.ACTIVITY ? 10 : 0;
      case ITEM_TYPES.TRANSPORTATION:
        return isTripItem ? 36 : 24;
      case ITEM_TYPES.HEADING:
        return prevItemType === ITEM_TYPES.DESTINATION ? 8 : 28;
      default:
        return 0;
    }
  };

  const computedTopSpace = useMemo(
    () => getItemTopSpace() + (isFirstIndex ? 12 : 0),
    [itemType, prevItemType]
  );

  return <div style={{ minHeight: computedTopSpace }} />;
}

export default Spacer;
