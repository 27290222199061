import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import QUERY from '../../V3/graphql/queries';
import graphqlClient from '../../V3/graphql';
import ITEM_TYPES, { DROP_TYPES, TRANSPORT_TYPES } from '../../const';
import { getAsyncThunkV2 } from '../helpers';
import { EVENTS, phTrackEvent } from '../../analytics';

function getMapinsFromItemsRecursively(items, itemId, deleteMapPinsIds) {
  if (!items[itemId]) return deleteMapPinsIds;

  const mapPinIds = [];

  const item = items[itemId];

  if (item.mapPin) {
    mapPinIds.push({ mapPinId: item.mapPin, itemId });
  }

  if (Array.isArray(item.children) && item.children.length > 0) {
    item.children.forEach((childId) => {
      mapPinIds.push(...getMapinsFromItemsRecursively(items, childId, []));
    });
  }

  return [...deleteMapPinsIds, ...mapPinIds];
}

export const isLocalItem = (itemId, itemType) =>
  typeof itemId === 'string' && itemId?.startsWith(`local-${itemType}`);

const initialState = {
  items: {},
  newItemId: null,

  // TODO: Remove this after migrating hotel to createLocalItem logic
  openItemSearchbar: false,
  openItemSearchbarIndex: null,
};

export const getAllItems = createAsyncThunk(
  'ITEM/getAllItems',
  async (payload, { rejectWithValue }) => {
    const { data, error } = await graphqlClient.query({
      query: QUERY.GET_ALL_ITEMS,
      variables: payload?.variables,
    });
    if (error) rejectWithValue(error);
    return data;
  }
);

export const createItem = createAsyncThunk(
  'ITEM/createItem',
  async (payload, { rejectWithValue }) => {
    const { data, error } = await graphqlClient.mutate({
      mutation: QUERY.CREATE_ITEM,
      variables: payload?.variables,
    });
    if (error) rejectWithValue(error);
    return data;
  }
);

export const updateItem = createAsyncThunk(
  'ITEM/updateItem',
  async (payload, { rejectWithValue }) => {
    const { data, error } = await graphqlClient.mutate({
      mutation: QUERY.UPDATE_ITEM,
      variables: payload?.variables,
    });
    if (error) rejectWithValue(error);
    return data;
  }
);

export const deleteItem = createAsyncThunk(
  'ITEM/deleteItem',
  async (payload, { rejectWithValue, getState }) => {
    if (
      isLocalItem(payload?.variables?.id, ITEM_TYPES.ACTIVITY) ||
      isLocalItem(payload?.variables?.id, ITEM_TYPES.TRANSPORT) ||
      isLocalItem(payload?.variables?.id, ITEM_TYPES.DESTINATION) ||
      isLocalItem(payload?.variables?.id, ITEM_TYPES.HEADING) ||
      isLocalItem(payload?.variables?.id, ITEM_TYPES.ACCOMMODATION)
    ) {
      return null;
    }

    const { items } = getState().Item;
    const mapPinsToDelete = getMapinsFromItemsRecursively(
      items,
      payload?.variables?.id,
      []
    );

    const { data, error } = await graphqlClient.mutate({
      mutation: QUERY.DELETE_ITEM,
      variables: payload?.variables,
    });
    if (error) rejectWithValue(error);
    return { data, mapPinsToDelete, tripId: payload?.variables?.tripId };
  }
);

export const moveItem = getAsyncThunkV2('ITEM/moveItem', QUERY.MOVE_ITEM);

const ItemSlice = createSlice({
  name: 'ITEM',
  initialState,
  reducers: {
    updateItems: (state, { payload }) => {
      state.items = {
        ...state.items,
        ...payload,
      };
    },
    updateFileAttachments: (
      state,
      { payload: { id, removeFromItems, addToItems } }
    ) => {
      addToItems?.forEach((itemId) => {
        state.items = {
          ...state.items,
          [itemId]: {
            ...state.items[itemId],
            files: [...(state.items[itemId]?.files || []), id],
          },
        };
      });
      removeFromItems?.forEach((itemId) => {
        state.items = {
          ...state.items,
          [itemId]: {
            ...state.items[itemId],
            files: state.items[itemId]?.files?.filter((file) => file !== id),
          },
        };
      });
    },
    setOpenItemSearchbar: (state, { payload }) => {
      const { id, index } = payload;
      state.openItemSearchbar = id || null;
      state.openItemSearchbarIndex = index || null;
    },
    removeFileFromItem: (state, { payload: { itemId, fileId } }) => {
      state.items[itemId].files = state.items[itemId].files?.filter(
        (file) => file !== fileId
      );
    },
    setNewItemId: (state, { payload }) => {
      state.newItemId = payload;
    },
    createLocalItem: (
      state,
      {
        payload: {
          localId,
          item,
          parentId,
          index,
          tripId,
          ignoreParentUpdate,
          updateNewItemId,
        },
      }
    ) => {
      state.items[localId] = item;
      if (updateNewItemId) {
        state.newItemId = localId;
      }
      if (parentId !== tripId && !ignoreParentUpdate) {
        const currentChildren = state.items[parentId]?.children || [];
        state.items[parentId] = {
          ...state.items[parentId],
          children: [
            ...currentChildren.slice(0, index),
            localId,
            ...currentChildren.slice(index),
          ],
        };
      }
    },
    removeItemTemp: (state, action) => {
      const { itemId } = action.payload;
      if (itemId && state.items[itemId]) {
        state.items[itemId].tempRemove = true;
      }
    },
    addItemTemp: (state, action) => {
      const { itemId } = action.payload;
      if (itemId && state.items[itemId]) {
        delete state.items[itemId].tempRemove;
      }
    },
    deleteItemsByTripId: (state, action) => {
      const { tripId } = action.payload;
      Object.keys(state.items).forEach((itemId) => {
        if (state.items[itemId].tripId === tripId) {
          delete state.items[itemId];
        }
      });
    },
    deleteMapPinFromItem: (state, action) => {
      const { mapPinId } = action.payload;
      Object.keys(state.items).forEach((itemId) => {
        if (state.items[itemId].mapPin === mapPinId) {
          state.items[itemId].mapPin = null;
        }
      });
    },
  },
  extraReducers: {
    [createItem.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            localId,
            localItem,
            variables,
            shouldAppendItem,
            parentId,
            index,
            tripId,
            ...rest
          },
        },
        payload: {
          createItem: { id: itemId, mapPin, files, ...itemProps },
        },
      }
    ) => {
      if (localId) {
        delete state.items[localId];
      }
      state.items[itemId] = {
        ...(state.items[itemId] || {}),
        ...(variables || {}),
        ...(itemProps || {}),
        id: itemId,
        mapPin: mapPin?.id,
        files: files?.map((file) => file?.id),
      };

      if (shouldAppendItem && localItem?.id) {
        state.items = {
          ...state.items,
          [localItem?.id]: localItem,
        };
        state.newItemId = localItem?.id;
      }
      if (!variables?.ignoreParentUpdate && parentId !== tripId) {
        if (variables?.type === ITEM_TYPES.TRANSPORT) {
          const currentChildren =
            state.items[parentId]?.content?.connections?.filter(
              (childId) => childId !== localId
            ) || [];
          state.items[parentId] = {
            ...state.items[parentId],
            content: {
              ...state.items[parentId]?.content,
              connections: [
                ...currentChildren.slice(0, index),
                itemId,
                ...currentChildren.slice(index),
              ],
            },
          };
        } else {
          const currentChildren =
            state.items[parentId]?.children?.filter(
              (childId) => childId !== localId
            ) || [];
          state.items[parentId].children = [
            ...currentChildren.slice(0, index),
            itemId,
            ...(shouldAppendItem && localItem?.id ? [localItem?.id] : []),
            ...currentChildren.slice(index),
          ];
        }
      }
      try {
        // event tracking
        if (variables?.type === ITEM_TYPES.TRANSPORT && rest?.transportType) {
          const transportEvent =
            rest?.transportType === TRANSPORT_TYPES.FLIGHT
              ? EVENTS.PLAN_FLIGHT.ADD_CREATE
              : rest?.transportType === TRANSPORT_TYPES.BUS
              ? EVENTS.PLAN_BUS_TRAIN.ADD_CREATE
              : EVENTS.PLAN_OTHER_TRANSPORT.ADD_CREATE;
          phTrackEvent({
            event: transportEvent,
          });
        }
      } catch (error) {
        // handle error
      }
    },

    [moveItem.pending]: (
      state,
      {
        meta: {
          arg: {
            variables: {
              targetId,
              targetType,
              targetIndex,
              sourceId,
              sourceType,
              itemId,
            },
          },
        },
      }
    ) => {
      if (sourceType === DROP_TYPES.ITEM) {
        const currentChildren = state.items[sourceId]?.children || [];
        const updatedChildren = currentChildren?.filter(
          (childId) => childId !== itemId
        );
        state.items[sourceId] = {
          ...state.items[sourceId],
          children: updatedChildren,
        };
      }
      if (targetType === DROP_TYPES.ITEM) {
        const currentChildren = state.items[targetId]?.children || [];
        const updatedChildren = [
          ...currentChildren.slice(0, targetIndex),
          itemId,
          ...currentChildren.slice(targetIndex),
        ];
        state.items[targetId] = {
          ...state.items[targetId],
          children: updatedChildren,
        };
      }
    },

    [moveItem.rejected]: (
      state,
      {
        meta: {
          arg: {
            variables: { targetId, targetType, sourceId, sourceType, itemId },
            sourceIndex = 0,
          },
        },
      }
    ) => {
      if (targetType === DROP_TYPES.ITEM) {
        const currentChildren = state.items[targetId]?.children || [];
        const updatedChildren = currentChildren?.filter(
          (childId) => childId !== itemId
        );
        state.items[targetId] = {
          ...state.items[targetId],
          children: updatedChildren,
        };
      }
      if (sourceType === DROP_TYPES.ITEM) {
        const currentChildren = state.items[sourceId]?.children || [];
        const updatedChildren = [
          ...currentChildren.slice(0, sourceIndex),
          itemId,
          ...currentChildren.slice(sourceIndex),
        ];
        state.items[sourceId] = {
          ...state.items[sourceId],
          children: updatedChildren,
        };
      }
    },

    [getAllItems.pending]: (
      state,
      {
        meta: {
          arg: { id },
        },
      }
    ) => {
      state.items[id] = {
        ...state.items[id],
        status: 'LOADING',
      };
    },
    [getAllItems.fulfilled]: (
      state,
      {
        payload: {
          data: { getAllItems: items },
        },
      }
    ) => {
      items.forEach((item) => {
        state.items[item.id] = item;
      });
    },
    [deleteItem.pending]: (
      state,
      {
        meta: {
          arg: {
            variables: { id, parentId, tripId, type },
          },
        },
      }
    ) => {
      if (parentId !== tripId) {
        if (type === ITEM_TYPES.TRANSPORT) {
          state.items[parentId] = {
            ...state.items[parentId],
            content: {
              ...state.items[parentId]?.content,
              connections: state.items[parentId]?.content?.connections?.filter(
                (itemId) => itemId !== id
              ),
            },
          };
        } else {
          state.items[parentId] = {
            ...state.items[parentId],
            children: state.items[parentId]?.children?.filter(
              (itemId) => itemId !== id
            ),
          };
        }
      }
    },
    [deleteItem.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { id },
          },
        },
        payload,
      }
    ) => {
      if (payload) {
        const { mapPinsToDelete = [] } = payload;
        mapPinsToDelete?.forEach(({ itemId }) => {
          delete state.items[itemId];
        });
      }
      delete state.items[id];
    },
    [updateItem.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { id, ...updatedAttributes },
          },
        },
      }
    ) => {
      const { content, ...rest } = updatedAttributes;
      state.items[id] = {
        ...state.items[id],
        ...(rest || {}),
        content: {
          ...(state.items[id]?.content || {}),
          ...(content || {}),
        },
      };
    },
  },
});

export const ItemActions = ItemSlice.actions;
export const ItemReducer = ItemSlice.reducer;
