import { useState } from 'react';

import {
  Icon,
  ListItemText,
  Box,
  List,
  ListItem,
  Fade,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';

import {
  AddCircle,
  AddCircleOutline,
  Language,
  FlightTakeoff,
  DirectionsBus,
  DirectionsCar,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import classList from '../../../classList';
import actions from '../../../../redux/actions';
import { getLocalItemId } from '../../../../utils';
import ITEM_TYPES, { TRANSPORT_TYPES } from '../../../../const';
import { EVENTS, phTrackEvent } from '../../../../analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 4,
    border: `2px solid ${theme.palette.system.lightgrey}`,
    // height: '36px',
    width: '100%',
    padding: '4px',
    backgroundColor: 'white',
  },
  lineGrid: {
    width: '100%',
    flex: 1,
  },
  iconGrid: {
    display: 'flex',
    padding: '4px',
  },
  popover: {
    marginTop: 24,
  },
  icon: {
    color: theme.palette.text.light,
    marginRight: '5px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '10px',
    },
  },
  iconHovered: {
    color: theme.palette.primary.main,
    marginRight: '5px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '10px',
    },
  },
  listIcon: {
    display: 'flex',
    alignItems: 'center',
    width: '16px',
    height: '16px',
    marginRight: '8px',
    color: '#222222',
  },
  listItem: {
    height: '28px',
    padding: '4px 12px 4px 8px',
    borderRadius: '8px',
    margin: '0 4px',
    '&:hover': {
      cursor: 'pointer',
      '& $listIcon': {
        color: theme.palette.primary.main,
      },
      '& .MuiListItemText-primary': {
        color: theme.palette.primary.main,
      },
    },
  },
  spaced: {
    height: '36px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: (props) => (props.type === 'transportation' ? '15px' : '32px'),
    flexGrow: 1,
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginTop: '100px',
    zIndex: 100,
    width: '100%',
  },
  addModuleButton: {
    position: 'relative',
    display: 'flex',
    marginLeft: 'calc(3.6rem - 21px)',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem - 15px)',
    },
    justifyContent: 'flex-start',
  },
}));

function AddModuleMenu({ handleClose, handleAddItem = () => {} }) {
  const classes = useStyles();

  const callOptionCallback = (itemType, childType) => {
    handleClose();
    handleAddItem(itemType, childType);
  };

  function ListItemMaker({ icon, text, callback, className }) {
    return (
      <ListItem
        disablePadding
        className={`${classes.listItem}`}
        onClick={callback}>
        <Icon className={`${classes.listIcon} ${className}`}>{icon}</Icon>
        <ListItemText
          className={`${className}-text`}
          primary={text}
          primaryTypographyProps={{
            fontSize: '14px',
            lineHeight: '19.6px',
          }}
        />
      </ListItem>
    );
  }

  return (
    <List
      disablePadding
      className={`${classes.root} ${classList.addModuleMenuBar}`}>
      <ListItemMaker
        icon={<Language style={{ width: '16px', height: '16px' }} />}
        text="Destination"
        callback={() => callOptionCallback(ITEM_TYPES.DESTINATION)}
        className="add-destination-button"
      />

      <ListItemMaker
        icon={<FlightTakeoff style={{ width: '16px', height: '16px' }} />}
        text="Flight"
        callback={() =>
          callOptionCallback(ITEM_TYPES.TRANSPORTATION, TRANSPORT_TYPES.FLIGHT)
        }
      />
      <ListItemMaker
        icon={<DirectionsBus style={{ width: '16px', height: '16px' }} />}
        text="Bus/Train"
        callback={() =>
          callOptionCallback(ITEM_TYPES.TRANSPORTATION, TRANSPORT_TYPES.BUS)
        }
      />
      <ListItemMaker
        icon={<DirectionsCar style={{ width: '16px', height: '16px' }} />}
        text="Other"
        callback={() =>
          callOptionCallback(ITEM_TYPES.TRANSPORTATION, TRANSPORT_TYPES.OTHER)
        }
      />
    </List>
  );
}

function DashedLine({ anchorEl, isCommandBarEnabled }) {
  return anchorEl === null ? (
    <svg width="100%" height="3px">
      <line
        x1="0"
        x2="100%"
        y1="1.5"
        y2="1.5"
        stroke="#DEDDDD"
        strokeWidth="3"
        strokeLinecap="round"
        strokeDasharray="10, 10"
        style={
          isCommandBarEnabled
            ? { transform: 'translateX(calc(-15px + 3.6rem))' }
            : {}
        }
      />
    </svg>
  ) : null;
}

function AddModuleButton({
  needsInteraction = true,
  type,
  isCommandBarEnabled,
  index,
}) {
  const classes = useStyles({ type });
  const [anchorEl, setAnchorEl] = useState(null);
  const { slug: tripId } = useParams();

  const dispatch = useDispatch();
  const { currentStep: tourCurrentStep, inProgress: tourInProgress } =
    useSelector((state) => state.View.tour);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAddItem = (itemType, childType) => {
    let itemContent = {};

    const { FLIGHT_ADD, OTHER_TRANSPORT_ADD, BUS_TRAIN_ADD } = EVENTS.PLAN_ADD;
    const transportEvent =
      childType === TRANSPORT_TYPES.FLIGHT
        ? FLIGHT_ADD
        : childType === TRANSPORT_TYPES.BUS
        ? BUS_TRAIN_ADD
        : OTHER_TRANSPORT_ADD;

    phTrackEvent({
      event:
        itemType === ITEM_TYPES.DESTINATION
          ? EVENTS.PLAN_ADD.LOCATION_START
          : transportEvent,
    });
    if (itemType === ITEM_TYPES.TRANSPORTATION) {
      const localConnectionId = getLocalItemId(ITEM_TYPES.TRANSPORT);
      dispatch(
        actions.Item.createLocalItem({
          localId: localConnectionId,
          item: {
            id: localConnectionId,
            type: ITEM_TYPES.TRANSPORT,
          },
          ignoreParentUpdate: true,
          parentId: tripId,
          index: 0,
          tripId,
        })
      );
      itemContent = {
        connections: [localConnectionId],
        transportType: childType,
      };
    }
    const item = {
      id: getLocalItemId(itemType),
      tripId,
      type: itemType,
      content: itemContent,
    };
    dispatch(
      actions.Item.createLocalItem({
        localId: item.id,
        parentId: tripId,
        item,
        tripId,
        index,
        updateNewItemId: true,
      })
    );
    dispatch(
      actions.TripsV2.addChildToTrip({ tripId, childId: item.id, index })
    );
  };

  return (
    <div
      onClick={() => {
        if (tourInProgress && tourCurrentStep === 0) {
          dispatch(actions.View.setTourCurrentStep(tourCurrentStep + 1));
        }
      }}
      id="add-module-button"
      onMouseLeave={() => {
        if (needsInteraction) setAnchorEl(null);
      }}
      className={classes.addModuleButton}>
      <Box className={classes.spaced}>
        {isMobile || !isCommandBarEnabled ? (
          <Box
            item
            container
            className={classes.iconGrid}
            aria-owns={anchorEl ? 'addModulePopover' : undefined}
            aria-haspopup="true"
            onMouseEnter={(e) => {
              if (needsInteraction) setAnchorEl(e.currentTarget);
            }}>
            {!anchorEl && needsInteraction ? (
              <AddCircleOutline
                id="add-module-button-icon"
                className={`${classes.icon} ${classList.addModuleButton}`}
              />
            ) : (
              <AddCircle
                id="add-module-button-icon"
                className={`${classes.iconHovered} ${classList.addModuleButton}`}
              />
            )}
          </Box>
        ) : null}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          {anchorEl || !needsInteraction ? (
            needsInteraction ? (
              <Fade
                in={Boolean(anchorEl)}
                className={classes.menuContainer}
                timeout={{ enter: 10 }}>
                <div>
                  <AddModuleMenu
                    handleClose={() => setAnchorEl(null)}
                    handleAddItem={handleAddItem}
                  />
                </div>
              </Fade>
            ) : (
              <div className={classes.menuContainer}>
                <AddModuleMenu
                  handleClose={() => {}}
                  handleAddItem={handleAddItem}
                />
              </div>
            )
          ) : (
            <DashedLine
              anchorEl={anchorEl}
              isCommandBarEnabled={isCommandBarEnabled}
            />
          )}
        </Box>
      </Box>
    </div>
  );
}

export default AddModuleButton;
