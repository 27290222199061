import { createAsyncThunk } from '@reduxjs/toolkit';
import graphqlClient from '../graphql/index';
import graphqlClientV2 from '../V3/graphql/index';

const getAsyncThunk = (
  thunkName,
  query,
  dispatchAction,
  getDispatchParams = null
) => {
  return createAsyncThunk(
    thunkName,
    async (payload, { rejectWithValue, dispatch }) => {
      const { data, error } = await graphqlClient.mutate({
        mutation: query,
        variables: payload?.variables,
      });
      if (error) rejectWithValue(error);
      if (dispatchAction) {
        dispatch(dispatchAction(getDispatchParams(payload)));
      }
      return data;
    }
  );
};

export const getAsyncThunkV2 = (
  thunkName,
  query,
  dispatchAction,
  getDispatchParams = null
) => {
  return createAsyncThunk(
    thunkName,
    async (payload, { rejectWithValue, dispatch }) => {
      const { data, error } = await graphqlClientV2.mutate({
        mutation: query,
        variables: payload?.variables,
      });
      if (error) rejectWithValue(error);
      if (dispatchAction) {
        dispatch(dispatchAction(getDispatchParams(payload)));
      }
      return data;
    }
  );
};
// function to remove on item from the sourceList and push it into the destinationList
export const reorderList = (
  sourceList,
  destinationList,
  source,
  destination
) => {
  const draggable = sourceList[source.index];
  let newOrder = {};
  // the reorder is in the same list
  if (source.droppableId === destination.droppableId) {
    const newList = [...sourceList];
    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, draggable);
    newOrder = {
      sourceList: newList,
      isSameList: true,
    };
  } else {
    const newSourceList = [...sourceList];
    newSourceList.splice(source.index, 1);

    const newDestinationList = [...destinationList];
    newDestinationList.splice(destination.index, 0, draggable);

    newOrder = {
      sourceList: newSourceList,
      destinationList: newDestinationList,
      isSameList: false,
    };
  }
  return newOrder;
};

// query all the mapPins within the activities as well as thingsToDo
export const getMapPinList = (trip) => {
  const pins = [...(trip?.mapPins || [])];
  trip?.items.forEach((item) => {
    if (item.__typename === 'Location') {
      if (item.mapPin) {
        pins.push(item.mapPin);
      }
      item.hotels?.forEach((accommodation) => {
        if (accommodation.mapPin) {
          pins.push(accommodation.mapPin);
        }
      });
      item.thingsToDo?.forEach((section) => {
        section?.todos?.forEach((todo) => {
          if (todo.mapPin) {
            pins.push(todo.mapPin);
          }
        });
      });
    }
  });
  return pins;
};

export const getMapPinListV2 = (tripMapPins, items) => {
  const pins = [...(tripMapPins || [])];
  items?.forEach((item) => {
    if (item?.mapPin) {
      pins.push(item?.mapPin);
    }
  });
  return pins;
};

export const getFilesPropertiesV2 = (tripFiles, items, tripId) => {
  const files = [...(tripFiles || [])];
  const fileRelations = tripFiles?.map((file) => ({
    attachedToType: 'Trip',
    attachedToId: tripId,
    fileId: file?.id,
  }));
  items?.forEach((item) => {
    if (item?.files) {
      files?.push(...(item?.files || []));
      fileRelations.push(
        ...(item?.files?.map((file) => ({
          attachedToType: item?.type,
          attachedToId: item?.id,
          fileId: file?.id,
        })) || [])
      );
    }
  });
  return { files, fileRelations };
};

export default getAsyncThunk;
