import Heading from '../Heading';

function HeadingItem({ itemId, parentId, index, ...props }) {
  return (
    <div>
      <Heading
        headingId={itemId}
        parentId={parentId}
        index={index}
        {...props}
      />
    </div>
  );
}

export default HeadingItem;
