import { makeStyles } from '@mui/styles';
import {
  Stack,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  addUserToTrip,
  getTripsUsingAtc,
} from '../../../../../redux/slices/TripV2';
import classList from '../../../../classList';
import { EVENTS, phTrackEvent } from '../../../../../analytics';

const useStyles = makeStyles(({ palette }) => ({
  declineButton: {
    width: '50%',
    display: 'inline-block',
    backgroundColor: 'transparent',
    color: palette.primary?.light,
    borderColor: palette?.primary?.light,
    borderStyle: 'solid',
    borderWidth: '1px',
    '&:hover': {
      borderColor: palette?.primary?.main,
      backgroundColor: 'transparent',
      // backgroundColor: palette?.primary?.main,
      color: palette?.primary?.main,
    },
  },
}));

function FooterActions({ tripId }) {
  const styles = useStyles();
  const { palette, breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const userId = useSelector((state) => state.Auth?.userId);
  const user = useSelector((state) => state.Auth?.userData?.user);
  const firebaseUid = user?.id;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleJoinTrip = async () => {
    phTrackEvent({
      event: EVENTS.TRIP_INVITE.INVITE_START,
      meta: {
        tripId,
      },
    });
    const res = await dispatch(
      addUserToTrip({
        variables: {
          tripId,
          userId,
          firebaseUid,
          userEmail: user?.email,
        },
      })
    );
    if (res?.payload?.addUserToTrip?.userAddedToTrip) {
      dispatch(getTripsUsingAtc());
      // setTimeout(() => {
      navigate(`/trips/${tripId}/planner`);
      // }, 4000);
    }
  };

  const handleDeclineTrip = async () => {
    phTrackEvent({
      event: EVENTS.TRIP_INVITE.INVITE_LATER_CLICK,
      meta: {
        tripId,
      },
    });
    navigate('/trips');
  };

  return (
    <>
      <Stack width="100%" flexDirection="row" justifyContent="center">
        <Stack
          px={isMobile ? '10px' : '0px'}
          flexDirection="row"
          width={isMobile ? '100%' : '430px'}
          gap="12px"
          alignItems="center"
          justifyContent="center">
          <Button
            onClick={handleDeclineTrip}
            className={`${styles.declineButton}`}>
            Maybe later
          </Button>{' '}
          <Button
            onClick={handleJoinTrip}
            sx={{ width: '50%' }}
            className={classList.joinTripButton}>
            Join the trip
          </Button>
        </Stack>
      </Stack>
      <Typography
        sx={{ marginTop: '20px' }}
        textAlign="center"
        width={isMobile ? '200px' : '100%'}
        fontSize="10px"
        fontWeight={500}
        mt="4px"
        color={palette?.text?.light}>
        Don&apos;t worry, you can revisit the invitation by using the same link!{' '}
      </Typography>
    </>
  );
}

export default FooterActions;
