import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  Popper,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Paper,
  Fade,
} from '@mui/material';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { DraggableIconPlaceHolder, HeadingIcon } from '../../../atoms/Icon';
import PortalAwareDraggableItem from './PortalAwareDraggableItem';
import actions from '../../../../redux/actions';
import useTour from '../../../molecules/Tour/useTour';
import { ITEM_DRAG_LEVELS } from '../../../../const';

const useStyles = makeStyles({
  listItemContainer: {
    cursor: 'default',
    height: '42px',
    '&:hover': {
      backgroundColor: '#f4f4f4',
      color: '#000000',
    },
  },
  listItemText: {
    '&:hover': {
      color: '#000000',
    },
    maxWidth: '150px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  arrowDropDown: {
    transform: 'rotate(90deg)',
    height: 22,
    width: 22,
  },
});

function HeadingButton({
  tripId,
  locations,
  thingsToDo,
  activeSectionId,
  commonStyles,
  headingAnchorEl,
  setHeadingAnchorEl,
  activeLocationId,
  handleClickAway,
  isHeadingClicked,
  handleAddSection,
  isArrowClicked,
  handleArrowClick,
  isKeyboardShortcutEnabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getTour } = useTour();

  const handleSubMenuClick = (event) => {
    if (getTour().tourOpen) {
      return;
    }
    handleArrowClick();
    setHeadingAnchorEl(headingAnchorEl ? null : event.currentTarget);
  };
  const handleSectionClick = (sectionId) => {
    setHeadingAnchorEl(null);
    dispatch(
      actions.View.setCommandBarV2({
        activeSectionId: sectionId,
      })
    );
    dispatch(
      actions.View.setHighlightedHeading({
        section: null,
      })
    );
  };

  const renderSections = () => {
    const handleSectionHover = (e, highLightedsectionId) => {
      e.stopPropagation();
      const element = document.getElementById(
        `todo-header-${highLightedsectionId}`
      );
      if (element && !getTour().tourInProgress) {
        dispatch(
          actions.View.setHighlightedHeading({
            section: highLightedsectionId,
          })
        );
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    };

    const location = locations[activeLocationId];

    const sections =
      location?.children
        ?.map((thingsToDoId) => thingsToDo[thingsToDoId])
        ?.filter(Boolean) || [];

    return sections?.map((section) => {
      const { id, title } = section;
      return (
        <ListItem
          key={id}
          className={classes.listItemContainer}
          onClick={() => handleSectionClick(id)}
          onMouseOver={(e) => {
            handleSectionHover(e, section?.id);
          }}
          onMouseLeave={() => {
            dispatch(
              actions.View.setHighlightedHeading({
                section: null,
              })
            );
          }}
          alignItems="center"
          style={{ padding: '2px 12px' }}>
          <ListItemText
            primary={title === '' ? 'Unnamed Section' : title}
            primaryTypographyProps={{ fontSize: '14px' }}
            className={classes.listItemText}
          />
        </ListItem>
      );
    });
  };

  return (
    <Grid item style={{ paddingLeft: 1 }}>
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        id="heading-button-container">
        <Droppable
          droppableId={`TRIP-${tripId}-heading`}
          type={ITEM_DRAG_LEVELS.Heading}
          isDropDisabled>
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}>
              <Tooltip
                title={
                  <Box display="flex" alignItems="center">
                    <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
                      Heading
                    </Typography>
                    {isKeyboardShortcutEnabled && (
                      <Box sx={commonStyles.shortcutKey}>H</Box>
                    )}
                  </Box>
                }
                enterDelay={500}
                TransitionComponent={Fade}
                placement="top"
                arrow
                componentsProps={commonStyles.componentsProps}>
                <Draggable draggableId="commandBar-heading" index={0}>
                  {(provided, snapshot) => (
                    <PortalAwareDraggableItem snapshot={snapshot}>
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Box
                          sx={
                            snapshot.isDragging
                              ? commonStyles.draggableExpandedState
                              : isHeadingClicked
                              ? commonStyles.draggableIconClickStyle
                              : commonStyles.hoverStyle
                          }
                          onClick={handleAddSection}>
                          <HeadingIcon />
                        </Box>
                      </div>
                    </PortalAwareDraggableItem>
                  )}
                </Draggable>
                {droppableProvided.placeholder &&
                  droppableSnapshot.isUsingPlaceholder && (
                    <DraggableIconPlaceHolder />
                  )}
              </Tooltip>
            </div>
          )}
        </Droppable>
        <Tooltip
          title={
            <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
              Select heading
            </Typography>
          }
          placement="top"
          arrow
          componentsProps={commonStyles.componentsProps}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '90px',
              ...(isArrowClicked
                ? commonStyles.arrowClickedStyle
                : commonStyles.clickableTextContainer),
            }}
            onClick={handleSubMenuClick}>
            <Typography
              sx={{
                maxWidth: '65px',
                marginRight: '4px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                marginLeft: 1,
                cursor: 'pointer',
              }}>
              {thingsToDo[activeSectionId]?.title || 'New Heading'}
            </Typography>

            <Box sx={commonStyles.arrowStyle}>
              <ArrowRightRoundedIcon className={classes.arrowDropDown} />
            </Box>
          </Box>
        </Tooltip>

        <Popper
          open={Boolean(headingAnchorEl)}
          anchorEl={headingAnchorEl}
          style={{ zIndex: 1123 }}
          placement="top-end"
          disablePortal
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ]}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper>
              <List style={{ maxHeight: 400, overflowY: 'auto' }}>
                {renderSections()}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </Grid>
  );
}

export default HeadingButton;
