import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { auth } from '../../firebase/FirebaseIndex';

const endpoint = process.env.REACT_APP_ATC_API;

const authLink = setContext(async (_, { headers }) => {
  const bearerToken = await auth?.currentUser?.getIdToken();
  return {
    headers: {
      ...headers,
      authorization: bearerToken ? `Bearer ${bearerToken}` : '',
    },
  };
});

const graphqlClient = new ApolloClient({
  uri: endpoint,
  link: authLink.concat(
    createHttpLink({
      uri: endpoint,
    })
  ),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default graphqlClient;
