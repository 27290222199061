import { useState, useEffect, useRef } from 'react';
import {
  ExpandMoreRounded,
  ExpandLessRounded,
  CloseRounded,
  Restaurant,
  SmokeFreeRounded,
  BeachAccessRounded,
  FitnessCenterRounded,
} from '@mui/icons-material';
import {
  IconButton,
  Typography,
  Popper,
  Collapse,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
  Modal,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BasicButton, TertiaryButton } from '../../../../atoms/Button/index';
import newCategories from './assets/bookingFilters.json';
import {
  AirConditionedIcon,
  BreakfastIcon,
  CancellationIcon,
  FrontDeskIcon,
  HotTubIcon,
  ParkingIcon,
  PetFriendlyIcon,
  PoolIcon,
  WiFiIcon,
} from '../../../../atoms/Icon';
import actions from '../../../../../redux/actions';
import PriceRangeSlider from './PriceRangeSlider';
import { phTrackEvent, EVENTS } from '../../../../../analytics';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  // Categories
  popper: {
    height: 'calc(100% - 90px)',
    zIndex: 1301,
    bottom: 0,
    marginTop: '8px',
    [breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
      height: '100%',
    },
  },
  popperModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 16px',
  },
  categoryContainer: {
    height: '100%',
    maxWidth: 530,
    [breakpoints.down('lg')]: {
      width: '100%',
      minWidth: '100%',
    },
    backgroundColor: '#FFF',
    boxShadow: '0px 0px 4px rgba(0,0,0,0.25)',
    borderRadius: '0px 8px 8px 0px',
    display: 'flex',
    flexDirection: 'column',
  },
  categoryList: {
    display: 'flex',
    flex: 1,
    paddingLeft: '12px',
    flexDirection: 'column',
  },
  bottomContainer: {
    display: 'flex',
    flex: 0,
    padding: '16px 24px',
    justifyContent: 'space-between',
    borderTop: '1px solid #DEDDDD',
  },
  topContainer: {
    flex: 1,
    display: 'flex',
    padding: 12,
    overflow: 'auto',
  },
  categoryItemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  categoryHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 12,
    paddingTop: 16,
    paddingLeft: 24,
    alignItems: 'center',
    borderBottom: '1px solid #DEDDDD',
  },

  // searchbar
  searchBar: {
    width: '100%',
    backgroundColor: '#F4F4F4',
    borderRadius: 6,
    padding: 8,
    border: '1px solid transparent',
    '&:hover': {
      boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    },
  },
  searchbarFocused: {
    backgroundColor: '#FFF',
    boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    border: `1px solid ${palette.primary.light}`,
  },
  '@media (max-width: 600px)': {
    popper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 10,
      marginLeft: 10,
      height: '90%',
      width: '95%',
    },
    categoryContainer: {
      width: '100%',
    },
    // Add more responsive styles as needed
  },
}));

function SubCategoryButton({
  active,
  ButtonIcon,
  handleClick,
  marginTop,
  marginRight,
  subCategoryIdType,
  subCategoryIdName,
  subCategoryId,
}) {
  return (
    <BasicButton
      disableRipple
      startIcon={
        ButtonIcon && (
          <ButtonIcon
            style={{
              color: active ? '#ED702E' : '#1D1D1D',
            }}
          />
        )
      }
      sx={{
        marginTop: marginTop || '8px',
        marginRight: marginRight || '8px',
        backgroundColor: active ? 'primary.extraLight' : 'auto',
        color: active ? 'primary.main' : 'auto',
        borderColor: active ? 'primary.main' : 'auto',
        '&:hover': {
          backgroundColor: active ? 'primary.extraLight' : 'auto',
          color: active ? 'primary.main' : 'auto',
          borderColor: active ? 'primary.main' : 'auto',
        },
      }}
      id={`recommendations-filter-pill-${subCategoryId}`}
      className="recommendations-filter-pill"
      onClick={() =>
        handleClick({
          filterId: subCategoryId,
          action: active ? 'DELETE' : 'ADD',
        })
      }>
      {subCategoryIdName}{' '}
      {subCategoryIdType === 'guestRatings' ||
      (subCategoryIdType === 'starRating' && subCategoryIdName !== '5')
        ? '+'
        : ''}
    </BasicButton>
  );
}

function SubCategoryFilter({
  subCategory,
  categoryId,
  handleClick = () => {},
  active = false,
  activeFilterIds = [],
}) {
  const AMENITY_FILTERS = {
    Wifi: {
      icon: WiFiIcon,
      label: 'Wifi',
    },
    Breakfast: {
      icon: BreakfastIcon,
      label: 'Breakfast',
    },
    Cancellation: {
      icon: CancellationIcon,
      label: 'Cancellation',
    },
    Parking: {
      icon: ParkingIcon,
      label: 'Parking',
    },
    Swimming: {
      icon: PoolIcon,
      label: 'Swimming',
    },
    Pets: {
      icon: PetFriendlyIcon,
      label: 'Pet Friendly',
    },
    'Hot tub': {
      icon: HotTubIcon,
      label: 'Hot tub',
    },
    'Air-conditioned': {
      icon: AirConditionedIcon,
      label: 'Air-conditioned',
    },
    '24hrs front desk': {
      icon: FrontDeskIcon,
      label: '24hrs front desk',
    },
    Restaurant: {
      icon: Restaurant,
      label: 'Restaurant',
    },
    NonSmoking: {
      icon: SmokeFreeRounded,
      label: 'Non Smoking',
    },
    Beach: {
      icon: BeachAccessRounded,
      label: 'Near beach',
    },
    Fitness: {
      icon: FitnessCenterRounded,
      label: 'Fitness center',
    },
  };

  const priceFilterList =
    activeFilterIds?.filter((fid) => fid?.includes('price')) || [];
  const priceRange =
    (priceFilterList?.length > 0 &&
      priceFilterList[0]?.split('-')[0]?.split(',')) ||
    [];

  if (categoryId === 'Price Range') {
    return (
      <PriceRangeSlider
        handleAction={handleClick}
        defaultValue={
          priceRange?.length > 0
            ? priceRange?.map((p) => parseInt(p, 10))
            : null
        }
      />
    );
  }

  if (categoryId === 'Hotel Name') {
    const defaultValue =
      activeFilterIds
        ?.filter((fid) => fid?.includes('hname-'))?.[0]
        ?.split('-')[1] || '';
    return (
      <TextField
        fullWidth
        placeholder="Filter by hotel name, eg. Hilton"
        defaultValue={defaultValue}
        inputProps={{
          style: {
            padding: '8px 12px',
          },
        }}
        sx={{
          mt: 1,
          '& .MuiInputBase-root': {
            '& fieldset': {
              borderColor: '#D9D9D9',
            },
          },
          '&:hover': {
            '& .MuiInputBase-root': {
              '& fieldset': {
                borderColor: 'primary.light',
              },
            },
          },
        }}
        onChange={(e) =>
          handleClick({
            filterId: `hname-${e.target.value}`,
            action: 'UPDATE',
          })
        }
      />
    );
  }

  const matchedCategories = Object.keys(AMENITY_FILTERS).find((key) =>
    subCategory?.value?.toLowerCase()?.includes(key?.toLowerCase())
  );

  const category =
    matchedCategories?.length > 0 ? AMENITY_FILTERS[matchedCategories] : null;

  if (categoryId === 'Amenities' && !category) return null;
  return (
    <SubCategoryButton
      handleClick={handleClick}
      active={active}
      ButtonIcon={category?.icon}
      subCategoryIdType={categoryId}
      subCategoryIdName={category?.label || subCategory?.value}
      subCategoryId={subCategory?.id}
    />
  );
}

function CategoryItem({
  categoryId,
  subCategories,
  handleFilterChange,
  activeFilterIds = [],
}) {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={classes.categoryItemContainer}>
      <div style={{ display: 'flex', width: '100%' }}>
        {collapsed ? (
          <ExpandLessRounded
            style={{ cursor: 'pointer' }}
            onClick={() => setCollapsed(false)}
          />
        ) : (
          <ExpandMoreRounded
            style={{ cursor: 'pointer' }}
            onClick={() => setCollapsed(true)}
          />
        )}

        <div>
          <Typography variant="h5Sub" marginLeft="8px" fontWeight={600}>
            {categoryId}
          </Typography>
        </div>
      </div>
      <Collapse in={collapsed}>
        <div className={classes.subCategoriesContainer}>
          {subCategories?.map((subCategory) => (
            <SubCategoryFilter
              isHovered={isHovered}
              handleClick={handleFilterChange}
              categoryId={categoryId}
              subCategory={subCategory}
              active={activeFilterIds.includes(subCategory.id)}
              activeFilterIds={activeFilterIds}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
}

function BookingsFilterMenu({ filterOpen, setFilterOpen }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { slug: tripId } = useParams();

  const { filterLabels: fLabels = [] } = useSelector(
    (state) => state.Bookings[tripId] || {}
  );
  const filterOptions = useSelector(
    (state) => state.Bookings[tripId]?.otherDetails
  );

  const getSubcategoryValue = (filterId) => {
    switch (filterId) {
      case 'Price Range':
        return [
          {
            id: 'priceRange',
            minPrice: filterOptions?.lowerstPrice,
            maxPrice: filterOptions?.highestPrice,
          },
        ];
      case 'Hotel Name':
        return [
          {
            id: 'name',
          },
        ];
      case 'Stars':
        return filterOptions?.starRating?.map((star) => ({
          id: `starRating-${star?.Value}`,
          value: star?.Value,
          count: star?.Count,
        }));
      case 'Amenities':
        return filterOptions?.amenities?.map((amenity) => ({
          id: `amenity-${amenity?.Value}`,
          value: amenity?.Value,
          count: amenity?.Count,
        }));
      default:
        return null;
    }
  };

  const [currentFilterLabels, setCurrentFilterLabels] = useState([...fLabels]);
  const filterRef = useRef(null);
  const handleFilterChange = ({ filterId, action }) => {
    let newFilterLabels = [...currentFilterLabels];
    if (action === 'RESET') newFilterLabels = [];
    else if (action === 'ADD') newFilterLabels.push(filterId);
    else if (action === 'UPDATE') {
      for (let i = 0; i < newFilterLabels.length; i += 1) {
        if (
          newFilterLabels[i].includes('price-') &&
          filterId.includes('price-')
        ) {
          newFilterLabels.splice(i, 1);
          break;
        }
        if (
          newFilterLabels[i].includes('hname-') &&
          filterId.includes('hname-')
        ) {
          newFilterLabels.splice(i, 1);
          break;
        }
      }

      newFilterLabels.push(filterId);
    } else
      newFilterLabels = newFilterLabels.filter((label) => label !== filterId);
    setCurrentFilterLabels(newFilterLabels);
    phTrackEvent({
      event: EVENTS.EXPLORE_STAYS.FILTERS_APPLY_CLICK,
    });
  };

  useEffect(() => {
    setCurrentFilterLabels(fLabels);
  }, [fLabels]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterOpen(false);
      }
    }

    // Add event listener when the filter is open
    if (filterOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterOpen]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const SelectorWrapper = isMobile ? Modal : Popper;

  return (
    <SelectorWrapper
      ref={filterRef}
      open={Boolean(filterOpen)}
      id="categories-popper"
      anchorEl={filterOpen}
      className={isMobile ? classes.popperModal : classes.popper}
      disablePortal>
      <div
        className={classes.categoryContainer}
        style={
          !isMobile && !isDesktop
            ? {
                maxWidth:
                  document.getElementById('map-container')?.clientWidth || 530,
              }
            : {}
        }>
        <div className={classes.categoryHeader}>
          <IconButton
            sx={{ padding: '4px', marginLeft: 1 }}
            size="small"
            onClick={() => {
              setFilterOpen(null);
            }}>
            <CloseRounded />
          </IconButton>
        </div>
        <div className={classes.topContainer}>
          <div className={classes.categoryList}>
            {Object.keys(newCategories || {}).map((categoryId, idx) => (
              <div style={{ marginTop: idx === 0 ? 0 : 24 }}>
                <CategoryItem
                  handleFilterChange={handleFilterChange}
                  categoryId={categoryId}
                  activeFilterIds={currentFilterLabels}
                  subCategories={getSubcategoryValue(categoryId)}
                />
                <Divider sx={{ marginTop: 2 }} />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <TertiaryButton
            id="recommendations-clear-filter-button"
            onClick={() => handleFilterChange({ action: 'RESET' })}
            disableRipple>
            Clear filters
          </TertiaryButton>
          <Button
            disabled={
              [...fLabels].sort().join(',') ===
              [...currentFilterLabels].sort().join(',')
            }
            id="recommendations-show-results-button"
            onClick={() => {
              dispatch(
                actions.Bookings.setFilter({
                  filterItems: currentFilterLabels,
                  tripId,
                })
              );
              if (isMobile) setFilterOpen(null);
            }}>
            {`Apply filters ${
              currentFilterLabels?.length > 0
                ? `(${currentFilterLabels.length})`
                : ''
            }`}
          </Button>
        </div>
      </div>
    </SelectorWrapper>
  );
}

export default BookingsFilterMenu;
