import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Grid,
  ClickAwayListener,
  InputBase,
  CircularProgress,
  Fade,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HotelRounded, HomeOutlined, SellOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import AccommodationBlock from '../../molecules/AccommodationBlock';
import DraggableTodoSection from './todoSection/TodoSection';

import {
  ItineraryActionButton,
  LightGreyButton,
  OrangeOutlinedButton,
  TravelRestrictionsButton,
} from '../../atoms/Button/index';
import BlockHeader from './BlockHeader';
import { PlaceholderTodo } from './todo/Todo';
import { firebaseAuth } from '../../../provider/AuthProvider';
import { PlacesSearchBar } from '../../molecules/SearchBar';
import { useMapUtils } from '../MapUtils';
import { createSection } from '../../../redux/slices/sharedThunks';
import { createAccommodation } from '../../../redux/slices/Accommodation';
import actions from '../../../redux/actions';
import classList from '../../classList';
import { getStaysSearchConfig } from '../Stays/bookingsUtils';
import { useSounds, SOUNDS } from '../../../sounds';
import { EVENTS, phTrackEvent } from '../../../analytics';
import { useTour } from '../../molecules/Tour/useTour';
import { stepName } from '../../../assets/onboarding/steps';

const useItineraryBodyStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  container: {
    display: 'inline-flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'calc(3.6rem + 15px)',
  },
  spacing: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3% 0% 0% 0%',
  },
  addAccommodationButton: {
    fontSize: 14,
    width: '100%',
    whiteSpace: 'nowrap',
    [breakpoints.down('md')]: {
      flexBasis: '100%',
    },
    '&:hover': {
      backgroundColor: palette.primary.extraLight,
      color: palette.primary.main,
    },
  },

  // searchbar styles
  titleInput: {
    fontSize: 14,
    flex: 1,
    padding: '8px',
    fontWeight: 'normal',
    width: '100%',
    border: '1px solid #DEDDDD',
    borderRadius: 4,
    backgroundColor: '#F4F4F4',
  },

  inputContainer: {
    margin: '0% 0% 0% calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      margin: '0% 0% 0% calc(3.6rem + 15px + 12px)',
    },
  },

  viewStaysButtonContainer: {
    margin: '0% 0% 5px calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      margin: '0% 0% 5px calc(3.6rem + 15px + 12px)',
    },
  },

  addAccommodationButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: (props) =>
      props.isDragging || props.isSectionHighlight ? 0 : 'calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      paddingLeft: (props) =>
        props.isDragging || props.isSectionHighlight
          ? 0
          : 'calc(3.6rem + 15px)',
      marginLeft: 12,
    },
  },

  hotelBookingsButton: {
    marginLeft: (props) =>
      props.isDragging || props.isSectionHighlight
        ? 12
        : 'calc(3.6rem + 15px + 12px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 27px)',
    },
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid #FFA766',
    },
  },
}));

// Searchbar for the accommodation block before entering.
function AccommodationSearchBar({ setOpen, onSelect, locationBias }) {
  const classes = useItineraryBodyStyles();
  const inputRef = useRef();
  const { handleLocationSelect } = useMapUtils();
  const [loading, setLoading] = useState(false);
  const { playSound } = useSounds();
  let addAccomodationTimer = null;

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener('keyup', handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keyup', handleKeyDown);
    };
  }, [setOpen]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={classes.inputContainer}>
        <PlacesSearchBar
          fullWidth
          id="accommodation"
          onChange={() => {}}
          locationBias={locationBias}
          tripLocation={false}
          placeholder="Search by name, address"
          handleSelect={async (option) => {
            setLoading(true);
            const { title, mapPin, ...location } = await handleLocationSelect(
              option.place_id,
              undefined,
              'ACCOMMODATION'
            );
            const acc = {
              name: title,
              mapPin: mapPin?.id,
              ...location,
            };
            playSound(SOUNDS.softPop);
            await onSelect(acc);
            setLoading(false);
            if (addAccomodationTimer) {
              clearTimeout(addAccomodationTimer);
            }
          }}
          popperPlacement="bottom"
          CustomSearchBar={InputBase}
          searchBarProps={{
            className: classes.titleInput,
            autoFocus: true,
            inputProps: {
              style: {
                padding: '2px 0px',
              },
            },
            placeholder: 'Search by name, address',
            endAdornment: (
              <Fade
                in={loading}
                style={{
                  transitionDelay: loading ? '800ms' : '0ms',
                }}
                unmountOnExit>
                <CircularProgress size={16} style={{ marginRight: 4 }} />
              </Fade>
            ),
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                // call onBlur after 1sec
                addAccomodationTimer = setTimeout(() => {
                  e.target.blur();
                }, 1000);
              }
            },
            onBlur: async (e) => {
              setLoading(true);
              await onSelect({ name: e.target.value });
              setLoading(false);
              if (addAccomodationTimer) {
                clearTimeout(addAccomodationTimer);
              }
            },
          }}
          inputRef={inputRef}
        />
      </div>
    </ClickAwayListener>
  );
}

// Component containing all the accomodation attributes.
function AccommodationSection({
  section,
  tripId,
  isDragging,
  isSectionHighlight,
  locationBias,
  sectionMapPin,
}) {
  const navigate = useNavigate();
  const { slug } = useParams();
  const commandBarState = useSelector((state) => state.View.commandBar);
  const [openAccommodationSearchbar, setOpenAccommodationSearchbar] =
    useState(false);
  const { user } = useContext(firebaseAuth);
  const classes = useItineraryBodyStyles({ isDragging, isSectionHighlight });

  const dispatch = useDispatch();

  // state to reference the active accommodation id
  const [activeAccommodation, setActiveAccommodation] = useState(null);

  // function to handle adding accommodation
  const openAccommodationSearchBarUsingCommandBar =
    (commandBarState?.openAccommodationSearchbar &&
      section?.id === commandBarState?.activeLocationId) ||
    section?.id === commandBarState?.accommodationDroppableSectionId;

  useEffect(() => {
    if (openAccommodationSearchBarUsingCommandBar) {
      setOpenAccommodationSearchbar(true);
    }
  }, [openAccommodationSearchBarUsingCommandBar]);

  useEffect(() => {
    if (!openAccommodationSearchbar) {
      dispatch(
        actions.View.setCommandBar({
          openAccommodationSearchbar: false,
          accommodationDroppableSectionId: null,
        })
      );
    }
  }, [openAccommodationSearchbar]);

  const handleAddAccommodation = async (accommodation) => {
    if (accommodation?.name === '' || !accommodation) return;
    await dispatch(
      createAccommodation({
        variables: {
          user: [{ user: user.uid, option: '' }],
          type: 1,
          trip: '',
          locationId: section.id,
          index: section?.hotels?.length || 0,
          ...accommodation,
          // add street info
        },
        extra: { locationId: section.id },
      })
    )
      .then(async (result) => {
        setOpenAccommodationSearchbar(false);
        phTrackEvent({
          event: EVENTS.PLAN_STAY.ADD_CREATE,
        });
        setActiveAccommodation(result.payload.createAccommodation.id);
        return null;
      })
      .catch((err) => {
        return err;
      });
  };

  const handlSearchDealButtonClick = () => {
    phTrackEvent({ event: EVENTS.PLAN_STAY.TRIP_LINK_CLICK });
    const { cityLocationDetails = null, date = null } = getStaysSearchConfig({
      fromDate: section?.arrivalDate,
      toDate: section?.departureDate,
      mapPin: sectionMapPin,
      title: section?.name,
    });
    dispatch(
      actions.Recommendations.setCity({
        city: {
          coordinates: {
            latitude: cityLocationDetails?.latitude,
            longitude: cityLocationDetails?.longitude,
          },
          title: cityLocationDetails?.title,
          placeId: cityLocationDetails?.placeId,
          redirect: true,
        },
        tripId: slug,
      })
    );
    dispatch(
      actions.Bookings.triggerSearch({
        tripId: slug,
        cityLocationDetails,
        date,
      })
    );
    navigate(`/trips/${slug}/explore?focus=stays`);
  };

  return (
    <Grid item className={classes.spacing}>
      <Grid item xs={12}>
        {section?.hotels?.length > 0 ? (
          <TravelRestrictionsButton
            className={classes.hotelBookingsButton}
            onClick={handlSearchDealButtonClick}
            startIcon={
              <SellOutlined
                sx={{
                  transform: 'rotate(90deg)',
                  width: '14px',
                  height: '14px',
                  marginRight: '2px',
                }}
              />
            }>
            Search Deals
          </TravelRestrictionsButton>
        ) : null}
      </Grid>
      <AccommodationBlock
        activeAccommodation={activeAccommodation}
        setActiveAccommodation={setActiveAccommodation}
        update
        hotels={section.hotels}
        locationId={section.id}
        tripId={tripId}
        isDragging={isDragging}
        isSectionHighlight={isSectionHighlight}
        setOpenAccomodationPanel={setOpenAccommodationSearchbar}
        locationBias={locationBias}
      />
      {openAccommodationSearchbar && (
        <>
          <div className={classes.viewStaysButtonContainer}>
            <OrangeOutlinedButton
              onClick={handlSearchDealButtonClick}
              startIcon={<HomeOutlined sx={{ height: 15, width: 15 }} />}
              sx={{
                padding: '2px 8px',
                fontSize: 12,
                borderRadius: '20px',
              }}>
              View Stays
            </OrangeOutlinedButton>
          </div>

          <AccommodationSearchBar
            onSelect={(accommodation) => handleAddAccommodation(accommodation)}
            setOpen={setOpenAccommodationSearchbar}
            locationBias={locationBias}
          />
        </>
      )}
      <Grid item xs={12} className={classes.addAccommodationButtonContainer}>
        {section?.hotels?.length > 0 ? (
          <ItineraryActionButton
            style={{ marginTop: '-4px' }}
            onClick={() => {
              setOpenAccommodationSearchbar(true);
              phTrackEvent({ event: EVENTS.PLAN_ADD.STAY_ADD_ICON_CLICK });
            }}>
            + Accommodation
          </ItineraryActionButton>
        ) : !openAccommodationSearchbar ? (
          <div
            style={{
              display: 'flex',
              columnGap: 10,
              rowGap: 4,
              flexWrap: 'wrap',
            }}>
            <LightGreyButton
              onClick={() => {
                setOpenAccommodationSearchbar(true);
              }}
              className={`${classes.addAccommodationButton} ${classList.addAccommodationButton}`}
              sx={{
                flex: 1,
                border: isDragging
                  ? '1px solid #DEDDDD'
                  : '1px solid transparent',
              }}
              startIcon={<HotelRounded />}>
              Add Accommodations
            </LightGreyButton>

            <LightGreyButton
              onClick={handlSearchDealButtonClick}
              className={`${classes.addAccommodationButton} ${classList.hotelBookingsButton}`}
              sx={{
                flex: 1,
                border: isDragging
                  ? '1px solid #DEDDDD'
                  : '1px solid transparent',
                position: 'relative',
              }}
              startIcon={<SellOutlined sx={{ transform: 'rotate(90deg)' }} />}>
              Search Deals
            </LightGreyButton>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
}

function ItineraryBody({ tripId, location, isDragging, isSectionHighlight }) {
  const dispatch = useDispatch();
  const inputRef = useRef();

  const navigate = useNavigate();
  const { slug } = useParams();

  const classes = useItineraryBodyStyles();
  const { getCoordsFromMapPin } = useMapUtils();

  const sectionMapPin = useSelector(
    (state) =>
      (location?.mapPin && (state.Map.mapPins[slug] || {})[location?.mapPin]) ||
      {}
  );

  const { getTour } = useTour();
  const locationTitle =
    (sectionMapPin?.pinData && JSON.parse(sectionMapPin.pinData).title) || null;

  const createSectionWithEmptyActivity = async (sectionTitle) => {
    if (sectionTitle === '') return;

    // TODO: replace with uuid
    const newActivityId = `local-todo-${Date.now()}`;
    const newActivity = {
      id: newActivityId,
      title: '',
    };
    if (!getTour().isActiveStep(stepName.ADD_TODO)) {
      dispatch(
        actions.Activity.setNewActivity({
          activityId: newActivityId,
        })
      );
    }

    const res = await dispatch(
      await createSection({
        variables: {
          locationId: location.id,
          name: sectionTitle,
          startDate: null,
          endDate: null,
          todos: [],
        },
        index: 0,
        locationId: location.id,
        shouldAppendActivity: true,
        localActivity: newActivity,
      })
    );

    dispatch(
      actions.View.setCommandBar({
        activeLocationId: location.id,
        activeSectionId: res?.payload?.createThingsToDo?.id,
      })
    );
    getTour().onActiveStep(stepName.MAP_SEARCH_BAR).openTour();
    getTour()
      .onActiveTour(['emptyTripFlowMobile', 'blankTripFlowMobile'])
      .onActiveStep(stepName.ADD_TODO)
      .closeTour()
      .openTour({
        delay: 3500,
      });
  };

  const handleThingsToDoButtonClick = () => {
    phTrackEvent({
      event: EVENTS.EXPLORE_ACTIVITIES.TRIP_LINK_CLICK,
    });
    const { lat, lng } = getCoordsFromMapPin(location?.mapPin);
    const newCity = {
      title: locationTitle || location?.name,
      coordinates: {
        latitude: lat,
        longitude: lng,
      },
      id: sectionMapPin.id,
      placeId: sectionMapPin.placeId,
    };
    navigate(`/trips/${slug}/explore?focus=pois`, {
      state: {
        newCity,
      },
    });
  };

  return (
    <>
      <AccommodationSection
        section={location}
        tripId={tripId}
        isDragging={isDragging}
        isSectionHighlight={isSectionHighlight}
        locationBias={getCoordsFromMapPin(location?.mapPin) || null}
        sectionMapPin={sectionMapPin}
      />
      <div className={classes.root}>
        <Droppable
          droppableId={location?.id}
          type="SECTION"
          direction="vertical">
          {(provided) => (
            <div
              className={classes.container}
              ref={provided.innerRef}
              {...provided.droppableProps}>
              {location?.thingsToDo?.length ? (
                location?.thingsToDo?.map((sectionId, index) => {
                  return (
                    <DraggableTodoSection
                      sectionId={sectionId}
                      key={sectionId}
                      index={index}
                      tripId={tripId}
                      locationId={location.id}
                      locationName={locationTitle || location?.name}
                      isDragging={isDragging}
                      isSectionHighlight={isSectionHighlight}
                      locationBias={
                        getCoordsFromMapPin(location?.mapPin) || null
                      }
                      handleThingsToDoButtonClick={handleThingsToDoButtonClick}
                    />
                  );
                })
              ) : (
                <div
                  style={{ marginTop: 16, marginBottom: 8 }}
                  onClick={() => {
                    getTour()
                      .onActiveStep(stepName.SET_HEADING, () => {
                        inputRef?.current?.focus();
                      })
                      .closeTour()
                      .goToNextStep();
                  }}>
                  <BlockHeader
                    inputRef={inputRef}
                    updateDate={(e) => {
                      return e;
                    }}
                    updateTitle={(title) =>
                      createSectionWithEmptyActivity(title)
                    }
                    defaultTitle=""
                    hideActions
                    inputProps={{
                      placeholder: 'New Heading',
                      inputProps: { style: { padding: 2 } },
                      name: 'placeholder',
                    }}
                    iconDatePickerProps={{
                      useRange: true,
                      defaultDate: {
                        from: null,
                        to: null,
                      },
                      placement: 'bottom-end',
                    }}
                    isSectionHighlight={isSectionHighlight}
                    handleDelete={() => {}}
                    handleAdd={() => {}}
                  />
                  <PlaceholderTodo
                    locationId={location.id}
                    locationName={locationTitle || location?.name}
                    locationBias={getCoordsFromMapPin(location?.mapPin) || null}
                    handleThingsToDoButtonClick={handleThingsToDoButtonClick}
                    isSectionHighlight={isSectionHighlight}
                  />
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </>
  );
}

export default ItineraryBody;
