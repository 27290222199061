import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { useMediaQuery, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import AppLayout from '../templates/AppLayout';
import Step1Page from '../organisms/CreateATripFlow/Step1';
import Header from '../organisms/CreateATripFlow/Header';
import Step2Page from '../organisms/CreateATripFlow/Step2';
import Step3Page from '../organisms/CreateATripFlow/Step3';
import Step4Page from '../organisms/CreateATripFlow/Step4';
import Step5Page from '../organisms/CreateATripFlow/Step5';
import Step6Page from '../organisms/CreateATripFlow/Step6';
import { useMapUtils, MapProvider } from '../organisms/MapUtils';
import { createLocation } from '../../redux/slices/Location';
import {
  updateTrip,
  getCompleteTrip,
  deleteTrip,
} from '../../redux/slices/Trips';
import {
  deleteTripUsingAtc,
  getCompleteTripUsingAtc,
} from '../../redux/slices/TripV2';
import StepCoverImage from '../organisms/CreateATripFlow/StepCoverImage';

import { EVENTS, phTrackEvent } from '../../analytics';
import { DeleteModal } from '../molecules/Modal';
import { BookingsActions } from '../../redux/slices/Bookings';
import getCfConnector from '../cfConnector';
import { isTripV2 } from '../../utils';
import { createItem } from '../../redux/slices/Item';
import ITEM_TYPES from '../../const';

function CreateATripFlow() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { handleLocationSelect } = useMapUtils();

  const userObj = useSelector((state) => state.Auth.userData);
  const tripId = window.location.pathname.split('/').pop();
  const trip = useSelector((state) => state.Trips.trips[tripId]);

  const [step, setStep] = useState(1);
  const [locationText, setLocationText] = useState('');
  const [location, setLocation] = useState();
  const [tripImageURL, setTripImageURL] = useState(
    '/images/blank-trip-placeholder.png'
  );
  const [tripName, setTripName] = useState('');
  const [dateRange, setDateRange] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showPilotLabsPrompt, setShowPilotLabsPrompt] = useState(false);

  useEffect(() => {
    if (!trip || trip?.status === 'IDLE') {
      if (isTripV2(tripId)) {
        dispatch(getCompleteTripUsingAtc({ tripId }));
      } else {
        dispatch(getCompleteTrip({ tripId }));
      }
    }
    setShowPilotLabsPrompt(
      userObj?.user?.labDisabled && userObj?.user?.showPilotLabsPrompt
    );
  }, []);

  const skipAll = () => {
    navigate(`/trips/${tripId}/planner?o=create-a-trip`, {
      state: { newTrip: true },
    });
  };

  const showFinalTrip = (
    autoImport = false,
    description = '',
    filterLabels = []
  ) => {
    phTrackEvent({
      event: EVENTS.TRIP_CREATE.END,
    });
    const finalDescription = `plan a trip ${description} for ${
      dateRange?.from && !description.includes('days')
        ? `${Math.round(
            (new Date(dateRange.to).getTime() -
              new Date(dateRange.from).getTime()) /
              (1000 * 3600 * 24)
          )} days`
        : ''
    } that includes ${locationText} and name of trip is ${trip.title}`;
    navigate(
      `/trips/${tripId}/planner${
        autoImport
          ? `?autoImport=true&description=${encodeURIComponent(
              finalDescription
            )}&filterLabels=${encodeURIComponent(filterLabels)}`
          : '?o=create-a-trip'
      }`,
      {
        state: { newTrip: true },
      }
    );
  };

  const handleLocationAdd = async () => {
    const { title, mapPin } = await handleLocationSelect(
      location.place_id,
      '',
      'LOCATION'
    );
    phTrackEvent({
      event: EVENTS.TRIP_CREATE.LOCATION_ADD,
      meta: {
        location_name: location.description.split(',')[0].trim(),
        location_country: location.description.split(',').pop().trim(),
      },
    });
    if (isTripV2(tripId)) {
      await dispatch(
        createItem({
          variables: {
            title,
            type: ITEM_TYPES.DESTINATION,
            tripId,
            mapPin,
            content: {},
            children: [],
            index: 0,
          },
        })
      );
      // update the trip document
    } else {
      const locationId = await dispatch(
        createLocation({
          variables: {
            name: title,
            tripID: tripId,
            mapPin,
            thingsToDo: [],
            index: 0,
          },
        })
      ).then((data) => data.payload.createLocation.id);

      await dispatch(
        updateTrip({
          variables: {
            id: tripId,
            items: [{ location: locationId }],
          },
        })
      );
    }
  };

  const handleDeleteTrip = async () => {
    if (isTripV2(tripId)) {
      await dispatch(deleteTripUsingAtc(tripId));
    } else {
      await dispatch(deleteTrip(tripId));
    }
    dispatch(BookingsActions.deleteTrip({ tripId }));
    navigate('/trips');
  };

  useEffect(() => {
    const fetchImage = async () => {
      if (locationText) {
        const { data: imagedata } = await (
          await getCfConnector()
        ).post(process.env.REACT_APP_IMAGES_API, {
          keyword: locationText,
        });

        const result = imagedata.result.response.results;
        setTripImageURL(result?.[0]?.urls?.regular);

        dispatch(
          updateTrip({
            variables: {
              id: tripId,
              coverImage: result?.[0]?.urls?.regular,
            },
          })
        );
      }
    };

    fetchImage();
  }, [location]);

  const friends = [
    ...(trip?.owner ? [trip?.owner] : []),
    ...(trip?.invitedUsers?.map(({ email: userEmail, profilePicture }) => ({
      firstName: userEmail,
      email: userEmail,
      profilePicture,
    })) || []),
  ];

  useEffect(() => {
    window.history.replaceState(
      '',
      {},
      `${window.location.pathname}?step=${step}`
    );
  }, [step]);

  return (
    <AppLayout>
      <Helmet>
        <title>Create A Trip | Pilot</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: isMobile ? '#F4F4F4' : 'white',
          height: { xs: '100vh', sm: '90vh' },
          overflow: 'scroll',
        }}>
        <Header
          step={step}
          setStep={setStep}
          skipAll={skipAll}
          cancel={() => setDeleteModalOpen(true)}
        />
        <Box sx={{ height: { xs: '30px', sm: '70px' } }} />
        {step === 1 && (
          <Step1Page
            setStep={setStep}
            tripImageURL={tripImageURL}
            tripName={tripName}
            dateRange={dateRange}
            location={location}
            setLocation={setLocation}
            locationText={locationText}
            setLocationText={setLocationText}
            handleLocationAdd={handleLocationAdd}
            tripUsers={friends}
            cancel={() => setDeleteModalOpen(true)}
          />
        )}
        {step === 2 && (
          <Step2Page
            setStep={setStep}
            tripImageURL={tripImageURL}
            tripId={tripId}
            tripName={tripName}
            setTripName={setTripName}
            dateRange={dateRange}
            tripUsers={friends}
          />
        )}
        {step === '2.5' && (
          <StepCoverImage
            tripId={tripId}
            setStep={setStep}
            tripImageURL={tripImageURL}
            setTripImageURL={setTripImageURL}
          />
        )}
        {step === 3 && (
          <Step3Page
            setStep={setStep}
            tripId={tripId}
            dateRange={dateRange}
            setDateRange={setDateRange}
            locationText={locationText}
            tripUsers={friends}
          />
        )}
        {step === 4 && (
          <Step4Page
            setStep={setStep}
            tripId={tripId}
            showFinalTrip={showFinalTrip}
            tripUsers={friends}
            showPilotLabsPrompt={showPilotLabsPrompt}
          />
        )}
        {step === 5 && (
          <Step5Page
            setStep={setStep}
            tripId={tripId}
            tripImageURL={tripImageURL}
            tripName={tripName}
            dateRange={dateRange}
            showFinalTrip={showFinalTrip}
            tripUsers={friends}
          />
        )}
        {step === 6 && (
          <Step6Page
            setStep={setStep}
            tripId={tripId}
            tripName={tripName}
            dateRange={dateRange}
            tripImageURL={tripImageURL}
            showFinalTrip={showFinalTrip}
            tripUsers={friends}
            location={location}
            showPilotLabsPrompt={showPilotLabsPrompt}
          />
        )}
      </Box>
      <DeleteModal
        executeFunc={() => {
          handleDeleteTrip(tripId);
        }}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        emoji="😮"
        alertText="Slow down!"
        descriptionText="You're about to  delete your trip. Are you sure you'd like to do that?"
        confirmText={isMobile ? 'Yes' : 'Yes, delete it!'}
        cancelText={isMobile ? 'No' : 'No, keep it.'}
      />
    </AppLayout>
  );
}

function MapCreateTripFlow() {
  return (
    <MapProvider>
      <CreateATripFlow />
    </MapProvider>
  );
}

export default MapCreateTripFlow;
