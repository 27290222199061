import { WishlistActions } from './slices/Wishlist';
import { AuthActions } from './slices/Auth';
import { TripsActions } from './slices/Trips';
import { LocationActions } from './slices/Location';
import { TransportationActions } from './slices/Transportation';
import { AccommodationActions } from './slices/Accommodation';
import { SectionActions } from './slices/Section';
import { ActivityActions } from './slices/Activity';
import { MapActions } from './slices/Map';
import { FilesActions } from './slices/Files';
import { ErrorActions } from './slices/Error';
import { ViewActions } from './slices/View';
import { RecommendationsActions } from './slices/Recommendations';
import { DiscoverActions } from './slices/Discover';
import { BookingsActions } from './slices/Bookings';
import { SysInfoActions } from './slices/SysInfo';
import { ItemActions } from './slices/Item';
import { TripAtcActions } from './slices/TripV2';
import { MapV2Actions } from './slices/MapV2';
import { FilesV2Actions } from './slices/FilesV2';

const actions = {
  Wishlist: WishlistActions,
  Auth: AuthActions,
  Trips: TripsActions,
  Location: LocationActions,
  Transportation: TransportationActions,
  Accommodation: AccommodationActions,
  Section: SectionActions,
  Activity: ActivityActions,
  Map: MapActions,
  Files: FilesActions,
  Error: ErrorActions,
  View: ViewActions,
  Recommendations: RecommendationsActions,
  Discover: DiscoverActions,
  Bookings: BookingsActions,
  SysInfo: SysInfoActions,
  Item: ItemActions,
  TripsV2: TripAtcActions,
  MapV2: MapV2Actions,
  FilesV2: FilesV2Actions,
};

export default actions;
