import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useParams } from 'react-router-dom';
import FLAGS from '../../../../../../featureFlags';
import HotelDetailsPageV1 from './HotelDetailsPage';
import HotelDetailsPageV2 from './HotelDetailsPageV2';

function HotelDetailsPage(props) {
  const { slug: hotelKey = '' } = useParams();
  const shouldShowV2 = useFeatureFlagEnabled(FLAGS.PILOT_BOOKINGS);
  return shouldShowV2 &&
    !(hotelKey?.startsWith('hotel:') || hotelKey?.startsWith('khotel:')) ? (
    <HotelDetailsPageV2 {...props} />
  ) : (
    <HotelDetailsPageV1 {...props} />
  );
}

export default HotelDetailsPage;
