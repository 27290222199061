import {
  ListItemText,
  List,
  ClickAwayListener,
  ListItem,
  Popper,
  ListItemIcon,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import React, { useRef, useState } from 'react';
import {
  CheckRounded,
  ChevronRightRounded,
  ArrowDropDownRounded,
  AddCircleOutlineRounded,
  SearchOutlined,
} from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { ButtonDefault } from '../../../atoms/Button/index';
import { PlacesSearchBar } from '../SearchBar';
import ITEM_TYPES from '../../../../const';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 9121,
    width: 'inherit',
  },
  list: {
    maxHeight: 160,
    overflowY: 'auto',
    backgroundColor: '#FFF',
    borderRadius: 4,
    padding: '4px 0px',
    marginTop: 8,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: 12,
    },
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
  },
  listItem: {
    minWidth: 120,
    padding: '4px 12px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      '& .MuiListItemIcon-root': {
        color: '#ED702E !important',
      },
    },
  },
  addedButton: {
    backgroundColor: '#43A047',
    color: '#FFF',
    pointerEvents: 'none',
  },
  listItemText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}));

function AddToTrip({
  handleSelectLocation,
  handleSelectHeading,
  isAdded,
  searchBarProps,
  popoverTitle,
  showHeading,
  searchBarOpen,
  setSearchBarOpen,
}) {
  const classes = useStyles();
  const addToTripButtonRef = useRef(null);
  const items = useSelector((state) => state.Item.items);
  const tripId = useParams().slug;

  const trip = useSelector((state) => state.TripsV2.tripsAtc[tripId]);

  const locations = trip?.children
    ?.map((item) => items[item])
    .filter((item) => item?.type === ITEM_TYPES.DESTINATION);

  const [locationAnchor, setLocationAnchor] = useState(null);

  const [open, setOpen] = useState(false);

  const [locationPopperOpen, setLocationPopperOpen] = useState(null);
  const handleClickAway = () => {
    setOpen(false);
    setLocationPopperOpen(null);
    setSearchBarOpen(false);
  };

  const renderLocations = () =>
    locations?.length > 0 &&
    locations.map((location, index) => {
      if (!location) return;
      const { id, title } = location;
      const handleLocationHover = (e) => {
        if (!showHeading) {
          return;
        }
        setLocationAnchor(e.currentTarget);

        if (locationPopperOpen === id) return;
        setLocationPopperOpen(id);
        setLocationAnchor(e.currentTarget);
      };
      // const sections = [];
      const sections =
        location?.children
          ?.map((childId) => items[childId])
          .filter((item) => item?.type === ITEM_TYPES.HEADING) || [];

      // eslint-disable-next-line consistent-return
      return (
        <ListItem
          key={id}
          onClick={() => {
            if (handleSelectLocation) {
              handleClickAway();
              return handleSelectLocation(id, index);
            }
            if (sections.length === 0) {
              //   handleAddActivity(placeDetails?.title, id, null);
              handleClickAway();
            }
          }}
          id="si-add-to-trip-button"
          onMouseOver={handleLocationHover}
          onMouseLeave={() => {
            if (sections?.length === 0) {
              setLocationPopperOpen(null);
            }
          }}
          className={`${classes.listItem} `}
          style={{
            // to align the text, 12px(specified) + 2px(alignment spacing) with the 'new location' icon according to the designs
            paddingLeft: 14,
          }}
          alignItems="center">
          <ListItemText
            primary={title}
            primaryTypographyProps={{ fontSize: '14px' }}
            className={classes.listItemText}
          />
          {sections.length > 0 && (
            <>
              <ListItemIcon
                style={{
                  minWidth: 0,
                }}>
                <ChevronRightRounded
                  style={{
                    height: 14,
                    width: 14,
                    ...(locationPopperOpen === id
                      ? {}
                      : { color: 'transparent' }),
                  }}
                />
              </ListItemIcon>
              <Popper
                id={`location-${id}`}
                open={locationPopperOpen === id}
                anchorEl={locationAnchor}
                placement="right"
                style={{
                  width: 'inherit',
                  zIndex: 9122,
                  visibility: !locationAnchor ? 'hidden' : 'visible',
                }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [-4, 8],
                    },
                  },
                ]}>
                <ClickAwayListener onClickAway={() => {}}>
                  <List
                    className={classes.list}
                    style={{
                      maxHeight: 100,
                      maxWidth: 120,
                    }}
                    onMouseLeave={() => {
                      setLocationPopperOpen(null);
                    }}>
                    {sections?.map((section) => {
                      return (
                        <ListItem
                          key={section.id}
                          onClick={() => {
                            if (handleSelectHeading) {
                              handleClickAway();
                              return handleSelectHeading(section?.id);
                            }
                            // handleAddActivity(
                            //   placeDetails?.title,
                            //   id,
                            //   section?.id
                            // );
                            handleClickAway();
                          }}
                          className={classes.listItem}
                          style={{
                            padding: '2px 12px',
                            border: '1px solid #D9D9D9',
                          }}>
                          <ListItemText
                            primary={
                              section.name === ''
                                ? 'Unnamed Section'
                                : section?.title
                            }
                            primaryTypographyProps={{ fontSize: '14px' }}
                            className={classes.listItemText}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </ClickAwayListener>
              </Popper>
            </>
          )}
        </ListItem>
      );
    });
  return (
    <>
      <ButtonDefault
        ref={(r) => {
          addToTripButtonRef.current = r;
        }}
        sx={{
          ml: 2,
          mb: '10px',
          height: '28px',
          paddingLeft: '8px',
          paddingRight: '6px',
          gap: '4px',
        }}
        onClick={() => setOpen(true)}
        endIcon={isAdded ? <CheckRounded /> : <ArrowDropDownRounded />}
        className={isAdded && classes.addedButton}>
        {isAdded ? 'Added to trip' : 'Add to trip'}
      </ButtonDefault>
      <Popper
        id="add-to-trip-button"
        className={classes.popper}
        open={open}
        anchorEl={addToTripButtonRef.current}
        sx={{
          width: searchBarOpen ? '200px' : 'inherit',
        }}
        placement="bottom">
        <ClickAwayListener onClickAway={handleClickAway}>
          <List
            className={classes.list}
            sx={{
              width: '220px',
              border: searchBarOpen ? 'none' : '2px solid #DEDDDD',
            }}>
            {popoverTitle && (
              <Box ml="8px">
                <Typography fontWeight="500" fontSize="10px" color="#8A8A8A">
                  {popoverTitle}
                </Typography>
              </Box>
            )}
            {searchBarOpen && (
              <PlacesSearchBar
                {...searchBarProps}
                searchBarProps={{
                  startIcon: <SearchOutlined />,
                  inputProps: {
                    style: {
                      border: 'none',
                    },
                  },
                }}
                handleClickAway={handleClickAway}
              />
            )}
            <>
              {!searchBarOpen && (
                <ListItem
                  key="create-new-location"
                  onClick={() => {
                    setSearchBarOpen(true);
                  }}
                  onMouseOver={() => {
                    setLocationPopperOpen('create-new-location');
                  }}
                  className={classes.listItem}
                  style={{
                    whiteSpace: 'nowrap',
                    justifyContent: 'flex-start',
                  }}>
                  <ListItemIcon
                    style={{
                      minWidth: 0,
                      marginRight: 4,
                    }}>
                    <AddCircleOutlineRounded
                      style={{
                        height: 14,
                        width: 14,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="New Location"
                    primaryTypographyProps={{
                      fontSize: '14px',
                    }}
                  />
                </ListItem>
              )}

              {!searchBarProps?.value && (
                <Divider style={{ marginTop: 4, marginBottom: 4 }} />
              )}
            </>

            {!searchBarProps?.value && renderLocations()}
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default AddToTrip;
