import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { useDispatch } from 'react-redux';
import {
  Box,
  ClickAwayListener,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
  Fade,
} from '@mui/material';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { DirectionsBus, DirectionsCar, Check } from '@mui/icons-material';
import PortalAwareDraggableItem from './PortalAwareDraggableItem';
import { DraggableIconPlaceHolder } from '../../../atoms/Icon';
import actions from '../../../../redux/actions';
import { ITEM_DRAG_LEVELS } from '../../../../const';

const useStyles = makeStyles({
  accommodationIconContainer: {
    height: 22,
    width: 22,
    color: '#4e4e4e',
  },
  arrowDropDown: {
    transform: 'rotate(90deg)',
    height: 22,
    width: 22,
  },
});

const accommodations = [
  {
    icon: <img src="/images/Flight.svg" alt="Flight Icon" />,
    text: 'Flight',
    type: 'flight',
  },
  {
    icon: <DirectionsBus />,
    text: 'Bus/Train',
    type: 'bus',
  },
  {
    icon: <DirectionsCar />,
    text: 'Other',
    type: 'other',
  },
];

function TransportationButton({
  commonStyles,
  transportationAnchorEl,
  setTransportationAnchorEl,
  handleClickAway,
  handleAddTransportation,
  isTransportationClicked,
  isArrowClicked,
  handleArrowClick,
  isKeyboardShortcutEnabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTransportation, setSelectedTransportation] =
    useState('flight');

  const handleDropdwonClick = (event) => {
    handleArrowClick();
    setTransportationAnchorEl(
      transportationAnchorEl ? null : event.currentTarget
    );
  };

  const handleSelectTransportation = (type) => {
    setSelectedTransportation(type);
    // also set the type in the store
    dispatch(
      actions.View.setCommandBar({
        transportationBladeType: type,
      })
    );
    setTransportationAnchorEl(null);
  };

  const selectedIcon = accommodations.find(
    (accommodation) => accommodation.type === selectedTransportation
  )?.icon;

  return (
    <Grid item sx={{ ml: '-10px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Droppable
          droppableId={`TRIP-${selectedTransportation}-transportation`}
          type={ITEM_DRAG_LEVELS.Transportation}
          isDropDisabled>
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}>
              <Tooltip
                title={
                  <Box display="flex" alignItems="center">
                    <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
                      Transportation
                    </Typography>
                    {isKeyboardShortcutEnabled && (
                      <Box sx={commonStyles.shortcutKey}>T</Box>
                    )}
                  </Box>
                }
                enterDelay={500}
                TransitionComponent={Fade}
                placement="top"
                arrow
                componentsProps={commonStyles.componentsProps}>
                <Draggable draggableId="commandBar-transport" index={0}>
                  {(provided, snapshot) => (
                    <PortalAwareDraggableItem snapshot={snapshot}>
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Box
                          sx={
                            snapshot.isDragging
                              ? commonStyles.draggableExpandedState
                              : isTransportationClicked
                              ? commonStyles.draggableIconClickStyle
                              : commonStyles.hoverStyle
                          }
                          onClick={() =>
                            handleAddTransportation(selectedTransportation)
                          }>
                          <Box className={classes.accommodationIconContainer}>
                            {selectedIcon}
                          </Box>
                        </Box>
                      </div>
                    </PortalAwareDraggableItem>
                  )}
                </Draggable>
                {droppableProvided.placeholder &&
                  droppableSnapshot.isUsingPlaceholder && (
                    <DraggableIconPlaceHolder />
                  )}
              </Tooltip>
            </div>
          )}
        </Droppable>
        <Tooltip
          title={
            <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
              Select transportation
            </Typography>
          }
          enterDelay={500}
          TransitionComponent={Fade}
          placement="top"
          arrow
          componentsProps={commonStyles.componentsProps}>
          <Box
            sx={{
              ...(isArrowClicked || transportationAnchorEl
                ? {
                    ...commonStyles.arrowClickedStyle,
                    bgcolor: '#D9D9D9',
                    width: '24px',
                  }
                : {
                    ...commonStyles.arrowStyle,
                    '&:hover': {
                      backgroundColor: '#f4f4f4',
                      cursor: 'pointer',
                      borderRadius: 2,
                    },
                  }),
            }}
            onClick={handleDropdwonClick}>
            <ArrowRightRoundedIcon className={classes.arrowDropDown} />
          </Box>
        </Tooltip>
        <Popper
          open={Boolean(transportationAnchorEl)}
          anchorEl={transportationAnchorEl}
          placement="top-end"
          disablePortal
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ]}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper>
              <List sx={{ width: '200px' }}>
                {accommodations.map((accommodation, index) => {
                  return (
                    <ListItem
                      key={index}
                      sx={commonStyles.listItemContainer}
                      onClick={() =>
                        handleSelectTransportation(accommodation.type)
                      }>
                      <ListItemIcon
                        sx={{
                          minWidth: '42px',
                        }}
                        className={classes.accommodationIconContainer}>
                        {accommodation.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={accommodation.text}
                        primaryTypographyProps={{ fontSize: '14px' }}
                        sx={commonStyles.listItemText}
                      />
                      {selectedTransportation === accommodation.type && (
                        <Check sx={{ ml: 'auto', color: '#000' }} />
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </Grid>
  );
}

export default TransportationButton;
