/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { parseISODate, removeTimezoneOffset } from '../../../utils';
import IconDatePicker from '../../molecules/IconDatePicker';
import classList from '../../classList';
import { updateTripUsingAtc } from '../../../redux/slices/TripV2';
import { BookingsActions } from '../../../redux/slices/Bookings';

const useStyles = makeStyles(() => ({
  iconButtonPropsRoot: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 8px',
  },
  dateStringPropsRoot: {
    marginLeft: '8px',
    marginRight: '0em',
  },
}));

export default function TripDateSelector({ trip, tripStartDate, tripEndDate }) {
  const classes = useStyles();
  const { slug } = useParams();
  const [tripDate, setTripDate] = useState({
    from: tripStartDate,
    to: tripEndDate,
  });

  const dispatch = useDispatch();

  const updateTripDate = (date) => {
    const newStartDate = date?.from;
    const newEndDate = date?.to;

    const dateToUpdate = {
      from:
        newStartDate &&
        (typeof newStartDate === 'string'
          ? newStartDate
          : removeTimezoneOffset(newStartDate)?.toISOString()),
      to:
        newEndDate &&
        (typeof newEndDate === 'string'
          ? newEndDate
          : removeTimezoneOffset(newEndDate)?.toISOString()),
    };
    dispatch(
      updateTripUsingAtc({
        variables: {
          id: slug,
          startDate: dateToUpdate?.from,
          endDate: dateToUpdate?.to,
        },
      })
    );
  };

  return (
    <IconDatePicker
      id="trip-date-selector-root"
      useRange
      defaultDate={{
        from: tripDate?.from
          ? parseISODate(tripDate?.from, trip?.version)
          : null,
        to: tripDate?.to ? parseISODate(tripDate?.to, trip?.version) : null,
      }}
      onDateUpdate={setTripDate}
      onCalanderClose={() => updateTripDate(tripDate)}
      iconButtonProps={{
        classes: {
          root: `${classes.iconButtonPropsRoot} ${classList.tripDatePickerButton}`,
          label: classes.iconButtonPropsLabel,
        },
        id: 'trip-date-selector',
      }}
      dateStringProps={{
        classes: {
          root: classes.dateStringPropsRoot,
        },
      }}
    />
  );
}
