import { Stack, Typography } from '@mui/material';
import { CalendarMonthOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Tape } from './assets';
import ImgWithFallback from '../../../../atoms/ImgWithFallback';

const useStyles = makeStyles(() => ({
  coverContainer: {
    borderRadius: '8px',
    borderTop: '2x solid var(--product-system-white-bg, #FFF)',
    borderRight: '2px solid var(--product-system-white-bg, #FFF)',
    borderBottom: '2px solid var(--product-system-white-bg, #FFF)',
    borderLeft: '2px solid var(--product-system-white-bg, #FFF)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: '#fff',
    paddingTop: '10px',
  },
}));

function Polaroid({ coverImage, dateAndDuration }) {
  const styles = useStyles();
  return (
    <Stack
      sx={{
        backgroundColor: 'transparent',
        position: 'relative',
      }}>
      <Stack className={styles.coverContainer}>
        <Tape />
        <ImgWithFallback src={coverImage} width="346px" height="365px" />
        <Stack
          height="80px"
          bgcolor="#fff"
          gap="5px"
          alignItems="center"
          flexDirection="row"
          justifyContent="center">
          <CalendarMonthOutlined
            height="16px"
            width="16px"
            sx={{ marginTop: '8px' }}
          />
          <Typography
            fontSize="16px"
            bgcolor="#fff"
            pt="10px"
            fontFamily="Clearface">
            {dateAndDuration}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Polaroid;
