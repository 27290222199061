// TODO: Move this into a common sharedUtils folder
export const parseISODate = (date) => {
  if (!date) return null;
  const unparsedDate = new Date(date);
  if (unparsedDate.toISOString() === date) {
    return new Date(
      unparsedDate.getTime() + unparsedDate.getTimezoneOffset() * 60000
    );
  }
  return unparsedDate;
};

export const stringToGuestV2 = (str) => {
  if (!str) return null;
  const headings = ['adults', 'children', 'rooms', 'childAges'];
  const result = str.split('|')?.reduce((acc, curr, index) => {
    if (curr?.includes(',')) {
      acc[headings[index]] = curr
        ?.split(',')
        ?.filter((age) => !(!age && age !== '0'))
        ?.map((age) => parseInt(age, 10));
    } else {
      acc[headings[index]] = parseInt(curr, 10);
    }
    return acc;
  }, {});
  result.total = result.adults + result.children;
  return result;
};

// TODO: Refine logic based on restrictions
export const getRoomSplit = ({ adults, children, rooms, childAges }) => {
  if (!rooms) return null;

  const finalRoomSplit = new Array(rooms).fill().map(() => ({
    adults: 1,
    children: [],
  }));

  let adultsLeft = adults - rooms;
  let childrenLeft = children;

  while (childrenLeft > 0) {
    finalRoomSplit[childrenLeft % rooms].children.push(
      children - childrenLeft >= childAges?.length
        ? 3
        : childAges[children - childrenLeft]
    );
    childrenLeft -= 1;
  }

  while (adultsLeft > 0) {
    finalRoomSplit[adultsLeft % rooms].adults += 1;
    adultsLeft -= 1;
  }
  return finalRoomSplit;
};

export const guestToStringV2 = ({ adults, children, rooms, childAges }) => {
  if (!adults || !rooms) return null;
  return `${adults}|${children}|${rooms}${
    childAges?.length > 0 ? `|${childAges.join(',')},` : ''
  }`;
};

const guestToString = ({ adults, children, rooms }) => {
  let str = '';

  const defaultChildAge = 6;

  // The number of adults and children yet to be allocated to rooms
  let adultsLeft = adults - rooms;
  let childrenLeft = children;

  for (let i = 0; i < rooms; i += 1) {
    let adultsInRoom = 1; // At least one adult must be in each room
    let childrenInRoom = 0;

    // Try to fill the room to capacity (max 4 people)
    while (adultsInRoom + childrenInRoom < 4) {
      if (adultsLeft > 0) {
        adultsInRoom += 1;
        adultsLeft -= 1;
      } else if (childrenLeft > 0) {
        childrenInRoom += 1;
        childrenLeft -= 1;
      } else {
        break;
      }
    }

    // Add the adults and children to this room to the string
    str += adultsInRoom;
    if (childrenInRoom > 0) {
      str += `:${Array(childrenInRoom).fill(defaultChildAge).join(',')}`;
    }

    // Add room separator if not the last room
    if (i < rooms - 1) {
      str += '|';
    }
  }

  // If there are still adults left, just add them to the last room
  if (adultsLeft > 0) {
    str += `|${adultsLeft}`;
  }

  // If there are still children left, just add them to the last room
  if (childrenLeft > 0) {
    str += `:0:${Array(childrenLeft).fill(defaultChildAge).join(',')}`;
  }

  return str;
};

export const ONE_DAY_MS = 24 * 60 * 60 * 1000;
export const getNumberOfNights = (date) => {
  try {
    const timeDiff =
      new Date(date.to).getTime() - new Date(date.from).getTime();
    return Math.ceil(timeDiff / ONE_DAY_MS);
  } catch (err) {
    // error
  }
  return 1;
};

export const getStaysSearchConfig = ({ fromDate, toDate, title, mapPin }) => {
  let arrivalDate = fromDate && parseISODate(fromDate);
  let departureDate = toDate && parseISODate(toDate);

  if (!arrivalDate || arrivalDate < new Date(Date.now())) {
    arrivalDate = new Date(Date.now() + 2 * ONE_DAY_MS);
    departureDate = new Date(Date.now() + 4 * ONE_DAY_MS);
  }
  if (
    (arrivalDate && !departureDate) ||
    arrivalDate?.getTime() === departureDate?.getTime()
  ) {
    departureDate = new Date(new Date(arrivalDate).getTime() + 2 * ONE_DAY_MS);
  }
  return {
    date: {
      from: arrivalDate,
      to: departureDate,
    },
    cityLocationDetails: {
      title: title || '',
      ...(mapPin
        ? {
            longitude: mapPin?.long,
            latitude: mapPin?.lat,
            placeId: mapPin?.placeId,
          }
        : {}),
    },
  };
};

export const stringToGuest = (roomString) => {
  let adultCount = 0;
  let childrenCount = 0;
  let rooms = [];
  try {
    rooms = roomString.split('|');
    rooms.forEach((room) => {
      const [adults = '', children = ''] = room
        ?.split(':')
        ?.filter(Boolean) || ['', ''];
      childrenCount += children?.split(',')?.filter(Boolean)?.length || 0;
      adultCount += parseInt(adults, 10);
    });
  } catch (err) {
    adultCount = 2;
    rooms = [''];
  }

  // Return the JavaScript object
  return {
    adults: adultCount,
    children: childrenCount,
    rooms: rooms.length,
    total: adultCount + childrenCount,
  };
};

export default guestToString;
