import { useParams } from 'react-router-dom';
import Activity from '../Activity';

function ActivityItem({ itemId, parentId, index, ...props }) {
  const { slug: tripId } = useParams();
  return (
    <div>
      <Activity
        itemId={itemId}
        parentId={parentId}
        tripId={tripId}
        index={index}
        {...props}
      />
    </div>
  );
}

export default ActivityItem;
