import { useParams } from 'react-router-dom';
import { useState } from 'react';
import QuickstartModalV2 from '../../molecules/Modal/AutoPilotModal';

function AutoPilotV2() {
  const { slug: tripId } = useParams();
  const [isQuickstartEnable, setIsQuickstartEnable] = useState(false);

  return (
    <QuickstartModalV2
      open={isQuickstartEnable}
      onClose={() => setIsQuickstartEnable(false)}
      handleClose={() => {
        setIsQuickstartEnable(false);
      }}
      tripId={tripId}
      handleOpen={() => setIsQuickstartEnable(true)}
      isImported
    />
  );
}

export default AutoPilotV2;
