import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BookmarkRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { useLocation, useParams } from 'react-router-dom';
import HotelImageContainer from '../ImageContainer';
import HotelNavbar from '../Navbar';
import HotelHeading from '../Heading';
import HotelDealsContainer from '../DealsContainer';
import HotelReviewSection from '../ReviewSection';
import HotelPerksSection from '../PerksSection';
import HotelLocationSection from '../LocationSection';
import HotelAmenities from '../Amenities';
import { getHotel } from '../api';
import actions from '../../../../../redux/actions';
import LoadingLayout from '../../../../templates/LoadingLayout';
import { getNumberOfNights } from '../utils';
import { getCompleteTrip } from '../../../../../redux/slices/Trips';
import { ONE_DAY_MS } from '../../bookingsUtils';
import { OutlinedButton } from '../../../../atoms/Button/index';
import { addSavedItem } from '../../../../../redux/slices/Recommendations';
import { deleteSavedItem } from '../../../../../redux/slices/Bookings';
import TripSelectorStays from '../../TripSelectorStays';
import {
  createAccommodation,
  deleteAccommodation,
} from '../../../../../redux/slices/Accommodation';
import {
  createLocation,
  deleteLocation,
} from '../../../../../redux/slices/Location';
import { MapProvider, useMapUtils } from '../../../MapUtils';
import { firebaseAuth } from '../../../../../provider/AuthProvider';
import NotificationPopper from '../../NotificationPopper';

const useStyles = makeStyles(({ breakpoints }) => ({
  page: {
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    paddingBottom: 120,
  },
  controlledWidth: {
    width: 1032,
    margin: '0 auto',
    [breakpoints.down('lg')]: {
      width: '100%',
      padding: '0 48px',
    },
    [breakpoints.down('sm')]: {
      padding: '0 24px',
    },
  },
  saveIconContainer: (saved) => ({
    display: 'flex',
    marginRight: 8,
    marginLeft: 'auto',
    alignItems: 'center',
    cursor: 'pointer',
    color: saved ? '#ED702E' : '#8A8A8A',
    '&:hover': {
      color: '#FFA766',
      '& > svg > path': {
        stroke: '#FFA766',
      },
    },
  }),
  saveIcon: (saved) => ({
    color: saved ? '#ED702E' : 'transparent',
    marginRight: 4,
    '& > path': {
      stroke: saved ? '#ED702E' : '#8A8A8A',
      strokeWidth: '1',
    },
  }),
}));

function HotelDetailsPage() {
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const prevTripId = window.localStorage.getItem('prevTripId');
  const [isTripSelectorOpen, setIsTripSelectorOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [activeActionProps, setActiveActionProps] = useState(null);
  const [addToTripLoader, setAddToTripLoader] = useState(false);
  const [popperNotification, setPopperNotification] = useState(null);
  const isNewDestination = useRef(false);
  const { user } = useContext(firebaseAuth);

  const { slug: hotelKey } = useParams();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const city = queryParams.get('city');

  const dispatch = useDispatch();

  const hotelDetails =
    useSelector((state) => state.Bookings.hotelDetailsPage) || null;

  // saved list logic
  const [saved, setSaved] = useState(false);
  const [allowSave, setAllowSave] = useState(true);
  const savedItemList = useSelector((state) =>
    Object.values(state.Bookings.saved[prevTripId] || {})?.filter(
      (savedItem) =>
        savedItem?.referenceId === hotelDetails?.data[hotelKey].id?.toString()
    )
  );
  const { cityLocationDetails } = useSelector((state) => state.Bookings);

  const { checkInDate = null, checkOutDate = null } = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.searchProps || {}
  );

  const savedItem = savedItemList?.length > 0 && savedItemList[0];
  const isSaved = Boolean(savedItem);

  const { createMapPinForPlace, createMapPinForPlaceId } = useMapUtils();

  const mapPins = useSelector((state) => state.Map.mapPins[prevTripId] || {});
  const tripDestinations = useSelector((state) => state.Location.locations);
  const trip = useSelector((state) => state.Trips.trips[prevTripId]);

  const tripItems = trip?.tripItems || [];
  const [validLocations, setValidLocations] = useState([]);

  useEffect(() => {
    setValidLocations(
      Object.values(tripDestinations || {})
        .filter(
          (loc) => loc.tripID === prevTripId && loc.name !== '' && loc?.mapPin
        )
        .map((loc) => {
          const mapPin = mapPins[loc.mapPin] || null;
          if (!mapPin) return null;
          return {
            id: loc.id,
            title: loc.name,
            mapPin,
            hotelsCount: loc.hotels?.length || 0,
            fromDate: loc.arrivalDate,
            toDate: loc.departureDate,
          };
        })
        .filter((tag) => Boolean(tag))
    );
  }, [tripDestinations, mapPins]);

  const classes = useStyles(saved);

  const locationNameList = validLocations?.map((locationItem) =>
    locationItem?.title?.toLowerCase().trim()
  );

  const locationDetails = validLocations?.filter(
    (locationItem) =>
      locationItem?.title?.toLowerCase().trim() === city?.toLowerCase().trim()
  );

  const updateSearchUrl = (params) => {
    const defaultProps = {
      checkInDate: format(new Date(Date.now() + 2 * ONE_DAY_MS), 'yyyy-MM-dd'),
      checkOutDate: format(new Date(Date.now() + 4 * ONE_DAY_MS), 'yyyy-MM-dd'),
      rooms: 2,
      city,
    };
    const urlParams = new URLSearchParams(params);
    const updatedSearchProps = {};

    Object.keys(defaultProps)?.forEach((searchProp) => {
      if (!urlParams.get(searchProp))
        urlParams.set(searchProp, defaultProps[searchProp]);
      updatedSearchProps[searchProp] = urlParams.get(searchProp);
    });

    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${urlParams.toString()}`
    );
    return updatedSearchProps;
  };

  // eslint-disable-next-line
  const refreshHotel = async () => {
    const requiredSearchProps = ['checkInDate', 'checkOutDate', 'rooms'];
    if (
      !requiredSearchProps?.filter((prop) =>
        Boolean(hotelDetails.searchProps[prop])
      )?.length === 3
    ) {
      return;
    }

    if (!hotelDetails?.data[hotelKey]) {
      setLoading(true);
    }
    try {
      const hotel = await getHotel({
        hotelKey,
        ...(hotelDetails.searchProps || {}),
      });

      setLoading(false);

      if (hotel === null) return;
      dispatch(
        actions.Bookings.setHotelDetails({
          ...(hotel || {}),
          numberOfNights: getNumberOfNights(
            parse(
              hotelDetails?.searchProps?.checkInDate,
              'yyyy-MM-dd',
              new Date()
            ),
            parse(
              hotelDetails?.searchProps?.checkOutDate,
              'yyyy-MM-dd',
              new Date()
            )
          ),
        })
      );
      updateSearchUrl(hotelDetails?.searchProps);
    } catch (err) {
      // error
    }
  };

  const handleSaveClick = async (e) => {
    if (!prevTripId || !allowSave) return;

    setAllowSave(false);
    e.stopPropagation();
    if (isSaved) {
      setSaved(false);
      await dispatch(
        deleteSavedItem({
          variables: {
            id: savedItem?.id,
          },
          tripId: prevTripId,
        })
      ).catch(() => setSaved(true));
    } else {
      setSaved(true);
      await dispatch(
        addSavedItem({
          variables: {
            type: 'HOTEL',
            tripId: prevTripId,
            referenceId: hotelDetails?.data[hotelKey].id?.toString(),
            savedData: JSON.stringify({
              hotelKey,
              title: hotelDetails?.data[hotelKey]?.name,
              hotelName: hotelDetails?.data[hotelKey]?.name,
              starRating: hotelDetails?.data[hotelKey]?.starRating,
              guestRating:
                hotelDetails?.data[hotelKey]?.reviews?.guestRatings?.OVERALL,
              numberOfReviews:
                hotelDetails?.data[hotelKey]?.reviews?.numberOfReviews,
              guestRatingSentiment:
                hotelDetails?.data[hotelKey]?.reviews?.sentiment,
              rates: hotelDetails?.data[hotelKey]?.reviews?.results,
              images: hotelDetails?.data[hotelKey]?.images,
              referenceId: hotelDetails?.data[hotelKey]?.id?.toString(),
              providers: hotelDetails?.data[hotelKey]?.providers,
              currencyCode: hotelDetails?.data[hotelKey]?.currencyCode,
              propertyType: hotelDetails?.data[hotelKey]?.propertyType,
            }),
          },
        })
      ).catch(() => setSaved(false));
    }
    setAllowSave(true);
  };

  const createMapPinForAccomodation = async () => {
    const mapPinDetails = {
      title: hotelDetails?.data[hotelKey]?.name,
      photo: hotelDetails?.data[hotelKey]?.images?.[0]?.small,
      rating: hotelDetails?.data[hotelKey]?.starRating,
      website: '',
      ratingCount: hotelDetails?.data[hotelKey]?.reviews?.numberOfReviews,
      lat: hotelDetails?.data[hotelKey]?.latitude,
      long: hotelDetails?.data[hotelKey]?.longitude,
      types: 'ACCOMMODATION',
      hotelId: hotelKey?.toString(),
    };

    const pinDetails = createMapPinForPlace(mapPinDetails, 'ACCOMMODATION');
    return pinDetails;
  };

  const getStayLocationInfo = (
    controlledSelectedLocation = selectedLocation
  ) => {
    try {
      if (controlledSelectedLocation) {
        // This location is selected form trip selector execute this
        return [
          controlledSelectedLocation?.locationId,
          controlledSelectedLocation?.hotels?.length || 0,
          controlledSelectedLocation?.title,
        ];
      }
      if (
        locationNameList?.includes(city?.toLowerCase().trim()) &&
        locationDetails?.length === 1
      ) {
        return [
          locationDetails[0]?.id,
          locationDetails[0]?.hotels?.length || 0,
          city,
        ];
      }
      return [null, 0, city];
    } catch (err) {
      return [null, 0, city];
    }
  };

  const handleUndoAction = async (undoActionProps = activeActionProps) => {
    if (!undoActionProps) return;

    const {
      locationId = null,
      locationMapPinId = null,
      hotelId = null,
      hotelMapPinId = null,
    } = undoActionProps;

    if (hotelId === null) return;

    if (isNewDestination.current) {
      await Promise.all([
        dispatch(
          deleteLocation({
            variables: {
              id: locationId,
              prevTripId,
            },
            mapPin: locationMapPinId,
          })
        ),
        dispatch(
          deleteAccommodation({
            variables: {
              id: hotelId,
              mapPin: hotelMapPinId,
              locationId,
            },
            extra: { locationId },
            mapPin: hotelMapPinId,
          })
        ),
      ]);
    } else {
      await dispatch(
        deleteAccommodation({
          variables: {
            id: hotelId,
            mapPin: hotelMapPinId,
            locationId,
          },
          extra: { locationId },
          mapPin: hotelMapPinId,
        })
      );
    }
    setActiveActionProps(null);
  };

  const handleAddLocation = async () => {
    const placeDetails = {
      lat: cityLocationDetails?.latitude,
      long: cityLocationDetails?.longitude,
      placeId: cityLocationDetails?.placeId,
      title: cityLocationDetails?.title,
    };

    const mapPinId = await createMapPinForPlaceId(
      placeDetails?.placeId,
      'LOCATION'
    );
    const locationObj = await dispatch(
      createLocation({
        variables: {
          name: placeDetails.title,
          tripID: prevTripId,
          mapPin: mapPinId,
          index: tripItems.length || 0,
        },
      })
    ).then((data) => data.payload.createLocation);

    return { ...locationObj, mapPin: mapPinId };
  };

  const handleAddAccommodationToTripClick = async (
    controlledSelectedLocation = selectedLocation
  ) => {
    setAddToTripLoader(true);
    try {
      const stayLocationInfo = getStayLocationInfo(controlledSelectedLocation);
      let [locationId] = stayLocationInfo;
      const [stayIndex, locationTitle] = stayLocationInfo.slice(1);
      let locationMapPinId = null;

      if (!locationId) {
        const locationObj = await handleAddLocation();
        locationId = locationObj?.id;
        locationMapPinId = locationObj?.mapPin;
      }

      if (locationId) {
        const mapPinDetails = await createMapPinForAccomodation();

        const hotel = (
          await dispatch(
            createAccommodation({
              variables: {
                user: [{ user: user.uid, option: '' }],
                type: 1,
                trip: prevTripId,
                index: stayIndex,
                name: hotelDetails?.data[hotelKey]?.name,
                city,
                checkInDate,
                checkOutDate,
                streetAddress: city,
                mapPin: mapPinDetails?.id,
                locationId,
              },
              extra: { locationId },
            })
          )
        )?.payload?.createAccommodation;
        isNewDestination.current = Boolean(locationMapPinId);
        // Set action props to handle undo action if needed
        setActiveActionProps({
          ...activeActionProps,
          locationId,
          locationMapPinId,
          hotelId: hotel?.id,
          hotelMapPinId: mapPinDetails?.id,
        });
      }
      setSelectedLocation(null);
      setPopperNotification(locationTitle);
    } catch (err) {
      // handle error
      setIsTripSelectorOpen(true);
    }
    setAddToTripLoader(false);
  };

  const handleAddToTripButtonClick = async (e) => {
    e.stopPropagation();

    // if duplicate location exist then open the tripSelector else open notification popup
    if (locationDetails?.length > 1 || saved) {
      setIsTripSelectorOpen(true);
    } else {
      await handleAddAccommodationToTripClick();
    }
  };

  useEffect(() => setSaved(isSaved), [isSaved]);

  useEffect(() => {
    const updatedSearchProps = updateSearchUrl(location.search);
    dispatch(actions.Bookings.setHotelDetailsSearchProps(updatedSearchProps));
  }, []);

  useEffect(() => {
    refreshHotel();
  }, [hotelDetails?.searchProps]);

  // load trip for saved items if prevItem is defined
  useEffect(() => {
    if (prevTripId) {
      dispatch(
        getCompleteTrip({
          tripId: prevTripId,
          ignoreRejection: true,
        })
      );
    }
  }, []);

  return (
    <MapProvider>
      <div className={classes.page} id="hotel-details-page">
        {loading ? (
          <LoadingLayout />
        ) : (
          hotelDetails?.data[hotelKey] && (
            <>
              <HotelNavbar
                saved={saved}
                handleSaveClick={handleSaveClick}
                handleAddToTripButtonClick={handleAddToTripButtonClick}
              />
              <div style={{ marginTop: 16 }} />

              <div className={classes.controlledWidth}>
                <div
                  style={{
                    display: isMd ? 'flex' : 'block',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: isMobile ? '10px' : '0px',
                    marginBottom: isMobile ? '10px' : '0px',
                  }}>
                  {isMd && (
                    <div>
                      <Typography variant="h2">
                        {hotelDetails?.data[hotelKey]?.name}&apos;s Profile
                      </Typography>
                    </div>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '20px',
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}>
                    {!isMd && prevTripId && (
                      <Typography
                        variant="h5"
                        className={classes.saveIconContainer}
                        onClick={handleSaveClick}>
                        <BookmarkRounded className={classes.saveIcon} />
                        {saved ? 'Saved' : 'Save'}
                      </Typography>
                    )}
                    <div style={{ position: 'relative' }}>
                      {!isMobile && (
                        <OutlinedButton onClick={handleAddToTripButtonClick}>
                          {addToTripLoader ? (
                            <CircularProgress size="19px" />
                          ) : (
                            'Add to trip'
                          )}
                        </OutlinedButton>
                      )}
                      {isTripSelectorOpen && (
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 100000,
                            left: isMobile ? '-295px' : '-195px',
                            top: isMd ? (isMobile ? '-70px' : '30px') : '50px',
                            width: '300px',
                          }}>
                          <TripSelectorStays
                            setIsTripSelectorOpen={setIsTripSelectorOpen}
                            handleLocationUpdate={async (locationProps) => {
                              if (
                                locationProps?.locationId ===
                                activeActionProps?.locationId
                              )
                                return;
                              setSelectedLocation(locationProps);
                              await handleUndoAction();
                              await handleAddAccommodationToTripClick(
                                locationProps
                              );
                            }}
                            defaultLocationId={getStayLocationInfo()[0] || null}
                            locationsList={validLocations}
                          />
                        </div>
                      )}

                      <NotificationPopper
                        open={Boolean(popperNotification)}
                        onClose={(e, reason) => {
                          if (reason === 'timeout') {
                            setActiveActionProps(null);
                          }
                          setPopperNotification(null);
                        }}
                        onCancel={async () => {
                          setPopperNotification(null);
                          if (validLocations?.length > 1) {
                            setIsTripSelectorOpen(true);
                          } else {
                            setAddToTripLoader(true);
                            await handleUndoAction();
                            setAddToTripLoader(false);
                          }
                        }}
                        destinationName={popperNotification}
                        isNewDestination={isNewDestination?.current}
                        cancelButtonLabel={
                          validLocations?.length <= 1 ? 'Undo' : 'Change'
                        }
                        paperStyle={{
                          marginLeft: isMobile ? '-180px' : '0',
                          marginTop: isMobile ? '-100px' : '0',
                        }}
                      />
                    </div>
                  </div>
                </div>

                <HotelImageContainer />
                <HotelHeading saved={saved} handleSaveClick={handleSaveClick} />
                <div style={{ marginTop: 48 }} />
                <HotelDealsContainer />
                <div
                  style={{
                    marginTop: 48,
                    borderTop: '1px solid #D9D9D9',
                    paddingTop: 32,
                  }}>
                  <HotelPerksSection />
                </div>
              </div>
              <div style={{ marginTop: 48 }} />
              <HotelReviewSection />
              <div
                className={classes.controlledWidth}
                style={{ marginTop: 48 }}>
                <HotelLocationSection />
                <div style={{ marginTop: 48 }} />
                <HotelAmenities />
              </div>
            </>
          )
        )}
      </div>
    </MapProvider>
  );
}

export default HotelDetailsPage;
