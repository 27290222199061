import config from '../../../config';
import getCfConnector from '../../../cfConnector';

/*
  Cached calls on session storage for infinite load.

  * shouldAltSearch -> search using OR instead of AND
*/
const getPoiRecommendations = async ({
  coordinates = {},
  filterTags = [],
  // isRefetch = false,
  shouldAltSearch = false,
  sortOption = 'RATING',
}) => {
  // if (isRefetch && window.sessionStorage.getItem('final-page') === 'true')
  //   return [];
  // const currOffset = isRefetch
  //   ? window.sessionStorage.getItem('current-search-offset')
  //   : 0;
  const queryOptions = {
    categories: filterTags?.join('+'),
    lat: coordinates?.latitude,
    lon: coordinates?.longitude,
    shouldAltSearch,
    sortOption,
  };
  const queryURL = `${
    config.recommendationsAPI
  }/recommendations?${new URLSearchParams(queryOptions).toString()}`;
  const poisResult = await (await getCfConnector())
    .get(queryURL)
    .then(({ data: response }) => {
      return {
        pois: response,
      };
    });
  return poisResult;
};

export default getPoiRecommendations;
