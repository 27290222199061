import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BlockHeader from '../BlockHeader';
import actions from '../../../../redux/actions';
import {
  getLocalItemId,
  isLocalItem,
  parseISODate,
  removeTimezoneOffset,
} from '../../../../utils';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import ITEM_TYPES from '../../../../const';
import {
  createItem,
  deleteItem,
  updateItem,
} from '../../../../redux/slices/Item';
import { stepName } from '../../../../assets/onboarding/steps';
import useTour from '../../../molecules/Tour/useTour';

function Heading({
  parentId,
  headingId,
  dragHandleProps,
  index,
  isDragging,
  isSectionHighlight,
  isParentDragging,
}) {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { slug: tripId } = useParams();
  const heading = useSelector((state) => state.items.items[headingId]);
  const isNewHeading = useSelector(
    (state) => state.Item.newItemId === headingId
  );
  const isHighlighted = useSelector(
    (state) => state.View.highlightedHeading === headingId
  );

  const {
    inProgress: tourInProgress,
    tourName,
    currentStep: tourCurrentStep,
  } = useSelector((state) => state.View.tour);
  const { getTour } = useTour();

  const handleDeleteSection = () => {
    dispatch(
      deleteItem({
        variables: {
          tripId,
          parentId,
          id: headingId,
        },
        tripId,
      })
    );
  };

  const updateTitle = async (title) => {
    if ((title && title !== '') || heading?.children?.length) {
      if (isLocalItem(headingId, ITEM_TYPES.HEADING)) {
        const res = await dispatch(
          createItem({
            variables: {
              title,
              parentId,
              tripId,
              type: ITEM_TYPES.HEADING,
              index,
            },
            localId: headingId,
            parentId,
            tripId,
            index,
          })
        );
        dispatch(
          actions.View.setCommandBarV2({
            activeSectionId: res?.payload?.createItem?.id,
          })
        );

        phTrackEvent({
          event: EVENTS.PLAN_HEADING.ADD_START,
        });
      } else {
        if (heading?.title === title) return;
        dispatch(
          updateItem({
            variables: {
              id: headingId,
              type: ITEM_TYPES.HEADING,
              title,
            },
          })
        );
      }
    } else {
      // Delete this section
      handleDeleteSection();
    }
    getTour().onActiveStep(stepName.MAP_SEARCH_BAR).openTour();
    getTour()
      .onActiveTour(['emptyTripFlowMobile', 'blankTripFlowMobile'])
      .onActiveStep(stepName.ADD_TODO)
      .closeTour()
      .openTour({
        delay: 3500,
      });
  };

  const updateDate = (event) => {
    dispatch(
      updateItem({
        variables: {
          id: headingId,
          tripId,
          parentId,
          type: ITEM_TYPES.HEADING,
          content: {
            startDate:
              event.from && removeTimezoneOffset(event.from).toISOString(),
            endDate: event.to && removeTimezoneOffset(event.to).toISOString(),
          },
        },
      })
    );
  };

  const addLocalHeading = async () => {
    const newHeading = {
      id: getLocalItemId(ITEM_TYPES.HEADING),
      type: ITEM_TYPES.HEADING,
      title: '',
    };
    dispatch(
      actions.Item.createLocalItem({
        localId: newHeading?.id,
        item: newHeading,
        parentId,
        index: index + 1,
        tripId,
        updateNewItemId: true,
      })
    );

    if (parentId === tripId) {
      dispatch(
        actions.TripsV2.addChildToTrip({
          tripId,
          childId: newHeading?.id,
          index: index + 1,
        })
      );
    }
  };

  // To check if the section header needs to be focused on input
  useEffect(() => {
    if (isNewHeading) {
      inputRef?.current?.focus();
      dispatch(actions.Item.setNewItemId(null));
    }
  }, [isNewHeading]);

  return (
    <div
      id={`todo-header-${headingId}`}
      onClick={() => {
        if (
          tourInProgress &&
          tourName.startsWith('quickStartFlow') &&
          tourCurrentStep === 0
        ) {
          dispatch(actions.View.setTourCurrentStep(1));
        }
      }}>
      <BlockHeader
        updateDate={updateDate}
        updateTitle={updateTitle}
        inputRef={inputRef}
        dragHandleProps={dragHandleProps}
        defaultTitle={heading?.title}
        hideActions={isLocalItem(headingId, ITEM_TYPES.HEADING) || null}
        inputProps={{
          placeholder: 'New Heading',
          inputProps: { style: { padding: 2 } },
          name: 'sectionName',
        }}
        isHighlight={isHighlighted}
        iconDatePickerProps={{
          useRange: true,
          defaultDate: {
            from: heading?.content?.startDate
              ? parseISODate(heading?.content?.startDate)
              : null,
            to: heading?.content?.endDate
              ? parseISODate(heading?.content?.endDate)
              : null,
          },
          placement: 'bottom-end',
        }}
        handleDelete={handleDeleteSection}
        handleAdd={addLocalHeading}
        isDragging={isDragging}
        isSectionHighlight={isSectionHighlight}
        location={parentId}
        section={headingId}
        showDatePicker={!isLocalItem(headingId, ITEM_TYPES.HEADING)}
        isParentDragging={isParentDragging}
      />
    </div>
  );
}

export default Heading;
