import React, { useState, useEffect } from 'react';
import { Typography, Button, Backdrop } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { parse, format } from 'date-fns';
import { useParams } from 'react-router-dom';
import DealsList from './DealsList';
import CheckInCheckOutButton from '../CheckInCheckOutButton';
import RoomAndGuestButton from '../RoomAndPeopleButton';
import { PeopleOutlined } from '../../../../atoms/Icon';

import guestToString, { stringToGuest } from '../bookingsUtils';
import actions from '../../../../../redux/actions';

const useStyles = makeStyles(() => ({
  guestSelector: {
    backgroundColor: '#FFFFFF',
    color: '#4E4E4E',
    border: '1px solid #D9D9D9',
    borderRadius: '24px',
    maxHeight: '40px',
    margin: '4px',
    '&:hover': {
      border: '1px solid #D9D9D9',
      backgroundColor: '#F4F4F4',
      color: '#4E4E4E',
    },
  },
}));

function DealsContainer() {
  const classes = useStyles();

  const { slug: hotelKey } = useParams();
  const dispatch = useDispatch();
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );
  const {
    checkInDate = null,
    checkOutDate = null,
    rooms = '2',
  } = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.searchProps || {}
  );

  const [deals, setDeals] = useState([]);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [roomAnchorEl, setRoomAnchorEl] = useState(null);

  const getRoomPriceMap = (hotelDeals) =>
    hotelDeals?.reduce((roomPriceMap, deal) => {
      if (!(deal?.roomName in roomPriceMap)) {
        roomPriceMap[deal?.roomName] = [];
      }
      roomPriceMap[deal?.roomName].push(deal);
      return roomPriceMap;
    }, {});

  const searchDates = {
    from: checkInDate && parse(checkInDate, 'yyyy-MM-dd', new Date()),
    to: checkOutDate && parse(checkOutDate, 'yyyy-MM-dd', new Date()),
  };

  const updateSearchProps = (searchProps) => {
    dispatch(
      actions.Bookings.setHotelDetailsSearchProps({
        checkInDate,
        checkOutDate,
        rooms,
        ...searchProps,
      })
    );
  };

  const guestCount = stringToGuest(rooms);

  useEffect(() => {
    setDeals(getRoomPriceMap(hotelDetails?.results));
  }, [hotelDetails?.results]);

  return (
    <div id="deals-container">
      <Typography variant="h2">Take a look at the options...</Typography>

      <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
        <CheckInCheckOutButton
          date={searchDates}
          updateDate={({ from, to }) =>
            from &&
            to &&
            updateSearchProps({
              checkInDate: from && format(from, 'yyyy-MM-dd'),
              checkOutDate: to && format(to, 'yyyy-MM-dd'),
            })
          }
          overlayOpen={overlayOpen}
          setOverlayOpen={setOverlayOpen}
          dateStringFormat="eee, dd/MM/yy"
          disableScroll
        />
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: roomAnchorEl ? 1220 : 1099,
          }}
          open={overlayOpen}
          onClick={() => {
            setRoomAnchorEl(null);
            setOverlayOpen(false);
          }}
        />
        {!roomAnchorEl && (
          <Button
            startIcon={
              <PeopleOutlined sx={{ color: '#1D1D1D', fontSize: '30px' }} />
            }
            className={classes.guestSelector}
            onClick={(e) => {
              setRoomAnchorEl(e.currentTarget);
              setOverlayOpen(true);
            }}>
            {guestCount?.total}
          </Button>
        )}
        <RoomAndGuestButton
          anchorEl={roomAnchorEl}
          setAnchorEl={setRoomAnchorEl}
          guestCount={guestCount}
          setGuestCount={(newGuestCount) => {
            setOverlayOpen(false);
            if (newGuestCount) {
              updateSearchProps({
                rooms: guestToString(newGuestCount),
              });
            }
          }}
          containerProps={{
            style: { display: roomAnchorEl ? 'flex' : 'none' },
          }}
        />
      </div>

      <div style={{ marginTop: 32 }}>
        {Object.keys(deals || {})?.map((roomName, idx) => (
          <div style={{ marginTop: idx === 0 ? 0 : 16 }}>
            <DealsList
              deals={deals[roomName]}
              roomName={roomName}
              numberOfNights={hotelDetails?.numberOfNights}
              key={idx}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default DealsContainer;
