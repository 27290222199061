import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { CheckCircleRounded } from '@mui/icons-material';
import { ChevronDownIcon } from '@iconicicons/react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../../../redux/actions';
import ImageViewModal from './ImageContainer/ImageViewModal';
import CustomCarousel from '../../../../../molecules/Carousel';
import { getCurrencyFormatter, getSortedAmenities } from '../utils';
import { EVENTS, phTrackEvent } from '../../../../../../analytics';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    border: '1px solid #D9D9D9',
    height: 230,
    width: '100%',
    display: 'flex',
    borderRadius: 8,
    overflow: 'hidden',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  roomSelected: {
    border: `1px solid ${palette.primary.main}`,
  },
  whiteFilter: {
    filter: 'opacity(0.6)',
  },
  roomImage: {
    objectFit: 'cover',
    height: 230,
    width: '100%',
    borderRadius: 4,
    background: 'linear-gradient(180deg, #000000 0%, #000000 20%)',
  },
}));

function RoomListing({ roomPackageIds }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  const { slug: hotelKey } = useParams();
  const allPackageIds = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelKey]?.packages?.map(
      (p) => p?.packageId
    )
  );

  const packageInCartId = useSelector((state) =>
    (state.Bookings.cart.packages || [])?.find(
      (r) => allPackageIds?.includes(r) && roomPackageIds?.includes(r)
    )
  );

  const [selectedPackageId, setSelectedPackageId] = useState(
    roomPackageIds?.length > 0 ? packageInCartId || roomPackageIds[0] : null
  );

  const roomPackage = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelKey]?.packages?.find(
      (p) => p?.packageId === selectedPackageId
    )
  );
  const roomPackages = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelKey]?.packages?.filter((p) =>
      roomPackageIds?.includes(p?.packageId)
    )
  );

  const additionalRoomInfo = useSelector(
    (state) =>
      state.Bookings.hotelDetailsPage?.data[hotelKey]?.rooms[
        roomPackage?.rooms[0]?.key
      ]
  );
  const room = {
    ...roomPackage?.rooms[0],
    ...additionalRoomInfo,
  };

  const roomAmenities = getSortedAmenities([
    ...(room?.amenities || []),
    roomPackage?.rooms[0]?.basis,
  ]);

  const numberOfNights = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.searchProps?.numberOfNights
  );

  const selectedPackageLength = useSelector(
    (state) =>
      state.Bookings.cart.packages?.filter((p) => allPackageIds?.includes(p))
        ?.length
  );

  const [popperAnchorEl, setPopperAnchorEl] = useState(null);

  const handlePackageSelection = () => {
    phTrackEvent({ event: EVENTS.EXPLORE_STAY_DETAIL.ROOM_ADD });
    setPopperAnchorEl(null);
    if (!packageInCartId)
      dispatch(actions.Bookings.addPackage(selectedPackageId));
  };

  // const basePrice = useMemo(
  //   () =>
  //     roomPackages?.length > 0 &&
  //     (roomPackages[0]?.totalPrice?.price || 0) / (numberOfNights || 1),
  //   [roomPackages, numberOfNights]
  // );

  const EXTRAS_COPY_MAP = {
    RO: {
      title: 'No Extras',
      subtitle: '',
    },
    BB: {
      title: 'Breakfast',
      subtitle: '',
    },
    HB: {
      title: 'Half board',
      subtitle: 'Includes: Breakfast and dinner',
    },
    FB: {
      title: 'Full board',
      subtitle: 'Includes: Breakfast, lunch and dinner',
    },
  };

  const REFUNDABLE_STATUS = {
    NON_REFUNDABLE: 'Non Refundable',
    REFUNDABLE: 'Refundable',
  };

  const basePrices = useMemo(() => {
    const nrPackages = roomPackages
      ?.filter((p) => !p?.isRefundable)
      ?.sort(
        (a, b) => (a?.totalPrice?.price || 0) - (b?.totalPrice?.price || 0)
      )
      ?.map((p) => p?.totalPrice?.price || 0);
    const rPackages = roomPackages
      ?.filter((p) => p?.isRefundable)
      ?.sort(
        (a, b) => (a?.totalPrice?.price || 0) - (b?.totalPrice?.price || 0)
      )
      ?.map((p) => p?.totalPrice?.price || 0);
    return {
      [REFUNDABLE_STATUS.NON_REFUNDABLE]:
        (nrPackages?.length > 0 && nrPackages[0]) || -1,
      [REFUNDABLE_STATUS.REFUNDABLE]:
        (rPackages?.length > 0 && rPackages[0]) || -1,
    };
  });

  const getExtrasCopyFromPackage = (roomBasis) => {
    return Object?.keys(EXTRAS_COPY_MAP)
      ?.filter((key) => roomBasis?.includes(key))
      ?.map((key) => EXTRAS_COPY_MAP[key])[0];
  };

  const extras = useMemo(
    () =>
      roomPackages?.map((p) => ({
        totalPrice: p?.totalPrice?.price,
        currency: p?.totalPrice?.currency,
        basis: p?.rooms[0]?.basis,
        packageId: p?.packageId,
        copy: getExtrasCopyFromPackage(p?.rooms[0]?.basis),
        refundableUntil:
          p?.refundableUntil && format(new Date(p?.refundableUntil), 'MMMM dd'),
        isRefundable: p?.isRefundable,
      })) || [],
    [roomPackages]
  );

  const extrasMap = useMemo(
    () =>
      extras?.reduce((acc, curr) => {
        const rStatus = curr?.isRefundable
          ? REFUNDABLE_STATUS.REFUNDABLE
          : REFUNDABLE_STATUS.NON_REFUNDABLE;
        // return only cheapest packages for each room type
        if (
          curr?.basis in acc &&
          rStatus in acc[curr?.basis] &&
          curr?.totalPrice > acc[curr?.basis][rStatus]?.totalPrice
        ) {
          return acc;
        }
        acc[curr?.basis] = {
          ...(acc[curr?.basis] || {}),
          [rStatus]: curr,
        };
        return acc;
      }, {}),
    [extras]
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      className={`${classes.root} ${
        packageInCartId ? classes.roomSelected : ''
      } ${
        selectedPackageLength > 0 && !packageInCartId ? classes.whiteFilter : ''
      }`}>
      <Grid
        xs={12}
        sm={6}
        item
        height="100%"
        onClick={() => setImageModalOpen(true)}
        sx={{
          cursor: 'pointer',
        }}>
        {imageModalOpen && (
          <ImageViewModal
            open={imageModalOpen}
            images={room?.images}
            handleClose={() => {
              setImageModalOpen(false);
              setActiveImage(0);
            }}
            activeImage={activeImage}
            setActiveImage={setActiveImage}
            disableGrid
          />
        )}
        <CustomCarousel
          showArrows
          images={room?.images?.map((img) => ({ small: img }))}
          customClassName={classes.roomImage}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        container
        direction="column"
        p={isMobile ? '12px' : '16px 16px 24px 32px'}>
        <Box display="flex" flexDirection="row">
          <Typography
            variant="h2"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flex: 1,
              ...(isMobile ? { fontSize: 18 } : {}),
            }}>
            {room?.name}
          </Typography>
          {roomPackage?.isRefundable && (
            <div>
              <Typography
                noWrap
                variant="h5"
                sx={{
                  float: 'right',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 400,
                  minWidth: 150,
                  ...(isMobile ? { fontSize: 12 } : {}),
                }}>
                <CheckCircleRounded sx={{ mr: 0.5, color: 'success.main' }} />
                Free cancellation
              </Typography>
            </div>
          )}
        </Box>
        <Box
          flexDirection="row"
          display="flex"
          mt={isMobile ? 1 : 2}
          flexWrap="wrap"
          gap={1}>
          {roomAmenities?.slice(0, 3)?.map((property) => (
            <Typography
              variant="caption"
              color="#222"
              fontSize={16}
              alignItems="center"
              display="flex"
              noWrap
              mr={isMobile ? 1.5 : 3}>
              <property.icon
                sx={{
                  mr: 1,
                  color: '#222',
                  height: 20,
                  width: 20,
                  ...(isMobile ? { height: 16, width: 16 } : {}),
                }}
              />
              {property?.text}
            </Typography>
          ))}
        </Box>
        <Box
          flexDirection={isMobile ? 'column' : 'row'}
          display="flex"
          mt={isMobile ? 1 : 'auto'}
          p="0 8px 0 0">
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant={isMobile ? 'h3' : 'h2'} color="#222">
              {getCurrencyFormatter(roomPackage?.totalPrice?.currency)?.format(
                (
                  (roomPackage?.totalPrice?.price || 0) / (numberOfNights || 1)
                ).toFixed(2)
              )}{' '}
              / night
            </Typography>
            <Typography variant={isMobile ? 'caption' : 'h5'} color="#8A8A8A">
              {getCurrencyFormatter(roomPackage?.totalPrice?.currency)?.format(
                roomPackage?.totalPrice?.price
              )}{' '}
              total incl. tax
            </Typography>
          </Box>
          <Popover
            onClose={() => setPopperAnchorEl(null)}
            id={roomPackage?.packageId}
            open={Boolean(popperAnchorEl)}
            anchorEl={popperAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transition>
            <Box
              sx={{
                border: '1px solid #D9D9D9',
                borderRadius: '8px',
                padding: '24px',
                minWidth: 351,
              }}>
              <Typography variant="h3">Cancellation Policy</Typography>
              <Box>
                <RadioGroup sx={{ marginTop: 1 }} value={selectedPackageId}>
                  {Object?.keys(extrasMap[roomPackage?.rooms[0]?.basis])?.map(
                    (refundableStatus) => {
                      const currPackage =
                        extrasMap[roomPackage?.rooms[0]?.basis][
                          refundableStatus
                        ];

                      const minPrice = Math.min(
                        ...(Object?.values(
                          extrasMap[roomPackage?.rooms[0]?.basis]
                        )?.map((p) => p?.totalPrice) || [])
                      );

                      return (
                        <FormControlLabel
                          value={currPackage?.packageId}
                          onChange={() =>
                            setSelectedPackageId(currPackage?.packageId)
                          }
                          control={<Radio disableRipple disableFocusRipple />}
                          labelPlacement="end"
                          sx={{
                            margin: 0,
                            '& .MuiRadio-root': {
                              paddingLeft: 0,
                              '& .MuiSvgIcon-root': {
                                height: 20,
                                width: 20,
                              },
                            },
                            '& .MuiFormControlLabel-label': {
                              width: '100%',
                            },
                          }}
                          label={
                            <Box
                              sx={{
                                width: '100%',
                              }}
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center">
                              <Box display="flex" flexDirection="column">
                                <Typography
                                  variant="caption"
                                  color="text.secondary">
                                  {currPackage?.isRefundable
                                    ? `Refundable until ${currPackage?.refundableUntil}`
                                    : 'Non-refundable'}
                                </Typography>
                              </Box>
                              <Typography variant="h5">
                                +{' '}
                                {getCurrencyFormatter(
                                  currPackage?.currency
                                )?.format(
                                  ((currPackage?.totalPrice || 0) - minPrice) /
                                    (numberOfNights || 1)
                                )}
                              </Typography>
                            </Box>
                          }
                        />
                      );
                    }
                  )}
                </RadioGroup>
                <Typography variant="h3">Extras</Typography>
                <RadioGroup
                  sx={{ marginTop: 1 }}
                  value={roomPackage?.rooms[0]?.basis}>
                  {Object.values(extrasMap)?.map((extrasMapItem) => {
                    const rStatus = roomPackage?.isRefundable
                      ? REFUNDABLE_STATUS.REFUNDABLE
                      : REFUNDABLE_STATUS.NON_REFUNDABLE;
                    const extra =
                      extrasMapItem[rStatus] ||
                      extrasMapItem[REFUNDABLE_STATUS.NON_REFUNDABLE] ||
                      extrasMapItem[REFUNDABLE_STATUS.REFUNDABLE];

                    const minPrice = basePrices[rStatus];
                    return (
                      <FormControlLabel
                        value={extra?.basis}
                        onChange={() => setSelectedPackageId(extra?.packageId)}
                        control={<Radio disableRipple disableFocusRipple />}
                        labelPlacement="end"
                        sx={{
                          margin: 0,
                          '& .MuiRadio-root': {
                            paddingLeft: 0,
                            '& .MuiSvgIcon-root': {
                              height: 20,
                              width: 20,
                            },
                          },
                          '& .MuiFormControlLabel-label': {
                            width: '100%',
                          },
                        }}
                        label={
                          <Box
                            sx={{
                              width: '100%',
                            }}
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center">
                            <Box display="flex" flexDirection="column">
                              <Typography
                                variant="caption"
                                color="text.secondary">
                                {extra?.copy?.title}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                                fontSize={10}>
                                {extra?.copy?.subtitle}
                              </Typography>
                            </Box>
                            <Typography variant="h5">
                              +{' '}
                              {getCurrencyFormatter(extra?.currency)?.format(
                                ((extra?.totalPrice || 0) - minPrice) /
                                  (numberOfNights || 1)
                              )}
                            </Typography>
                          </Box>
                        }
                      />
                    );
                  })}
                </RadioGroup>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mt={2}
                  alignItems="center">
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h2">
                      {getCurrencyFormatter(
                        roomPackage?.totalPrice?.currency
                      )?.format(
                        (roomPackage?.totalPrice?.price || 0) /
                          (numberOfNights || 1)
                      )}{' '}
                      / night
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      fontWeight={200}>
                      {getCurrencyFormatter(
                        roomPackage?.totalPrice?.currency
                      )?.format(roomPackage?.totalPrice?.price)}{' '}
                      total incl. tax
                    </Typography>
                  </Box>
                  <Button
                    onClick={handlePackageSelection}
                    style={{
                      minWidth: 128,
                    }}>
                    Reserve
                  </Button>
                </Box>
              </Box>
            </Box>
          </Popover>
          {selectedPackageLength === 0 && (
            <Box
              display="flex"
              alignItems="center"
              ml={isMobile ? 0 : 'auto'}
              mt={isMobile ? 1 : 0}>
              <Button
                onClick={(e) => {
                  if (roomPackageIds?.length > 1) {
                    setPopperAnchorEl(e?.currentTarget);
                  } else {
                    handlePackageSelection();
                  }
                }}
                disabled={packageInCartId}
                sx={{
                  width: isMobile ? '100%' : 170,
                  py: 1,
                  backgroundColor: popperAnchorEl ? 'primary.light' : 'auto',
                }}>
                {packageInCartId
                  ? 'Added to cart'
                  : `Book ${roomPackage?.rooms?.length} room${
                      roomPackage?.rooms?.length > 1 ? 's' : ''
                    }`}
                {roomPackageIds?.length > 1 && (
                  <ChevronDownIcon
                    style={{
                      width: 20,
                      height: 20,
                      color: '#fff',
                    }}
                  />
                )}
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default RoomListing;
