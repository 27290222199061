import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ConfettiExplosion from 'react-confetti-explosion';
import Tour from 'reactour';
import { makeStyles } from '@mui/styles';
import Item from '../Item';
import AddModuleButton from '../../molecules/AddModuleButton';
import ITEM_TYPES, { DROP_LEVELS, DROP_TYPES } from '../../../../const';
import { moveItem } from '../../../../redux/slices/Item';
import Spacer from '../../molecules/Spacer';
import CommandBarV2 from '../CommandBar/index';
import actions from '../../../../redux/actions';
import { getLocalItemId } from '../../../../utils';
import { getSteps, stepName, steps } from '../../../../assets/onboarding/steps';
import useTour from '../../../molecules/Tour/useTour';
import { phTrackEvent } from '../../../../analytics';
import GuideStep from '../../../atoms/GuideStep';
// import DroppableDnd from '../DragAndDrop/Droppable';

const useStyles = makeStyles((theme) => ({
  instructionsBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0% 9.6% 0% calc(9.6% + 1.2rem + 6px)',
    [theme.breakpoints.down('sm')]: {
      padding: '0% 9.6% 0% 9.6%',
    },
  },
  instruction: {
    marginTop: '4vw',
    color: '#8A8A8A',
  },
}));

function TripItemsList({ tripId, isCommandBarEnabled }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDragging, setIsDragging] = useState(false);
  const trip = useSelector((state) => state.tripsAtc.tripsAtc[tripId]);
  const tripItems = trip?.children;

  const tourStep = useSelector((state) => state.View.tour.currentStep);
  const tourStepName = useSelector((state) => state.View.tour.currentStepName);
  const isTourOpen = useSelector((state) => state.View.tour.isOpen);
  const tourName = useSelector((state) => state.View.tour.tourName);
  const tourVariant = useSelector((state) => state.View.tour.variant);
  const lastStepSetAt = useSelector((state) => state.View.tour.lastStepSetAt);
  const [triggerConfetti, setTriggerConfetti] = useState(false);
  const showConfetti = useSelector((state) => state.View.showConfetti);
  const tourSteps =
    getSteps(tourName, {
      filterWithFeatureFlags: true,
      variant: tourVariant,
    }) || steps[tourName];

  const { getTour } = useTour();

  const getDropType = (droppableId) => {
    if (droppableId.includes('DROPPABLE-ITEM')) {
      return [droppableId.split('-')[2], DROP_TYPES.ITEM];
    }
    return [droppableId.split('-')[1], DROP_TYPES.TRIP];
  };

  const getItemIdFromDraggable = (draggableId) => {
    return draggableId.split('-')[1];
  };

  const handleAddItem = (itemType, childType, index, parentId = tripId) => {
    let itemContent = {};
    // handle accommodation
    if (itemType === ITEM_TYPES.ACCOMMODATION) {
      dispatch(actions.Item.setOpenItemSearchbar({ id: parentId, index }));
      return;
    }
    if (itemType === ITEM_TYPES.TRANSPORTATION) {
      const localConnectionId = getLocalItemId(ITEM_TYPES.TRANSPORT);
      dispatch(
        actions.Item.createLocalItem({
          localId: localConnectionId,
          item: {
            id: localConnectionId,
            type: ITEM_TYPES.TRANSPORT,
          },
          ignoreParentUpdate: true,
          parentId: tripId,
          index,
          tripId,
        })
      );
      itemContent = {
        connections: [localConnectionId],
        transportType: childType,
      };
    }
    const item = {
      id: getLocalItemId(itemType),
      tripId,
      type: itemType,
      content: itemContent,
    };

    dispatch(
      actions.Item.createLocalItem({
        localId: item.id,
        parentId,
        item,
        tripId,
        index,
        updateNewItemId: true,
      })
    );
    if (parentId === tripId) {
      dispatch(
        actions.TripsV2.addChildToTrip({ tripId, childId: item.id, index })
      );
    }
  };

  const getItemTypeFromDraggable = (draggableId) => {
    if (draggableId === 'commandBar-activity') {
      return ITEM_TYPES.ACTIVITY;
    }
    if (draggableId === 'commandBar-hotel') {
      return ITEM_TYPES.ACCOMMODATION;
    }
    if (draggableId === 'commandBar-transport') {
      return ITEM_TYPES.TRANSPORTATION;
    }
    if (draggableId === 'commandBar-heading') {
      return ITEM_TYPES.HEADING;
    }
    return ITEM_TYPES.DESTINATION;
  };

  const getTransportType = (droppableId) => {
    return droppableId.split('-')[1];
  };

  const handleDragEnd = ({ source, destination, draggableId }) => {
    if (
      !destination &&
      draggableId.includes('commandBar') &&
      isCommandBarEnabled &&
      tripItems?.length === 0
    ) {
      const commandBarItemType = getItemTypeFromDraggable(draggableId);
      if (
        commandBarItemType === ITEM_TYPES.DESTINATION ||
        commandBarItemType === ITEM_TYPES.TRANSPORTATION ||
        commandBarItemType === ITEM_TYPES.ACCOMMODATION
      ) {
        // create new item
        handleAddItem(
          commandBarItemType,
          commandBarItemType === ITEM_TYPES.TRANSPORTATION
            ? getTransportType(source?.droppableId)
            : null,
          0,
          getDropType(`TRIP-${tripId}`)[0]
        );
      }

      return;
    }

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (draggableId.includes('commandBar')) {
      const commandBarItemType = getItemTypeFromDraggable(draggableId);
      // create new item
      handleAddItem(
        commandBarItemType,
        commandBarItemType === ITEM_TYPES.TRANSPORTATION
          ? getTransportType(source?.droppableId)
          : null,
        destination.index,
        getDropType(destination.droppableId)[0]
      );
      return;
    }

    setIsDragging(false);

    const [destinationId, destinationDropType] = getDropType(
      destination.droppableId
    );
    const [sourceId, sourceDropType] = getDropType(source.droppableId);
    const itemId = getItemIdFromDraggable(draggableId);

    dispatch(
      moveItem({
        variables: {
          sourceId,
          targetId: destinationId,
          sourceType: sourceDropType,
          targetType: destinationDropType,
          itemId,
          targetIndex: destination.index,
        },
        sourceIndex: source.index,
      })
    );
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  useEffect(() => {
    if (showConfetti) {
      setTriggerConfetti(true);
      dispatch(actions.View.setShowConfetti(false));
    }
  }, [showConfetti]);

  useEffect(() => {
    if (tourStepName === stepName.FIRST_ACTIVITY_HOVER_INFO && isTourOpen) {
      const container = document.getElementsByClassName(
        'activity-inlineblade-container'
      )[0];
      container?.addEventListener('wheel', (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      });
    }
    if (tourStepName === stepName.MAP_SEARCH_BAR) {
      globalThis?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [tourStepName, isTourOpen]);

  return (
    <DndProvider backend={HTML5Backend} options={{ enableTouchEvents: true }}>
      <DragDropContext
        onDragEnd={handleDragEnd}
        onBeforeDragStart={handleDragStart}>
        {tourName && tourSteps?.length > 0 && (
          <Tour
            disableFocusLock={[
              stepName.FIRST_ACITVITY_OPEN_DETAILS,
              stepName.MAP_SEARCH_BAR,
              stepName.ADD_TODO,
              stepName.SET_HEADING,
              stepName.ENTER_DESTINATION,
              stepName.TRIP_NAME_INTRO_STEP,
              stepName.COMMAND_BAR_INTRO_STEP,
            ].includes(tourStepName)}
            scrollDuration={110}
            scrollOffset={0}
            rounded={5}
            getCurrentStep={(step) => {
              if (!tourSteps[step]) {
                // return dispatch(actions.View.setTourCurrentStep(2));
                return getTour().complete();
              }
              return dispatch(
                actions.View.setTourCurrentStepName(tourSteps[step].stepName)
              );
            }}
            goToStep={tourStep}
            startAt={tourStep}
            highlightedMaskClassName="tour-mask"
            onRequestClose={() => {
              if (Date.now() - lastStepSetAt < 500) {
                return;
              }
              if (tourStep === tourSteps.length - 1) {
                return getTour().complete();
              }
              if (!getTour().steps[tourStep]) {
                // return dispatch(actions.View.setTourCurrentStep(2));
                return getTour().complete();
              }
              if (getTour().steps[tourStep]?.waitForInteraction) {
                getTour().closeTour();
              }
              if (!getTour().steps[tourStep]?.disableIncrement) {
                getTour().goToNextStep();
              }
              return null;
            }}
            className="tour-mask"
            CustomHelper={(props) => {
              const {
                heading = '',
                description = '',
                footer = '',
                position = 'top',
                arrowStyles = {},
                phEvent = null,
                containerStyles = {},
              } = props.content;
              if (phEvent) {
                phTrackEvent({
                  event: phEvent,
                });
              }
              return (
                <GuideStep
                  heading={heading}
                  description={description}
                  footer={footer}
                  position={position}
                  currentStep={
                    Number.isNaN(getTour().getCount().completed)
                      ? props.current + 1
                      : getTour().getCount().completed
                  }
                  totalSteps={
                    Number.isNaN(getTour().getCount().total)
                      ? props.totalSteps
                      : getTour().getCount().total
                  }
                  arrowStyles={arrowStyles}
                  containerStyles={containerStyles}
                />
              );
            }}
            steps={tourSteps}
            isOpen={isTourOpen}
          />
        )}
        {triggerConfetti && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: '50%',
              width: '100%',
              height: '100%',
              zIndex: 1000,
            }}>
            <ConfettiExplosion
              onComplete={() => setTriggerConfetti(false)}
              particleCount={350}
              duration={2500}
              force={0.8}
              width={2000}
            />
          </Box>
        )}
        {tripItems?.length === 0 ? (
          <>
            <div className={classes.instructionsBox}>
              <Typography className={classes.instruction}>
                {trip?.title === ''
                  ? 'Time to Personalize: Enter your trip name and choose a cover photo.'
                  : "You're one step closer! Get started by adding a flight or destination with the buttons below."}
              </Typography>
            </div>
            {(isMobile || !isCommandBarEnabled) && trip?.title !== '' ? (
              <AddModuleButton needsInteraction />
            ) : null}
          </>
        ) : (
          <Droppable
            droppableId={`TRIP-${tripId}`}
            isDropDisabled={false}
            direction="vertical"
            ignoreContainerClipping
            type={DROP_LEVELS.L1}>
            {(provided) => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <div style={{ marginTop: 24 }}>
                    {trip?.children?.map((itemId, idx) => (
                      <>
                        <Spacer
                          itemId={itemId}
                          isTripItem
                          prevItemId={
                            idx === 0 ? tripId : trip?.children[idx - 1]
                          }
                          isFirstIndex={idx === 0}
                        />
                        <Item
                          key={itemId}
                          itemId={itemId}
                          parentId={tripId}
                          isTripItem
                          index={idx}
                          isDraggingOver={isDragging}
                          dragHandleProps={provided.dragHandleProps}
                          prevItemId={
                            idx === 0 ? tripId : trip?.children[idx - 1]
                          }
                          nextItemId={
                            idx === (trip?.children || []).length - 1
                              ? tripId
                              : trip?.children[idx + 1]
                          }
                          isCommandBarEnabled={isCommandBarEnabled}
                        />
                      </>
                    ))}

                    {!isCommandBarEnabled && (
                      <AddModuleButton
                        needsInteraction
                        index={tripItems?.length}
                      />
                    )}
                  </div>
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        )}

        {!isMobile && isCommandBarEnabled && <CommandBarV2 tripId={tripId} />}
      </DragDropContext>
    </DndProvider>
  );
}

export default TripItemsList;
