import { Modal, Typography, Stack, Switch, Button } from '@mui/material';
import {
  PictureAsPdf,
  Collections,
  Image,
  OutlinedFlag,
  ChevronRight,
} from '@mui/icons-material';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';

import classList from '../../../../classList';
import ExportButton from '../../../../molecules/Modal/ExportPDFModal/ExportButton';
import Container from '../../../../molecules/Modal/ExportPDFModal/Container';
import Header from '../../../../molecules/Modal/ExportPDFModal/Header';
import actions from '../../../../../redux/actions';
import { EVENTS, phTrackEvent } from '../../../../../analytics';

const exportFormats = {
  pdf: 'pdf',
  img: 'img',
};
const useStyles = makeStyles({
  customDottedLine: {
    marginTop: '1.5rem',
    backgroundImage:
      'linear-gradient(to right, #D9D9D9 33%, rgba(255, 255, 255, 0) 0%)',
    backgroundPosition: 'center bottom',
    backgroundSize: '29px 20px',
    backgroundRepeat: 'repeat-x',
    width: '100%',
    height: '1px',
  },
});

function ExportPDFModal({ open, onExport, ...props }) {
  const dispatch = useDispatch();
  const styles = useStyles();
  const pdfOptions = useSelector((state) => state.TripsV2.exportPdf.options);
  const setPdfOption = (option, val) => {
    phTrackEvent({
      event:
        option === 'includeImages'
          ? EVENTS.EXPORT_PDF.EXPORT_TRIP_IMAGES_TOGGLE
          : EVENTS.EXPORT_PDF.EXPORT_TRIP_NOTES_TOGGLE,
      meta: {
        on: val,
      },
    });
    dispatch(
      actions.TripsV2.setExportPdfOptions({ ...pdfOptions, [option]: val })
    );
  };
  useEffect(() => {
    if (open) {
      phTrackEvent({
        event: EVENTS.EXPORT_PDF.EXPORT_TRIP_OPEN,
        meta: {},
      });
    }
  }, [open]);

  const closeModal = () => {
    phTrackEvent({
      event: EVENTS.EXPORT_PDF.EXPORT_TRIP_CLOSE,
      meta: {},
    });
    props.onClose();
  };

  const exportPdf = () => {
    phTrackEvent({
      event: EVENTS.EXPORT_PDF.EXPORT_TRIP_START,
      meta: {},
    });
    onExport();
  };
  return (
    <Modal open={open} className={classList.exportTripModal} {...props}>
      <Container>
        <Header onClose={closeModal} />
        <Stack direction="column" gap="0.5rem">
          <Typography fontWeight={400} fontSize="0.75rem">
            In order to customize your trip export, toggle these options below
            to include or exclude them:
          </Typography>
          <Stack direction="column" px="0.7rem" py="1rem">
            <Stack direction="row" alignItems="center">
              <Switch
                checked={pdfOptions.includeImages}
                onChange={() =>
                  setPdfOption('includeImages', !pdfOptions.includeImages)
                }
                color="primary"
                sx={{ marginLeft: '-12px' }}
              />
              <Stack
                direction="row"
                alignItems="center"
                pl="0.6rem"
                gap="0.15rem">
                <Image width="16px" height="16px" />
                <Typography
                  fontSize="0.9rem"
                  variant="caption"
                  component="p"
                  color="text.secondary">
                  Images
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Switch
                checked={pdfOptions.includeNotes}
                onChange={() =>
                  setPdfOption('includeNotes', !pdfOptions.includeNotes)
                }
                color="primary"
                sx={{ marginLeft: '-12px' }}
              />
              <Stack
                direction="row"
                alignItems="center"
                pl="0.6rem"
                gap="0.15rem">
                <OutlinedFlag width="16px" height="16px" />
                <Typography
                  fontSize="0.9rem"
                  variant="caption"
                  component="p"
                  color="text.secondary">
                  Activity Notes
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Typography variant="h3" fontWeight={500}>
            Export Format
          </Typography>
          <ExportButton
            isActive={pdfOptions.format === exportFormats.pdf}
            onClick={() => setPdfOption('format', exportFormats.pdf)}>
            <PictureAsPdf style={{ marginRight: 5 }} />
            Export as PDF (Best for interactive use){' '}
          </ExportButton>

          <ExportButton
            isActive={pdfOptions.format === exportFormats.img}
            onClick={() => setPdfOption('format', exportFormats.img)}>
            <Collections style={{ marginRight: 5 }} />
            Save as an image
          </ExportButton>

          <div className={styles.customDottedLine} />
        </Stack>

        <Stack
          width="100%"
          alignItems="flex-end"
          direction="column"
          mt="0.75rem">
          <Button onClick={exportPdf} endIcon={<ChevronRight />}>
            Export Trip
          </Button>
        </Stack>
      </Container>
    </Modal>
  );
}

export default ExportPDFModal;
