import DestinationItem from '../Destination';

function Destination({ itemId, parentId, index, ...props }) {
  return (
    <DestinationItem
      itemId={itemId}
      parentId={parentId}
      index={index}
      {...props}
    />
  );
}

export default Destination;
