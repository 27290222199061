import React from 'react';
import { MapProvider } from '../organisms/Map';
import HotelDetailsPage from '../organisms/Stays/HotelDetails/HotelDetailsPage';

function HotelDetails() {
  return (
    <MapProvider>
      <HotelDetailsPage />
    </MapProvider>
  );
}

export default HotelDetails;
