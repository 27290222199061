import { useState } from 'react';
import { Tooltip, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { switchLink, trackTpLink } from '../../../../tp-switcher';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    fontSize: 12,
    padding: '2px 4px',
    marginTop: '15px',
    color: '#8A8A8A',
    border: '1px solid #DAD9D9',
    top: '6px',
    maxWidth: (props) => props.maxWidth,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  tooltipArrow: {
    '&:before': {
      border: '1px solid #DAD9D9',
    },
    color: '#fff',
  },
  ellipsisContent: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: (props) => props.maxWidth,
  },
}));

function TooltipWrapper({
  title,
  maxWidth = '80px',
  link = false,
  controlled = false,
  children,
}) {
  const classes = useStyles({ maxWidth });
  const [show, setShow] = useState(false);

  const formatLink = (url) => {
    if (typeof url !== 'string') return url;
    if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
      return `https://${url}`;
    }
    return switchLink(url) || url;
  };

  const titleVal = link ? (
    <a
      href={formatLink(title)}
      onClick={() => trackTpLink(title)}
      target="_blank"
      rel="noreferrer">
      <div className={classes.ellipsisContent}>{title}</div>
    </a>
  ) : (
    <div className={classes.ellipsisContent}>{title}</div>
  );

  if (!controlled) {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        arrow
        placement="top"
        title={titleVal}
        TransitionComponent={Fade}>
        {children}
      </Tooltip>
    );
  }

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onClick={() => setShow(false)}
      open={show}
      arrow
      placement="top"
      title={titleVal}
      // disableInteractive
      TransitionComponent={Fade}>
      {children}
    </Tooltip>
  );
}

export default TooltipWrapper;
