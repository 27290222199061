import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
  InputBase,
  Typography,
  CircularProgress,
  Divider,
  Box,
  Button,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { ConfirmationNumberRounded } from '@mui/icons-material';
import { format, parse } from 'date-fns';
import { airlines, airports } from '@pilotplans/aviation-data';
import InlineBlade, {
  TransportationDatePicker,
} from '../../molecules/InlineBlade';
import FeedbackPopup from '../../../molecules/Feedback/FeedbackPopup';
import { FlightSearchButton } from '../../../atoms/Button';
import { removeTimezoneOffset } from '../../../../utils';
import config from '../../../config';
import classList from '../../../classList';
import BookingsHeader from '../../../molecules/BookingsHeader';
import actions from '../../../../redux/actions';
import MultipleFlightSelect from '../../../organisms/MultipleFlightSelect';
import { PictureInPictureIcon } from '../../../atoms/Icon';
import getCfConnector from '../../../cfConnector';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '6px',
  },
  flightInput: {
    border: '1px solid #DEDDDD',
    padding: '2px 4px',
    fontSize: 12,
    borderRadius: 4,
    color: '#222',
    marginLeft: 6,
    '&:focus-within': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 48,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 8,
  },
  grayText: {
    fontSize: 12,
    color: '#8A8A8A',
  },
  closeBlade: {
    fontSize: 12,
    color: '#ED702E',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'inherit',
  },
  doneButtonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '2px',
  },
  backButtonContainer: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  backButtonMain: {
    paddingBottom: '1px !importnat',
    backgroundColor: 'transparent',
    color: '#8A8A8A',
    borderRadius: '0',
    transition: 'border-bottom-color 0.3s ease',
    // fontSize: '20px',
    '&:hover': {
      color: '#8A8A8A',
      padding: '1px !importnat',
      backgroundColor: 'transparent !important',
      textDecoration: 'underline',
    },
  },
  doneButtonMain: {
    width: '60px',
    height: '30px',
    borderRadius: '6px !important',
    color: '#FFFFFF',
    fontSize: '16px !important',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: `none`,
    },
  },
  PictureInPictureIconContainer: {
    marginRight: '2px',
    paddingTop: '2px',
  },
}));

function AutomatedFlightBlade({
  open,
  setOpen,
  setFlightProps,
  handleClickOutside = () => {},
  handleSuccess = () => {},
  showHeader = false,
}) {
  const classes = useStyles();
  const inputRef = useRef(null);
  const [flightDate, setFlightDate] = useState(null);
  const [flightInfo, setFlightInfo] = useState('');
  const [errorCode, setErrorCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [triggerOpenDate, setTriggerOpenDate] = useState(false);
  const [isFlightNumberFocus, setIsFlightNumberFocus] = useState(false);
  const [localData, setLocalData] = useState(null);
  const [isMultipleFlight, setIsMultipleFlight] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(-1);

  const dispatch = useDispatch();

  // function to check if the given flight number is valid
  const isValidFlightNumber = () => {
    if (flightInfo.slice(0, 2) === '' || flightInfo.slice(3) === '')
      return false;
    return true;
  };

  const getICAO = (airport) => {
    return airports.findWhere({ iata: airport })?.get('icao') || airport;
  };

  const getIata = (icaoCode) => {
    return airlines.findWhere({ icao: icaoCode })?.get('iata') || null;
  };

  const handleBookingsOpen = () => {
    dispatch(
      actions.View.setBookingsView({
        isInView: true,
        bookingProps: {},
      })
    );
  };

  // For IOS, standard date notations end up causing issues.
  const getCleanedDate = (dateString) => {
    try {
      return parse(dateString, 'h:mm aa, MMM dd', new Date());
    } catch (err) {
      return '';
    }
  };

  const handleSearch = async () => {
    let inputFlightIata = flightInfo.slice(0, 3).trim();
    if (inputFlightIata.length === 3) {
      // replace 3 letter ICAO with 2 letter IATA
      const flightIataCode = getIata(inputFlightIata);
      if (!flightIataCode) return setErrorCode(3);
      inputFlightIata = flightIataCode;
    }

    const inputFlightNumber = flightInfo.slice(3).trim();
    setLoading(true);
    setErrorCode(null);
    try {
      const url = `${config.flightsAPI}/info?${new URLSearchParams({
        flightIata: inputFlightIata,
        flightNumber: inputFlightNumber,
        flightDate: format(flightDate, 'dd-MM-yyyy'),
      })}`;
      const response = (await (await getCfConnector()).get(url))?.data;
      setLoading(false);
      const { data, error, success } = response;
      if (success) {
        setIsMultipleFlight(Boolean(data.arrival.length > 1));
        setLocalData(data);
      } else if (error) {
        setErrorCode(1);
      }
    } catch (error) {
      setErrorCode(2);
    }
    setLoading(false);
    return null;
  };

  useEffect(async () => {
    if (localData && !isMultipleFlight) {
      const arrivalDate = getCleanedDate(
        localData?.arrival[0].scheduled.replace(/\u00A0/g, ' ')
      );
      const departureDate = getCleanedDate(
        localData?.departure[0].scheduled.replace(/\u00A0/g, ' ')
      );

      departureDate.setFullYear(new Date(flightDate).getFullYear());

      // update arrival year if it is the last day of the year
      if (
        format(arrivalDate, 'dd-MM') === '31-12' &&
        format(departureDate, 'dd-MM') === '01-01'
      ) {
        arrivalDate.setFullYear(departureDate.getFullYear() + 1);
      } else {
        arrivalDate.setFullYear(departureDate.getFullYear());
      }

      setFlightProps({
        content: {
          flightNumber: `${localData?.flightIata} ${localData?.flightNumber}`,
          fromAirport: getICAO(localData?.departure[0]?.airport),
          startDate: removeTimezoneOffset(departureDate)?.toISOString(),
          toAirport: getICAO(localData?.arrival[0]?.airport),
          endDate: removeTimezoneOffset(arrivalDate)?.toISOString(),
        },
        automated: false,
      });
      handleSuccess();
      setOpen(false);
    }
  }, [localData]);

  useEffect(() => {
    if (flightDate && isValidFlightNumber() && !loading) handleSearch();
  }, [flightDate]);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const handleInput = (e) => {
    let input = e.target.value.toUpperCase().trim();
    if (input.length > 2 && input[2] !== ' ' && !input[2].match(/[A-Z]/i)) {
      input = `${input.slice(0, 2)} ${input.slice(2)}`;
    } else if (
      input.length > 3 &&
      input[3] !== ' ' &&
      input[2].match(/[A-Z]/i)
    ) {
      input = `${input.slice(0, 3)} ${input.slice(3)}`;
    }

    setFlightInfo(input);
  };

  const handleClose = () => {
    handleClickOutside();
  };

  const handleDoneButtonClick = () => {
    if (localData) {
      const arrivalDate = getCleanedDate(
        localData?.arrival[selectedFlight].scheduled.replace(/\u00A0/g, ' ')
      );
      const departureDate = getCleanedDate(
        localData?.departure[selectedFlight].scheduled.replace(/\u00A0/g, ' ')
      );

      departureDate.setFullYear(new Date(flightDate).getFullYear());

      // update arrival year if it is the last day of the year
      if (
        format(arrivalDate, 'dd-MM') === '31-12' &&
        format(departureDate, 'dd-MM') === '01-01'
      ) {
        arrivalDate.setFullYear(departureDate.getFullYear() + 1);
      } else {
        arrivalDate.setFullYear(departureDate.getFullYear());
      }

      setFlightProps({
        content: {
          flightNumber: `${localData?.flightIata} ${localData?.flightNumber}`,
          fromAirport: getICAO(localData?.departure[selectedFlight]?.airport),
          startDate: removeTimezoneOffset(departureDate)?.toISOString(),
          toAirport: getICAO(localData?.arrival[selectedFlight]?.airport),
          endDate: removeTimezoneOffset(arrivalDate)?.toISOString(),
        },
        automated: false,
      });
      handleSuccess();
      setOpen(false);
    }
  };

  const handleBackClick = () => {
    setIsMultipleFlight(false);
    setSelectedFlight(0);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
      className={classList.automatedFlightBlade}>
      {errorCode && (
        <FeedbackPopup
          feedbackText={
            errorCode === 1
              ? 'We couldn’t find a flight on that date!\n Please try again with Airline code + Flight number!'
              : errorCode === 3
              ? 'We could not identify the flight with that number, please retry'
              : 'Something unusual happened, we are working on it. Please try again.'
          }
        />
      )}
      <InlineBlade custom open={open} setOpen={handleClose} disableTransition>
        <div className={classes.root}>
          <div style={{ marginBottom: '6px' }}>
            {isMultipleFlight ? (
              // Render the component when isMultipleFlight is true
              <>
                <Typography
                  style={{
                    color: '#222',
                    fontSize: 12,
                    fontWeight: 500,
                    margin: '8px 2px',
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                  }}>
                  <div className={classes.PictureInPictureIconContainer}>
                    <PictureInPictureIcon />
                  </div>
                  Duplicate flights found. Please select one to proceed.
                </Typography>
                <Divider />
              </>
            ) : (
              // Render the showHeader component when isMultipleFlight is false
              showHeader && (
                <>
                  <BookingsHeader openBookingsModal={handleBookingsOpen} />
                  <Divider />
                </>
              )
            )}
          </div>
          {!isMultipleFlight ? (
            <div className={classes.header}>
              <ConfirmationNumberRounded
                sx={{
                  color: isFlightNumberFocus ? 'primary.main' : '#4E4E4E',
                  fontSize: 16,
                }}
              />
              <InputBase
                fullWidth
                value={flightInfo}
                onChange={handleInput}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (!flightDate) {
                      setTriggerOpenDate(true);
                      e.target.blur();
                    } else if (isValidFlightNumber()) {
                      handleSearch();
                    }
                  }
                }}
                placeholder="Search your flight number (Eg: AC 3660)"
                className={classes.flightInput}
                inputProps={{
                  style: {
                    padding: 0,
                  },
                }}
                inputRef={inputRef}
                onFocus={() => setIsFlightNumberFocus(true)}
                onBlur={() => setIsFlightNumberFocus(false)}
              />
              <TransportationDatePicker
                handleSubmit={(date) => {
                  setFlightDate(date);
                }}
                disableTime
                triggerOpen={triggerOpenDate}
                setTriggerOpen={setTriggerOpenDate}
                flightVersion="1.0.0"
              />
              <FlightSearchButton
                style={{
                  marginLeft: 8,
                  display: 'none',
                }}
                size="small"
                disabled={!flightDate || !isValidFlightNumber() || loading}
                onClick={handleSearch}>
                Search
              </FlightSearchButton>
            </div>
          ) : null}

          {isMultipleFlight && (
            <div>
              <MultipleFlightSelect
                flightNumber={localData?.flightNumber}
                flightIata={localData?.flightIata}
                departure={localData?.departure}
                arrival={localData?.arrival}
                setSelectedFlight={setSelectedFlight}
              />
            </div>
          )}

          {loading ? (
            <div className={classes.content}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.content}>
              {isMultipleFlight ? (
                <div className={classes.buttonContainer}>
                  <Box className={classes.backButtonContainer}>
                    <Button
                      variant="text"
                      className={classes.backButtonMain}
                      onClick={handleBackClick}>
                      Back
                    </Button>
                  </Box>

                  <Box className={classes.doneButtonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.doneButtonMain}
                      onClick={handleDoneButtonClick}>
                      Done
                    </Button>
                  </Box>
                </div>
              ) : (
                <>
                  <Typography className={classes.grayText}>OR</Typography>
                  <Typography
                    className={classes.closeBlade}
                    onClick={() => {
                      setFlightProps({
                        automated: false,
                      });
                      setOpen(false);
                    }}>
                    Enter flight details manually
                  </Typography>
                </>
              )}
            </div>
          )}
        </div>
      </InlineBlade>
    </div>
  );
}

export default AutomatedFlightBlade;
