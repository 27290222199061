import React, { useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BasicButton } from '../../../../../atoms/Button/index';
import AmenityCategory from './AmenityCategory';
import classList from '../../../../../classList';

const useStyles = makeStyles(() => ({
  gradientOverlay: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '100%',
    height: 120,
    background: 'linear-gradient(to top, rgba(255,255,255,1), transparent)',
  },
}));

const distributeSections = (sections) => {
  const leftColumn = [];
  const rightColumn = [];
  let leftColumnHeight = 0;

  const totalItemsCount = sections.reduce((acc, curr) => {
    const sectionItems = curr?.description?.split(',') || [];
    return acc + sectionItems.length;
  }, 0);

  const rowsPerColumn = Math.ceil((totalItemsCount + sections.length) / 2);
  let currColIndex = 0;

  sections
    .sort(
      (a, b) =>
        (b?.description?.split(',')?.length || 0) -
        (a?.description?.split(',')?.length || 0)
    )
    .forEach((fs) => {
      const amenity = {
        name: fs?.name,
        items: fs?.description?.split(',') || [],
        isNew: true,
      };
      const sectionItems = fs?.description?.split(',') || [];
      if (currColIndex === 0) {
        if (sectionItems.length + leftColumnHeight >= rowsPerColumn) {
          const remainingRows = rowsPerColumn - leftColumnHeight;
          // This check ensures no amenity heading is rendered on its own
          if (remainingRows !== 0) {
            leftColumn.push({
              ...amenity,
              items: sectionItems.slice(0, remainingRows),
            });
          }
          rightColumn.push({
            ...amenity,
            items: sectionItems.slice(remainingRows),
            isNew: remainingRows === 0,
          });
          leftColumnHeight += remainingRows + 1;
          currColIndex = 1;
        } else {
          leftColumn.push(amenity);
          leftColumnHeight += sectionItems.length + 1;
        }
      } else {
        rightColumn.push(amenity);
      }
    });

  return [leftColumn, rightColumn];
};

function AmenitiesSection() {
  const classes = useStyles();
  const { slug: hotelKey } = useParams();
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );
  const [collapsed, setCollapsed] = useState(true);
  const [leftColumn, rightColumn] = distributeSections([
    ...(hotelDetails?.featureSummary || []),
  ]);
  return (
    <div style={{ paddingTop: 48, borderTop: '1px solid #D9D9D9' }}>
      <Typography variant="h2">Everything else we can tell you:</Typography>

      <div
        style={{
          marginTop: 24,
          height: collapsed ? 210 : '100%',
          overflow: 'hidden',
          position: 'relative',
        }}>
        <Typography variant="h3">More Amenities</Typography>

        <Grid container mt={1}>
          <Grid item xs={12} md={6} sx={{ pr: 2 }}>
            {leftColumn?.map((featureSummary, idx) => {
              return <AmenityCategory {...featureSummary} key={idx} />;
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            {rightColumn?.map((featureSummary, idx) => {
              return <AmenityCategory {...featureSummary} key={idx} />;
            })}
          </Grid>
        </Grid>

        {collapsed && <div className={classes.gradientOverlay} />}
      </div>
      <BasicButton
        className={
          collapsed
            ? classList.stayDetailViewAllAmenitiesBtn
            : classList.stayDetailViewLessAmenitiesBtn
        }
        onClick={() => setCollapsed(!collapsed)}
        sx={{ mt: 2 }}>
        View {collapsed ? 'all amenities' : 'less'}
      </BasicButton>
    </div>
  );
}

export default AmenitiesSection;
