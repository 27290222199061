import { useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Collapse,
  Chip,
} from '@mui/material';
import ActivityCard from './activityCard';
import recommendationCategories from '../../../../../../assets/newRecommendationCategories.json';

function Recommendations({
  conversation,
  loading,
  tripId,
  isExpanded,
  lastPrompt,
  setPrompt,
  setShowActivityCard,
  setToastMessage,
  setShowSnackBar,
}) {
  const messagesEndRef = useRef(null);
  const latestActivityRef = useRef(null);

  useEffect(() => {
    const lastMessage = conversation[conversation.length - 1];

    if (lastMessage?.type === 'prompt' && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (lastMessage?.type === 'activity' && latestActivityRef.current) {
      latestActivityRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation]);

  const handleRetry = () => {
    if (lastPrompt !== '') {
      setShowActivityCard(false);
      setPrompt(lastPrompt);
    }
  };

  return (
    <Collapse in={isExpanded}>
      <Box sx={{ maxHeight: '350px', overflowY: 'auto' }}>
        {conversation.map((message, index) => {
          if (message.type === 'prompt' && message.content !== '') {
            return (
              <Box
                key={index}
                sx={{
                  maxWidth: '300px',
                  width: 'fit-content',
                  backgroundColor: '#DEECFF',
                  borderRadius: '4px',
                  textAlign: 'left',
                  padding: '4px 12px',
                  marginLeft: 'auto',
                  marginRight: '8px',
                  marginBottom: '10px',
                }}>
                <Typography variant="body1" color="#1877f2" fontSize="14px">
                  {message.content}
                </Typography>
              </Box>
            );
          }

          // Render filterLabels as chips
          if (message.type === 'filterLabels') {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                  gap: '8px',
                  marginRight: '8px',
                  marginBottom: '10px',
                }}>
                {message.content.map((label, chipIndex) => (
                  <Chip
                    key={chipIndex}
                    size="small"
                    label={recommendationCategories.ref[label].name}
                    sx={{
                      backgroundColor: '#FFFFFF',
                      color: '#4E4E4E',
                      borderRadius: '4px',
                      border: '1px solid #D9D9D9',
                      '&:hover': {
                        backgroundColor: '#F4F4F4',
                        color: '#4E4E4E',
                      },
                    }}
                  />
                ))}
              </Box>
            );
          }

          if (message.type === 'activity') {
            return (
              <div key={index} ref={latestActivityRef}>
                {!message.content ? (
                  <Box
                    key={index}
                    sx={{
                      maxWidth: '250px',
                      backgroundColor: '#fff1f1',
                      borderRadius: '4px',
                      textAlign: 'left',
                      padding: '4px',
                      marginBottom: '10px',
                    }}>
                    <Typography variant="body1" color="#ff4545" fontSize="14px">
                      Oops! Something went wrong. Please{' '}
                      <span
                        onClick={handleRetry}
                        style={{
                          textDecoration: 'underline',
                          color: '#ff4545',
                          cursor: 'pointer',
                        }}
                        onMouseOver={(e) => {
                          e.target.style.color = '#ff4545';
                        }}
                        onMouseOut={(e) => {
                          e.target.style.color = '#ff4545';
                        }}>
                        try again.
                      </span>
                    </Typography>
                  </Box>
                ) : (
                  message.content.map((activity, actIndex) => (
                    <ActivityCard
                      key={`${index}-${actIndex}`}
                      setShowSnackBar={setShowSnackBar}
                      setToastMessage={setToastMessage}
                      {...activity}
                      tripId={tripId}
                    />
                  ))
                )}
              </div>
            );
          }
          return null;
        })}

        {loading && (
          <Box sx={{ display: 'flex', marginBottom: '5px' }}>
            <CircularProgress size={24} />
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>
    </Collapse>
  );
}

export default Recommendations;
