import { useMemo, useState } from 'react';

import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import AvatarStack from '../../AvatarStack';
import ShareTripModal from '../../Modal/ShareTripModal';
import ShareTripModalV2 from '../../../V2/molecules/Modal/ShareTripModal';
import TripContextMenu from '../../Menu/TripContextMenu';
import { DeleteModal } from '../../Modal';
import DayCounterPill from '../../DayCounterPill';
import { getDaysDiff, getToday, isTripV2 } from '../../../../utils';
import classList from '../../../classList';
import { EVENTS, phTrackEvent } from '../../../../analytics';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: (props) => (props.short ? '12px 8px' : '8px 12px 16px'),
    height: (props) => (props.short ? '66px' : '78px'),
    '&:last-child': {
      paddingBottom: '16px',
    },
  },
  media: {
    display: 'flex',
    justifyContent: 'center',
    height: (props) => (props.short ? '195px' : '307px'),
    opacity: (props) => (props.hovered ? 0.9 : 1),
    [theme.breakpoints.down('sm')]: {
      height: (props) => (props.short ? '195px' : '50vw'),
      maxHeight: '307px',
    },
  },
  focus: {
    '&&&': {
      opacity: 0,
    },
  },
  ripple: {
    color: 'transparent',
  },
  avatarStackContainer: {
    position: 'absolute',
    left: (props) => (props.short ? '8px' : '12px'),
    bottom: (props) => (props.short ? '74px' : '90px'),
  },
}));

// TODO: rewrite the component cleanly seperating the components
function TripCard(props) {
  const isV2Card = useMemo(() => isTripV2(props.id), [props.id]);
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const classes = useStyles({ short: props.short, isBlank: false, hovered });
  const currUserId = useSelector((state) =>
    isV2Card ? state.Auth.userId : state.Auth.firebaseUser.uid
  );

  const { title } = props;
  let { tripType } = props;
  const { exampleTrip = false } = props;
  const startDate = (props.startDate && new Date(props.startDate)) || null;
  const endDate = (props.endDate && new Date(props.endDate)) || null;
  const duration = getDaysDiff(startDate, endDate);
  const durationText = duration ? `・${duration}` : '';
  const today = getToday();

  if (tripType === 'upcoming' && startDate < today) {
    tripType = 'current';
  }

  const tripCountdownCopyChange =
    (tripType === 'upcoming' && 'to go') ||
    (tripType === 'past' && '') ||
    (tripType === 'current' && 'left!');

  const coverImage = ['', 'empty'].includes(props.coverImage)
    ? '/images/TripCoverImage.jpg'
    : props.coverImage;
  const tripId = props.id;
  const tripUsers = [
    ...(props.owner ? [props.owner] : []),
    ...(props.sharedUsers ? props.sharedUsers : []),
    ...(props.users
      ? props.users.filter((user) => props?.owner?.id !== user?.id)
      : []),
  ];

  // eslint-disable-next-line no-restricted-globals
  const displayDate = !startDate
    ? ' '
    : startDate?.getFullYear() < 2000
    ? ' '
    : `${startDate?.toLocaleString('default', {
        month: 'long',
      })} ${startDate?.getFullYear()}${durationText}`;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isOwner = props?.owner?.id === currUserId;

  const eventMeta = {
    tripStatus: tripType,
    trip_length: duration || null,
    days_until_trip_start: getDaysDiff(new Date(), startDate),
    days_until_trip_end: getDaysDiff(new Date(), endDate),
    trip_dates: [
      (startDate && format(startDate, 'yyyy-MM-dd')) || null,
      (endDate && format(endDate, 'yyyy-MM-dd')) || null,
    ],
  };

  return (
    <Card
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={classList.tripCard}>
      <CardActionArea
        component="div"
        classes={{ focusHighlight: classes.focus }}
        TouchRippleProps={{ className: classes.ripple }}
        onClick={() => {
          try {
            if (!props.isProfile)
              phTrackEvent({
                event: EVENTS.TRIPS_DASHBOARD.TRIP_CLICK,
                meta: eventMeta,
              });
          } catch (err) {
            // handle error
          }
          props.onClick();
        }}>
        <Box style={{ backgroundColor: 'black' }}>
          <CardMedia className={classes.media} image={coverImage} title={title}>
            {tripCountdownCopyChange && (
              <DayCounterPill
                startDate={startDate}
                endDate={endDate}
                tripType={tripType}
                countDownCopyChange={tripCountdownCopyChange}
              />
            )}
            {!exampleTrip && (
              <Box className={classes.avatarStackContainer}>
                <AvatarStack
                  people={tripUsers || []}
                  renderSmall={props.short}
                  LTR
                  onCard
                />
              </Box>
            )}
          </CardMedia>
        </Box>
        <CardContent className={classes.content}>
          {(hovered || isMobile) && !exampleTrip && (
            <TripContextMenu
              shareTripCallback={() => {
                if (!props?.isProfile)
                  phTrackEvent({
                    event: EVENTS.TRIPS_DASHBOARD.TRIP_SHARE_CLICK,
                    meta: eventMeta,
                  });
                setOpen(true);
              }}
              deleteTripCallback={() => setDeleteModalOpen(true)}
              closeMenu={() => setHovered(false)}
              smallVersion={props.short}
              isOwner={isOwner}
            />
          )}
          <div>
            <Typography
              gutterBottom
              noWrap
              variant={props.short ? 'h5Sub' : 'h4Sub'}>
              {title}
            </Typography>
            <Typography
              variant={props.short ? 'h5' : 'h4'}
              color="text.light"
              mt={props.short ? '4px' : '8px'}>
              {displayDate}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
      {isV2Card ? (
        <ShareTripModalV2
          tripId={tripId}
          context={props?.isProfile ? 'profile' : 'dashboard'}
          trackerMeta={eventMeta}
          open={open}
          handleClose={() => setOpen(false)}
        />
      ) : (
        <ShareTripModal
          open={open}
          handleClose={() => setOpen(false)}
          img={coverImage}
          tripTitle={title}
          slug={tripId}
          tripUsers={tripUsers || []}
          invitedUsers={props?.invitations}
          ownerId={props?.owner?.id}
          tripCoverImage={coverImage}
          context={props?.isProfile ? 'profile' : 'dashboard'}
          trackerMeta={eventMeta}
        />
      )}
      <DeleteModal
        executeFunc={() => {
          if (!props?.isProfile)
            phTrackEvent({
              event: EVENTS.TRIPS_DASHBOARD.TRIP_DELETE,
              meta: {
                ...(eventMeta || {}),
                has_cover_photo:
                  !props.coverImage ||
                  !['', 'empty'].includes(props.coverImage),
              },
            });
          props.deleteTripCallback(tripId);
        }}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        emoji="😮"
        alertText="Slow down!"
        descriptionText={`You’re about to ${
          isOwner ? 'delete' : 'leave'
        } your trip. Are you sure you’d like to do that?`}
        confirmText={`Yes, ${isOwner ? 'delete' : 'leave'} it!`}
        cancelText="No, keep it."
      />
    </Card>
  );
}

export default TripCard;
