import React, { useState, useRef, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Modal,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CloseRounded } from '@mui/icons-material';
import { getFileIcon, uploadNewFileV2 } from '../../../../../utils';
import { firebaseAuth } from '../../../../../provider/AuthProvider';
import FeedbackPopup from '../../../../molecules/Feedback/FeedbackPopup';
import {
  createFile,
  updateFile,
  createImport,
} from '../../../../../redux/slices/FilesV2';
import { ButtonDefault } from '../../../../atoms/Button/index';
import { updateTripUsingAtc as updateTrip } from '../../../../../redux/slices/TripV2';
import { EVENTS, phTrackEvent } from '../../../../../analytics';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '16px',
  },
  root: {
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: ({ isModal }) => (isModal ? '55%' : '100%'),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '340px',
    },
    borderRadius: 12,
    padding: 24,
    flexDirection: 'column',
    position: 'relative',
  },
  modalHeader: {
    fontSize: 24,
    marginTop: 8,
    marginBottom: 12,
  },
  dropContainer: {
    border: '2px dashed #DEDDDD',
    borderRadius: 8,
    backgroundColor: '#F4F4F4',
    padding: 24,
    width: '100%',
    marginTop: 12,
    height: 148,
  },
  dragging: {
    opacity: 0.8,
    border: `2px dashed ${theme.palette.primary.extralight}`,
  },
  modalText: {
    fontSize: 14,
    color: '#8A8A8A',
    maxWidth: '230px',
    textAlign: 'center',
    marginTop: 8,
  },
  uploadButton: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 500,
  },

  // link input styles
  linkContainer: {
    width: '100%',
    flexDirection: 'row',
  },
  linkInput: {
    backgroundColor: '#F4F4F4',
    borderRadius: 6,
    padding: 8,
    border: '1px solid #DEDDDD',
    fontSize: 14,
    marginRight: 8,
  },
  attachLinkIcon: {
    color: '#8A8A8A',
    width: 16,
    height: 16,
    marginRight: 8,
    marginLeft: 4,
    transform: 'rotate(45deg)',
  },
  attachButton: {
    display: 'flex',
    padding: '4px 16px',
  },
  closeIcon: {
    position: 'absolute',
    top: 24,
    right: 24,
    padding: 2,
  },
  filesList: {
    border: '2px dashed #DEDDDD',
    borderRadius: 8,
    backgroundColor: '#F4F4F4',
    width: '100%',
    marginTop: 12,
    maxHeight: 224,
    overflowY: 'scroll',
  },
  filesListItem: {
    borderTop: '1px solid #DEDDDD',
    padding: 0,
    backgroundColor: '#F4F4F4',
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  filesListHeader: {
    fontSize: 12,
    color: '#4E4E4E',
    fontWeight: 'bold',
    padding: '8px 16px',
    marginBottom: -8,
  },
  listItemButton: {
    fontSize: 14,
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  submitButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  submitButton: {
    width: 'fit-content',
    padding: '10px 20px',
  },
}));

export function FileUpload({
  handleClose,
  attachedToID,
  attachedToType = 'Trip',
  attachedFiles = [],
  attachFunc,
  filesTab,
  transportType = 'flight',
  customHeader,
  customFooter,
  autoImport,
}) {
  const [selectedFile, setSelectedFile] = useState(attachedFiles || []);
  const isModal = !!handleClose;
  const params = useParams();
  const files = useSelector(
    (state) =>
      Object.values(state.FilesV2.files).filter(
        (file) => file?.tripId === params.slug
      ),
    shallowEqual
  );

  const classes = useStyles({ isModal });
  const dispatch = useDispatch();
  // const [fileLink, setFileLink] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { user } = useContext(firebaseAuth);
  const { slug: tripId } = useParams();

  const trip = useSelector((state) => state.Trips.trips[params.slug]);
  const fileRelations = useSelector(
    (state) => state.Files.fileRelations[tripId]
  );

  const handleCreateFile = (fileVars) => {
    return Promise.resolve(
      dispatch(
        createFile({
          variables: {
            file: {
              ...fileVars,
              tripId,
              attachedToId: attachedToID,
              attachedToType,
            },
          },
        })
      )
    );
  };

  const handleUpdateFile = (fileVars) => {
    return Promise.resolve(dispatch(updateFile(fileVars)));
  };

  // state to keep track of errors during the upload
  const [errorCode, setErrorCode] = useState(null);

  // all accepted file types
  const acceptedTypes = [
    'image/jpg',
    'image/png',
    'image/jpeg',
    'application/pdf',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/plain',
  ];

  // logic to validate, upload file to the backend and create a FILE.
  const handleFileSubmit = async (file) => {
    if (!file) return;

    setErrorCode(null);
    setIsUploading(true);

    if (file.size > 4 * 1024 * 1024) {
      setErrorCode(1);
      setIsUploading(false);
      return;
    }
    if (acceptedTypes.indexOf(file.type) === -1) {
      setErrorCode(2);
      setIsUploading(false);
      return;
    }
    let { name } = file;
    const filenames = attachedFiles.map((tripFile) => tripFile?.name);
    if (autoImport) {
      phTrackEvent({
        event: EVENTS.SMART_IMPORT.PLAN_IMPORT_UPLOAD_START,
        meta: {
          file_name: name,
        },
      });
    }

    // updating filename to avoid duplicate filenames
    let fileNameFrequency = 0;
    while (filenames.includes(name)) {
      fileNameFrequency += 1;
      name = `${file.name} (${fileNameFrequency})`;
    }

    const newFileId = await uploadNewFileV2(
      file,
      user.uid,
      attachedToID,
      name,
      'Upload',
      handleCreateFile,
      handleUpdateFile,
      attachedToType
    );

    if (autoImport) {
      await dispatch(
        createImport({
          variables: {
            import: {
              source: 'file',
              name,
              trip: tripId,
              file: newFileId,
            },
          },
        })
      );
    }

    // Track file upload
    try {
      let trackEvent = EVENTS.TRIP_FILES.FILE_CREATE;
      switch (attachedToType) {
        case 'Activity':
          trackEvent = EVENTS.PLAN_ACTIVITY.FILE_ADD;
          break;
        case 'Accommodation':
          trackEvent = EVENTS.PLAN_STAY.FILE_ADD;
          break;
        case 'Transportation':
          switch (transportType) {
            case 'flight':
              trackEvent = EVENTS.PLAN_FLIGHT.FILE_ADD;
              break;
            case 'bus':
              trackEvent = EVENTS.PLAN_BUS_TRAIN.FILE_ADD;
              break;
            case 'other':
              trackEvent = EVENTS.PLAN_OTHER_TRANSPORT.FILE_ADD;
              break;
            default:
              trackEvent = EVENTS.PLAN_FLIGHT.FILE_ADD;
          }
          break;
        default:
          trackEvent = EVENTS.TRIP_FILES.FILE_CREATE;
      }
      phTrackEvent({
        event: trackEvent,
        meta: {
          file_size_in_mb: file?.size || 0,
          file_name: name,
          file_type: file?.type,
        },
      });
    } catch (err) {
      // handle error
    }
    setSelectedFile((arr) => [...arr, newFileId]);
    await attachFunc([...attachedFiles, newFileId]);
    setIsUploading(false);
    if (filesTab) handleClose?.();
  };

  // reference to the input element
  const fileInputRef = useRef(null);

  // const filesList = [
  //   {
  //     id: 1,
  //     name: 'New pdf file',
  //     url: 'filepath.pdf',
  //   },
  //   {
  //     id: 2,
  //     name: 'Image file',
  //     url: 'filepath.jpg',
  //   },
  //   {
  //     id: 3,
  //     name: 'Doc file',
  //     url: 'filepath.doc',
  //   },
  //   {
  //     id: 4,
  //     name: 'Xls File',
  //     url: 'filepath.xls',
  //   },
  //   {
  //     id: 5,
  //     name: 'File 5',
  //     url: 'filepath.pdf',
  //   },
  //   {
  //     id: 6,
  //     name: 'File 6',
  //     url: 'filepath.pdf',
  //   },
  // ];

  const arrCompare = (arr1, arr2) => {
    let equal = false;
    if (arr1.length === arr2.length) {
      equal = true;
      arr1.forEach((element) => {
        if (!arr2.includes(element)) equal = false;
      });
    }
    return equal;
  };

  const handleFileClick = (val) => {
    const currentIndex = selectedFile.indexOf(val);
    const newCheckedFiles = [...selectedFile];
    if (currentIndex === -1) {
      newCheckedFiles.push(val);
    } else {
      newCheckedFiles.splice(currentIndex, 1);
    }
    setSelectedFile(newCheckedFiles);
  };

  const handleFileAttach = async () => {
    if (!arrCompare(selectedFile, attachedFiles)) {
      await attachFunc(selectedFile, tripId);

      // if file is detached and it doesn't have any other relation, add it to the trip files

      const filesToBeRemoved = attachedFiles?.filter(
        (file) =>
          fileRelations?.filter(
            (relation) =>
              relation?.fileId === file &&
              relation?.attachedToId !== attachedToID
          ).length === 0
      );

      if (filesToBeRemoved?.length > 0) {
        dispatch(
          updateTrip({
            variables: {
              id: trip.id,
              files: [...trip.files, ...filesToBeRemoved],
            },
            addFiles: filesToBeRemoved,
          })
        );
      }
    }

    // This will remove fileId from Files array in the Trip object

    // const newFileArr = trip?.files?.filter(
    //   (file) => !selectedFile.includes(file)
    // );
    // if (!arrCompare(trip.files, newFileArr)) {
    //   dispatch(
    //     updateTrip({
    //       variables: {
    //         id: trip.id,
    //         files: newFileArr,
    //       },
    //     })
    //   );
    // }
    handleClose?.();
  };

  return (
    <>
      {errorCode && (
        <FeedbackPopup
          containerProps={{
            style: {
              maxWidth: '550px',
              marginBottom: 16,
            },
          }}
          feedbackText={
            errorCode === 1
              ? 'The file you tried to upload is too large. Maximum file size is 4MB'
              : errorCode === 2
              ? 'The file you tried to upload is not supported.'
              : 'Unknown error occured. Please try again later.'
          }
        />
      )}

      <Grid container className={classes.root}>
        {handleClose && (
          <>
            <IconButton
              className={classes.closeIcon}
              onClick={() => {
                setErrorCode(null);
                setIsUploading(false);
                handleClose();
              }}>
              <CloseRounded style={{ color: '#1D1D1D' }} />
            </IconButton>
            <Box width="100%">
              <Typography variant="h4" className={classes.modalHeader}>
                Attach Files
              </Typography>
            </Box>
          </>
        )}
        <Stack direction="column" width="100%">
          <Box padding="0px 1px">
            {customHeader || (
              <Typography color="#8A8A8A" fontSize="14px" fontWeight="400">
                Pilot will automatically import any flights, bookings, and
                reservations into your planner into your planner
              </Typography>
            )}
          </Box>
          <Grid
            item
            className={`${classes.flexContainer} ${classes.dropContainer} ${
              isDragging || isUploading ? classes.dragging : ''
            }`}
            onDragEnter={(e) => {
              setIsDragging(true);
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragLeave={(e) => {
              setIsDragging(false);
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e) => {
              setIsDragging(false);
              e.preventDefault();
              e.stopPropagation();

              const file = e.dataTransfer.files[0];
              handleFileSubmit(file);
            }}>
            <input
              type="file"
              ref={fileInputRef}
              id="files-input"
              style={{ display: 'none' }}
              onChange={(e) => {
                handleFileSubmit(e.target.files[0]);
              }}
              accept=".jpg, .jpeg, .png, .pdf, .doc, .xls, .txt, .docx, .xlsx"
            />
            {isUploading ? (
              <CircularProgress />
            ) : (
              <>
                <img
                  src="/images/FileUpload.svg"
                  alt="file-upload-icon"
                  height="52"
                />
                <Typography className={classes.modalText}>
                  Drag & Drop <br />
                  or{' '}
                  <span
                    onClick={() => fileInputRef?.current?.click()}
                    className={classes.uploadButton}>
                    Browse Files
                  </span>
                </Typography>
                <Typography
                  className={classes.modalText}
                  style={{ fontSize: 10, marginTop: 6 }}>
                  Max file size: 4MB
                </Typography>
              </>
            )}
          </Grid>
          {customFooter || (
            <>
              <Box width="100%" display="flex" justifyContent="center" py="8px">
                <Typography color="#8A8A8A" fontSize="14px" fontWeight="400">
                  or
                </Typography>
              </Box>
              <Box width="100%" display="flex" justifyContent="center">
                <Typography color="#8A8A8A" fontSize="14px" fontWeight="400">
                  Email us your bookings and files at{' '}
                  <span>
                    <a
                      style={{
                        color: '#ED702E',
                      }}
                      href="mailto:import@pilotplans.com">
                      import@pilotplans.com
                    </a>
                  </span>
                </Typography>
              </Box>
            </>
          )}
        </Stack>
        {!filesTab && files?.length > 0 && (
          <>
            <Box className={classes.filesList}>
              <Typography className={classes.filesListHeader}>
                FILE NAME
              </Typography>
              <List>
                {files?.map((file, index) => {
                  const url = file.url.split('?')[0];
                  const extension = url.split('.').pop();
                  const FileLogo = getFileIcon(extension);
                  return (
                    <ListItem className={classes.filesListItem} key={index}>
                      <ListItemButton
                        disableRipple
                        className={classes.listItemButton}
                        onClick={() => {
                          handleFileClick(file.id);
                        }}>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                          <Checkbox
                            checked={selectedFile.includes(file.id)}
                            disableRipple
                            tabIndex={-1}
                            inputProps={{
                              'aria-labelledby': `checkbox-list-label-${index}`,
                            }}
                            sx={{
                              padding: 0,
                              color: '#D9D9D9',
                              '&.Mui-checked': {
                                color: 'primary.main',
                              },
                            }}
                            size="small"
                          />
                        </ListItemIcon>
                        <FileLogo
                          style={{
                            marginRight: 6,
                            height: 20,
                            width: 20,
                            viewBox: '0 0 20 20',
                          }}
                        />
                        {file.name}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
            <Box className={classes.submitButtonContainer}>
              {!selectedFile?.length > 0 ||
              arrCompare(selectedFile, attachedFiles) ? (
                <ButtonDefault
                  size="large"
                  className={classes.submitButton}
                  disabled>
                  Select Files
                </ButtonDefault>
              ) : (
                <ButtonDefault
                  size="large"
                  className={classes.submitButton}
                  onClick={handleFileAttach}>
                  {selectedFile?.length > 1 ? 'Attach Files' : 'Attach File'}
                </ButtonDefault>
              )}
            </Box>
          </>
        )}
      </Grid>
    </>
  );
}

function FileUploadModal({
  open,
  handleClose,
  attachedToID,
  attachedToType,
  attachedFiles,
  attachFunc,
  attachedToText,
  filesTab,
  transportType,
}) {
  const classes = useStyles({ isModal: true });
  return (
    <Modal className={classes.flexContainer} open={open} onClose={handleClose}>
      <FileUpload
        handleClose={handleClose}
        attachedToID={attachedToID}
        attachedToType={attachedToType}
        attachedFiles={attachedFiles}
        attachFunc={attachFunc}
        attachedToText={attachedToText}
        filesTab={filesTab}
        transportType={transportType}
      />
    </Modal>
  );
}

export default FileUploadModal;
