import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isIOS: false,
};

const SysInfoSlice = createSlice({
  name: 'SYS_INFO',
  initialState,
  reducers: {
    initializeSysInfo: (state) => {
      state.isIOS =
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    },
  },
});

export const SysInfoActions = SysInfoSlice.actions;
export const SysInfoReducer = SysInfoSlice.reducer;
