import { Box, FormControl, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import { createImport } from '../../../../redux/slices/FilesV2';
import { FileUpload } from '../../../V2/molecules/Modal/FileUploadModal';
import FilesSelect from './FilesSelect';
import ImportFilesFooter from './Footer';

function ImportStepV2() {
  const dispatch = useDispatch();
  const tripId = useParams().slug;
  const handleFileSelect = async (file) => {
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.PLAN_IMPORT_FILE_SELECT_CLICK,
      meta: {
        file_name: file?.name,
        isV2: true,
      },
    });
    await dispatch(
      createImport({
        variables: {
          import: {
            source: 'file',
            name: file?.name,
            trip: tripId,
            file: file?.id,
          },
        },
      })
    );
  };
  return (
    <Stack direction="column" height="100%" justifyContent="space-between">
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="column"
        width="100%"
        px="24px"
        height="100%">
        <Typography color="#8A8A8A" fontSize="14px">
          Step 1
        </Typography>
        <Typography mt={1} fontSize="24px">
          Import Your Files
        </Typography>
        <FileUpload
          autoImport
          customHeader={<Box width="100%" />}
          customFooter={' '}
          attachedToID={tripId}
          attachedToType="Trip"
          attachedFiles={[]}
          filesTab
          attachFunc={() => {}}
        />
        <Stack direction="column" width="100%" px="24px">
          <Typography fontSize="15px">Choose from Files:</Typography>
          <FormControl>
            <FilesSelect
              filter={(file) => {
                if (file.type === 'EmailAttachment') return false;
                if (!file.url) return false;
                return true;
              }}
              onFileSelect={handleFileSelect}
            />
          </FormControl>
        </Stack>
      </Stack>
      <ImportFilesFooter />
    </Stack>
  );
}

export default ImportStepV2;
