import MapboxMap, { Marker } from 'react-map-gl';
import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line
import mapboxgl from '!mapbox-gl';
import config from '../../../../../config';
import { CategoryMarker } from '../../../Map/CustomMap';

function LocationSection() {
  const { slug: hotelKey } = useParams();
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!hotelDetails?.lat || !hotelDetails?.lng) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: isMobile ? 'column' : 'row',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 60,
          marginRight: 40,
        }}>
        <Typography variant="h2">Check out the Location:</Typography>
        <Typography variant="h4" sx={{ mt: 2 }}>
          {hotelDetails?.address} <br /> {hotelDetails?.name}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          width: isMobile ? '98%' : '680px',
          height: 360,
          borderRadius: 8,
          overflow: 'hidden',
          marginTop: isMobile ? 16 : 0,
        }}>
        <MapboxMap
          reuseMaps
          initialViewState={{
            latitude: hotelDetails?.lat,
            longitude: hotelDetails?.lng,
            zoom: 13,
          }}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/streets-v12"
          mapboxAccessToken={config.mapboxAccessToken}
          mapLib={mapboxgl}>
          <Marker
            longitude={hotelDetails?.lng}
            latitude={hotelDetails?.lat}
            anchor="bottom"
            offset={[0, 6]}>
            <CategoryMarker
              className="customMarker"
              color="#ED702E"
              categoryId="stay"
            />
          </Marker>
        </MapboxMap>
      </div>
    </div>
  );
}

export default LocationSection;
