/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';

import {
  Popper,
  List,
  ListItem,
  ClickAwayListener,
  ListItemIcon,
  Divider,
  ListItemText,
  Typography,
  Box,
} from '@mui/material';
// removed LaunchRounded
import {
  ArrowDropDownRounded,
  AddCircleOutlineRounded,
  ChevronRightRounded,
  CheckRounded,
} from '@mui/icons-material';

import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMapUtils } from '../../organisms/Map';
import { createLocation } from '../../../../redux/slices/Location';
import { ButtonDefault } from '../../../atoms/Button/index';
import { trackEvents, Events } from '../../../../intercom';
import { useSounds, SOUNDS } from '../../../../sounds';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import { useTour } from '../../../molecules/Tour/useTour';
import { stepName } from '../../../../assets/onboarding/steps';
import actions from '../../../../redux/actions';
import ITEM_TYPES from '../../../../const';
import { createItem } from '../../../../redux/slices/Item';
import ItemDropDown from './ItemDropdown';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 9999,
    width: 'inherit',
  },
  list: {
    maxHeight: 160,
    overflowY: 'auto',
    backgroundColor: '#FFF',
    border: '2px solid #DEDDDD',
    borderRadius: 4,
    padding: '4px 0px',
    marginTop: 8,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: 12,
    },
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
  },
  listItem: {
    minWidth: 120,
    padding: '4px 12px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      '& .MuiListItemIcon-root': {
        color: '#ED702E !important',
      },
    },
  },
  addedButton: {
    backgroundColor: '#43A047',
    color: '#FFF',
    pointerEvents: 'none',
  },
  listItemText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}));

/**
 *
 * @param {Boolean} isAdded - condition to toggle between the 'Add to trip' and 'Added' button states
 */
function AddToTripButton({
  tripId,
  placeDetails,
  // info we aren't storing in the backend
  additionalPlaceDetails = {},
  addressComponents,
  id: idSelector,
  className: classSelector,
  source = 'map',
  buttonProps = {},
  handleSelectHeading = null,
  handleSelectLocation = null,
  isAdded = false,
  customAnchor = null,
  CustomButton = null,
  setLoader = () => {},
  initAction = () => {},
  postAction = () => {},
  handleClose = () => {},
  setOpenSearchbar = () => {},
  smartImport = false,
  trigger = null,
  popoverTitle = '',
  newLocationText = 'New Destination',
  disableNewLocation = false,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { playSound } = useSounds();
  const dispatch = useDispatch();
  const currentTrip = useSelector((state) => state.Trips.trips[tripId]);
  const locationContext = useSelector(
    (state) => state.Recommendations.recommendations[tripId]?.city || {}
  );

  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  const [locationAnchor, setLocationAnchor] = useState(null);
  const popperId = open ? 'search-location-popper' : undefined;

  const {
    createMapPinForPlace,
    extractAddressComponents,
    createMapPinForPlaceId,
  } = useMapUtils();
  const { getTour } = useTour();

  const setLocationPopperOpen = (value) =>
    dispatch(actions.View.setLocationPopperOpen(value));
  const handleAddToTripClick = (e) => {
    getTour()
      .onActiveTour([
        'emptyTripFlowWeb',
        'blankTripFlowWeb',
        'sampleTripFlowWeb',
        'quickStartFlowWeb',
      ])
      .onActiveStep(stepName.ADD_TO_TRIP_BUTTON)
      .closeTour();

    e.stopPropagation();
    setAnchorEl(CustomButton ? customAnchor : ref?.current);
    setLocationAnchor(null);
    setOpen(!open);
  };

  useEffect(() => {
    if (trigger && !anchorEl) {
      setAnchorEl(customAnchor);
      setOpen(true);
    }
  }, [trigger]);

  const handleAddLocation = async () => {
    setLoader(true);
    playSound(SOUNDS.inOutClick);
    let headingId = null;
    let destinationId = null;
    try {
      let destinationMapPinId = null;
      if (locationContext?.placeId) {
        destinationMapPinId = await createMapPinForPlaceId(
          locationContext?.placeId,
          'LOCATION'
        );
      }
      destinationId = await dispatch(
        createItem({
          variables: {
            title: locationContext.title || 'New destination',
            tripId,
            parentId: tripId,
            mapPin: destinationMapPinId,
            index: 0,
            type: ITEM_TYPES.DESTINATION,
          },
          index: 0,
          parentId: tripId,
          tripId,
        })
      ).then((data) => data.payload.createItem?.id);
      headingId = await dispatch(
        createItem({
          variables: {
            title: 'Things to do',
            tripId,
            parentId: destinationId,
            type: ITEM_TYPES.HEADING,
            index: 0,
          },
          index: 0,
          tripId,
          parentId: destinationId,
        })
      ).then((data) => data.payload.createItem?.id);
    } catch (error) {
      console.error(error);
    }

    const mapPinId = await createMapPinForPlace(
      placeDetails,
      'LOCATION',
      additionalPlaceDetails
    );
    const address = extractAddressComponents(addressComponents);
    await dispatch(
      createItem({
        variables: {
          type: ITEM_TYPES.ACTIVITY,
          title: placeDetails.title,
          tripId,
          parentId: headingId,
          mapPin: mapPinId,
          index: 0,
          content: {
            address: {
              streetAddress: address?.address || placeDetails?.address,
              city: address?.city || placeDetails?.city,
              state: address?.state || placeDetails?.state,
              country: address?.country || placeDetails?.country,
              zipCode: address?.zipCode || placeDetails?.zipCode,
            },
          },
        },
        index: 0,
        tripId,
        parentId: headingId,
      })
    );
    postAction({
      locationId: destinationId,
      sectionId: headingId,
    });
    setLoader(false);
  };

  const handleAddItem = async (
    sourceItemId,
    itemType,
    destinationId = null
  ) => {
    const itemTitle = placeDetails?.title;
    setLoader(true);
    const result = await initAction({
      newParentId: sourceItemId,
      newItemTitle: itemTitle,
    });
    playSound(SOUNDS.inOutClick);
    let parentId = sourceItemId;

    // action already done, no need to repeat activity
    if (result === 'NO-ACTION') {
      setLoader(false);
      return;
    }
    if (
      source === 'map' ||
      source === 'recommendations' ||
      source === 'recommendations-details'
    ) {
      phTrackEvent({
        event:
          source === 'map'
            ? EVENTS.PLAN_MAP_CARD.ACTIVITY_ADD
            : source === 'recommendations'
            ? EVENTS.EXPLORE_ACTIVITIES.ACTIVITY_PLAN_ADD
            : EVENTS.EXPLORE_ACTIVITY_DETAIL.ACTIVITY_PLAN_ADD,
      });
    }
    // addMapPin
    const mapPin = await createMapPinForPlace(
      {
        title: itemTitle,
        ...placeDetails,
      },
      'ACTIVITY',
      additionalPlaceDetails
    );
    const address = extractAddressComponents(addressComponents);
    if (window?.heap)
      window?.heap.track('Activity Created', {
        source: 'Map',
      });

    if (itemType === ITEM_TYPES.DESTINATION) {
      const headingId = (
        await dispatch(
          createItem({
            variables: {
              title: 'Things to do',
              tripId,
              parentId: sourceItemId,
              type: ITEM_TYPES.HEADING,
              index: 0,
            },
            index: 0,
            tripId,
            parentId: sourceItemId,
          })
        )
      )?.payload?.createItem?.id;
      parentId = headingId;
    }

    dispatch(
      createItem({
        variables: {
          type: ITEM_TYPES.ACTIVITY,
          title: itemTitle,
          mapPin: mapPin?.id,
          content: {
            address: {
              streetAddress: address?.address || placeDetails?.address,
              city: address?.city,
              state: address?.state,
              country: address?.country,
              zipCode: address?.zipCode,
            },
          },
          parentId,
          tripId,
          index: 0,
        },
        index: 0,
        tripId,
        parentId,
      })
    );

    trackEvents(
      source === 'map'
        ? Events.LocationAddedFromMap
        : Events.RecommendationsItemAdded
    );
    await postAction({
      locationId: destinationId,
      sectionId: parentId,
    });
    setLoader(false);
    if (getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON)) {
      getTour().goToNextStep().openTour({
        delay: 1000,
      });
    } else {
      getTour().onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO).openTour({
        delay: 1000,
      });
    }
  };

  const handleClickAway = () => {
    setOpen(false);
    setLocationPopperOpen(null);
    handleClose(null);
  };

  return (
    <>
      {CustomButton ? (
        <CustomButton ref={ref} onClick={handleAddToTripClick} />
      ) : (
        <ButtonDefault
          ref={ref}
          disableFocusRipple
          disableRipple
          onClick={handleAddToTripClick}
          endIcon={isAdded ? <CheckRounded /> : <ArrowDropDownRounded />}
          className={isAdded && classes.addedButton}
          {...buttonProps}>
          {isAdded ? 'Added to trip' : 'Add to trip'}
        </ButtonDefault>
      )}
      <Popper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <List className={classes.list}>
            {popoverTitle && (
              <Box ml="8px">
                <Typography fontWeight="500" fontSize="10px" color="#8A8A8A">
                  {popoverTitle}
                </Typography>
              </Box>
            )}
            {!disableNewLocation && (
              <>
                <ListItem
                  key="create-new-location"
                  onClick={async () => {
                    if (smartImport) {
                      setOpenSearchbar(true);
                    } else {
                      await handleAddLocation();
                    }
                    handleClickAway();
                  }}
                  onMouseOver={() => {
                    setLocationPopperOpen('create-new-location');
                  }}
                  className={classes.listItem}
                  style={{
                    whiteSpace: 'nowrap',
                    justifyContent: 'flex-start',
                  }}>
                  <ListItemIcon
                    style={{
                      minWidth: 0,
                      marginRight: 4,
                    }}>
                    <AddCircleOutlineRounded
                      style={{
                        height: 14,
                        width: 14,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={newLocationText}
                    primaryTypographyProps={{
                      fontSize: '14px',
                    }}
                  />
                </ListItem>
                <Divider style={{ marginTop: 4, marginBottom: 4 }} />
              </>
            )}
            <ItemDropDown
              handleClickAway={handleClickAway}
              destinationIdSelector={idSelector}
              destinationClassSelector={classSelector}
              handleAddItem={handleAddItem}
            />
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default AddToTripButton;
