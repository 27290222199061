import React, { useState, useRef, useEffect } from 'react';

import {
  ClickAwayListener,
  InputBase,
  CircularProgress,
  Fade,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useSounds, SOUNDS } from '../../../../sounds';
import { PlacesSearchBar } from '../../molecules/SearchBar';
import { useMapUtils } from '../Map';
import { createItem } from '../../../../redux/slices/Item';
import ITEM_TYPES from '../../../../const';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import actions from '../../../../redux/actions';

const useStyles = makeStyles(({ breakpoints }) => ({
  titleInput: {
    fontSize: 14,
    flex: 1,
    padding: '8px',
    fontWeight: 'normal',
    width: '100%',
    border: '1px solid #DEDDDD',
    borderRadius: 4,
    backgroundColor: '#F4F4F4',
  },

  inputContainer: {
    margin: '0% 0% 0% calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      margin: '0% 0% 0% calc(3.6rem + 15px + 12px)',
    },
  },
}));

// Searchbar for the accommodation block before entering.
function AccommodationSearchBar({
  locationBias,
  parentId,
  tripId,
  index,
  setActiveAccommodation = () => {},
}) {
  const classes = useStyles();
  const inputRef = useRef();
  const dispatch = useDispatch();
  const { handleLocationSelect } = useMapUtils();
  const [loading, setLoading] = useState(false);
  const { playSound } = useSounds();
  let addAccomodationTimer = null;

  const handleClose = () => {
    dispatch(actions.Item.setOpenItemSearchbar({ id: null }));
  };

  const handleItemCreate = async (accommodation) => {
    if (accommodation?.name === '' || !accommodation) return;
    await dispatch(
      createItem({
        variables: {
          title: accommodation?.name,
          type: ITEM_TYPES.ACCOMMODATION,
          tripId,
          parentId,
          index,
          mapPin: accommodation?.mapPin,
          content: {
            address: {
              streetAddress: accommodation?.streetAddress,
              city: accommodation?.city,
              country: accommodation?.country,
              state: accommodation?.state,
              zipCode: accommodation?.zipCode,
            },
          },
        },
        tripId,
        parentId,
        index,
      })
    )
      .then(async (result) => {
        handleClose();
        phTrackEvent({
          event: EVENTS.PLAN_STAY.ADD_CREATE,
        });
        setActiveAccommodation(result.payload.createAccommodation.id);
        return null;
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    // Attach the event listener
    document.addEventListener('keyup', handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keyup', handleKeyDown);
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.inputContainer}>
        <PlacesSearchBar
          fullWidth
          id="accommodation"
          onChange={() => {}}
          locationBias={locationBias}
          tripLocation={false}
          placeholder="Search by name, address"
          handleSelect={async (option) => {
            setLoading(true);
            const { title, mapPin, ...location } = await handleLocationSelect(
              option.place_id,
              undefined,
              'ACCOMMODATION'
            );
            const acc = {
              name: title,
              mapPin: mapPin?.id,
              ...location,
            };
            playSound(SOUNDS.softPop);
            await handleItemCreate(acc);
            setLoading(false);
            if (addAccomodationTimer) {
              clearTimeout(addAccomodationTimer);
            }
          }}
          popperPlacement="bottom"
          CustomSearchBar={InputBase}
          searchBarProps={{
            className: classes.titleInput,
            autoFocus: true,
            inputProps: {
              style: {
                padding: '2px 0px',
              },
            },
            placeholder: 'Search by name, address',
            endAdornment: (
              <Fade
                in={loading}
                style={{
                  transitionDelay: loading ? '800ms' : '0ms',
                }}
                unmountOnExit>
                <CircularProgress size={16} style={{ marginRight: 4 }} />
              </Fade>
            ),
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                // call onBlur after 1sec
                addAccomodationTimer = setTimeout(() => {
                  e.target.blur();
                }, 1000);
              }
            },
            onBlur: async (e) => {
              setLoading(true);
              await handleItemCreate({ name: e.target.value });
              setLoading(false);
              if (addAccomodationTimer) {
                clearTimeout(addAccomodationTimer);
              }
            },
          }}
          inputRef={inputRef}
        />
      </div>
    </ClickAwayListener>
  );
}

export default AccommodationSearchBar;
