import { useState } from 'react';

import { Grid, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  AddRounded,
  DragIndicatorRounded,
  DeleteOutlineRounded,
  AirplanemodeActiveRounded,
  TrainRounded,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { airports } from '@pilotplans/aviation-data';
import { useDispatch, useSelector } from 'react-redux';
import { SemiFoldedMapIcon } from '../atoms/Icon';
import Transportation from '../organisms/Transportation';
import { deleteTransportation } from '../../redux/slices/Transportation';
import classList from '../classList';
import { getAirlineFromFlightNumber, parseISODate } from '../../utils';
import { EVENTS, phTrackEvent } from '../../analytics';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    marginTop: '4.5%',
  },
  flightInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
  },
  root: {
    flex: 1,
    color: '#000000',
    backgroundColor: '#F4F4F4',
    border: '1px solid #DEDDDD',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginLeft: 4,
    flexDirection: 'column',
    width: '100%',
    '&:hover': {
      border: '1px solid #A7A7A7',
      color: '#000000',
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  isDragging: {
    border: `2px solid ${palette.primary.light}`,
    borderRadius: 8,
    marginLeft: 'calc(3.6rem + 15px)',
  },
  imageContainer: {
    backgroundColor: '#FFFFFF',
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 60,
    maxHeight: 60,
    objectFit: 'contain',
  },
  dateText: {
    fontSize: '0.8rem',
    display: 'inline-flex',
  },
  flightNumber: {
    fontSize: '0.8rem',
    marginLeft: '4%',
    display: 'inline-flex',
    color: '#A7A7A7',
  },
  arrow: {
    fontSize: '1rem',
  },
  city: {
    fontSize: '1.2rem',
  },
  iata: {
    fontSize: '1rem',
    color: '#A7A7A7',
  },
  actionContainer: {
    position: 'absolute',
    margin: 'auto',
    top: 12,
    left: 'calc(-3.6rem - 15px)',
    minWidth: 'calc(3.6rem + 15px)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionIcon: {
    fontSize: '1.2rem',
    color: 'rgba(138, 138, 138, 1)',
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
      color: '#474747',
    },
  },
  actionIconDrag: {
    marginRight: 4,
    fontSize: '1.2rem',
    color: 'rgba(138, 138, 138, 1)',
    '&:hover': {
      color: '#474747',
    },
  },
  actionIconDragSpan: {
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    padding: '2%',
    display: 'flex',
  },
  spacing: {
    marginBottom: '1.2rem',
  },
  detailsText: {
    fontSize: '0.7rem',
    color: '#8a8a8a',
  },
  mainText: {
    fontSize: '1.1rem',
  },
  transportIconWrapper: {
    width: '90%',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    position: 'relative',
  },
  transportIconContainer: {
    backgroundImage:
      'linear-gradient(to right, #A7A7A7 60%, rgba(0,0,0,0) 40%)',
    backgroundPosition: 'top',
    backgroundSize: '8px 1px',
    backgroundRepeat: 'repeat-x',
    width: '100%',
    height: 1,
  },
  transportIcon: {
    transform: 'rotate(90deg)',
    backgroundColor: '#FFF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20%',
  },
  transportBlockDiv: {
    position: 'relative',
    marginLeft: 'calc(3.6rem - 19px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px)',
    },
  },
}));

function TransportBlock({
  transportId,
  tripId,
  dragHandleProps,
  isDragging,
  ...props
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const transport = useSelector(
    (state) => state.Transportation.transports[transportId]
  );

  const [isHovered, setHovered] = useState(false);
  const [openTransportationPanel, setOpenTransportationPanel] = useState(false);
  const userObj = useSelector((state) => state.Auth.userData);
  const is24HourFormat = userObj.user.is24HourTime;
  const type = transport?.type || 'flight';
  const fileRelations = useSelector(
    (state) => state.Files.fileRelations[tripId]
  );
  const [mobileSingleClick, setMobileSingleClick] = useState(false);

  // for checking single and double clicks on mobile
  let wait = false;
  let timer;

  const handleDeleteTransportation = () => {
    const filesArr = transport?.details?.reduce(
      (files, flight) => [...files, ...(flight?.files || [])],
      []
    );
    const parsedFilesArray = [];
    // To avoid duplicates
    filesArr.forEach((element) => {
      if (parsedFilesArray.includes(element)) {
        return;
      }
      parsedFilesArray.push(element);
    });

    const filesToBeAdded = [];
    if (parsedFilesArray.length > 0) {
      const tempArr = fileRelations?.filter((relation) =>
        parsedFilesArray.includes(relation.fileId)
      );
      tempArr.forEach((relation) => {
        if (
          tempArr.filter((rel) => rel.fileId === relation.fileId).length === 1
        ) {
          filesToBeAdded.push(relation.fileId);
        }
      });
    }

    dispatch(
      deleteTransportation({
        variables: {
          id: transport.id,
          ...(filesToBeAdded?.length > 0 && {
            filesToBeAdded,
            tripId,
          }),
          tripId,
        },
        extra: { tripId },
        files: filesArr,
      })
    );
  };

  const formatDate = (date) => {
    if (!date) return '';
    return format(date, 'MMM dd');
  };

  const formatTime = (date) => {
    if (!date) return '';
    const timeFormat = is24HourFormat ? 'HH:mm' : 'hh:mm aa';
    return format(date, timeFormat);
  };

  function CollapsedIcon() {
    const iconStyle = {
      backgroundColor: '#FFF',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20%',
    };

    let res;
    switch (type) {
      case 'train':
      case 'bus':
        res = <TrainRounded style={iconStyle} />;
        break;
      case 'other':
        iconStyle.width = '24px';
        res = <SemiFoldedMapIcon style={iconStyle} />;
        break;
      default:
        iconStyle.transform = 'rotate(90deg)';
        res = <AirplanemodeActiveRounded style={iconStyle} />;
    }
    return (
      <div className={classes.transportIconWrapper}>
        <div className={classes.transportIconContainer} />
        <div style={{ position: 'absolute' }}>{res}</div>
      </div>
    );
  }

  // function to check single or double click on mobile screens
  const todoClickedMobile = () => {
    if (wait === true) {
      wait = false;
      clearTimeout(timer);
      return 'double';
    }
    wait = true;
    timer = setTimeout(() => {
      wait = false;
      return 'single';
    }, 5000);

    return 'single';
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      className={classes.container}
      // {...dragHandleProps}
    >
      {!openTransportationPanel ? (
        <Grid
          container
          direction="row"
          className={classes.spacing}
          style={{
            marginBottom: '2%',
          }}
          onMouseLeave={() => {
            setHovered(false);
            setMobileSingleClick(false);
          }}>
          {isDragging ? null : (
            <div
              // style={{
              //   position: 'relative',
              //   marginLeft: 'calc(3.6rem + 15px)',
              // }}
              className={classes.transportBlockDiv}>
              <Grid item className={classes.actionContainer}>
                {isHovered && !isMobile ? (
                  <>
                    <DeleteOutlineRounded
                      className={classes.actionIcon}
                      onClick={handleDeleteTransportation}
                    />
                    <AddRounded
                      className={classes.actionIcon}
                      onClick={() => {
                        props.handleAddTransportation(type);
                        phTrackEvent({
                          event:
                            EVENTS.PLAN_ADD.TRANSPORT_ONHOVER_ADD_ICON_CLICK,
                        });
                      }}
                    />
                  </>
                ) : null}
                {/* TODO: Add draghandleprops below */}
                {!isMobile && (
                  <span
                    className={classes.actionIconDragSpan}
                    {...dragHandleProps}>
                    {isHovered ? (
                      <DragIndicatorRounded
                        className={classes.actionIconDrag}
                      />
                    ) : null}
                  </span>
                )}
              </Grid>
            </div>
          )}
          <Grid display="flex" flex={1} alignItems="center">
            {mobileSingleClick && (
              <span
                className={classes.actionIconDragSpan}
                style={{ marginLeft: '0', marginRight: '4px' }}
                {...dragHandleProps}>
                <DragIndicatorRounded className={classes.actionIconDrag} />
              </span>
            )}
            <Grid
              item
              fullWidth
              justify="space-around"
              alignItems="center"
              className={`${classes.root} ${
                isDragging ? classes.isDragging : ''
              } ${classList.transportationBlock}`}
              onMouseEnter={() => {
                if (!isMobile) setHovered(true);
                else setMobileSingleClick(true);
              }}
              onClick={(e) => {
                if (!isMobile) {
                  setHovered(false);
                  setOpenTransportationPanel(e.currentTarget);
                } else {
                  const clickType = todoClickedMobile() || 'single';
                  if (clickType === 'single') {
                    setMobileSingleClick(true);
                  } else if (clickType === 'double') {
                    setMobileSingleClick(false);
                    setHovered(false);
                    setOpenTransportationPanel(e.currentTarget);
                  }
                }
              }}>
              {transport?.details?.map((flight, index) => {
                const fromCity = flight.fromAirport
                  ? transport.type === 'flight'
                    ? airports
                        ?.findWhere({
                          [flight.fromAirport?.length === 3 ? 'iata' : 'icao']:
                            flight.fromAirport,
                        })
                        ?.get('city')
                    : flight.fromAirport
                  : 'Departure';
                const toCity = flight.toAirport
                  ? transport.type === 'flight'
                    ? airports
                        ?.findWhere({
                          [flight.toAirport?.length === 3 ? 'iata' : 'icao']:
                            flight.toAirport,
                        })
                        ?.get('city')
                    : flight.toAirport
                  : 'Arrival';
                const fromDate = flight?.fromDate
                  ? parseISODate(flight?.fromDate, flight?.version)
                  : null;
                const toDate = flight?.toDate
                  ? parseISODate(flight?.toDate, flight?.version)
                  : null;

                const { airlineIcaoCode = null, airline = null } =
                  transport.type === 'flight'
                    ? getAirlineFromFlightNumber(flight?.flightNumber || '')
                    : {};
                // const duration =
                //   fromDate && toDate
                //     ? formatDuration(
                //         intervalToDuration({
                //           start: fromDate,
                //           end: toDate,
                //         }),
                //         {
                //           format: ['days', 'hours', 'minutes'],
                //           zero: false,
                //           delimiter: ',',
                //         }
                //       )
                //         ?.replace(/\s+|s/g, '')
                //         ?.replace(/,/g, ' ')
                //         ?.replace(/[a-zA-Z]+/g, (match) => match[0])
                //     : null;
                return (
                  <Grid
                    item
                    container
                    key={index}
                    style={{
                      borderTop:
                        index === 0
                          ? ''
                          : `1px solid ${isHovered ? '#A7A7A7' : '#DEDDDD'}`,
                    }}>
                    <Grid item xs={9} className={classes.mainContainer}>
                      <Box
                        style={{
                          display: 'flex',
                          flexShrink: 1,
                          maxWidth: '40%',
                        }}>
                        <Grid
                          container
                          item
                          direction="column"
                          style={{ display: 'block' }}>
                          <Typography className={classes.detailsText}>
                            {!fromDate ? (
                              <span style={{ color: 'transparent' }}>.</span>
                            ) : (
                              formatDate(fromDate)
                            )}
                          </Typography>
                          <Typography
                            noWrap
                            className={classes.mainText}
                            sx={{
                              maxWidth: '90px',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              '@media (min-width: 400px)': {
                                maxWidth: '150px',
                              },
                              '@media (min-width: 750px)': {
                                maxWidth: '100px',
                              },
                              '@media (min-width: 1300px)': {
                                maxWidth: '150px',
                              },
                              '@media (min-width: 1500px)': {
                                maxWidth: '200px',
                              },
                              textAlign: 'center',
                            }}>
                            {fromCity}
                          </Typography>
                          <Typography className={classes.detailsText}>
                            {!fromDate ? (
                              <span style={{ color: 'transparent' }}>hi</span>
                            ) : (
                              formatTime(fromDate)
                            )}
                          </Typography>
                        </Grid>
                      </Box>
                      <div style={{ display: 'flex', flex: 1 }}>
                        <Grid
                          container
                          item
                          direction="column"
                          alignItems="center">
                          <Typography className={classes.detailsText}>
                            {/* {!duration ? (
                              <span style={{ color: 'transparent' }}>.</span>
                            ) : (
                              `${duration ? `${duration}` : ''}`
                            )} */}
                          </Typography>
                          <CollapsedIcon />
                          <Typography
                            className={classes.detailsText}
                            sx={{
                              maxWidth: '90px',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              '@media (min-width: 400px)': {
                                maxWidth: '150px',
                              },
                              '@media (min-width: 750px)': {
                                maxWidth: '100px',
                              },
                              '@media (min-width: 1300px)': {
                                maxWidth: '150px',
                              },
                              '@media (min-width: 1500px)': {
                                maxWidth: '200px',
                              },
                              textAlign: 'center',
                            }}>
                            {!flight?.flightNumber ? (
                              <span style={{ color: 'transparent' }}>.</span>
                            ) : (
                              flight?.flightNumber
                            )}
                          </Typography>
                        </Grid>
                      </div>
                      <Box
                        style={{
                          display: 'flex',
                          flexShrink: 1,
                          maxWidth: '40%',
                        }}>
                        <Grid
                          container
                          item
                          direction="column"
                          alignItems="flex-end"
                          style={{ display: 'block' }}>
                          <Typography className={classes.detailsText}>
                            {!toDate ? (
                              <span style={{ color: 'transparent' }}>hi</span>
                            ) : (
                              formatDate(toDate)
                            )}
                          </Typography>
                          <Typography
                            noWrap
                            className={classes.mainText}
                            sx={{
                              maxWidth: '90px',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              '@media (min-width: 400px)': {
                                maxWidth: '150px',
                              },
                              '@media (min-width: 750px)': {
                                maxWidth: '100px',
                              },
                              '@media (min-width: 1300px)': {
                                maxWidth: '150px',
                              },
                              '@media (min-width: 1500px)': {
                                maxWidth: '200px',
                              },
                              textAlign: 'center',
                            }}>
                            {toCity}
                          </Typography>
                          <Typography className={classes.detailsText}>
                            {!toDate ? (
                              <span style={{ color: 'transparent' }}>.</span>
                            ) : (
                              formatTime(toDate)
                            )}
                          </Typography>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={3} className={classes.imageContainer}>
                      {type === 'flight' ? (
                        <img
                          className={classes.image}
                          src={
                            airlineIcaoCode
                              ? `https://storage.googleapis.com/prod-airplane-logos/${airlineIcaoCode}.png`
                              : '/images/flights.svg'
                          }
                          style={{
                            width: airlineIcaoCode ? '100%' : 60,
                          }}
                          onError={(e) => {
                            e.target.src = '/images/flights.svg';
                            e.target.style.width = 60;
                          }}
                          alt={airline || 'flight'}
                        />
                      ) : (
                        <img
                          src="/images/foldedMap.svg"
                          alt="transport"
                          className={classes.image}
                        />
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            {mobileSingleClick && (
              <DeleteOutlineRounded
                className={classes.actionIcon}
                onClick={handleDeleteTransportation}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <div>
          {isMobile && (
            <div
              style={{
                position: 'relative',
                marginLeft: 'calc(3.6rem + 15px)',
              }}>
              <Grid
                item
                className={`${classes.actionContainer} ${classList.itemActionContainer}`}
                style={{ marginTop: type === 'flight' ? 32 : 0 }}>
                {isHovered ? (
                  <>
                    <DeleteOutlineRounded
                      className={classes.actionIcon}
                      onClick={handleDeleteTransportation}
                    />
                    <AddRounded
                      className={classes.actionIcon}
                      onClick={() => props.handleAddTransportation(type)}
                    />
                  </>
                ) : null}
                {/* TODO: Add draghandleprops below */}
                <span
                  className={classes.actionIconDragSpan}
                  {...dragHandleProps}>
                  {isHovered ? (
                    <DragIndicatorRounded className={classes.actionIconDrag} />
                  ) : null}
                </span>
              </Grid>
            </div>
          )}
          <Transportation
            open={openTransportationPanel}
            setOpen={(open) => {
              if (isMobile) setHovered(false);
              setOpenTransportationPanel(open);
            }}
            newFlight={false}
            tripId={transport?.trip}
            id={transport?.id}
            key={transport?.id}
            defaultFlightProps={transport}
            type={type}
            handleDeleteTransportation={handleDeleteTransportation}
          />
        </div>
      )}
    </div>
  );
}

export default TransportBlock;
