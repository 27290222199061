import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Appreciation from './Appreciation';
import AlternateSupport from './AlternateSupport';
import SellScreen from './SellScreen';
import StripePayment from './StripePayment';
import actions from '../../../../redux/actions';
import { updateUser, updateUserV2 } from '../../../../redux/slices/Auth';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

// stripe integrated embedded checkout form
function PwywModal() {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  /*
    -1 -> closed
    0 -> sell screen (default)
    1 -> stripe contribute screen
    2 -> thank you screen
    3 -> other support screen
  */
  const actionStep = useSelector((state) => state.View.contribute?.action);
  const userId = useSelector((state) => state.Auth.firebaseUser.uid);
  const mongoUserId = useSelector((state) => state.Auth.userId);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const contribute = queryParams.get('contribute');
    const action = queryParams.get('action');
    if (contribute === '1' && action === 'thank-you') {
      window.history.replaceState({}, '', `${window.location.pathname}`);
      dispatch(
        actions.View.setContribute({
          action: 2,
        })
      );
      dispatch(
        updateUser({
          variables: {
            disableTravelStats: true,
            id: userId,
          },
        })
      );
      dispatch(updateUserV2({ id: mongoUserId, disableTravelStats: true }));
      dispatch(actions.Trips.setTravelStats(null));
    }
  }, [location.search]);

  const Container = useMemo(() => {
    switch (actionStep) {
      case 0:
        return <SellScreen />;
      case 1:
        return <StripePayment />;
      case 2:
        return <Appreciation />;
      case 3:
        return <AlternateSupport />;
      default:
        return <div />;
    }
  }, [actionStep]);

  const handleCloseModal = () => {
    dispatch(actions.View.setContribute({ action: -1 }));
  };

  return (
    <Modal
      open={actionStep !== -1}
      className={classes.modal}
      onClose={handleCloseModal}>
      {Container}
    </Modal>
  );
}

export default PwywModal;
