import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { Snackbar, SnackbarContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AutomatedFlightBlade from '../AutomatedFlightBlade';
import { updateItem } from '../../../../redux/slices/Item';
import { COST_TYPES, TRANSPORT_TYPES } from '../../../../const';
import InlineBlade from '../../molecules/InlineBlade';

const useStyles = makeStyles(() => ({
  bladeContainer: {
    marginBottom: 4,
    display: 'flex',
    flexDirection: 'row',
  },
  deleteIconContainer: {
    display: 'flex',
    marginRight: 8,
    marginTop: 16,
    minWidth: 20,
  },
  deleteIcon: {
    cursor: 'pointer',
    fontSize: 20,
    color: 'rgba(138, 138, 138, 1)',
    '&:hover': {
      color: '#474747',
    },
  },
  deleteIconHide: {
    display: 'none',
  },
}));

const getEmptyFlightProps = () => ({
  title: '',
  content: {
    description: '',
    links: [],
    expense: {
      amount: null,
      currency: null,
      costType: null,
    },
    fromAirport: '',
    toAirport: '',
    startDate: null,
    endDate: null,
  },
});

function ConnectionBlock({
  open,
  setOpen,
  newFlight,
  defaultFlightProps = {},
  id,
  index,
  transportType = TRANSPORT_TYPES.FLIGHT,
  loading,
  canShowDeleteIcon = false,
  handleUpdateTransportationBlade = () => {},
  handleDeleteConnectingFlight = () => {},
  handleClickOutside = () => {},
  newFlightIds = [],
  setNewFlightIds = () => {},
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { slug: tripId } = useParams();

  const [openAutomatedFlightBlade, setOpenAutomatedFlightBlade] = useState(
    transportType === TRANSPORT_TYPES.FLIGHT &&
      (newFlight || defaultFlightProps.automated)
  );
  const [bladeHovered, setBladeHovered] = useState(false);

  const [searchSuccess, setSearchSuccess] = useState(false);
  const connection = useSelector((state) => state.items.items[id]);

  const [flightProps, setFlightProps] = useState({
    ...getEmptyFlightProps(),
    ...defaultFlightProps,
  });
  const checkNewFlightId = flightProps?.id || (newFlight && 'new-flight');
  const handleUpdateConnection = async (data, additionalFields) => {
    if (
      newFlightIds.includes(checkNewFlightId) &&
      !Object.prototype.hasOwnProperty.call(data, 'automated')
    ) {
      setNewFlightIds(
        newFlightIds.filter((flightId) => flightId !== checkNewFlightId)
      );
    }

    setFlightProps({
      ...flightProps,
      ...data,
    });
    handleUpdateTransportationBlade({ ...flightProps, ...data }, index);
    if (newFlight) {
      return;
    }
    dispatch(
      updateItem({
        variables: {
          id,
          tripId,
          ...data,
        },
        tripId,
        ...additionalFields,
      })
    );
  };

  const namePlaceholder = () => {
    let res;
    switch (transportType) {
      case TRANSPORT_TYPES.TRAIN:
      case TRANSPORT_TYPES.BUS:
        res = 'ticket number, company, link';
        break;
      case TRANSPORT_TYPES.OTHER:
        res = 'title, link, transportation type';
        break;
      default:
        res = 'flight number, airline, link';
    }
    return res;
  };

  return (
    <div
      className={classes.bladeContainer}
      onMouseEnter={() => setBladeHovered(true)}
      onMouseLeave={() => setBladeHovered(false)}>
      <div className={classes.deleteIconContainer} onClick={handleClickOutside}>
        <DeleteOutlineRounded
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteConnectingFlight();
          }}
          className={`${classes.deleteIcon} ${
            bladeHovered && canShowDeleteIcon ? '' : classes.deleteIconHide
          }`}
        />
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={searchSuccess}
        autoHideDuration={5000}
        onClose={() => setSearchSuccess(false)}
        key="auto-flight-search-success-popup">
        <SnackbarContent
          sx={{
            backgroundColor: 'success.main',
            justifyContent: 'center',
          }}
          message="Success! We have found your flight."
        />
      </Snackbar>

      {openAutomatedFlightBlade ? (
        <AutomatedFlightBlade
          open={openAutomatedFlightBlade}
          setOpen={setOpenAutomatedFlightBlade}
          setFlightProps={handleUpdateConnection}
          setOpenFlightBlade={setOpen}
          handleSuccess={() => setSearchSuccess(true)}
          showHeader={index === 0}
        />
      ) : (
        <InlineBlade
          key={id}
          id={id}
          isFlight
          flightProps={{
            ...flightProps,
            handleUpdateConnection,
            showHeader: index === 0,
            setOpenAutomatedFlightBlade,
            isNewFlight: newFlightIds.includes(checkNewFlightId),
          }}
          isLoading={loading}
          name={flightProps?.content?.flightNumber}
          notes={flightProps?.content?.description}
          placeholder={namePlaceholder()}
          costVal={{
            amount: flightProps?.content?.expense?.amount,
            currency: flightProps?.content?.expense?.currency,
            per:
              flightProps?.content?.expense?.costType === COST_TYPES[0] ? 0 : 1,
          }}
          linkVal={flightProps?.content?.links?.[0]}
          hasLink
          hasCost
          open={open}
          transportationType={transportType}
          setOpen={() => {}}
          updateLinkCallback={(link) =>
            handleUpdateConnection({
              content: {
                links: [link],
              },
            })
          }
          updateNotesCallback={(notes) =>
            handleUpdateConnection({
              content: {
                description: notes,
              },
            })
          }
          updateCostCallback={({ amount, currency, per }) =>
            handleUpdateConnection({
              content: {
                expense: {
                  currency,
                  amount: parseFloat(amount),
                  costType: COST_TYPES[parseInt(per, 10)],
                },
              },
            })
          }
          updateNameCallback={(title) =>
            handleUpdateConnection({
              content: {
                flightNumber: title,
              },
            })
          }
          disableTransition
          fileAttachmentModalProps={{
            attachedToText: flightProps?.content?.flightNumber,
            attachedToID: id,
            attachedToType: 'Transportation',
            transportType,
            attachedFiles: connection?.files || flightProps?.files || [],
            attachFunc: (newFiles) => {
              handleUpdateConnection(
                {
                  files: newFiles,
                },
                {
                  attachedToType: 'Transportation',
                  tripId,
                }
              );
            },
          }}
        />
      )}
    </div>
  );
}

export default ConnectionBlock;
