import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Stack,
  Box,
  Typography,
  CircularProgress,
  InputBase,
  ClickAwayListener,
  capitalize,
  IconButton,
  Skeleton,
  Tooltip,
  ButtonBase,
  Button,
  Chip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { onValue, ref } from 'firebase/database';
import {
  Close,
  MailOutlineOutlined,
  MoreHoriz,
  MoreHorizRounded,
} from '@mui/icons-material';
import { ChevronDownIcon, ChevronUpIcon } from '@iconicicons/react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  getFileExtention,
  isTripV2,
  getFileIcon,
  getFileName,
  truncateString,
} from '../../../../utils';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import { database } from '../../../../firebase/FirebaseIndex';
import actions from '../../../../redux/actions';
import { createImport } from '../../../../redux/slices/Files';
import {
  createImport as createImportV2,
  updateFile,
} from '../../../../redux/slices/FilesV2';
import OptionsMenu from '../../../molecules/Menu/OptionsMenu';
import { DeleteModal } from '../../../molecules/Modal';
import SmartImport from '../../../molecules/SmartImport';
import SmartImportV2 from '../../molecules/SmartImport';
import UserAvatar from '../../../molecules/UserAvatar';
import ItemAttachment from './ItemAttachment';
import { importModelViews } from '../../../../redux/slices/View';
import { updateTrip } from '../../../../redux/slices/Trips';
import AlertAction from '../../../atoms/AlertAction';
import useSmartImport from '../../molecules/SmartImport/useSmartImport';
import queries from '../../../../V3/graphql/queries';

const filesListStyles = makeStyles(() => ({
  headerText: {
    fontSize: 12,
    color: '#4E4E4E',
    textTransform: 'uppercase',
    padding: '0 4px',
  },
  mainText: {
    fontSize: 14,
    color: '#000',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  secondaryText: {
    fontSize: 14,
    color: '#8A8A8A',
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  headerContainer: {
    borderBottom: '1px solid #DEDDDD',
    padding: '6px 12px',
  },
  nameRow: {
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  nameRowFocused: {
    background: '#FFFFFF',
    boxShadow: 'inset 0px 0px 0px 2px #F4F4F4',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  filesListContainer: {
    // 100vh - (static space above the files list + navbar height + static padding below the files list).
    maxHeight: 'calc(100vh - 234px - 60px)',
    overflowY: 'auto',
  },
  fileContainer: {
    borderBottom: '1px solid #DEDDDD',
    padding: '4px 0px',
  },
  fileWrapper: {
    borderRadius: 4,
    padding: '0 12px',
  },
  flexRow: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '8px 4px',
  },
  moreVertButton: {
    padding: 2,
  },
  moreVertIcon: {
    width: 16,
    height: 16,
  },
  fileLogo: {
    marginRight: 9,
    height: 20,
    width: 20,
    viewBox: '0 0 20 20',
    minWidth: 20,
  },
  fileAttachTrigger: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '0 4px',
    maxWidth: '100%',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  renameInput: {
    padding: 0,
    fontSize: 14,
  },
  attachItemsButton: {
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 4,
  },
  chip: {
    borderRadius: 4,
    borderColor: '#9747FF',
    color: '#9747FF',
    fontSize: 12,
    padding: '13px 4px',
    '& .MuiChip-deleteIcon': {
      color: '#9747FF',
    },
  },
}));

function FileRow({
  file,
  fileIndex,
  handleTriggerDeleteFile,
  tripId,
  tripFiles,
  showOnlyFileAndImports,
  isForwardedFile,
  isModalView,
  showAsAddedonAdded,
  collapsible,
  onCollapseChange,
  isCollapsed,
  containerId,
}) {
  const [isSavingToFilesTab, setIsSavingToFilesTab] = useState(false);
  const isTablet = useMediaQuery('(max-width:660px)');
  const isSmallDevice = useMediaQuery('(max-width:360px)');
  const classes = filesListStyles();
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [optionsMenuAnchor, setOptionsMenuAnchor] = useState(false);
  const [isTextFocused, setIsTextFocused] = useState(false);
  const [fileName, setFileName] = useState(getFileName(file.name));
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const importInProgress =
    file?.import?.status === 'pending' ||
    file?.import?.status === 'in_progress';
  const importErrored = file?.import?.status === 'error';
  const importItemsDeleted = file?.import?.status === 'itemsDeleted';
  // const importInProgress = true;
  const totalDetectedImports = file?.import?.items?.length;
  const hasDetectedImports = totalDetectedImports > 0;
  const detectedDetailsLabel =
    totalDetectedImports > 1
      ? 'Multiple Detected'
      : `${capitalize(file?.import?.items?.[0]?.type || '')} Details`;
  const [itineraryItemProps, setItineraryItemProps] = useState([]);
  const [showDetectedItems, setShowDetectedItems] = useState(true);
  const [showImportDetailsButton, setShowImportDetailsButton] = useState(false);
  const { locationPopperOpen, reviewQueue } = useSelector(
    (state) => state.View
  );
  const navigate = useNavigate();
  const moreHorizButtonRef = useRef(null);
  // remove the parameters from the file link url and extract the file extension
  const url = file.url?.split('?')[0];
  const extension = url?.split('.')?.pop();
  const [isMoreOptionsPopperOpen, setIsMoreOptionsPopperOpen] = useState(false);

  const Logo = getFileIcon(extension);

  const handleNameSubmit = () => {
    const fileExtenstion = getFileExtention(file.name);
    if (file.name !== `${fileName}.${fileExtenstion}`) {
      dispatch(
        updateFile({
          variables: {
            id: file.id,
            file: {
              name: `${fileName}.${fileExtenstion}`,
            },
          },
        })
      );
      phTrackEvent({ event: EVENTS.TRIP_FILES.FILE_NAME_UPDATE });
    }
  };

  const { stopPolling, data: importDataV2 } = useQuery(queries.GET_IMPORT, {
    variables: { importId: file?.import?.id },
    pollInterval: isTripV2(file.id) && importInProgress ? 1000 : 0,
  });

  useEffect(() => {
    if (
      importDataV2?.getImport?.status === 'processed' ||
      importDataV2?.getImport?.status === 'error' ||
      importDataV2?.getImport?.status === 'importError'
    ) {
      stopPolling();
      setShowDetectedItems(true);
      dispatch(
        actions.FilesV2.updateImport({
          fileId: file?.id,
          import: importDataV2?.getImport,
        })
      );
    }
  }, [importDataV2]);

  useEffect(() => {
    if (importInProgress && !isTripV2(file.id)) {
      const unSub = onValue(
        ref(database, `imports/${file?.import?.id}`),
        (snapshot) => {
          const snap = snapshot.val();
          if (snap?.status === 'processed' || snap?.status === 'error') {
            setShowDetectedItems(true);
            dispatch(
              actions.Files.updateImport({
                fileId: file.id,
                import: snap,
              })
            );
            unSub();
          }
        },
        (error) => {
          throw error;
        }
      );
    }
  }, [importInProgress]);

  const populateItineraryItemProps = (item) => {
    if (item?.type === 'flight') {
      // check if flight item is object or array, if array - take the first item
      const details = Array.isArray(item?.flight)
        ? item?.flight.map((flight) => ({
            ...flight,
            __typename: 'Flight',
          }))
        : [item?.flight];

      const listKey = details[0]?.id;

      setItineraryItemProps((items) => [
        ...items,
        {
          listKey,
          trip: tripId,
          type: item?.type,
          details,
        },
      ]);
    }
    if (item?.type === 'accommodation') {
      setItineraryItemProps((items) => [
        ...(items || []),
        {
          listKey: item?.accommodation?.id,
          ...item?.accommodation,
          type: item?.type,
          placeId: item?.placeId,
          __typename: 'Accommodation',
        },
      ]);
    }
    if (item?.type === 'transport') {
      setItineraryItemProps((items) => [
        ...(items || []),
        {
          listKey: item?.transport?.id,
          trip: tripId,
          type: item?.transport?.type,
          details: [
            {
              ...item?.transport,
              __typename: 'Transportation',
            },
          ],
          __typename: 'Transportation',
        },
      ]);
    }
    if (item?.type === 'activity') {
      setItineraryItemProps((items) => [
        ...(items || []),
        {
          listKey: item?.activity?.id,
          ...item?.activity,
          type: item?.type,
          placeId: item?.placeId,

          __typename: 'Todo',
        },
      ]);
    }
  };

  useEffect(() => {
    setItineraryItemProps([]);
    if (file?.import?.items?.length > 0) {
      file?.import?.items?.forEach((item) => {
        populateItineraryItemProps(item);
        // createItineraryItems(item);
      });
    }
  }, [file]);

  const attachFileToTrip = async () => {
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.PLAN_IMPORT_SAVE_TO_FILES_ADD,
    });
    setIsSavingToFilesTab(true);
    await dispatch(
      updateFile({
        variables: {
          id: file.id,
          attachedToID: tripId,
          tripId,
          attachedToType: 'Trip',
        },
      })
    );
    await dispatch(
      updateTrip({
        variables: {
          id: tripId,
          files: [...(tripFiles || []), file.id],
        },
        addFiles: [file.id],
      })
    );
    setIsSavingToFilesTab(false);
  };

  const getDetectedDetailsLabel = () => {
    if (file?.import?.status === 'processed') {
      return (
        <Typography
          onClick={() => {
            if (hasDetectedImports) {
              dispatch(actions.View.setImportOpenId(file?.id));
              dispatch(
                actions.View.setImportsModelView(importModelViews.history)
              );
              dispatch(actions.View.setIsFilesTabOpen(true));
              navigate(`/trips/${tripId}/planner`);
            }
          }}
          color={hasDetectedImports ? '#9747FF' : '#8A8A8A'}
          fontSize="14px"
          sx={{
            fontStyle: 'italic',
            '&:hover': hasDetectedImports
              ? {
                  textDecoration: 'underline',
                  color: '#5D3FD3',
                  cursor: 'pointer',
                }
              : {},
          }}>
          {hasDetectedImports ? detectedDetailsLabel : 'None'}
        </Typography>
      );
    }
    return (
      <Button
        disableFocusRipple
        disableRipple
        onClick={async () => {
          if (isTripV2(file.id)) {
            await dispatch(
              createImportV2({
                variables: {
                  import: {
                    source: 'file',
                    name: file.name,
                    trip: tripId,
                    file: file.id,
                  },
                },
              })
            );
          } else {
            await dispatch(
              createImport({
                variables: {
                  import: {
                    source: 'file',
                    name: file.name,
                    tripId,
                    fileId: file.id,
                  },
                },
              })
            );
          }
          dispatch(actions.View.setIsFilesTabOpen(true));
          await navigate(`/trips/${tripId}/planner`);
        }}
        sx={{
          height: 28,
          visibility: showImportDetailsButton ? 'visible' : 'hidden',
          color: '#4E4E4E',
          backgroundColor: 'white',
          border: '1px solid #D9D9D9',
          '&:hover': {
            backgroundColor: '#F4F4F4',
            border: '1px solid #D9D9D9',
            color: '#4E4E4E',
          },
          '&:active': {
            backgroundColor: '#D9D9D9',
            border: '1px solid #D9D9D9',
            color: '#4E4E4E',
          },
        }}>
        Import Details
      </Button>
    );
  };
  const attachedItems =
    file?.import?.items?.length > 0
      ? file?.import?.items?.map((it) => it.attachedToItem).filter(Boolean)
      : [];

  const getSmartImportProps = (item, index) => ({
    showAsAddedonAdded,
    width: showOnlyFileAndImports || isTablet ? '360px' : '300px',
    flexDirection: showOnlyFileAndImports || isTablet ? 'column' : 'row',
    fileId: file?.id,
    item,
    tripId,
    itemIndex: index,
    fileIndex,
    isForwardedFile,
    isModalView: isModalView || isTablet,
    importId: file?.import?.id,
    attachedToItem: file?.import?.items[index]?.attachedToItem,
  });

  return (
    <Grid
      sx={
        isModalView
          ? {
              overflowY: 'hidden',
            }
          : {}
      }
      item
      container
      className={classes.fileContainer}
      id={containerId}>
      <Stack direction="column" width="100%">
        <Grid
          container
          className={classes.fileWrapper}
          onMouseEnter={() => !locationPopperOpen && setIsHovered(true)}
          onMouseLeave={() => !locationPopperOpen && setIsHovered(false)}>
          <Grid
            onClick={(e) => {
              if (collapsible) {
                onCollapseChange(!isCollapsed);
                e.stopPropagation();
              }
            }}
            item
            sx={{
              cursor: collapsible ? 'pointer' : 'default',
            }}
            xs={showOnlyFileAndImports ? 12 : 4}
            display="flex">
            <Box
              className={`${classes.flexRow} ${classes.nameRow} ${
                (isTextFocused && classes.nameRowFocused) || ''
              }`}
              {...(!isTextFocused
                ? {
                    onClick: () => {
                      dispatch(
                        actions.FilesV2.setFilePreview({
                          extension,
                          ...file,
                        })
                      );
                    },
                  }
                : {})}>
              <Logo className={classes.fileLogo} />

              {!isTextFocused ? (
                <Typography className={classes.mainText}>
                  {truncateString(
                    file?.name,
                    isSmallDevice ? 32 : isTablet ? 10 : 30
                  )}
                </Typography>
              ) : (
                <ClickAwayListener
                  onClickAway={() => {
                    handleNameSubmit();
                    setIsTextFocused(false);
                  }}>
                  <form
                    onSubmit={() => {
                      handleNameSubmit();
                      setIsTextFocused(false);
                    }}>
                    <InputBase
                      value={getFileName(fileName)}
                      classes={{
                        input: classes.renameInput,
                      }}
                      inputRef={(input) => input && input.focus()}
                      inputProps={{ 'aria-label': 'Rename File' }}
                      onChange={(e) => {
                        setFileName(e.target.value);
                      }}
                    />
                  </form>
                </ClickAwayListener>
              )}
              <Box
                sx={{
                  position: 'absolute',
                  right: 12,
                }}>
                <Stack direction="row" alignItems="center">
                  {isModalView && (
                    <IconButton
                      sx={{
                        visibility: isHovered ? 'visible' : 'hidden',
                      }}
                      ref={(r) => {
                        moreHorizButtonRef.current = r;
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsMoreOptionsPopperOpen(!isMoreOptionsPopperOpen);
                      }}>
                      <MoreHoriz />
                    </IconButton>
                  )}
                  {isMoreOptionsPopperOpen && (
                    <OptionsMenu
                      id="importPopper"
                      state={moreHorizButtonRef.current}
                      setState={setIsMoreOptionsPopperOpen}
                      callbacks={[() => setDeleteModalOpen(true)]}
                      options={['Delete']}
                      menuItemProps={{
                        style: {
                          fontSize: 12,
                          padding: '4px 16px',
                        },
                      }}
                      menuProps={{
                        MenuListProps: {
                          style: {
                            zIndex: 19000,
                            padding: '4px 0',
                          },
                        },
                      }}
                    />
                  )}
                  {collapsible && (
                    <IconButton
                      onClick={(e) => {
                        onCollapseChange(!isCollapsed);
                        e.stopPropagation();
                      }}>
                      {isCollapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
                    </IconButton>
                  )}
                </Stack>
              </Box>
            </Box>
          </Grid>
          {!showOnlyFileAndImports ? (
            <>
              {!isTablet && (
                <Grid
                  item
                  onMouseEnter={() => setShowImportDetailsButton(true)}
                  onMouseLeave={() => setShowImportDetailsButton(false)}
                  xs={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  {importInProgress ? (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: '#9747FF',
                      }}
                    />
                  ) : (
                    getDetectedDetailsLabel()
                  )}
                </Grid>
              )}
              <Grid
                item
                xs={isTablet ? 6 : 2}
                display="flex"
                alignItems="center">
                {!hasDetectedImports ? (
                  <Box className={classes.fileAttachTrigger}>
                    <ItemAttachment
                      tripId={tripId}
                      fileId={file?.id}
                      tripFiles={tripFiles}
                    />
                  </Box>
                ) : (
                  <Box width="100%">
                    <Box
                      onClick={() => {}}
                      className={classes.attachItemsButton}>
                      {attachedItems.length > 0 &&
                        file?.import?.items?.map((item, index) => {
                          const { getAttachedItemName, detachItem } =
                            useSmartImport({
                              attachedToItem: item.attachedToItem,
                              itemType: item.type,
                              isForwardedFile,
                              fileId: file.id,
                              tripId,
                              fileIndex,
                              itemIndex: index,
                              importId: file?.import?.id,
                            });
                          return (
                            getAttachedItemName() && (
                              <Chip
                                key={item}
                                size="small"
                                label={getAttachedItemName()}
                                className={classes.chip}
                                variant="outlined"
                                onDelete={() => detachItem()}
                                deleteIcon={<Close />}
                              />
                            )
                          );
                        })}
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  paddingRight: 12,
                }}
                className={classes.flexRow}
                justifyContent="center">
                {file.createdBy && file.type === 'Upload' ? (
                  <UserAvatar
                    user={file.createdBy}
                    index={0}
                    showNameOnHover
                    avatarProps={{
                      style: {
                        height: 28,
                        width: 28,
                        fontSize: 12,
                      },
                    }}
                  />
                ) : (
                  <Tooltip
                    title={`Forwarded by ${file.createdBy.firstName} ${file.createdBy.lastName}`}
                    arrow>
                    <MailOutlineOutlined />
                  </Tooltip>
                )}
                <IconButton
                  sx={{
                    visibility: isHovered ? 'visible' : 'hidden',
                    marginLeft: 1,
                    marginRight: 4,
                  }}
                  className={`${classes.moreVertButton}`}
                  onClick={(e) => setOptionsMenuAnchor(e.currentTarget)}>
                  <MoreHorizRounded
                    className={classes.moreVertIcon}
                    aria-controls={`${file.id}-file-menu`}
                    aria-haspopup="true"
                  />
                </IconButton>
                <OptionsMenu
                  id={`${file.id}-file-menu`}
                  state={optionsMenuAnchor}
                  setState={setOptionsMenuAnchor}
                  callbacks={[
                    () => {
                      setIsTextFocused(true);
                    },
                    () => {
                      phTrackEvent({
                        event:
                          EVENTS.SMART_IMPORT.PLAN_IMPORT_HISTORY_FILE_DELETE,
                      });
                      setDeleteModalOpen(true);
                    },
                  ]}
                  options={['Rename', 'Delete']}
                  menuItemProps={{
                    style: {
                      fontSize: 12,
                      padding: '4px 16px',
                    },
                  }}
                  menuProps={{
                    MenuListProps: {
                      style: {
                        padding: '4px 0',
                      },
                    },
                  }}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
        <Grid
          container
          className={classes.fileWrapper}
          onMouseEnter={() => !locationPopperOpen && setIsHovered(true)}
          onMouseLeave={() => !locationPopperOpen && setIsHovered(false)}>
          {isModalView && (
            <Grid
              item
              xs={showOnlyFileAndImports || isTablet ? 12 : 10}
              display="flex">
              <Box width="100%">
                {importInProgress && (
                  <Grid width="100%" container>
                    <Grid item xs={10} display="flex">
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height={80}
                        sx={{
                          borderRadius: 2,
                          marginLeft: 4,
                          margin: 2,
                        }}
                        width="100%"
                      />
                    </Grid>
                  </Grid>
                )}
                {importErrored && (
                  <AlertAction
                    bgColor="#FFDF97"
                    textColor="#4E4E4E"
                    actionText="Retry"
                    action={async () => {
                      phTrackEvent({
                        event: EVENTS.SMART_IMPORT.PLAN_IMPORT_RETRY_START,
                      });
                      await dispatch(
                        isTripV2(file?.id)
                          ? createImportV2({
                              variables: {
                                import: {
                                  source: 'file',
                                  name: file?.name,
                                  file: file?.id,
                                },
                              },
                            })
                          : createImport({
                              variables: {
                                import: {
                                  source: 'file',
                                  name: file?.name,
                                  fileId: file?.id,
                                },
                              },
                            })
                      );
                    }}>
                    Error Detecting details. Please try again.
                  </AlertAction>
                )}

                {!isCollapsed &&
                  importItemsDeleted &&
                  !isCollapsed &&
                  !importInProgress && (
                    <AlertAction
                      bgColor="#FFDF97"
                      textColor="#4E4E4E"
                      actionText="Retry"
                      action={async () => {
                        phTrackEvent({
                          event: EVENTS.SMART_IMPORT.PLAN_IMPORT_RETRY_START,
                        });
                        const createVars = {
                          variables: {
                            import: {
                              source: 'file',
                              name: file?.name,
                            },
                          },
                        };
                        console.log('createVars', createVars, file?.id);
                        await dispatch(
                          isTripV2(file?.id)
                            ? createImportV2({
                                variables: {
                                  import: {
                                    source: 'file',
                                    name: file?.name,
                                    file: file?.id,
                                  },
                                },
                              })
                            : createImport({
                                variables: {
                                  import: {
                                    source: 'file',
                                    name: file?.name,
                                    fileId: file?.id,
                                  },
                                },
                              })
                        );
                      }}>
                      Details deleted.
                    </AlertAction>
                  )}
                {itineraryItemProps?.length > 0 && !isCollapsed ? (
                  <Stack direction="column" width="100%">
                    {/* <Typography
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        '&:hover': {
                          color: '#A12BFF',
                        },
                      }}
                      fontSize={10}
                      onClick={() => setShowDetectedItems(!showDetectedItems)}>
                      {showDetectedItems ? 'Hide' : 'Show'} Detected Items
                    </Typography> */}
                    {showDetectedItems &&
                      itineraryItemProps.map(
                        (item, index) =>
                          item?.type && (
                            <Box
                              key={item?.listKey}
                              sx={{
                                p: isForwardedFile ? 0 : 0,
                                pt: index > 0 ? 2 : 0,
                              }}>
                              {isModalView &&
                                hasDetectedImports &&
                                index === 0 && (
                                  <Typography
                                    py={1}
                                    color={
                                      hasDetectedImports ? '#9747FF' : '#8A8A8A'
                                    }
                                    fontSize="14px"
                                    sx={{
                                      fontStyle: 'italic',
                                      pl: {
                                        xs: 1,
                                        sm: 4,
                                      },
                                    }}>
                                    {detectedDetailsLabel}
                                  </Typography>
                                )}
                              {isTripV2(tripId) ? (
                                <SmartImportV2
                                  {...getSmartImportProps(item, index)}
                                />
                              ) : (
                                <SmartImport
                                  {...getSmartImportProps(item, index)}
                                />
                              )}
                            </Box>
                          )
                      )}
                  </Stack>
                ) : isModalView &&
                  !isCollapsed &&
                  !hasDetectedImports &&
                  !importItemsDeleted &&
                  !importInProgress ? (
                  <Stack px="24px" direction="column">
                    <Box
                      sx={{
                        backgroundColor: '#FFDF97',
                        padding: '16px 24px',
                        borderRadius: '8px',
                        margin: '16px 0',
                        color: '#4E4E4E',
                        fontSize: '16px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                      }}>
                      <Typography fontSize="12px">
                        We couldn&apos;t detect any details from this file.
                        Please upload any files with transportation,
                        accommodation, or activity details to import to Pilot.
                      </Typography>
                    </Box>
                    <Stack
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      width="100%">
                      <Stack direction="row" alignItems="center" gap={1}>
                        {!file?.attachedToType && (
                          <ButtonBase
                            disabled={isSavingToFilesTab}
                            onClick={attachFileToTrip}
                            sx={{
                              borderRadius: '4px',
                              padding: '8px',
                              height: '32px',
                              borderColor: '#D9D9D9',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                            }}>
                            {isSavingToFilesTab ? (
                              <CircularProgress size={20} />
                            ) : (
                              'Save to Files Tab'
                            )}
                          </ButtonBase>
                        )}
                        <Button
                          sx={{
                            height: '32px',
                          }}
                          onClick={() => {
                            phTrackEvent({
                              event:
                                EVENTS.SMART_IMPORT.PLAN_IMPORT_RESTART_CLICK,
                            });
                            dispatch(
                              actions.View.setImportsModelView(
                                importModelViews.import
                              )
                            );
                            if (reviewQueue?.length === 0) {
                              dispatch(actions.View.removeImportIdToReview());
                              dispatch(
                                actions.View.setImportsModelView(
                                  importModelViews.import
                                )
                              );
                            } else {
                              dispatch(actions.View.popReviewQueue());
                              if (reviewQueue?.length === 0) {
                                dispatch(actions.View.removeImportIdToReview());
                                dispatch(
                                  actions.View.setImportsModelView(
                                    importModelViews.import
                                  )
                                );
                              }
                            }
                          }}>
                          Upload Another File
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                ) : null}
              </Box>
            </Grid>
          )}
        </Grid>
      </Stack>
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        executeFunc={() => {
          handleTriggerDeleteFile(file);
          setDeleteModalOpen(false);
        }}
        alertText="Are you sure?"
        descriptionText="This action will detach your file from all itinerary items and remove your file from Pilot permanently."
        confirmText="Delete"
        cancelText="Cancel"
        emoji="⚠️"
      />
    </Grid>
  );
}
export default FileRow;
