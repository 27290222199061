import { useDispatch, useSelector } from 'react-redux';
import { ChevronRightRounded, DeleteOutlineRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { getCurrencyFormatter } from '../utils';
import actions from '../../../../../../redux/actions';
import { EVENTS, phTrackEvent } from '../../../../../../analytics';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '36px',
    justifyContent: 'center',
    borderTop: '1px solid #e0e0e0',
    boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
    // TODO: Make this part of theme
    zIndex: 2222,
    [breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  controlledWidth: {
    display: 'flex',
    alignItems: 'center',
    width: 1032,
    margin: '0 auto',
    [breakpoints.down('lg')]: {
      width: '100%',
      padding: '0 48px',
    },
    [breakpoints.down('sm')]: {
      padding: '0 12px',
    },
  },
}));

function CartReviewBottomBar() {
  const classes = useStyles();
  const { slug: hotelKey } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const selectedPackageId = useSelector(
    (state) =>
      state.Bookings.cart?.packages?.length > 0 &&
      state.Bookings.cart?.packages[0]
  );

  const roomPackage = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelKey]?.packages?.find(
      (p) => p?.packageId === selectedPackageId
    )
  );

  const room = roomPackage?.rooms[0];

  if (!selectedPackageId || !roomPackage || !room) return null;

  const handleRemoveFromCart = () => {
    phTrackEvent({ event: EVENTS.EXPLORE_STAY_DETAIL.ROOM_REMOVE });
    dispatch(actions.Bookings.removePackage({ packageId: selectedPackageId }));
  };

  const handleCheckout = () => {
    phTrackEvent({ event: EVENTS.EXPLORE_STAY_DETAIL.CHECKOUT_START });
    navigate(`/hotel/${hotelKey}/checkout/${selectedPackageId}`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.controlledWidth}>
        <Box flexDirection="column" display="flex">
          <Typography variant="caption" color="#8A8A8A">
            Your selection:
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: isMobile ? 0 : 4,
            }}>
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              style={{ fontWeight: 400, marginRight: '5px' }}>
              <span style={{ fontWeight: 'bold' }}>
                {roomPackage?.rooms?.length}x
              </span>{' '}
              {room?.name}
            </Typography>
            <IconButton
              sx={{ padding: 0.5, mt: -0.4 }}
              onClick={handleRemoveFromCart}>
              <DeleteOutlineRounded
                sx={{ color: '#8A8A8A', height: 20, width: 20 }}
              />
            </IconButton>
          </div>
        </Box>

        <Box sx={{ ml: 'auto', mr: isMobile ? 1 : 4 }}>
          <Typography variant={isMobile ? 'h4' : 'h3'}>
            {roomPackage?.totalPrice?.currency &&
              getCurrencyFormatter(roomPackage?.totalPrice?.currency).format(
                roomPackage?.totalPrice?.price
              )}
          </Typography>
          {!isMobile && (
            <Typography variant="caption" color="#4E4E4E">
              Total Price incl. tax
            </Typography>
          )}
        </Box>
        <Button
          sx={{ ...(isMobile && { padding: '12px 8px' }) }}
          onClick={handleCheckout}>
          Reserve {isMobile ? '' : 'now'}
          <ChevronRightRounded />
        </Button>
      </div>
    </div>
  );
}

export default CartReviewBottomBar;
