import { createSlice } from '@reduxjs/toolkit';
import { updateTrip } from './Trips';
import QUERY from '../../graphql/queries';
import QUERYV2 from '../../V3/graphql/queries';
import { getAsyncThunkV2 as getAsyncThunk } from '../helpers';
import { trackEvents, Events } from '../../intercom';
import { getCompleteTripUsingAtc } from './TripV2';
import { deleteItem, updateItem } from './Item';

const initialState = {
  files: {},
  fileRelations: {},
  openFile: null,
  multipleFiles: [],
};

export const createFile = getAsyncThunk(
  'FILES_V2/createFile',
  QUERYV2.CREATE_FILE
);

export const updateFile = getAsyncThunk(
  'FILES_V2/updateFile',
  QUERYV2.UPDATE_FILE
);

export const deleteFile = getAsyncThunk(
  'FILES_V2/deleteFile',
  QUERYV2.DELETE_FILE
);

export const createImport = getAsyncThunk(
  'FILES_V2/createImport',
  QUERY.CREATE_IMPORT
);

export const attachFiletoItems = getAsyncThunk(
  'FILES_V2/attachFiletoItems',
  QUERYV2.ATTACH_FILE_TO_ITEMS
);

export const updateImport = getAsyncThunk(
  'FILES_V2/updateImport',
  QUERY.UPDATE_IMPORT
);

export const updateImportAttachedTo = getAsyncThunk(
  'FILES_V2/updateImportAttachedTo',
  QUERYV2.UPDATE_IMPORT_ATTACHED_TO
);

export const deleteImportItem = getAsyncThunk(
  'FILES_V2/deleteImportItem',
  QUERYV2.DELETE_IMPORT_ITEM
);

const FilesV2Slice = createSlice({
  name: 'FILES_V2',
  initialState,
  reducers: {
    setFileRelations: (state, { payload }) => {
      state.fileRelations = { ...state.fileRelations, ...payload };
    },
    setFilePreview: (state, { payload: file }) => {
      state.openFile = file;
    },
    resetFilePreview: (state) => {
      state.openFile = null;
      state.multipleFiles = [];
    },
    setMultipleFilesPreview: (state, { payload: attachedFiles }) => {
      state.multipleFiles = [...attachedFiles].map((file) =>
        typeof file === 'string' ? file : file?.id
      );
      if (attachedFiles?.length > 0) {
        state.openFile = {
          extension: state.files[attachedFiles[0]?.id || attachedFiles[0]]?.url
            ?.split('?')[0]
            ?.split('.')
            ?.pop(),
          ...state.files[attachedFiles[0]?.id || attachedFiles[0]],
        };
      }
    },
    updateImport: (state, { payload }) => {
      if (state.files[payload.fileId]) {
        state.files[payload.fileId].import = payload.import;
      }
    },
  },
  extraReducers: {
    [getCompleteTripUsingAtc.fulfilled]: (
      state,
      {
        payload: {
          tripFiles,
          trip: { id: tripId, importedFiles },
          allFilesRelations,
        },
      }
    ) => {
      tripFiles?.forEach((file) => {
        if (
          !state.files[file?.id] ||
          (!state.files[file?.id].import && file.import)
        ) {
          state.files = { ...state.files, [file?.id]: { ...file, tripId } };
        }
      });
      importedFiles?.forEach((file) => {
        state.files = { ...state.files, [file?.id]: { ...file, tripId } };
      });
      state.fileRelations = {
        ...state.fileRelations,
        [tripId]: allFilesRelations,
      };
    },
    [createFile.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: {
              file: { attachedToId, attachedToType, tripId },
            },
          },
        },
        payload: { createFile: file },
      }
    ) => {
      state.files[file.id] = { ...file, tripId };
      state.fileRelations[tripId] = [
        ...(state.fileRelations[tripId] || []),
        {
          attachedToType,
          attachedToId,
          fileId: file.id,
        },
      ];
    },
    [updateFile.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: {
            variables: { id, file },
          },
        },
      }
    ) => {
      if (state.files[id]) {
        state.files[id] = {
          ...(state.files[id] || {}),
          ...file,
        };
      } else {
        state.files[id] = {
          ...payload.updateFile,
          tripId: file?.attachedToId,
        };
        if (file?.attachedToType && file?.attachedToId) {
          state.fileRelations[file?.attachedToId] = [
            ...(state.fileRelations[file?.attachedToId] || []),
            {
              attachedToType: file?.attachedToType,
              attachedToId: file?.attachedToId,
              fileId: id,
            },
          ];
        }
      }
    },

    // creating and deleting mapPins in real time, this setup to reduce repetitive code.
    ...([deleteItem.fulfilled].reduce(
      (deleteFileReducers, action) => ({
        ...deleteFileReducers,
        [action]: (
          state,
          {
            meta: {
              arg: {
                variables: { id, tripId, filesToBeAdded },
              },
            },
          }
        ) => {
          state.fileRelations[tripId] = state.fileRelations[tripId]?.filter(
            (relation) => relation.attachedToId !== id
          );
          if (filesToBeAdded?.length > 0) {
            state.fileRelations[tripId] = [
              ...state.fileRelations[tripId],
              ...filesToBeAdded.map((file) => ({
                attachedToType: 'Trip',
                attachedToId: tripId,
                fileId: file,
              })),
            ];
          }
        },
      }),
      {}
    ) || {}),

    ...([updateItem.fulfilled].reduce(
      (updateFileReducers, action) => ({
        ...updateFileReducers,
        [action]: (
          state,
          {
            meta: {
              arg: {
                variables: { title, id, files },
                attachedToType,
                tripId,
              },
            },
          }
        ) => {
          // check for updated titles to update file tab text
          const updatedTitle = title;
          if (updatedTitle) {
            const newFiles = Object.values(state.files).filter(
              (file) => file.attachedToId === id
            );
            newFiles?.forEach((file) => {
              state.files[file?.id] = {
                ...state.files[file?.id],
                attachedToText: updatedTitle,
              };
            });
          }

          if (files && tripId && attachedToType) {
            state.fileRelations[tripId] = [
              ...state.fileRelations[tripId].filter(
                (relation) => relation.attachedToId !== id
              ),
              ...files.map((file) => ({
                attachedToType,
                attachedToId: id,
                fileId: file,
              })),
            ];
          }
        },
      }),
      {}
    ) || {}),

    [updateTrip.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { id },
            addFiles,
          },
        },
      }
    ) => {
      if (addFiles) {
        state.fileRelations[id] = [
          ...state.fileRelations[id],
          ...addFiles.map((file) => ({
            attachedToType: 'Trip',
            attachedToId: id,
            fileId: file,
          })),
        ];
      }
    },

    // [deleteFile.pending]: (
    //   state,
    //   {
    //     meta: {
    //       arg: {
    //         variables: { id, fileRelation },
    //         tripId,
    //       },
    //     },
    //   }
    // ) => {
    //   delete state.files[id];
    //   state.fileRelations[tripId] = state.fileRelations[tripId]?.filter(
    //     (relation) => {
    //       const tempArr = fileRelation?.filter((rel) => {
    //         return (
    //           rel?.fileId === relation?.fileId &&
    //           rel?.attachedToId === relation?.attachedToId &&
    //           rel?.attachedToType === relation?.attachedToType
    //         );
    //       });
    //       return tempArr?.length === 0;
    //     }
    //   );
    // },

    [deleteFile.pending]: (
      state,
      {
        meta: {
          arg: {
            variables: { id },
            tripId,
          },
        },
      }
    ) => {
      const fileToBeDeleted = state.files[id];
      if (fileToBeDeleted?.attachedToId) {
        state.fileRelations[tripId] = state.fileRelations[tripId]?.filter(
          (relation) => relation.fileId !== id
        );
      }
      delete state.files[id];
    },

    [attachFiletoItems.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { id, tripId },
            addToItems,
            removeFromItems,
            addToTrip,
          },
        },
      }
    ) => {
      trackEvents(Events.FileAddedAttached);
      if (addToItems?.length > 0) {
        state.fileRelations[tripId] = [
          ...state.fileRelations[tripId],
          ...addToItems.map((item) => ({
            attachedToType: item.type,
            attachedToId: item.id,
            fileId: id,
          })),
        ];
      }
      if (removeFromItems?.length > 0) {
        state.fileRelations[tripId] = state.fileRelations[tripId]?.filter(
          (relation) =>
            !(
              relation.fileId === id &&
              removeFromItems
                ?.map((item) => item.id)
                .includes(relation.attachedToId)
            )
        );
      }
      if (addToTrip) {
        state.fileRelations[tripId] = [
          ...state.fileRelations[tripId],
          {
            attachedToType: 'Trip',
            attachedToId: tripId,
            fileId: id,
          },
        ];
      }
    },
    [createImport.fulfilled]: (state, { payload, meta }) => {
      const fileId = meta.arg.variables?.import?.file;
      const importData = payload?.createImport;
      state.files[fileId] = {
        ...state.files[fileId],
        import: importData,
      };
    },
    [updateImportAttachedTo.fulfilled]: (state, { payload, meta }) => {
      const { file } = payload?.updateImportItemAttachedTo || {};
      const { itemIndex, attachedToItem } = meta.arg.variables;
      if (state.files[file]?.import?.items?.[itemIndex]) {
        state.files[file].import.items[itemIndex] = {
          ...state.files[file].import.items[itemIndex],
          attachedToItem,
        };
      }
    },
    [deleteImportItem.fulfilled]: (state, { payload, meta }) => {
      const { file } = payload?.deleteImportItem || {};
      const { itemIndex } = meta.arg.variables;
      if (state.files[file]?.import?.items?.[itemIndex]) {
        state.files[file].import.items.splice(itemIndex, 1);
        if (state.files[file].import.items.length === 0) {
          state.files[file].import.status = 'itemsDeleted';
        }
      }
    },
  },
});

export const FilesV2Actions = FilesV2Slice.actions;
export const FilesV2Reducer = FilesV2Slice.reducer;
