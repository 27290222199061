import { useEffect, useRef, useState } from 'react';

import {
  IconButton,
  InputBase,
  Tab,
  Tabs,
  Typography,
  Grid,
  InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { SendIcon } from '@iconicicons/react';
import { startOfDay, format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../redux/slices/Auth';
import CHAT from '../../firebase/ChatUtils';
import Message from '../molecules/Message';
import { HeaderWithButton } from '../molecules/Header';
import MessagesTab from '../molecules/MessageTab';
import { FriendsSearchBar } from '../molecules/SearchBar';
import AppLayout from '../templates/AppLayout';
import LoadingLayout from '../templates/LoadingLayout';
import { trackEvents, Events } from '../../intercom';
import { EVENTS, phTrackEvent } from '../../analytics';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    fontStyle: 'normal',
    height: '85%',
  },
  chat: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  chatHeader: {
    padding: '12px 8px 8px 12px',
    borderBottom: '1px solid #DEDDDD',
  },
  chatWindow: {
    flexGrow: 1,
    overflowY: 'scroll',
    padding: '8px 16px 0px 16px',
  },
  friends: {
    borderRight: '1px solid #A7A7A7',
    minWidth: 200,
    overflowY: 'auto',
    scrollbarWidth: 'none',
  },
  tabs: {
    backgroundColor: '#FFFFFF',
    paddingLeft: '2%',
    margin: '0 0',
    display: 'flex',
  },
  tab: {
    textTransform: 'none',
  },
  trips: {
    margin: '1em 0em 1.0em 1.75em',
    color: '#8A8A8A',
    fontSize: '0.9em',
  },
  header: {
    boxShadow: '2px 5px 10px 0px rgba(0,0,0,0.13)',
    position: 'relative',
    zIndex: '1',
  },
  form: {
    backgroundColor: '#FFF',
    alignItems: 'center',
    padding: '8px 16px 0px 16px',
    width: '100%',
    marginBottom: 24,
  },
  searchChat: {
    padding: '0 2em',
  },
  closeButton: {
    float: 'right',
    padding: 4,
    color: '#1D1D1D',
  },
  chatTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineclamp: 1,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  chatMembers: {
    fontSize: 12,
    color: '#8A8A8A',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineclamp: 1,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  chatDate: {
    color: '#8A8A8A',
    fontSize: 12,
    textAlign: 'center',
    marginTop: 12,
    marginBottom: 4,
  },
  chatContainer: {
    maxHeight: '80vh',
    width: '100%',
  },
  inputFocused: {
    border: `1px solid ${theme.palette.primary.main} !important`,
    boxShadow: `0px 0px 0px 2px ${theme.palette.primary.extraLight}`,
  },
  input: {
    padding: '6px 12px',
    border: '1px solid #DEDDDD',
    borderRadius: 8,
    '&:hover': {
      boxShadow: `0px 0px 0px 2px ${theme.palette.primary.extraLight}`,
    },
  },
  sendIcon: {
    transform: 'rotate(90deg)',
    color: '#ED702E',
    height: 16,
    width: 16,
    '&:hover': {
      fill: '#ED702E',
      cursor: 'pointer',
    },
  },
}));

function AlwaysScrollToBottom() {
  const elementRef = useRef();
  useEffect(() =>
    elementRef.current.scrollIntoView({ block: 'nearest', inline: 'start' })
  );
  return <div ref={elementRef} />;
}

export function ChatWindow({ user, chat, handleClose = () => {} }) {
  const [currMessage, setCurrMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [check, setCheck] = useState(false);
  const classes = useStyles();

  const messageConstructor = (message) => {
    return {
      content: message,
      senderId: user.id,
      sentAt: new Date(),
      userName: user.firstName,
    };
  };

  const sendMessage = () => {
    if (currMessage !== '') {
      phTrackEvent({
        event: EVENTS.CHAT.MESSAGE_SEND,
      });
      const newMessage = messageConstructor(currMessage);
      CHAT.ADD_MESSAGE(newMessage, chat.id, chat.isTrip, check, setCheck);
      trackEvents(Events.ChatMessageSent);
      setCurrMessage('');
    }
  };

  const getStartOfDay = (message) =>
    startOfDay(new Date(message.sentAt.toDate()));

  useEffect(() => {
    // getting realtime messages from firebase and grouping them according to date
    CHAT.GET_MESSAGES(chat.id, (chatMessages) => {
      const messagesGroupedByDate = chatMessages.reduce(
        (groupedMessages, message) => {
          // eslint-disable-next-line no-param-reassign
          (groupedMessages[getStartOfDay(message)] =
            groupedMessages[getStartOfDay(message)] || []).push(message);
          return groupedMessages;
        },
        {}
      );
      setMessages(messagesGroupedByDate);
    });
  }, [chat]);
  return (
    <div className={classes.chat}>
      <Grid container className={classes.chatHeader}>
        <Grid
          item
          style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Typography className={classes.chatTitle}>{chat.chatName}</Typography>
          {chat?.isTrip ? (
            <Typography className={classes.chatMembers}>
              {chat?.members?.map((member, idx) => {
                return (
                  (member?.firstName || '') +
                  (idx + 1 === chat?.members?.length ? ' ' : ', ')
                );
              })}
            </Typography>
          ) : null}
        </Grid>
        <Grid item style={{ flex: 0 }}>
          <IconButton
            disableRipple
            className={classes.closeButton}
            onClick={handleClose}>
            <Close fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
      <div className={classes.chatWindow}>
        {!(messages && Object.keys(messages)?.length > 0) ? (
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20%',
            }}>
            <Typography
              style={{
                fontSize: 16,
                color: '#8A8A8A',
                textAlign: 'center',
                width: '100%',
              }}>
              Looks like everyone’s acting shy...
              <br />
              <br />
              Use this chat to discuss all things trip planning!
            </Typography>
          </div>
        ) : (
          Object.keys(messages).map((sentDate, idx) => {
            return (
              <div key={idx}>
                <Typography className={classes.chatDate}>
                  {format(new Date(sentDate), 'EEEE, LLLL d')}
                </Typography>
                {messages[sentDate].map((message) => {
                  const senderIndex = chat?.members?.findIndex((chatUser) => {
                    return (
                      chatUser?.id === message.senderId ||
                      chatUser?.firebaseUid === message.senderId
                    );
                  });
                  const sender =
                    (chat?.members && chat?.members[senderIndex]) || {};
                  return (
                    <Message
                      index={senderIndex}
                      userName={
                        sender?.id === user.id ? 'You' : message.userName
                      }
                      text={message.content}
                      user={sender}
                      sentAt={format(
                        new Date(message.sentAt.toDate()),
                        'h:mm a'
                      )}
                    />
                  );
                })}
              </div>
            );
          })
        )}

        <AlwaysScrollToBottom />
      </div>
      <div className={classes.form}>
        <InputBase
          fullWidth
          multiline
          maxRows={3}
          value={currMessage}
          onChange={(e) => setCurrMessage(e.target.value)}
          className={classes.input}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              sendMessage();
            }
          }}
          placeholder="Say something...."
          inputProps={{
            style: {
              padding: 0,
            },
          }}
          endAdornment={
            <InputAdornment position="end">
              <SendIcon className={classes.sendIcon} onClick={sendMessage} />
            </InputAdornment>
          }
          classes={{
            root: classes.input,
            focused: classes.inputFocused,
          }}
        />
      </div>
    </div>
  );
}

function SearchChat({ setChat, setActiveChat }) {
  const classes = useStyles();

  return (
    <div className={classes.searchChat}>
      <FriendsSearchBar setActiveChat={setActiveChat} setChat={setChat} />
    </div>
  );
}

function Messages() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [chatRoom, setChat] = useState(false);
  const [personalChats, setPersonalChats] = useState([]);
  const [tripChats, setTripChats] = useState([]);
  const [activeChat, setActiveChat] = useState({});

  const userId = useSelector((state) => state.Auth.firebaseUser.uid);
  const userObj = useSelector((state) => state.Auth.userData);

  useEffect(() => {
    if (userObj.status === 'IDLE') {
      dispatch(getUser({ id: userId }));
    }

    CHAT.GET_CHATS(userId, setPersonalChats, setTripChats);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (userObj.status === 'LOADING') {
    return <LoadingLayout />;
  }
  if (userObj.error) {
    return (
      <AppLayout>
        <Typography>{`Error Occured! Contact Admin. ${userObj.errorPayload}`}</Typography>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <div className={classes.header}>
        <HeaderWithButton
          title="Messenger"
          handleClick={() => {
            setChat(false);
          }}
        />
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          indicatorColor="primary">
          <Tab className={classes.tab} label="All" />
          <Tab
            style={{ display: 'none' }}
            className={classes.tab}
            label="Trips"
          />
        </Tabs>
      </div>
      <div className={classes.content}>
        <div className={classes.friends}>
          {personalChats.map((chat) => {
            const index = chat.members.sort().indexOf(userId) === 0 ? 1 : 0;
            const username = chat.chatName.split('_')[index];
            return (
              <MessagesTab
                userName={username}
                chatId={chat.id}
                isTrip={false}
                setActiveChat={setActiveChat}
                setChat={setChat}
              />
            );
          })}
          <div className={classes.trips}>Trip Chats</div>
          {tripChats.map((trip) => (
            <MessagesTab
              userName={trip.chatName}
              chatId={trip.id}
              isTrip
              setActiveChat={setActiveChat}
              setChat={setChat}
              members={trip.members}
            />
          ))}
        </div>
        <div className={classes.chatContainer}>
          {chatRoom ? (
            <ChatWindow
              user={userObj.user}
              chat={activeChat}
              handleClose={() => {
                setChat(false);
              }}
            />
          ) : (
            <SearchChat setActiveChat={setActiveChat} setChat={setChat} />
          )}
        </div>
      </div>
    </AppLayout>
  );
}

export default Messages;
