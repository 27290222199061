import { Typography, Stack, ButtonBase } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { phTrackEvent, EVENTS } from '../../../../analytics';
import { importModelViews, ViewActions } from '../../../../redux/slices/View';
import { handleFileDelete, isTripV2 } from '../../../../utils';
import DeleteModal from '../DeleteModal';

export default function ReviewStepFooter({ onDone }) {
  const tripId = useParams().slug;
  const { forwardedFiles } = useSelector((state) => state.Auth.userData.user);
  const { importIdToReview } = useSelector((state) => state.View);
  const { files: filesV1 } = useSelector((state) => state.Files);
  const { files: filesV2 } = useSelector((state) => state.FilesV2);
  const { Transportation, Files } = useSelector((state) => state);
  const [file, setFile] = useState(null);
  const [showDone, setShowDone] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const isV2 = isTripV2(tripId);

  const files = isV2 ? filesV2 : filesV1;
  const openDeleteModal = () => {
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.CANCEL_IMPORT_CLICK,
    });
    setDeleteModalOpen(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const fileToReview =
      files[
        Object.keys(files).find(
          (fileId) => files[fileId]?.import?.id === importIdToReview
        )
      ] ||
      forwardedFiles.find((_file) => _file?.import?.id === importIdToReview);
    const attachedItems =
      fileToReview?.import?.items?.length > 0
        ? fileToReview?.import?.items
            ?.map((it) => it.attachedToItem)
            .filter(Boolean)
        : [];
    setFile(fileToReview);
    setShowDone(attachedItems.length > 0);
  }, [importIdToReview, files, forwardedFiles]);

  const handleTriggerDeleteFile = () => {
    handleFileDelete(file, tripId, dispatch, Transportation, Files);
    dispatch(ViewActions.removeImportIdToReview());
    dispatch(ViewActions.setImportsModelView(importModelViews.import));
    setDeleteModalOpen(false);
    onDone();
  };
  return (
    <Stack
      flexDirection="column"
      justifyContent="center"
      px="24px"
      height="71px"
      sx={{
        borderTop: '1px solid #D9D9D9',
        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
      }}
      mt={2}>
      <Stack alignItems="center" />
      <Stack justifyContent="flex-end" alignItems="flex-end" width="100%">
        <Stack direction="row" alignItems="center" gap={2}>
          {showDone && (
            <ButtonBase
              onClick={openDeleteModal}
              sx={{
                borderRadius: '4px',
                padding: '8px',
                width: '60px',
                height: '32px',
                borderStyle: 'none',
              }}>
              <Typography>Cancel</Typography>
            </ButtonBase>
          )}
          <ButtonBase
            onClick={() => (showDone ? onDone() : openDeleteModal())}
            sx={{
              borderRadius: '4px',
              padding: '8px',
              width: '72px',
              height: '32px',
              borderColor: '#D9D9D9',
              borderWidth: '1px',
              borderStyle: 'solid',
            }}>
            <Typography>{showDone ? 'Done' : 'Cancel'}</Typography>
          </ButtonBase>
        </Stack>
      </Stack>
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        executeFunc={handleTriggerDeleteFile}
        alertText="Are you sure?"
        descriptionText="This action will detach your file from all itinerary items and remove your file from Pilot permanently."
        confirmText="Delete"
        cancelText="Cancel"
        emoji="⚠️"
      />
    </Stack>
  );
}
