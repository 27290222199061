import { useRef, useEffect, useState } from 'react';

import {
  Avatar,
  Badge,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { AlternateEmail, Close, PhotoCamera } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Alert } from '@mui/lab';
import { useMutation } from '@apollo/client';
import QUERY from '../../graphql/queries';
import { getUserHandle } from '../../graphql/api';
import {
  getUser,
  getUserV2,
  updateUser,
  updateUserV2,
  updateUserHandle as updateUserHandleV2,
} from '../../redux/slices/Auth';
import {
  ButtonDefault,
  LightGreyButton,
  ClearButton,
} from '../atoms/Button/index';
import { PlacesSearchBar } from '../molecules/SearchBar';
import { handleProfileImageUpload, handleOldPhotoDelete } from '../../utils';
import classList from '../classList';
import graphqlClient from '../../graphql';

const useStyles = makeStyles((theme) => ({
  avatarEditModal: {
    width: '80px',
    height: '80px',
    backgroundColor: theme.palette.primary.main,
  },
  input: {
    display: 'none',
  },
  iconButton: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #FFF',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  modalLabelText: {
    ...theme.typography.h5,
    fontWeight: 600,
    marginTop: 8,
  },
  aboutMeContainer: {
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

function EditModal() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const userId = useSelector((state) => state.Auth.firebaseUser.uid);
  const mongoUserId = useSelector((state) => state.Auth.userId);
  const userObj = useSelector((state) => state.Auth.userData);
  const { user } = userObj;

  const [errorState, setErrorState] = useState(0);
  const [url, setURL] = useState(null);
  const [open, setOpen] = useState(false);
  const [isChanged, setChanged] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState(0);

  const [createUserHandle] = useMutation(QUERY.CREATE_USER_HANDLE, {
    client: graphqlClient,
  });
  const [updateUserHandle] = useMutation(QUERY.UPDATE_USER_HANDLE, {
    client: graphqlClient,
  });

  useEffect(() => {
    if (userObj.status === 'IDLE') {
      dispatch(getUser({ id: userId }));
      dispatch(getUserV2({ id: userId }));
    }
  }, []);

  // Adding form data as a state
  const [userFields, setUserFields] = useState({
    firstName: '',
    lastName: '',
    aboutMe: '',
    location: '',
    handle: '',
  });

  useEffect(() => {
    setURL(user?.profilePicture);
    if (user)
      setUserFields({
        ...userFields,
        ...user,
      });
  }, [user]);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setChanged(false);
  };

  // Function to set the save button state.
  function checkIfChanged(newUserFields) {
    if (
      newUserFields?.firstName === (user?.firstName || '') &&
      newUserFields?.lastName === (user?.lastName || '') &&
      newUserFields?.handle === (user?.handle || '') &&
      newUserFields?.location === user?.location &&
      newUserFields?.aboutMe === user?.aboutMe
    )
      setChanged(false);
  }

  // Function to handle form field changes
  async function handleOnChange(e) {
    const { id, value } = e.target;
    const newUserFields = {
      ...userFields,
      [id]: value,
    };
    setUserFields(newUserFields);
    setChanged(true);
    checkIfChanged(newUserFields);
  }

  const checkLastHandleChangeDate = () => {
    const lastChangeDate = new Date(userFields.handleChangeDate);
    const today = new Date();
    const dayDiff = Math.round(
      Math.abs((today - lastChangeDate) / (1000 * 3600 * 24))
    );
    const daysleft = 30 - dayDiff;
    // check if its been 30 days
    if (daysleft <= 0) {
      return true;
    }
    setDaysRemaining(daysleft);
    setErrorState(2);
    return false;
  };

  function handleOnChangeLocation(val) {
    const newUserFields = {
      ...userFields,
      location: val,
    };
    setUserFields(newUserFields);
    setChanged(true);
    checkIfChanged(newUserFields);
  }

  // Function to reset the modal on cancel button click
  function handleCancelChanges() {
    setUserFields(user);
    setErrorState(0);
    setOpen(false);
    setChanged(false);
  }

  /* Function to handle userHandleUpdation based on the following parameters and returns the errorstate status
    - Last handle change is done before 30 days (errorstate 2)
    - User handle not available (errorstate 1)
  */
  async function handleUpdateUserHandle() {
    const oldHandle = user?.handle ? user.handle : null;
    const newHandle = userFields.handle;
    let status = 0; // default no error
    if (newHandle) {
      // Once a user handle is assigned, we will not save a blank
      if (oldHandle !== newHandle) {
        try {
          // If oldHandle and newHandle are different then we save it.
          const userHandleData = await getUserHandle(newHandle);
          const userHandleExists = userHandleData?.data?.getUserHandle?.userId;

          if (userHandleExists) {
            // The user handle already exists. Ask the user to enter a new handle.
            setErrorState(1);
            status = 1;
          } else {
            // if user handle does not already exist, it can be assigned to this user.
            if (!oldHandle && newHandle) {
              // The handle in the database is empty;
              // the user is setting the handle for the first time
              setErrorState(0);
              await createUserHandle({
                variables: {
                  handleName: newHandle,
                  userId,
                },
              });
              return 0;
            }
            if (oldHandle && newHandle) {
              // The user has already set a handle in the database and is updating
              // it again now
              if (checkLastHandleChangeDate()) {
                setErrorState(0);
                await updateUserHandle({
                  variables: {
                    oldHandleName: oldHandle,
                    newHandleName: newHandle,
                  },
                });
              } else {
                status = 1;
              }
            }
          }
        } catch (err) {
          console.error(err);
        }
        try {
          const result = (
            await dispatch(
              updateUserHandleV2({
                variables: {
                  newHandleName: newHandle,
                },
              })
            )
          )?.payload?.updateUserHandle;
          if (result) {
            status = 0;
          } else {
            status = 1;
          }
        } catch (err) {
          console.error(err);
        }
      }
    }
    return status;
  }

  async function handleSubmitUser(e) {
    e.preventDefault();
    const status = await handleUpdateUserHandle();
    if (status === 0) {
      dispatch(
        updateUser({
          variables: {
            id: userFields.id,
            firstName: userFields.firstName,
            lastName: userFields.lastName,
            handle: userFields.handle,
            handleChangeDate: new Date().toString(),
            location: userFields.location,
            aboutMe: userFields.aboutMe,
          },
        })
      );
      dispatch(
        updateUserV2({
          variables: {
            id: mongoUserId,
            firstName: userFields.firstName,
            lastName: userFields.lastName,
            location: userFields.location,
            aboutMe: userFields.aboutMe,
          },
        })
      );
      window.history.replaceState(null, ``, `/profile/${userFields.handle}`);
      setOpen(false);
      setChanged(false);
    }
  }

  const handleProfilePicUpload = async (e) => {
    await handleProfileImageUpload(e, userId).then((result) => {
      const originalUrl = url;
      dispatch(
        updateUser({
          variables: {
            id: userId,
            welcomeScreen: userFields.welcomeScreen,
            profilePicture: result.url,
          },
        })
      );
      dispatch(
        updateUserV2({
          variables: {
            id: mongoUserId,
            welcomeScreen: userFields.welcomeScreen,
            profilePicture: result.url,
          },
        })
      );
      setURL(result.url);
      // Delete old picture unless it's being replaced by the current img
      if (originalUrl?.split('?')[0] !== result?.url?.split('?')[0])
        handleOldPhotoDelete(originalUrl);

      setChanged(true);
      return true;
    });
  };

  function AddPhotoIcon() {
    const pictureInputRef = useRef();
    return (
      <>
        <IconButton
          aria-label="upload picture"
          component="span"
          className={classes.iconButton}
          size="small"
          onClick={() => pictureInputRef.current.click()}>
          <PhotoCamera fontSize="small" style={{ color: 'white' }} />
        </IconButton>
        <input
          accept="image/*"
          className={classes.input}
          id="profile-pic-icon"
          type="file"
          ref={pictureInputRef}
          onChange={handleProfilePicUpload}
        />
      </>
    );
  }

  function ErrorMessage() {
    setChanged(false);
    return (
      <Alert severity="error">
        You must wait {daysRemaining} days before you can change your handle
      </Alert>
    );
  }

  const locationRef = useRef();

  return (
    <>
      <LightGreyButton sx={{ py: '4px' }} onClick={handleClickOpen}>
        Edit Profile
      </LightGreyButton>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <DialogTitle id="edit-modal-title">
          <Typography variant="h3Sub">Edit Profile</Typography>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="large">
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start">
            <Grid item>
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={<AddPhotoIcon />}>
                <Avatar className={classes.avatarEditModal} src={url} />
              </Badge>
            </Grid>
            <Grid item>
              <form style={{ paddingLeft: '1em' }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <DialogContentText className={classes.modalLabelText}>
                      First Name
                    </DialogContentText>
                    <TextField
                      required
                      size="small"
                      id="firstName"
                      fullWidth
                      defaultValue={userFields.firstName}
                      onChange={handleOnChange}
                    />
                  </Grid>
                  <Grid item>
                    <DialogContentText className={classes.modalLabelText}>
                      Last Name
                    </DialogContentText>
                    <TextField
                      required
                      size="small"
                      id="lastName"
                      fullWidth
                      defaultValue={userFields.lastName}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DialogContentText className={classes.modalLabelText}>
                      Handle
                    </DialogContentText>
                    <TextField
                      required
                      variant="outlined"
                      size="small"
                      id="handle"
                      error={errorState === 1}
                      helperText={
                        errorState === 1 ? 'Handle not available' : null
                      }
                      fullWidth
                      defaultValue={userFields.handle}
                      onChange={handleOnChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AlternateEmail style={{ height: 15, width: 15 }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DialogContentText className={classes.modalLabelText}>
                      Location
                    </DialogContentText>
                    <PlacesSearchBar
                      fullWidth
                      id="location"
                      size="small"
                      value={userFields.location}
                      onChange={handleOnChangeLocation}
                      disableLocationBias
                      handleSelect={(option) => {
                        handleOnChangeLocation(
                          option?.structured_formatting?.main_text
                        );
                        locationRef?.current?.blur();
                      }}
                      inputRef={locationRef}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DialogContentText className={classes.modalLabelText}>
                      About Me
                    </DialogContentText>
                    <TextField
                      style={{ color: 'black' }}
                      id="aboutMe"
                      fullWidth
                      defaultValue={userFields.aboutMe}
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 160 }}
                      minRows={5}
                      onChange={handleOnChange}
                      className={classes.aboutMeContainer}
                      helperText={`${userFields?.aboutMe?.length || 0}/160`}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogContent style={{ padding: '1em 24px' }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Grid item xs={12} md={3} lg={3}>
              <ClearButton
                style={{ height: '40px' }}
                onClick={() => handleCancelChanges()}>
                Cancel
              </ClearButton>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <ButtonDefault
                style={{ height: '40px', width: '100%' }}
                type="submit"
                disabled={!isChanged}
                className={classList.profileSaveButton}
                onClick={(e) => handleSubmitUser(e)}>
                Save
              </ButtonDefault>
            </Grid>
            {errorState === 2 ? <ErrorMessage /> : null}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default EditModal;
