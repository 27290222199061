import React, { useState } from 'react';

import {
  Popper,
  List,
  ListItem,
  ClickAwayListener,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ChevronRightRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ITEM_TYPES from '../../../../const';
import useTour from '../../../molecules/Tour/useTour';
import actions from '../../../../redux/actions';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 9121,
    width: 'inherit',
  },
  list: {
    maxHeight: 160,
    overflowY: 'auto',
    backgroundColor: '#FFF',
    border: '2px solid #DEDDDD',
    borderRadius: 4,
    padding: '4px 0px',
    marginTop: 8,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: 12,
    },
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
  },
  listItem: {
    minWidth: 120,
    padding: '4px 12px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      '& .MuiListItemIcon-root': {
        color: '#ED702E !important',
      },
    },
  },
  addedButton: {
    backgroundColor: '#43A047',
    color: '#FFF',
    pointerEvents: 'none',
  },
  listItemText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}));

function ItemDropDown({
  handleClickAway = () => {},
  handleAddItem = () => {},
  destinationIdSelector = null,
  destinationClassSelector = null,
  enableScroll = false,
}) {
  const { slug: tripId } = useParams();
  const classes = useStyles();
  const { getTour } = useTour();
  const dispatch = useDispatch();
  const [destinationAnchor, setDestinationAnchor] = useState(null);
  const [destinationPopperOpen, setDestinationPopperOpen] = useState(null);
  const items = useSelector((state) => state.Item.items);
  const firstLevelDestinations = useSelector((state) =>
    state.tripsAtc.tripsAtc[tripId]?.children?.filter(
      (itemId) => items[itemId]?.type === ITEM_TYPES.DESTINATION
    )
  );

  const handleItemScroll = (e, itemId, type, parentId = null) => {
    if (!enableScroll) return;
    e?.stopPropagation();
    const elementId = `${
      type === ITEM_TYPES.DESTINATION ? 'scrollable-container' : 'todo-header'
    }-${itemId}`;
    const element = document.getElementById(elementId);
    if (element && !getTour().tourInProgress) {
      if (type === ITEM_TYPES.DESTINATION) {
        dispatch(
          actions.View.setHighlightedSection({
            section: itemId,
          })
        );
      } else if (type === ITEM_TYPES.HEADING) {
        dispatch(
          actions.View.setHighlightedHeading({
            section: itemId,
          })
        );
        if (parentId) {
          dispatch(
            actions.View.setHighlightedSection({
              section: parentId,
            })
          );
        }
      }
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const resetHighlightScroll = () => {
    if (!enableScroll) return;
    dispatch(
      actions.View.setHighlightedHeading({
        section: null,
      })
    );
    dispatch(
      actions.View.setHighlightedSection({
        section: null,
      })
    );
  };

  const handleLocationHover = (e, id) => {
    setDestinationAnchor(e.currentTarget);
    handleItemScroll(e, id, ITEM_TYPES.DESTINATION);
    if (destinationPopperOpen === id) return;
    setDestinationPopperOpen(id);
  };

  const handleItemClick = (parentId, type, destinationId = null) => {
    handleAddItem(parentId, type, destinationId);
    resetHighlightScroll();
    handleClickAway();
  };

  return (
    firstLevelDestinations &&
    firstLevelDestinations.map((destinationId) => {
      const destination = items[destinationId];
      if (!destination) return null;
      const { id, title } = destination;

      const secondLevelHeadings = destination?.children?.filter(
        (itemId) => items[itemId]?.type === ITEM_TYPES.HEADING
      );

      return (
        <ListItem
          key={id}
          onClick={(e) => {
            e.stopPropagation();
            handleItemClick(id, ITEM_TYPES.DESTINATION, id);
          }}
          id={destinationIdSelector || 'add-to-trip-button'}
          onMouseOver={(e) => handleLocationHover(e, id)}
          onMouseLeave={() => {
            if (secondLevelHeadings?.length === 0) {
              resetHighlightScroll();
              setDestinationPopperOpen(null);
            }
          }}
          className={`${classes.listItem} ${destinationClassSelector || ''}`}
          style={{
            // to align the text, 12px(specified) + 2px(alignment spacing) with the 'new location' icon according to the designs
            paddingLeft: 14,
          }}
          alignItems="center">
          <ListItemText
            primary={title}
            primaryTypographyProps={{ fontSize: '14px' }}
            className={classes.listItemText}
          />
          {secondLevelHeadings?.length > 0 && (
            <>
              <ListItemIcon
                style={{
                  minWidth: 0,
                }}>
                <ChevronRightRounded
                  style={{
                    height: 14,
                    width: 14,
                    ...(destinationPopperOpen === id
                      ? {}
                      : { color: 'transparent' }),
                  }}
                />
              </ListItemIcon>
              <Popper
                id={`location-${id}`}
                open={destinationPopperOpen === id}
                anchorEl={destinationAnchor}
                placement="right"
                style={{
                  width: 'inherit',
                  zIndex: 9999,
                  visibility: !destinationAnchor ? 'hidden' : 'visible',
                }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [-4, 8],
                    },
                  },
                ]}>
                <ClickAwayListener onClickAway={() => {}}>
                  <List
                    className={classes.list}
                    style={{
                      maxHeight: 100,
                      maxWidth: 120,
                    }}
                    onMouseLeave={() => {
                      resetHighlightScroll();
                      setDestinationPopperOpen(null);
                    }}>
                    {secondLevelHeadings?.map((headingId) => {
                      const heading = items[headingId];
                      return (
                        <ListItem
                          key={headingId}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleItemClick(headingId, ITEM_TYPES.HEADING, id);
                          }}
                          onMouseOver={(e) => {
                            handleItemScroll(
                              e,
                              headingId,
                              ITEM_TYPES.HEADING,
                              id
                            );
                          }}
                          onMouseLeave={() => {
                            resetHighlightScroll();
                          }}
                          className={classes.listItem}
                          justify="center"
                          style={{ padding: '2px 12px' }}>
                          <ListItemText
                            primary={heading?.title || 'Unnamed Heading'}
                            primaryTypographyProps={{ fontSize: '14px' }}
                            className={classes.listItemText}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </ClickAwayListener>
              </Popper>
            </>
          )}
        </ListItem>
      );
    })
  );
}

export default ItemDropDown;
