import { createSlice } from '@reduxjs/toolkit';
import getAsyncThunk, { getAsyncThunkV2 } from '../helpers';
import QUERY from '../../graphql/queries';
import QUERY_V2 from '../../V3/graphql/queries';
import {
  ONE_DAY_MS,
  getNumberOfNights,
  parseISODate,
} from '../../components/organisms/Stays/bookingsUtils';

const initialSearchDataState = (startDate, endDate) => {
  let arrivalDate = startDate && parseISODate(startDate);
  let departureDate = endDate && parseISODate(endDate);

  if (!arrivalDate || arrivalDate < new Date(Date.now())) {
    arrivalDate = new Date(Date.now() + 2 * ONE_DAY_MS);
    departureDate = new Date(Date.now() + 4 * ONE_DAY_MS);
  }
  if (
    (arrivalDate && !departureDate) ||
    arrivalDate?.getTime() === departureDate?.getTime()
  ) {
    departureDate = new Date(new Date(arrivalDate).getTime() + 2 * ONE_DAY_MS);
  }
  return {
    date: {
      from: arrivalDate,
      to: departureDate,
    },
    guestCount: {
      adults: 2,
      children: 0,
      rooms: 1,
      total: 2,
    },
  };
};

export const createCheckoutSession = getAsyncThunk(
  'Bookings/createCheckoutSession',
  QUERY.CREATE_CHECKOUT_SESSION
);

export const createBooking = getAsyncThunk(
  'Bookings/createBooking',
  QUERY.CREATE_BOOKING
);

export const getCheckoutSession = getAsyncThunk(
  'Bookings/getCheckoutSession',
  QUERY.GET_CHECKOUT_SESSION
);

export const getBookingFromId = getAsyncThunk(
  'Bookings/getBookingFromId',
  QUERY.GET_BOOKING_FROM_ID
);

const initialTripState = (startDate, endDate) => ({
  recommendations: {},
  filterLabels: [],
  cityLocationDetails: null,
  search: initialSearchDataState(startDate, endDate),
  hotelList: [],
  hoveredCardIndex: null,
  clickedCardIndex: null,
  activeHotelMarker: null,
  hoveredHotelMarker: null,
  numberOfNights:
    getNumberOfNights(initialSearchDataState(startDate, endDate)?.date) || 1,
  isSaveListVisible: false,
  otherDetails: {
    currencyCode: 'USD',
    lowestTotalRate: 0,
    highestTotalRate: 100,
    totalResults: 0,
    totalAvailableResults: 0,
    totalFilteredResults: 0,
  },
  searchTrigger: false,
});

const initialState = {
  hotelDetailsPage: {
    data: {},
    searchProps: {
      checkInDate: null,
      checkOutDate: null,
      rooms: null,
    },
  },
  cart: {
    rooms: [],
    guests: {},
    packages: [],
    cxlPolicy: {},
    guestRequest: '',
  },
  saved: {},
  searchLocale: {
    currencyCode: 'USD',
    countryCode: 'US',
  },
};

export const addSavedItem = getAsyncThunk(
  'Bookings/addSavedItem',
  QUERY.ADD_SAVED_ITEM
);

export const addSavedItemV2 = getAsyncThunkV2(
  'Bookings/addSavedItemV2',
  QUERY_V2.CREATE_SAVED_ITEM
);

export const deleteSavedItemV2 = getAsyncThunkV2(
  'Bookings/deleteSavedItemV2',
  QUERY_V2.DELETE_SAVED_ITEM
);

export const deleteSavedItem = getAsyncThunk(
  'Bookings/deleteSavedItem',
  QUERY.DELETE_SAVED_ITEM
);

const BookingsSlice = createSlice({
  name: 'Bookings',
  initialState,
  reducers: {
    initializeTripState: (state, action) => {
      const { tripId, startDate = null, endDate = null } = action.payload;
      if (!state[tripId]) {
        state[tripId] = initialTripState(startDate, endDate);
      }
    },
    setSearchLocale: (state, action) => {
      const { locale } = action.payload;
      state.searchLocale = locale;
    },
    setTotalPages: (state, action) => {
      const { totalPages } = action.payload;
      state.hotelDetailsPage.totalPages = totalPages;
    },
    setCxlPolicy: (state, action) => {
      const { packageId, cxlPolicy } = action.payload;
      if (!packageId || !cxlPolicy) return;
      state.cart.cxlPolicy[packageId] = cxlPolicy;
    },
    setGuestRequest: (state, action) => {
      const { guestRequest } = action.payload;
      state.cart.guestRequest = guestRequest;
    },
    deleteTrip: (state, action) => {
      const { tripId } = action.payload;
      if (state[tripId]) {
        delete state[tripId];
      }
    },
    initializeSaves: (state, action) => {
      state.saved[action.payload.tripId] = {
        ...state.saved[action.payload.tripId],
        ...Object.fromEntries(
          action.payload.saved
            ?.filter((savedItem) => savedItem?.type === 'HOTEL')
            ?.map((savedItem) => [savedItem.id, savedItem]) || []
        ),
      };
    },
    setHotelDetails: (state, { payload }) => {
      state.hotelDetailsPage.data[payload.key] = {
        ...(state.hotelDetailsPage.data[payload.key] || {}),
        ...payload,
      };
    },
    setHotelDetailsSearchProps: (state, { payload }) => {
      const updatedSearchProps = {
        ...state.hotelDetailsPage.searchProps,
        ...payload,
      };
      if (payload.checkInDate && payload.checkOutDate) {
        updatedSearchProps.numberOfNights = getNumberOfNights({
          from: new Date(payload.checkInDate),
          to: new Date(payload.checkOutDate),
        });
      }
      state.hotelDetailsPage.searchProps = updatedSearchProps;
    },
    setCity: (state, action) => {
      const { tripId, cityName } = action.payload;
      state[tripId].recommendations.city = cityName;
    },
    setPois: (state, action) => {
      const { tripId, pois } = action.payload;
      state[tripId].recommendations.pois = pois;
    },
    resetCity: (state, action) => {
      const { tripId } = action.payload;
      state[tripId].recommendations.pois = null;
      state[tripId].recommendations.city = null;
    },
    setFilter: (state, action) => {
      const { tripId, filterItems } = action.payload;
      state[tripId].filterLabels = filterItems;
    },
    setHotelList: (state, action) => {
      const { tripId, hotelList } = action.payload;
      state[tripId].hotelList = hotelList;
    },
    addHotelList: (state, action) => {
      const { tripId, hotelList } = action.payload;
      state[tripId].hotelList = [...hotelList];
    },
    setCityLocationDetails: (state, action) => {
      const { tripId, cityLocationDetails } = action.payload;
      state[tripId].cityLocationDetails = cityLocationDetails;
    },
    setOtherDetails: (state, action) => {
      const { tripId, parsedRegionData } = action.payload;
      state[tripId].otherDetails = parsedRegionData;
    },
    setHoveredCardIndex: (state, action) => {
      const { tripId, cardIndex } = action.payload;
      state[tripId].hoveredCardIndex = cardIndex;
    },
    setClickedCardIndex: (state, action) => {
      const { tripId, cardIndex } = action.payload;
      state[tripId].clickedCardIndex = cardIndex;
    },
    setActiveHotelMarker: (state, action) => {
      const { tripId, activeHotelMarker = null } = action.payload;
      state[tripId].activeHotelMarker = activeHotelMarker;
    },
    setHoveredHotelMarker: (state, action) => {
      const { tripId, hoveredHotelMarker = null } = action.payload;
      state[tripId].hoveredHotelMarker = hoveredHotelMarker;
    },
    setNumberOfNights: (state, action) => {
      const { tripId, numberOfNights } = action.payload;
      state[tripId].numberOfNights = numberOfNights;
    },
    setSearchDate: (state, action) => {
      const { tripId, date } = action.payload;
      state[tripId].search = { ...(state[tripId].search || {}), date };
      state[tripId].numberOfNights = getNumberOfNights(date);
    },
    setGuestCount: (state, action) => {
      const { tripId, guestCount } = action.payload;
      state[tripId].search = { ...(state[tripId].search || {}), guestCount };
    },
    triggerSearch: (state, action) => {
      const {
        tripId,
        cityLocationDetails = null,
        date = null,
      } = action.payload;
      if (cityLocationDetails && date) {
        state[tripId].cityLocationDetails = cityLocationDetails;
        state[tripId].search.date = date;
        state[tripId].numberOfNights = getNumberOfNights(date);
        state[tripId].searchTrigger = true;
      } else {
        state[tripId].searchTrigger = false;
      }
    },
    setIsSaveListVisible: (state, action) => {
      const { tripId, isSaveListVisible } = action.payload;
      state[tripId].isSaveListVisible = isSaveListVisible;
    },

    // Room cart logic
    updateRoom: (state, { payload }) => {
      const { room, qty } = payload;
      state.cart.rooms = [
        ...(state.cart.rooms?.filter((r) => r.id !== room.id) || []),
        ...Array.from({ length: qty }, () => room),
      ].flat();
    },

    addPackage: (state, { payload }) => {
      const packageId = payload;
      // TODO: Figure out multi package booking
      state.cart.packages = [packageId];
    },

    removePackage: (state, { payload }) => {
      const { packageId } = payload;
      state.cart.packages = [...(state.cart.packages || [])].filter(
        (p) => p !== packageId
      );
    },
    updateCartGuest: (state, { payload: guest }) => {
      state.cart.guests = {
        ...state.cart.guests,
        [guest.id]: guest,
      };
    },
    setCartGuests: (state, { payload }) => {
      state.cart.guests = { ...payload };
    },
    setDetailToAllGuests: (state, { payload }) => {
      state.cart.guests = Object.keys(state.cart.guests).reduce((acc, curr) => {
        acc[curr] = {
          ...state.cart.guests[curr],
          ...payload,
        };
        return acc;
      }, {});
    },
  },
  extraReducers: {
    [addSavedItem.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: { variables },
        },
      }
    ) => {
      state.saved[variables.tripId] = {
        ...state.saved[variables.tripId],
        [payload.addSavedItem.id]: {
          ...variables,
          ...(payload.addSavedItem || {}),
        },
      };
    },
    [addSavedItemV2.fulfilled]: (
      state,
      {
        payload: { createSavedItem },
        meta: {
          arg: {
            variables: { tripId, itemToSave },
          },
        },
      }
    ) => {
      state.saved[tripId] = {
        ...state.saved[tripId],
        [createSavedItem.id]: {
          ...itemToSave,
          ...(createSavedItem || {}),
        },
      };
    },
    [deleteSavedItem.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: { tripId },
        },
      }
    ) => {
      if (payload.deleteSavedItem) {
        delete state.saved[tripId][payload.deleteSavedItem];
      }
    },
    [deleteSavedItemV2.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: { tripId },
        },
      }
    ) => {
      if (payload.deleteSavedItem) {
        delete state.saved[tripId][payload.deleteSavedItem];
      }
    },
  },
});

export const BookingsActions = BookingsSlice.actions;
export const BookingsReducer = BookingsSlice.reducer;
