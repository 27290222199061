import Accommodation from '../Accommodation';

function AccommodationItem({ itemId, parentId, tripId, index, ...props }) {
  return (
    <Accommodation
      tripId={tripId}
      hotelId={itemId}
      parentId={parentId}
      index={index}
      {...props}
    />
  );
}

export default AccommodationItem;
