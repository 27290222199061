import { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useParams } from 'react-router-dom';
import CheckoutNavbar from '../organisms/Checkout/CheckoutNavbar';
import CheckoutProgress from '../organisms/Checkout/CheckoutProgress';
import CHECKOUT_STEPS from '../organisms/Checkout/constants';
import PrimaryGuestInfo from '../organisms/Checkout/PrimaryGuestInfo';
import GuestRequests from '../organisms/Checkout/GuestRequests';
import HouseRules from '../organisms/Checkout/HouseRules';
import PaymentForm from '../organisms/Checkout/PaymentForm';
import CartSummary from '../organisms/Checkout/CartSummary';
import CheckoutRoomSummary from '../organisms/Checkout/CheckoutRoomSummary';
import { getCxlPolicy } from '../organisms/Stays/HotelDetails/api';
import actions from '../../../redux/actions';
import { EVENTS, phTrackEvent } from '../../../analytics';
import CheckoutBottomPriceBar from '../organisms/Checkout/CheckoutBottomPriceBar';

// import CheckoutSuccessModal from '../organisms/Checkout/CheckoutSuccessModal';

const useStyles = makeStyles(({ breakpoints }) => ({
  appbar: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid #DEDDDD',
    padding: '24px 60px',
    [breakpoints.down('lg')]: {
      padding: '18px 8px',
    },
    [breakpoints.down('sm')]: {
      padding: '6px 8px',
    },
    zIndex: 1098,
  },
  toolbar: {
    minHeight: '40px',
    [breakpoints.down('lg')]: {
      minHeight: '65px',
    },
    [breakpoints.down('sm')]: {
      minHeight: '80px',
    },
  },
  startContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  endContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  roomImage: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    background: 'linear-gradient(180deg, #000000 0%, #000000 20%)',
  },
  box: {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    overflow: 'hidden',
  },
  spacing: {
    padding: '24px 140px',
    [breakpoints.down('lg')]: {
      padding: '18px 8px',
    },
    [breakpoints.down('sm')]: {
      padding: '0px',
      flexDirection: 'column',
    },
  },
}));

function GuestInfo({ setStep }) {
  const { slug: hotelId } = useParams();
  const dispatch = useDispatch();
  const guests = useSelector((state) => state.Bookings.cart.guests);
  const guestRequest = useSelector((state) => state.Bookings.cart.guestRequest);
  const user = useSelector((state) => state.Auth.userData?.user);
  const packageId = useSelector((state) => state.Bookings.cart.packages[0]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeGuestRequest = (e) => {
    phTrackEvent({ event: EVENTS.CHECKOUT_GUEST_INFO.ADDITIONAL_INFO_UPDATE });
    dispatch(
      actions.Bookings.setGuestRequest({ guestRequest: e.target.value })
    );
  };

  const rooms = useSelector(
    (state) =>
      state.Bookings.hotelDetailsPage?.data[hotelId]?.packages?.find(
        (p) => p?.packageId === packageId
      )?.rooms
  );

  const nextStepAvailable =
    Object.values(guests || {})
      .map((g) =>
        Object.values(g)?.every(
          (v) => v !== '' && v !== null && v !== undefined
        )
      )
      .filter(Boolean)?.length === Object.values(guests || {})?.length;

  useEffect(() => {
    dispatch(
      actions.Bookings.setCartGuests(
        new Array(rooms?.length)
          .fill(0)
          ?.map((_, index) => ({
            id: index === 0 ? user?.id : `${user?.id}-${index}`,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            phoneNumber: user?.phoneNumber,
          }))
          ?.reduce((acc, curr) => {
            acc[curr?.id] = curr;
            return acc;
          }, {})
      )
    );
  }, []);
  return (
    <>
      <Grid container flexDirection={isMobile ? 'column' : 'row'}>
        <Grid
          item
          xs={12}
          sm={8.2}
          container
          sx={isMobile ? { px: 2.5 } : { pl: 6, pr: 2.5 }}>
          <div>
            <CheckoutRoomSummary />
            <div style={{ marginTop: 24 }} />
            {rooms?.map((r, index) => (
              <div key={`room-${index}`} style={{ marginBottom: 24 }}>
                <PrimaryGuestInfo
                  title={`Room ${index + 1} information`}
                  captureAllInfo={index === 0}
                  index={index}
                />
              </div>
            ))}
            <GuestRequests
              value={guestRequest}
              handleChange={handleChangeGuestRequest}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3.8}
          sx={
            isMobile
              ? { display: 'none' }
              : { position: 'sticky', top: 120, height: 'max-content' }
          }>
          <div style={{ width: '100%' }}>
            <CartSummary
              disabledBtn={
                !nextStepAvailable || Object.values(guests || {})?.length === 0
              }
              onNextClick={() => {
                phTrackEvent({
                  event: EVENTS.CHECKOUT_GUEST_INFO.NEXT_SUBMIT,
                });
                setStep(CHECKOUT_STEPS.PAYMENT_DETAILS);
              }}
            />
          </div>
        </Grid>
      </Grid>
      {isMobile && (
        <CheckoutBottomPriceBar
          onNextClick={() => setStep(CHECKOUT_STEPS.PAYMENT_DETAILS)}
          isDisabledBtn={
            !nextStepAvailable || Object.values(guests || {})?.length === 0
          }
        />
      )}
    </>
  );
}

function PaymentInfo() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

  const { slug: hotelId } = useParams();
  const packageId = useSelector((state) => state.Bookings.cart.packages[0]);

  const [paymentDisabled, setPaymentDisabled] = useState(true);

  const totalPrice = useSelector(
    (state) =>
      state.Bookings.hotelDetailsPage?.data[hotelId]?.packages?.find(
        (p) => p.packageId === packageId
      )?.totalPrice
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'payment',
        amount: Math.round(totalPrice.price * 100),
        currency: totalPrice?.currency.toLowerCase(),
        appearance: {
          variables: { colorPrimary: '#FFA766' },
        },
      }}>
      <>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={8.2}
            container
            sx={isMobile ? { px: 2.5 } : { pl: 6, pr: 2.5 }}>
            <div>
              <CheckoutRoomSummary />
              <div style={{ marginTop: 24 }} />
              <PaymentForm setPaymentDisabled={setPaymentDisabled} />
              <div style={{ marginTop: 24 }} />
              <HouseRules />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3.8}
            container
            sx={
              isMobile
                ? { display: 'none' }
                : {
                    position: 'sticky',
                    top: 120,
                    height: 'max-content',
                  }
            }>
            <div style={{ width: '100%' }}>
              <CartSummary
                btnCopy="Confirm and Pay"
                isStripeStep
                isPaymentInfoPending={paymentDisabled}
              />
            </div>
          </Grid>
        </Grid>
        {isMobile && (
          <CheckoutBottomPriceBar
            isStripeStep
            isPaymentInfoPending={paymentDisabled}
          />
        )}
      </>
    </Elements>
  );
}

function CheckoutV2() {
  const classes = useStyles();
  const [step, setStep] = useState(CHECKOUT_STEPS.GUEST_INFO);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { slug: hotelId } = useParams();
  const packageId = useSelector((state) => state.Bookings.cart.packages[0]);
  const currentPackage = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelId]?.packages?.find(
      (p) => p.packageId === packageId
    )
  );
  const cxlPolicy = useSelector(
    (state) => state.Bookings.cart.cxlPolicy[packageId]
  );

  useEffect(() => {
    if (packageId && !cxlPolicy) {
      getCxlPolicy({
        packageId,
        hotelId,
        supplierId: currentPackage?.supplierId,
      }).then((res) =>
        dispatch(actions.Bookings.setCxlPolicy({ packageId, cxlPolicy: res }))
      );
    }
  }, [packageId, hotelId]);

  useEffect(() => {
    const url = new URL(window.location);
    url.searchParams.set('step', step);
    window.history.replaceState({}, '', url);
  }, [step]);

  const handleBackButtonClick = () => {
    if (step === CHECKOUT_STEPS.PAYMENT_DETAILS) {
      setStep(CHECKOUT_STEPS.GUEST_INFO);
    } else {
      navigate(-1);
    }
  };

  return (
    <div
      style={{
        paddingBottom: 120,
        height: '100vh',
        width: '100vw',
        overflow: 'auto',
      }}>
      <CheckoutNavbar handleBackButtonClick={handleBackButtonClick} />
      {/* <CheckoutSuccessModal open /> */}
      <div style={{ marginTop: 48 }} />
      <CheckoutProgress activeStep={step} />
      <div style={{ marginTop: 48 }} />

      <Grid container className={classes.spacing}>
        {step === CHECKOUT_STEPS.GUEST_INFO ? (
          <GuestInfo setStep={setStep} />
        ) : (
          <PaymentInfo setStep={setStep} />
        )}
      </Grid>
    </div>
  );
}

export default CheckoutV2;
