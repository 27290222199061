import { createContext, useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import QUERY from '../../graphql/queries';
import graphqlClient from '../../graphql';

export const ItineraryDndContext = createContext();

export const useItineraryDnd = () => {
  return useContext(ItineraryDndContext);
};

const modifyItems = (items) =>
  items?.map((item) =>
    item.__typename === 'Location'
      ? { location: item.id }
      : { transportation: item.id }
  ) || [];

export function ItineraryDndProvider({
  children,
  trip,
  triggerAddActivity,
  setTriggerAddActivity,
  triggerAddLocation,
  setTriggerAddLocation,
}) {
  const [items, setItems] = useState(trip?.items);

  const [isNewActivity, setNewActivity] = useState(null);
  const [isNewSection, setNewSection] = useState(null);
  const [isNewLocation, setNewLocation] = useState(false);

  const [createLocation] = useMutation(QUERY.CREATE_LOCATION, {
    client: graphqlClient,
  });

  const [updateTrip] = useMutation(QUERY.UPDATE_TRIP, {
    client: graphqlClient,
  });
  const [updateLocation] = useMutation(QUERY.UPDATE_LOCATION, {
    client: graphqlClient,
  });
  const [updateThingsToDo] = useMutation(QUERY.UPDATE_THINGS_TODO, {
    client: graphqlClient,
  });

  const [createThingsToDo] = useMutation(QUERY.CREATE_THINGS_TODO, {
    client: graphqlClient,
  });
  const [createActivity] = useMutation(QUERY.CREATE_TODO, {
    client: graphqlClient,
  });

  // utility functions
  const findLocationById = (locationId) => {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < items?.length; index++) {
      const item = items[index];
      if (item?.id === locationId) {
        return [item, index];
      }
    }
    return [null, null];
  };

  const findSectionById = (sectionId, locationIndex) => {
    const item = items[locationIndex];
    return (
      item?.thingsToDo?.find((section) => section?.id === sectionId) || null
    );
  };

  const findSectionByActivity = (sectionId) => {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < items?.length; index++) {
      const item = items[index];
      const sectionIndex = item?.thingsToDo
        ?.map((section) => section?.id)
        ?.indexOf(sectionId);
      if (sectionIndex !== undefined && sectionIndex !== -1) {
        return [item.thingsToDo[sectionIndex], sectionIndex, index];
      }
    }
    return [null, null, null];
  };

  const findActivityById = (activityId, sectionIndex, locationIndex) => {
    const section = items[locationIndex]?.thingsToDo[sectionIndex];
    return section?.todos?.find((activity) => activity?.id === activityId);
  };

  const activityCreator = (
    id,
    title,
    mapPin = undefined,
    activityProps = {}
  ) => {
    return {
      id,
      likes: [],
      isLiked: false,
      title,
      dbId: id,
      activityTime: undefined,
      cost: undefined,
      costPer: undefined,
      currency: undefined,
      description: undefined,
      links: undefined,
      mapPin,
      streetAddress: null,
      city: null,
      country: null,
      state: null,
      zipCode: null,
      ...activityProps,
    };
  };

  // end utility functions

  const addActivity = (
    locationId,
    sectionId,
    todoTitle,
    dbId,
    index = -1,
    originalItems = null,
    mapPin = null,
    activityProps = {}
  ) => {
    const newItems = originalItems ? [...originalItems] : [...items];
    const locationIndex = items?.map((item) => item?.id)?.indexOf(locationId);
    const sections = [...(newItems[locationIndex]?.thingsToDo || [])];
    const sectionIndex = sections
      ?.map((section) => section?.id)
      ?.indexOf(sectionId);
    const activity = activityCreator(dbId, todoTitle, mapPin, activityProps);
    const activities = [
      ...newItems[locationIndex].thingsToDo[sectionIndex].todos,
    ];
    if (index === -1) activities.push(activity);
    else activities.splice(index + 1, 0, activity);

    sections[sectionIndex] = {
      ...newItems[locationIndex].thingsToDo[sectionIndex],
      todos: activities,
    };
    newItems[locationIndex] = {
      ...newItems[locationIndex],
      thingsToDo: sections,
    };
    setItems(newItems);
  };

  // For Sections
  const addSection = (
    locationId,
    newSectionId,
    index = -1,
    withActivity = undefined,
    title = ''
  ) => {
    const newSection = {
      id: newSectionId,
      name: title,
      todos: withActivity === undefined ? [] : [withActivity],
    };
    let newThingsToDo;
    const locationIndex = items?.map((item) => item.id)?.indexOf(locationId);
    if (index === -1) {
      newThingsToDo = [...(items[locationIndex]?.thingsToDo || []), newSection];
    } else {
      newThingsToDo = [...(items[locationIndex]?.thingsToDo || [])];
      newThingsToDo.splice(index + 1, 0, newSection);
    }

    const newItems = [...items];
    newItems[locationIndex] = {
      ...newItems[locationIndex],
      thingsToDo: newThingsToDo,
    };
    if (withActivity === undefined) setNewSection(newSectionId);
    setItems(newItems);
  };

  const updateSection = (locationId, localId, section) => {
    const newSection = {
      id: localId,
      ...section,
    };
    const locationIndex = items?.map((item) => item.id)?.indexOf(locationId);
    const newThingsToDo = [...(items[locationIndex]?.thingsToDo || [])];
    const sectionIndex = newThingsToDo
      ?.map((localSection) => localSection?.id)
      .indexOf(localId);
    if (sectionIndex !== -1) {
      newThingsToDo.splice(sectionIndex, 1, newSection);
    }
    const newItems = [...items];
    newItems[locationIndex] = {
      ...newItems[locationIndex],
      thingsToDo: newThingsToDo,
    };
    setItems(newItems);
  };

  const deleteSection = (locationId, sectionId) => {
    const locationIndex = items?.map((item) => item.id)?.indexOf(locationId);
    const newThingsToDo = items[locationIndex]?.thingsToDo?.filter(
      (section) => section?.id !== sectionId
    );
    const newItems = [...items];
    newItems[locationIndex] = {
      ...newItems[locationIndex],
      thingsToDo: newThingsToDo,
    };
    setItems(newItems);
  };

  const updateItem = (item) => {
    const itemIndex = items
      ?.map((localItem) => localItem.id)
      ?.indexOf(item?.id);
    if (itemIndex === -1) return;

    const newItem = {
      ...items[itemIndex],
      ...item,
    };
    const newItems = [...items];
    newItems.splice(itemIndex, 1, newItem);
    setItems(newItems);
  };

  // Create Location item
  const createLocationItem = async (locationData) => {
    const updatedItems = modifyItems(items);
    const newLocation = await createLocation({
      variables: {
        ...locationData,
      },
    });
    const newLocationId = newLocation.data.createLocation.id;
    updatedItems.push({ location: newLocationId });
    await updateTrip({
      variables: {
        id: trip?.id,
        items: updatedItems,
      },
    });
  };

  const addActivityToSection = async ({
    location: locationId,
    title,
    address = {},
    mapPin = undefined,
    mapPinData = undefined,
    sectionIndex = null,
  }) => {
    // eslint-disable-next-line no-unused-vars
    const [location, _] = findLocationById(locationId);
    const sections =
      location?.thingsToDo?.filter(
        (section) => section.id !== 'local-section'
      ) || [];
    let sectionId;
    let activities = [];

    // if no sections exist
    if (sections?.length === 0) {
      sectionId = (
        await createThingsToDo({
          variables: {
            locationId,
            name: 'Places to visit',
            startDate: null,
            endDate: null,
            todos: [],
          },
        })
      )?.data?.createThingsToDo?.id;
      if (window?.heap) window?.heap.track('Activity Created');
      const newActivity = (
        await createActivity({
          variables: {
            thingsToDoId: sectionId,
            title,
            mapPin,
            ...address,
          },
        })
      )?.data?.createTodo;
      activities.push(newActivity.id);
      const activity = activityCreator(newActivity.id, title, mapPinData, {
        ...address,
      });
      addSection(locationId, sectionId, -1, activity, 'Places to visit');
    } else {
      if (sectionIndex === null) return;
      sectionId = sections[sectionIndex]?.id;
      const newActivity = (
        await createActivity({
          variables: {
            thingsToDoId: sectionId,
            title,
            mapPin,
            ...address,
          },
        })
      )?.data?.createTodo;
      activities = [
        ...(sections[sectionIndex]?.todos?.map((todo) => todo.id) || []),
        newActivity.id,
      ];
      if (window?.heap) window?.heap.track('Activity Created');
      addActivity(
        locationId,
        sectionId,
        title,
        newActivity.id,
        -1,
        null,
        mapPinData,
        { ...address }
      );
    }
    updateThingsToDo({
      variables: {
        id: sectionId,
        todos: activities,
      },
    });
  };

  useEffect(() => {
    if (triggerAddActivity) {
      addActivityToSection(triggerAddActivity)
        .then(() => setTriggerAddActivity(null))
        .catch((err) => {
          return err;
        });
    } else if (triggerAddLocation) {
      createLocationItem(triggerAddLocation)
        .then(() => setTriggerAddLocation(null))
        .catch((err) => {
          return err;
        });
    }
    return () => {};
  }, [triggerAddActivity, triggerAddLocation]);

  // function to parse and check if the local state needs to be updated.
  const stateHasUpdated = () => {
    if (trip.items?.length !== items?.length) {
      return true;
    }
    let result = false;
    trip?.items?.forEach((tripItem, idx) => {
      const item = items[idx];
      if (
        item.__typename === 'Location' &&
        ((item?.hotels?.length || 0) !== (tripItem?.hotels?.length || 0) ||
          item.name === '')
      ) {
        result = true;
      }
    });
    return result;
  };

  /* 
    itinerary CRUD functions
  */

  // For accommodations
  const updateLocalAccommodation = async (locationId, accommodation) => {
    const locationIndex = items?.map((item) => item.id)?.indexOf(locationId);
    const updatedAccommodations = [...(items[locationIndex]?.hotels || [])];
    const accommodationIndex = updatedAccommodations
      ?.map((localAccommodation) => localAccommodation?.id)
      .indexOf(accommodation?.id);
    if (accommodationIndex !== -1) {
      const updatedAccommodation = {
        ...updatedAccommodations[accommodationIndex],
        ...(accommodation || {}),
      };
      updatedAccommodations.splice(accommodationIndex, 1, updatedAccommodation);
    }
    const newItems = [...items];
    newItems[locationIndex] = {
      ...newItems[locationIndex],
      hotels: updatedAccommodations,
    };
    setItems(newItems);
  };

  // For Activities

  const updateActivity = async (
    locationId,
    sectionId,
    localTodoId,
    activity,
    shouldUpdateSection = false
  ) => {
    const locationIndex = items?.map((item) => item?.id)?.indexOf(locationId);
    let sectionTodos = items[locationIndex]?.thingsToDo?.find(
      (section) => section.id === sectionId
    )?.todos;

    sectionTodos = sectionTodos
      ?.map((todo) => {
        if (todo.id === localTodoId) {
          return {
            ...todo,
            ...activity,
          };
        }
        return todo;
      })
      ?.filter((todo) => !todo?.id?.includes('local-todo'));

    const newItems = [...items];
    const sectionIndex = newItems[locationIndex].thingsToDo
      ?.map((section) => section.id)
      ?.indexOf(sectionId);
    const updatedThingsTodo = [...newItems[locationIndex].thingsToDo];
    updatedThingsTodo.splice(sectionIndex, 1, {
      ...newItems[locationIndex].thingsToDo[sectionIndex],
      todos: sectionTodos,
    });
    newItems[locationIndex] = {
      ...newItems[locationIndex],
      thingsToDo: updatedThingsTodo,
    };
    setItems(newItems);

    if (shouldUpdateSection) {
      await updateThingsToDo({
        variables: {
          id: sectionId,
          todos: sectionTodos?.map((todo) => todo?.id),
        },
      });
    }
    return newItems;
  };

  const deleteActivity = (locationId, sectionId, activityId) => {
    const newItems = JSON.parse(JSON.stringify(items));
    const locationIndex = newItems?.map((item) => item?.id).indexOf(locationId);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < newItems[locationIndex].thingsToDo.length; i++) {
      const section = newItems[locationIndex].thingsToDo[i];
      if (section.id === sectionId) {
        section.todos = section.todos.filter((todo) => todo.id !== activityId);
        newItems[locationIndex].thingsToDo[i] = section;
        setItems(newItems);
        updateThingsToDo({
          variables: {
            id: sectionId,
            todos: section.todos
              .map((todo) => todo.id)
              .filter((todo) => !todo.includes('local-todo')),
          },
        });
        break;
      }
    }
  };

  /* 
    end itinerary CRUD functions
  */

  // updateItems when the trip updates.
  useEffect(() => {
    if (stateHasUpdated()) setItems(trip.items);
  }, [trip.items]);

  /* Function that takes care of the drag and drop across locations */
  const handleDragEnd = async ({ draggableId, destination, source, type }) => {
    // don't do anything if there's no change in position/invalid position.
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    )
      return;

    if (type === 'ITEM') {
      const draggableIdItem = items[source.index];
      const newItems = [...items];
      newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, draggableIdItem);

      updateTrip({
        variables: {
          id: trip.id,
          items: modifyItems(newItems),
        },
      });
      setItems(newItems);
    } else if (type === 'SECTION') {
      /* eslint-disable prefer-const */
      let [sourceLocation, sourceLocationIndex] = findLocationById(
        source.droppableId
      );
      let [destinationLocation, destinationLocationIndex] = findLocationById(
        destination.droppableId
      );
      /* eslint-enable prefer-const */
      const draggableItem = findSectionById(draggableId, sourceLocationIndex);

      if (!draggableItem) return;

      // Within same location
      if (sourceLocationIndex === destinationLocationIndex) {
        let newColumnsOrder =
          (sourceLocation?.thingsToDo && [...sourceLocation.thingsToDo]) || [];
        newColumnsOrder.splice(source.index, 1);
        newColumnsOrder.splice(destination.index, 0, draggableItem);
        newColumnsOrder = newColumnsOrder.filter(
          (column) => column !== 'local-section'
        );
        sourceLocation = { ...sourceLocation, thingsToDo: newColumnsOrder };

        const newItems = [...items];
        newItems[sourceLocationIndex] = sourceLocation;
        setItems(newItems);
        updateLocation({
          variables: {
            id: source.droppableId,
            thingsToDo: newColumnsOrder.map((section) => section?.id),
          },
        });
      } // across locations
      else {
        let sourceColumnsOrder =
          (sourceLocation?.thingsToDo && [
            ...(sourceLocation.thingsToDo || []),
          ]) ||
          [];
        let destinationColumnsOrder =
          (destinationLocation?.thingsToDo && [
            ...(destinationLocation.thingsToDo || []),
          ]) ||
          [];
        sourceColumnsOrder.splice(source.index, 1);
        destinationColumnsOrder.splice(destination.index, 0, draggableItem);
        sourceColumnsOrder = sourceColumnsOrder.filter(
          (column) => column !== 'local-section'
        );
        destinationColumnsOrder = destinationColumnsOrder.filter(
          (column) => column !== 'local-section'
        );
        sourceLocation = { ...sourceLocation, thingsToDo: sourceColumnsOrder };
        destinationLocation = {
          ...destinationLocation,
          thingsToDo: destinationColumnsOrder,
        };

        const newItems = [...items];
        newItems[sourceLocationIndex] = sourceLocation;
        newItems[destinationLocationIndex] = destinationLocation;
        setItems(newItems);
        updateLocation({
          variables: {
            id: source.droppableId,
            thingsToDo: sourceColumnsOrder.map((section) => section?.id),
          },
        });
        updateLocation({
          variables: {
            id: destination.droppableId,
            thingsToDo: destinationColumnsOrder.map((section) => section?.id),
          },
        });
      }
    } else if (type === 'ACTIVITY') {
      /* eslint-disable prefer-const */
      let [sourceSection, sourceSectionIndex, sourceLocationIndex] =
        findSectionByActivity(source.droppableId);
      let [
        destinationSection,
        destinationSectionIndex,
        destinationLocationIndex,
      ] = findSectionByActivity(destination.droppableId);
      /* eslint-enable prefer-const */
      const draggableItem = findActivityById(
        draggableId,
        sourceSectionIndex,
        sourceLocationIndex
      );

      if (!draggableItem) return;

      // activity dnd in the same section
      if (
        sourceSectionIndex === destinationSectionIndex &&
        sourceLocationIndex === destinationLocationIndex
      ) {
        let newTodosOrder =
          (sourceSection?.todos && [...(sourceSection.todos || [])]) || [];
        newTodosOrder.splice(source.index, 1);
        newTodosOrder.splice(destination.index, 0, draggableItem);

        // TODO: remove this after verification
        newTodosOrder = newTodosOrder.filter(
          (todo) => !todo?.id.includes('local-todo')
        );

        sourceSection = { ...sourceSection, todos: newTodosOrder };

        const newItems = [...items];
        const newSections = [
          ...(newItems[sourceLocationIndex]?.thingsToDo || []),
        ];
        newSections[sourceSectionIndex] = sourceSection;
        newItems[sourceLocationIndex] = {
          ...newItems[sourceLocationIndex],
          thingsToDo: newSections,
        };
        setItems(newItems);
        updateThingsToDo({
          variables: {
            id: source.droppableId,
            todos: newTodosOrder?.map((todo) => todo.id),
          },
        });
      } // activity across sections
      else {
        let sourceTodosOrder =
          (sourceSection?.todos && [...(sourceSection.todos || [])]) || [];
        let destinationTodosOrder =
          (destinationSection?.todos && [
            ...(destinationSection.todos || []),
          ]) ||
          [];
        sourceTodosOrder.splice(source.index, 1);
        destinationTodosOrder.splice(destination.index, 0, draggableItem);

        // TODO: remove this after verification
        sourceTodosOrder = sourceTodosOrder.filter(
          (todo) => !todo.id.includes('local-todo')
        );
        destinationTodosOrder = destinationTodosOrder.filter(
          (todo) => !todo.id.includes('local-todo')
        );

        sourceSection = { ...sourceSection, todos: sourceTodosOrder };
        destinationSection = {
          ...destinationSection,
          todos: destinationTodosOrder,
        };

        const newItems = [...items];

        if (sourceLocationIndex === destinationLocationIndex) {
          const newSections = [
            ...(newItems[sourceLocationIndex]?.thingsToDo || []),
          ];
          newSections[sourceSectionIndex] = sourceSection;
          newSections[destinationSectionIndex] = destinationSection;
          newItems[sourceLocationIndex] = {
            ...newItems[sourceLocationIndex],
            thingsToDo: newSections,
          };
        } else {
          const newSourceSections = [
            ...(newItems[sourceLocationIndex]?.thingsToDo || []),
          ];
          const newDestinationSections = [
            ...(newItems[destinationLocationIndex]?.thingsToDo || []),
          ];

          newSourceSections[sourceSectionIndex] = sourceSection;
          newDestinationSections[destinationSectionIndex] = destinationSection;

          newItems[sourceLocationIndex] = {
            ...newItems[sourceLocationIndex],
            thingsToDo: newSourceSections,
          };
          newItems[destinationLocationIndex] = {
            ...newItems[destinationLocationIndex],
            thingsToDo: newDestinationSections,
          };
        }

        setItems(newItems);
        await updateThingsToDo({
          variables: {
            id: source.droppableId,
            todos: sourceTodosOrder?.map((todo) => todo.id) || [],
          },
        });
        await updateThingsToDo({
          variables: {
            id: destination.droppableId,
            todos: destinationTodosOrder?.map((todo) => todo.id) || [],
          },
        });
      }
    }
  };
  /* end handleDragEnd Function */
  const itineraryContext = {
    items,
    handleDragEnd,
    addActivity,
    addSection,
    deleteSection,
    updateSection,
    updateItem,
    isNewActivity,
    setNewActivity,
    isNewSection,
    isNewLocation,
    setNewLocation,
    setNewSection,
    activityCreator,
    updateActivity,
    deleteActivityState: deleteActivity,
    addActivityToSection,
    createLocationItem,
    updateLocalAccommodation,
    tripStartDate: trip.startDate,
    tripEndDate: trip.endDate,
  };

  return (
    <ItineraryDndContext.Provider value={itineraryContext}>
      {children}
    </ItineraryDndContext.Provider>
  );
}
