import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Backdrop,
  IconButton,
  List,
  ListItem,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  Divider,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  Paper,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { MoreVert } from '@mui/icons-material';
import { UserIcon } from '@iconicicons/react';
import { useSelector, useDispatch } from 'react-redux';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  getUser,
  getUserV2,
  updateUser,
  updateUserV2,
} from '../../../../redux/slices/Auth';
import Logo from '../../../molecules/Logo';
import SettingsPopover from '../../../molecules/Menu/SettingsPopover';
import LabPopover from '../../../molecules/Menu/LabPopover';
import {
  DefaultAvatar,
  Plane,
  Bookmark,
  DiscoverIcon,
  SupportIcon,
} from '../../../atoms/Icon';

import classList from '../../../classList';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import { openIntercom } from '../../../../intercom';

const useStyles = (showNavbar) =>
  makeStyles((theme) => ({
    root: {
      display: showNavbar ? 'auto' : 'none',
      flexGrow: 1,
      padding: 0,
    },
    appbar: {
      backgroundColor: '#FFF',
      borderBottom: '1px solid #DEDDDD',
    },
    toolbar: {
      minHeight: 64,
      paddingRight: 32,
      paddingLeft: 30,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 24,
        paddingLeft: 24,
      },
    },
    title: {
      flexGrow: 1,
    },
    text: {
      color: theme.palette.text.main,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: 194,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    listItem: {
      flex: 0,
      paddingLeft: 0,
      paddingRight: 0,
      color: theme.palette.text.light,
      '&:hover': {
        backgroundColor: '#fff',
        cursor: 'pointer',
        '& $text': {
          color: theme.palette.primary.main,
        },
      },
    },
    barLeft: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    list: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'flex-end',
    },
    iconButton: {
      padding: 4,
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
      width: 24,
      height: 24,
    },
    activeItem: {
      color: theme.palette.primary.main,
    },
    tabContainer: {
      flexGrow: 1,
      alignContent: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      backgroundColor: 'rgba(248, 248, 248, 0.5)',
    },
    divider: {
      marginLeft: 20,
      marginRight: 20,
      width: 1,
      height: 24,
      borderColor: '#DEDDDD',
      alignSelf: 'center',
    },
    bottomNavContainer: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      borderTop: '1px solid #DEDDDD',
    },
    bottomNavTab: {
      flex: 0,
      webkitFlex: 0,

      // space between the tabs is fixed at 60px in designs. 18px margin + 12px default padding on each element
      marginLeft: 8,
      marginRight: 8,
    },
    labStyle: {
      width: '130px',
      flexDirection: 'row',
      paddingLeft: 0,
      paddingRight: 0,
      color: '#8A8A8A',
      '&:hover': {
        backgroundColor: '#fff',
        cursor: 'pointer',
      },
      '&:hover .labs-icon': {
        src: '/images/PilotLabsIcon.svg',
      },
      [theme.breakpoints.down('md')]: {
        width: '100px',
      },
    },
    labtext: {
      fontSize: '13px',
      fontWeight: '400',
      marginLeft: '4px',
      marginRight: '4px',
      [theme.breakpoints.down('md')]: {
        fontSize: '11px',
        marginLeft: '2px',
        marginRight: '2px',
      },
    },
  }))();

const useTabStyles = makeStyles(() => ({
  root: {
    margin: '0 0',
    display: 'flex',
    minHeight: 0,
  },
  indicator: {
    display: 'none',
  },
}));

const useTabItemStyles = makeStyles(({ palette, typography }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    color: palette.text.light,
    textTransform: 'none',
    opacity: 1,
    padding: '8px 12px',
    ...typography.h4,
    '&:hover': {
      color: palette.primary.light,
    },
    '&$selected': {
      color: palette.primary.main,
      '& > svg': {
        color: palette.primary.main,
      },
      ...typography.h3,
    },
  },
  selected: {},
  labelIcon: {
    minHeight: 0,
  },
}));

function MainNavbar() {
  /**
   * activeItem:
   * 				disabled (0)  --> explore
   * 				0  --> trips (default active)
   *        1  --> discover
   * 				2  --> wishlist
   * 				3  --> profile
   * 				4  --> message
   */
  const [activeItem, setActiveItem] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);

  const classes = useStyles(showNavbar, activeItem);
  const tabItemsClasses = useTabItemStyles();
  const tabsClasses = useTabStyles();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const userId = useSelector((state) => state.Auth.firebaseUser.uid);
  const mongoUserId = useSelector((state) => state.Auth.userId);
  const user = useSelector((state) => state.Auth.userData);

  const prevDiscoverPath = useSelector((state) => state.Discover.prevPath);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const navRoutes = [
    '/trips',
    '/discover',
    '/wishlist',
    `/profile/${userId}`,
    '/messages',
    // '/offers',
  ];

  useEffect(() => {
    if (user.status === 'IDLE') {
      dispatch(getUser({ id: userId }));
      dispatch(getUserV2({ id: userId }));
    }
  }, []);

  // to maintain activeItem persistence on reload.
  useEffect(() => {
    const path = location.pathname;
    let index = navRoutes.indexOf(path);
    // eslint-disable-next-line prefer-regex-literals
    if (
      /trips\/.+/.test(location.pathname) ||
      /hotel\/.+/.test(location.pathname) ||
      /checkout\/.+/.test(location.pathname)
    ) {
      setShowNavbar(false);
    } else if (!showNavbar) setShowNavbar(true);
    // eslint-disable-next-line prefer-regex-literals
    else if (new RegExp('\\/discover\\/.+').test(location.pathname)) {
      index = 1;
    }
    const query = new URLSearchParams(location.search);
    if (query.get('focus') === 'wishlist') index = 2;
    setActiveItem(index);
  }, [location]);

  const firstName = user?.user?.firstName;
  const lastName = user?.user?.lastName;
  const profilePicture = user?.user?.profilePicture;

  const letters =
    ((firstName && firstName.charAt(0).toUpperCase()) || '') +
    ((lastName && lastName.charAt(0).toUpperCase()) || '');

  const handleSwitch = (item) => {
    switch (item) {
      case 0:
        navigate('/trips');
        break;
      case 1:
        navigate(`/discover${prevDiscoverPath}`);
        break;
      case 2:
        navigate('/wishlist');
        break;
      case 3:
        navigate(`/profile/${userId}`);
        break;
      case 4:
        navigate('/messages');
        break;
      // case 5:
      //   navigate('/offers');
      //   break;
      default:
        break;
    }
    setActiveItem(item);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [labStatus, setLabStatus] = useState(!user.user?.labDisabled);
  const { labDisabled } = useSelector((state) => state.Auth.userData?.user);

  useEffect(() => {
    setLabStatus(!labDisabled);
  }, [labDisabled]);

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleLabStateChange = (e) => {
    setLabStatus(e.target.checked);
    phTrackEvent({
      event: e.target.checked
        ? EVENTS.GLOBAL_NAV.LABS_TOGGLE_ON_CLICK
        : EVENTS.GLOBAL_NAV.LABS_TOGGLE_OFF_CLICK,
    });
    if (location.pathname.includes('/discover')) navigate('/trips');
    dispatch(
      updateUser({
        variables: {
          id: userId,
          labDisabled: !e.target.checked,
        },
      })
    );
    dispatch(
      updateUserV2({
        variables: {
          id: mongoUserId,
          labDisabled: !e.target.checked,
        },
      })
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appbar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.barLeft}>
            <Link to="/trips">
              <Logo fullLogo imageWidth={isMobile ? '80px' : '100px'} />
            </Link>
            {/* <div style={{ marginLeft: '37.75px', width: '130px' }}>
              <PeopleSearchBar /> 
            </div> */}
          </div>
          {isMobile ? (
            <Paper className={classes.bottomNavContainer}>
              <BottomNavigation
                showLabels
                value={activeItem}
                onChange={(event, index) => {
                  handleSwitch(index);
                }}>
                <BottomNavigationAction
                  label="Trips"
                  icon={<Plane />}
                  disableRipple
                  value={0}
                  className={`${classList.tripsTab} ${classes.bottomNavTab}`}
                />
                {labStatus && (
                  <BottomNavigationAction
                    label="Discover"
                    icon={<DiscoverIcon />}
                    disableRipple
                    value={1}
                    className={`${classList.discoverTab} ${classes.bottomNavTab}`}
                  />
                )}
                <BottomNavigationAction
                  label="Wishlist"
                  icon={<Bookmark />}
                  disableRipple
                  value={2}
                  className={`${classList.wishlistTab} ${classes.bottomNavTab}`}
                />
                <BottomNavigationAction
                  id="profile-button"
                  label="Profile"
                  icon={<UserIcon />}
                  disableRipple
                  value={3}
                  className={`${classList.profileTab} ${classes.bottomNavTab}`}
                />
              </BottomNavigation>
            </Paper>
          ) : (
            <div className={classes.tabContainer}>
              <Tabs
                classes={tabsClasses}
                value={activeItem}
                onChange={(e, index) => handleSwitch(index)}
                indicatorColor="primary">
                {/* <Tab classes={tabItemsClasses} wrapped label={"Explore"} icon={<ExploreRounded />} disableRipple /> */}
                <Tab
                  classes={tabItemsClasses}
                  className={classList.tripsTab}
                  label="Trips"
                  icon={
                    <Plane
                      style={{ marginBottom: 2, marginRight: 4 }}
                      height={20}
                      width={20}
                    />
                  }
                  disableRipple
                  value={0}
                />
                {labStatus && (
                  <Tab
                    classes={tabItemsClasses}
                    className={classList.discoverTab}
                    label="Discover"
                    icon={
                      <DiscoverIcon
                        style={{ marginBottom: 2, marginRight: 4 }}
                      />
                    }
                    disableRipple
                    value={1}
                  />
                )}

                <Tab
                  classes={tabItemsClasses}
                  className={classList.wishlistTab}
                  label="Wishlist"
                  icon={
                    <Bookmark
                      style={{ marginBottom: 2, marginRight: 4 }}
                      height={20}
                      width={20}
                    />
                  }
                  disableRipple
                  value={2}
                />

                {/* <Tab
                  classes={tabItemsClasses}
                  className={classList.offersTab}
                  label="Offers"
                  icon={
                    <OffersIcon
                      style={{ marginBottom: 2, marginRight: 4 }}
                      height={20}
                      width={20}
                    />
                  }
                  disableRipple
                  value={5}
                /> */}
              </Tabs>
            </div>
          )}
          <List className={classes.list}>
            <ListItem
              onClick={(e) => setAnchorEl2(e.currentTarget)}
              id="labs-button"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              className={classes.labStyle}>
              <img
                src={
                  hovered
                    ? '/images/Pilot_labs_Icon_orange.svg'
                    : '/images/PilotLabsIcon.svg'
                }
                alt="file-upload-icon"
                height="20"
                className="labs-icon"
              />
              <Typography className={classes.labtext}>Pilot Labs</Typography>
              <FiberManualRecordIcon
                color={labStatus ? 'success' : '#8A8A8A'}
                fontSize="2"
              />
            </ListItem>
            <ListItem
              id="profile-button"
              onClick={() => {
                handleSwitch(3);
              }}
              className={`${classes.listItem} ${
                activeItem === 3 ? classes.activeItem : ''
              }`}>
              {isMobile ? null : (
                <>
                  {profilePicture ? (
                    <Avatar src={profilePicture} className={classes.avatar}>
                      {letters}
                    </Avatar>
                  ) : (
                    <DefaultAvatar />
                  )}
                  <Typography
                    style={{
                      marginLeft: 8,
                    }}
                    className={`${classes.text} ${
                      activeItem === 3 ? classes.activeItem : ''
                    }`}>
                    {firstName}
                  </Typography>
                </>
              )}
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
            </ListItem>
            <IconButton
              disableRipple
              onClick={openIntercom}
              className={`${classes.iconButton} ${classList.tripSupportButton}`}>
              <SupportIcon />
            </IconButton>
            <IconButton
              disableRipple
              className={`${classes.iconButton} ${classList.globalNavMoreClick}`}
              onClick={handleMenuOpen}>
              <MoreVert />
            </IconButton>
            <Backdrop
              className={classes.backdrop}
              open={Boolean(anchorEl)}
              onClick={() => {
                setAnchorEl(null);
              }}>
              <SettingsPopover
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
                letters={letters}
                setActiveItem={setActiveItem}
              />
            </Backdrop>
            <LabPopover
              anchorEl={anchorEl2}
              setAnchorEl={setAnchorEl2}
              handleLabStateChange={handleLabStateChange}
              labStatus={labStatus}
            />
          </List>
        </Toolbar>
      </AppBar>
      {/* This toolbar helps fixing scrolling errors. */}
      <Toolbar className={classes.toolbar} />
    </div>
  );
}

export default MainNavbar;
