import React from 'react';
import { ClickAwayListener } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { Popup } from 'react-map-gl';
import { getTopCategories } from '../../../../../utils';
import actions from '../../../../../redux/actions';
import RecommendationCard from '../RecommendationCard';

const useStyles = makeStyles(() => ({
  markerCaption: {
    '& > .mapboxgl-popup-tip': {
      border: 0,
    },
    '& > .mapboxgl-popup-content': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      padding: 0,
      marginBottom: 45,
    },
  },
}));

function PoisCardPopup({ activePoi }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handlePoisMarkerClickAway = () =>
    dispatch(actions.Recommendations.setActivePoisPlaceMarker(null));

  return (
    <Popup
      closeOnClick={false}
      latitude={activePoi?.coordinates?.latitude}
      longitude={activePoi?.coordinates?.longitude}
      anchor="bottom"
      closeButton={false}
      key={activePoi?.referenceId}
      className={classes.markerCaption}
      style={{ zIndex: 4122 }}>
      <ClickAwayListener onClickAway={handlePoisMarkerClickAway}>
        <div style={{ width: 260 }} onClick={(e) => e?.stopPropagation()}>
          <RecommendationCard
            key="active-recommendation-card"
            place={{
              ...activePoi,
              rankedCategories: [
                ...(getTopCategories(activePoi?.categories || []) || []),
                ...(activePoi?.categories || []),
              ],
            }}
            isPopupCard
          />
        </div>
      </ClickAwayListener>
    </Popup>
  );
}

export default PoisCardPopup;
