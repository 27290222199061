import React, { useState } from 'react';

import { useTheme } from '@mui/styles';
import { Box, Button } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OptionsMenu from '../../../molecules/Menu/OptionsMenu';

function SortOptionsMenu({
  selectedSortOption,
  sortFieldName = [],
  handleMenuItemClick = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState();
  const theme = useTheme();
  const handleOptionClick = (idx) => {
    handleMenuItemClick(idx);
    setAnchorEl(null);
  };

  const menuOpen = Boolean(anchorEl);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
      }}>
      <Button
        id="stays-sort-button"
        aria-controls={menuOpen ? 'stays-sort-button' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 175,
          height: '23px',
          borderWidth: '1px',
          borderRadius: '4px',
          borderStyle: 'solid',
          borderColor: anchorEl
            ? theme?.palette?.primary?.light
            : 'transparent',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          padding: '17px 13px',
          color: theme?.palette?.text?.secondary,
          backgroundColor: anchorEl
            ? '#fff'
            : theme?.palette?.system?.lightgrey,
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: theme?.palette?.primary?.light,
            color: '#222222',
          },
        }}
        endIcon={
          menuOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowDownIcon />
        }>
        {sortFieldName[selectedSortOption]}
      </Button>

      <OptionsMenu
        menuItemProps={{
          sx: {
            color: '#222222',
            fontSize: '14px',
            width: anchorEl?.clientWidth,
            '&:hover ': {
              color: '#222222',
            },
          },
        }}
        id="booking-sort"
        state={anchorEl}
        setState={setAnchorEl}
        callbacks={[handleOptionClick]}
        options={sortFieldName}
        isDividerVisisble={false}
        isMultipleCallback={false}
      />
    </Box>
  );
}

export default SortOptionsMenu;
