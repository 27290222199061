import React, { useRef, useState } from 'react';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Box,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { FilterListRounded } from '@mui/icons-material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

import TripCard from '../../../molecules/CreateATripFlow/TripCard';
import CategorySelector from '../Recommendations/CategorySelector';
import { BasicButton } from '../../../atoms/Button/index';
import { EVENTS, phTrackEvent } from '../../../../analytics';

function Step6Page({
  setStep,
  tripName,
  dateRange,
  tripImageURL,
  showFinalTrip,
  tripUsers,
  location,
  showPilotLabsPrompt,
}) {
  const [filterOpen, setFilterOpen] = useState();
  const [filterLabels, setFilterLabels] = useState([]);
  const [description, setDescription] = useState('');
  const catButtonRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const shouldGenerateTrip = description !== '' || filterLabels.length > 0;

  const handleNext = () => {
    showFinalTrip(shouldGenerateTrip, description, filterLabels);
  };

  const ctaCopy = shouldGenerateTrip ? 'Build my trip' : 'Start from scratch';

  return (
    <Box
      sx={{
        height: { xs: 'auto', sm: '80vh' },
        display: 'flex',
        alignItems: { xs: 'start', sm: 'center' },
      }}>
      <Grid
        container
        sx={{
          paddingX: { xs: '20px', sm: '100px' },
          height: { xs: 'auto', sm: '450px' },
        }}>
        <Grid
          item
          sm={6}
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Stack maxWidth="400px" spacing={3} mt="30px">
            <Box
              onClick={() => {
                if (!showPilotLabsPrompt) return setStep((prev) => prev - 2);
                return setStep((prev) => prev - 1);
              }}
              sx={{
                width: 'fit-content',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: '#8A8A8A',
              }}>
              <ChevronLeftRoundedIcon sx={{ marginTop: '-2px' }} />
              Back
            </Box>

            {/* <Typography sx={{ fontSize: '37px', fontWeight: 500 }}>
              Quick Start
            </Typography> */}

            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography sx={{ fontSize: '37px', fontWeight: 500 }}>
                Quick Start
              </Typography>
              <Typography
                fontSize="15px"
                noWrap
                marginTop="5px"
                marginLeft={1}
                border="1px solid"
                borderColor="primary.main"
                borderRadius={8}
                paddingX="8px"
                color="primary.main">
                Labs
              </Typography>
            </Box>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleNext();
              }}>
              <TextField
                label="Enter a prompt to generate ideas for your trip!"
                variant="outlined"
                sx={{ display: 'block' }}
                multiline
                rows={3}
                maxRows={5}
                fullWidth
                onChange={(e) => setDescription(e.target.value)}
              />
            </form>

            <div
              style={{
                maxWidth: '180px',
                marginBottom: 30,
              }}>
              <BasicButton
                id="add-interests-button"
                marginRight={1}
                onClick={() => {
                  setFilterOpen(!filterOpen);
                  if (filterOpen) {
                    phTrackEvent({
                      event: EVENTS.TRIP_CREATE.QUICKSTART_FILTERS_VIEW,
                    });
                  }
                }}
                sx={{
                  ...((filterOpen && {
                    color: 'primary.main',
                    borderColor: 'primary.main',
                    backgroundColor: 'primary.extraLight',
                  }) ||
                    {}),
                }}
                disableRipple
                startIcon={<FilterListRounded />}>
                {`Add Interests ${
                  filterLabels?.length > 0 ? `(${filterLabels.length})` : ''
                }`}
              </BasicButton>
              <Box
                sx={{
                  position: 'fixed',
                  left: '40%',
                }}
                ref={catButtonRef}
              />
            </div>
            <CategorySelector
              filterLabels={filterLabels}
              setFilterLabels={(selected) => {
                setFilterLabels(selected);
                phTrackEvent({
                  event: EVENTS.TRIP_CREATE.QUICKSTART_FILTERS_APPLY_CLICK,
                  meta: {
                    activity_filters_selected: selected,
                    location_name: location.description.split(',')[0].trim(),
                    location_country: location.description
                      .split(',')
                      .pop()
                      .trim(),
                    trip_attendee_count: tripUsers.length,
                    trip_name: tripName,
                  },
                });
              }}
              filterOpen={filterOpen ? catButtonRef.current : null}
              setFilterOpen={setFilterOpen}
              isQuickFilter
            />
            {isMobile && (
              <Button fullWidth onClick={handleNext}>
                {ctaCopy}
              </Button>
            )}

            <Typography fontSize="11px" color="#8A8A8A">
              Pilot&apos;s Quick Start learns with each use, but can make
              mistakes. Verify itinerary details.
            </Typography>
          </Stack>
        </Grid>

        {!isMobile && (
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Box
              sx={{
                maxWidth: '400px',
              }}>
              <Box mb={8}>
                <TripCard
                  tripName={tripName}
                  dateRange={dateRange}
                  tripImage={
                    tripImageURL || '/images/blank-trip-placeholder.png'
                  }
                  tripUsers={tripUsers}
                />
              </Box>

              <Button fullWidth onClick={handleNext}>
                {ctaCopy}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Step6Page;
