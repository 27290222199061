import { useDispatch, useSelector } from 'react-redux';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import actions from '../../../../redux/actions';
import {
  updateImportAttachedTo,
  updateFile,
} from '../../../../redux/slices/FilesV2';
import { getParentId, isTripV2 } from '../../../../utils';
import { deleteItem } from '../../../../redux/slices/Item';

function useSmartImport({
  attachedToItem,
  itemType,
  isForwardedFile,
  fileId,
  tripId,
  fileIndex,
  itemIndex,
  importId,
}) {
  const tripItems = useSelector((state) => state.Item.items);
  const transports = useSelector((state) => state.Transportation.transports);
  const accommodations = useSelector((state) => state.Accommodation.hotels);
  const activties = useSelector((state) => state.Activity.todos);

  const dispatch = useDispatch();

  const attachToItem = async (itemId) => {
    await dispatch(
      updateImportAttachedTo({
        variables: { importId, itemIndex, attachedToItem },
      })
    );
    if (isForwardedFile) {
      dispatch(
        actions.Auth.updateForwardedFileImportItemAttachedToItemId({
          index: fileIndex,
          itemIndex,
          attachedToItemId: attachedToItem,
        })
      );
      await dispatch(
        updateFile({
          variables: {
            id: fileId,
            attachedToID: itemId ? tripId : '',
            tripId: attachedToItem ? tripId : '',
            attachedToType: attachedToItem ? 'Trip' : '',
          },
        })
      );
      // await dispatch(
      //   updateTrip({
      //     variables: {
      //       id: tripId,
      //       files: itemId
      //         ? [...(trip?.files || []), fileId]
      //         : trip?.files || [],
      //     },
      //     addFiles: !itemId ? [fileId] : [],
      //   })
      // );
    }
  };
  const detachItem = async () => {
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.PLAN_IMPORT_HISTORY_BLADE_REMOVE,
    });
    await attachToItem(null);
    dispatch(
      deleteItem({
        variables: {
          id: attachedToItem,
        },
        parentId: getParentId(attachedToItem),
        type: tripItems[attachedToItem]?.type,
        tripId,
      })
    );
  };
  const getAttachedItemNameV2 = () => {
    return (
      tripItems[attachedToItem]?.content?.flightNumber ||
      tripItems[attachedToItem]?.name ||
      tripItems[attachedToItem]?.title ||
      null
    );
  };

  const getAttachedItemName = () => {
    if (isTripV2(tripId)) {
      // return 'hello';
      return getAttachedItemNameV2();
    }
    if (['bus', 'train', 'other', 'flight'].includes(itemType)) {
      if (transports[attachedToItem]?.details[0]) {
        return (
          transports[attachedToItem]?.details[0]?.flightNumber ||
          transports[attachedToItem]?.details[0]?.toAirport
        );
      }
    }
    return (
      accommodations[attachedToItem]?.name ||
      activties[attachedToItem]?.title ||
      null
    );
  };
  return {
    detachItem,
    getAttachedItemName,
  };
}

export default useSmartImport;
