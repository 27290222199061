import { makeStyles } from '@mui/styles';
import { HotelRounded } from '@mui/icons-material';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { Box, Grid, Tooltip, Typography, Fade } from '@mui/material';
import PortalAwareDraggableItem from './PortalAwareDraggableItem';
import { DraggableIconPlaceHolder } from '../../../atoms/Icon';
import { ITEM_DRAG_LEVELS } from '../../../../const';

const useStyles = makeStyles({
  hotelContainer: {
    height: 22,
    width: 22,
    color: '#4e4e4e',
  },
});

function HotelButton({
  tripId,
  commonStyles,
  isClicked,
  handleAddAccommodation,
  isKeyboardShortcutEnabled,
}) {
  const classes = useStyles();

  return (
    <Grid item>
      <Droppable
        droppableId={`TRIP-${tripId}-hotel`}
        type={ITEM_DRAG_LEVELS.Heading}
        isDropDisabled>
        {(droppableProvided, droppableSnapshot) => (
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}>
            <Tooltip
              title={
                <Box display="flex" alignItems="center">
                  <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
                    Stays
                  </Typography>
                  {isKeyboardShortcutEnabled && (
                    <Box sx={commonStyles.shortcutKey}>S</Box>
                  )}
                </Box>
              }
              enterDelay={500}
              TransitionComponent={Fade}
              placement="top"
              arrow
              componentsProps={commonStyles.componentsProps}>
              <Draggable draggableId="commandBar-hotel" index={0}>
                {(provided, snapshot) => (
                  <PortalAwareDraggableItem snapshot={snapshot}>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <Box
                        sx={
                          snapshot.isDragging
                            ? commonStyles.draggableExpandedState
                            : isClicked
                            ? commonStyles.draggableIconClickStyle
                            : commonStyles.hoverStyle
                        }
                        onClick={handleAddAccommodation}>
                        <HotelRounded className={classes.hotelContainer} />
                      </Box>
                    </div>
                  </PortalAwareDraggableItem>
                )}
              </Draggable>
              {droppableProvided.placeholder &&
                droppableSnapshot.isUsingPlaceholder && (
                  <DraggableIconPlaceHolder />
                )}
            </Tooltip>
          </div>
        )}
      </Droppable>
    </Grid>
  );
}

export default HotelButton;
