import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Collapse,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  AddRounded,
  DeleteOutlineRounded,
  DragIndicatorRounded,
  Link,
} from '@mui/icons-material';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { format } from 'date-fns';
import InlineBlade from './InlineBlade';
import { useMapUtils } from '../organisms/MapUtils';
import {
  updateAccommodation,
  deleteAccommodation,
} from '../../redux/slices/Accommodation';
import classList from '../classList';
import actions from '../../redux/actions';
import { ONE_DAY_MS, parseISODate, removeTimezoneOffset } from '../../utils';
import { getStaysSearchConfig } from '../organisms/Stays/bookingsUtils';
import { switchLink, trackTpLink } from '../../tp-switcher';
import PlacesImage from './PlacesImage';
import { EVENTS, phTrackEvent } from '../../analytics';

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  root: {
    flex: 1,
    color: '#000000',
    backgroundColor: '#F4FCFD',
    border: '1px solid #DEDDDD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    overflow: 'hidden',
    '&:hover': {
      border: '1px solid #A7A7A7',
      color: '#000000',
      backgroundColor: '#F4FCFD',
      cursor: 'pointer',
    },
  },
  isDragging: {
    border: `2px solid ${palette.primary.light}`,
    borderRadius: 8,
  },
  actionContainer: {
    position: 'absolute',
    margin: 'auto',
    top: 12,
    left: 'calc(-3.6rem - 15px)',
    minWidth: 'calc(3.6rem + 15px)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionIcon: {
    fontSize: '1.2rem',
    color: '#8A8A8A',
    opacity: '1',
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
      color: '#474747',
    },
  },
  actionIconDrag: {
    fontSize: '1.2rem',
    color: 'rgba(138, 138, 138, 1)',
    '&:hover': {
      color: '#474747',
    },
  },
  actionIconDragSpan: {
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    padding: '8px 0% 8px 8px',
  },
  description: {
    ...typography.h5,
    marginTop: 4,
    fontSize: 14,
    color: '#8A8A8A',
  },
  imageContainer: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: 60,
    objectFit: 'cover',
  },
  link: {
    color: '#A7A7A7',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    textDecorationLine: 'underline',
  },
  linkIcon: {
    color: '#A7A7A7',
    width: '20px',
    padding: '0 0.25em',
  },
  bladeContainer: {
    marginLeft: 12,
    paddingLeft: 'calc(3.6rem - 27px)',
    [breakpoints.up('sm')]: {
      paddingLeft: 'calc(3.6rem + 15px)',
    },
  },
  spacing: {
    marginBottom: 4,
  },
  accomodationDiv: {
    position: 'relative',
    marginLeft: 'calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px)',
      marginRight: 12,
    },
  },
}));

function AccommodationDraggable(props) {
  const { hotel, index } = props;
  return (
    <Draggable
      key={hotel}
      draggableId={hotel}
      index={index}
      type="ACCOMMODATION"
      disableInteractiveElementBlocking>
      {(draggableProvided, snapshot) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}>
          <AccomodationItem
            hotelId={hotel}
            provided={draggableProvided}
            isDragging={snapshot.isDragging}
            {...props}
          />
        </div>
      )}
    </Draggable>
  );
}

function AccommodationBlock(props) {
  const { activeAccommodation, setActiveAccommodation } = props;
  const [hotelsList, setHotelsList] = useState(props.hotels);
  const dispatch = useDispatch();
  const fileRelations = useSelector(
    (state) => state.Files.fileRelations[props.tripId]
  );

  useEffect(() => {
    setHotelsList(props.hotels);
  }, [props.hotels]);

  const handleDeleteAccomodation = async (
    hotelId,
    mapPin = null,
    files = []
  ) => {
    if (hotelId && props.locationId) {
      const filesToBeAdded = [];
      if (files?.length > 0) {
        const tempArr = fileRelations?.filter((relation) =>
          files.includes(relation.fileId)
        );
        tempArr.forEach((relation) => {
          if (
            tempArr.filter((rel) => rel.fileId === relation.fileId).length === 1
          ) {
            filesToBeAdded.push(relation.fileId);
          }
        });
      }
      dispatch(
        deleteAccommodation({
          variables: {
            id: hotelId,
            mapPin,
            ...(filesToBeAdded?.length > 0 && {
              filesToBeAdded,
              tripId: props.tripId,
            }),
            locationId: props.locationId,
          },
          extra: { locationId: props.locationId },
          mapPin,
          files,
        })
      );
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Droppable
        droppableId={`${props?.locationId}-accommodation`}
        direction="vertical"
        type="ACCOMMODATION"
        ignoreContainerClipping>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {hotelsList?.map((hotel, index) => (
              <AccommodationDraggable
                update={props.update}
                locationId={props.locationId}
                hotelId={hotel}
                tripId={props.tripId}
                handleDeleteAccomodation={handleDeleteAccomodation}
                activeAccommodation={activeAccommodation}
                setActiveAccommodation={setActiveAccommodation}
                setOpenAccomodationPanel={props.setOpenAccomodationPanel}
                isLocationDragging={props.isDragging}
                isSectionHighlight={props.isSectionHighlight}
                locationBias={props.locationBias}
                hotel={hotel}
                index={index}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

function AccomodationItem({
  hotelId,
  activeAccommodation,
  setActiveAccommodation,
  provided,
  handleDeleteAccomodation,
  setOpenAccomodationPanel,
  isDragging,
  isSectionHighlight,
  isLocationDragging,
  locationBias = null,
}) {
  const navigate = useNavigate();
  const hotel = useSelector((state) => state.Accommodation.hotels[hotelId]);
  const isBooking = hotel?.isBooking;

  const { slug } = useParams();
  const hotelMapPin = useSelector(
    (state) =>
      (hotel?.mapPin && (state.Map.mapPins[slug] || {})[hotel.mapPin]) || {}
  );
  const [isHovered, setHovered] = useState(false);
  const [mobileSingleClick, setMobileSingleClick] = useState(false);
  const booking = useSelector((state) =>
    isBooking
      ? state.Trips.trips[slug]?.bookings?.find(
          (b) => b?.id === hotel?.bookingId
        )
      : null
  );
  // for checking single and double clicks on mobile
  let wait = false;
  let timer;

  const classes = useStyles();
  const dispatch = useDispatch();
  const formatDate = (date) => {
    // TODO: use format date from utils
    return date ? format(parseISODate(date, true), 'MMM do') : '';
  };
  const description =
    hotel.checkInDate && hotel.checkOutDate
      ? `
	  ${formatDate(hotel.checkInDate, 'iii, MMM d')} - ${formatDate(
          hotel.checkOutDate,
          'iii, MMM d'
        )}`
      : '';
  const link = hotel.link ? hotel.link : null;
  const { focusPin } = useMapUtils();

  useEffect(() => {
    if (activeAccommodation === hotel.id && hotel.mapPin) {
      focusPin(hotel.mapPin);
    }
  }, [activeAccommodation]);

  const updateAccomodationAttributes = (attributes) => {
    const updatedAttributes = { ...attributes };
    try {
      // to not update unupdated attributes
      Object.keys(updatedAttributes).forEach((attr) => {
        if (hotel[attr] === updatedAttributes[attr]) {
          delete updatedAttributes[attr];
        }
      });
      if (
        updatedAttributes?.mapPin &&
        hotel?.mapPin?.id &&
        updatedAttributes?.mapPin !== hotel?.mapPin?.id
      ) {
        updatedAttributes.oldMapPin = hotel?.mapPin?.id;
      }
    } catch (err) {
      return err;
    }
    if (!updatedAttributes || updatedAttributes.length === 0) return null;
    dispatch(
      updateAccommodation({
        variables: {
          id: hotel.id,
          ...updatedAttributes,
        },
      })
    );
    return true;
  };

  const openHotelBookingsModal = () => {
    const { cityLocationDetails = null, date = null } = getStaysSearchConfig({
      fromDate: hotel?.checkInDate,
      toDate: hotel?.checkOutDate,
      mapPin: hotelMapPin,
      title: hotel?.name,
    });

    dispatch(
      actions.Recommendations.setCity({
        city: {
          coordinates: {
            latitude: cityLocationDetails?.latitude,
            longitude: cityLocationDetails?.longitude,
          },
          title: cityLocationDetails?.title,
          placeId: cityLocationDetails?.placeId,
        },
        tripId: slug,
      })
    );

    if (hotelMapPin?.hotelId) {
      window.localStorage.setItem('prevTripId', slug);
      const urlParams = new URLSearchParams({
        checkInDate: format(
          date?.from || new Date(Date.now() + 4 * ONE_DAY_MS),
          'yyyy-MM-dd'
        ),
        checkOutDate: format(
          date?.to || new Date(Date.now() + 6 * ONE_DAY_MS),
          'yyyy-MM-dd'
        ),
        rooms: '2|0|1',
      });
      return navigate(`/hotel/${hotelMapPin?.hotelId}?${urlParams.toString()}`);
    }

    dispatch(
      actions.Bookings.triggerSearch({
        tripId: slug,
        cityLocationDetails,
        date,
      })
    );

    return navigate(`/trips/${slug}/explore?focus=stays`);
  };

  // function to check single or double click on mobile screens
  const todoClickedMobile = () => {
    if (wait === true) {
      wait = false;
      clearTimeout(timer);
      return 'double';
    }
    wait = true;
    timer = setTimeout(() => {
      wait = false;
      return 'single';
    }, 5000);

    return 'single';
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={classes.spacing}>
      <Grid container direction="row" onMouseLeave={() => setHovered(false)}>
        {isLocationDragging || isSectionHighlight ? null : (
          <div className={classes.accomodationDiv}>
            <Grid
              item
              className={`${classes.actionContainer} ${classList.itemActionContainer}`}>
              {isHovered && !isMobile ? (
                <>
                  {isBooking ? null : (
                    <DeleteOutlineRounded
                      className={classes.actionIcon}
                      onClick={() =>
                        handleDeleteAccomodation(
                          hotel.id,
                          hotel.mapPin,
                          hotel.files
                        )
                      }
                    />
                  )}
                  <AddRounded
                    className={classes.actionIcon}
                    onClick={() => {
                      setOpenAccomodationPanel(true);
                      phTrackEvent({
                        event: EVENTS.PLAN_ADD.STAY_ONHOVER_ADD_ICON_CLICK,
                      });
                    }}
                  />
                </>
              ) : null}
              <span
                className={classes.actionIconDragSpan}
                {...provided.dragHandleProps}>
                {isHovered && !isMobile ? (
                  <DragIndicatorRounded className={classes.actionIconDrag} />
                ) : null}
              </span>
            </Grid>
          </div>
        )}
        <Collapse
          in={activeAccommodation !== hotel.id}
          timeout={0}
          sx={{ flex: '1 1 0' }}>
          <Grid
            display="flex"
            alignItems="center"
            onMouseLeave={() => {
              setMobileSingleClick(false);
            }}>
            {mobileSingleClick && (
              <span
                className={classes.actionIconDragSpan}
                style={{ marginLeft: '0', marginRight: '4px' }}
                {...provided.dragHandleProps}>
                <DragIndicatorRounded className={classes.actionIconDrag} />
              </span>
            )}
            <Grid
              // {...provided.dragHandleProps}
              item
              container
              fullWidth
              justifyContent="flex-start"
              alignItems="center"
              className={`${classes.root} 
              ${classList.item} ${isDragging ? classes.isDragging : ''} ${
                classList.accommodationBlock
              }`}
              style={{
                marginLeft: isLocationDragging || isSectionHighlight ? 12 : 0,
              }}
              onMouseEnter={() => {
                if (!isMobile) setHovered(true);
                else setMobileSingleClick(true);
              }}
              onClick={() => {
                if (!isMobile) {
                  setHovered(false);
                  setActiveAccommodation(hotel.id);
                } else {
                  const clickType = todoClickedMobile() || 'single';
                  if (clickType === 'single') {
                    setMobileSingleClick(true);
                  } else if (clickType === 'double') {
                    setMobileSingleClick(false);
                    setHovered(false);
                    setActiveAccommodation(hotel.id);
                  }
                }
              }}>
              <Grid item xs={9} className={classes.mainContainer}>
                <Box alignItems="center" flexDirection="column">
                  <Typography variant="h4">{hotel.name}</Typography>
                  <div className={classes.description}>
                    {description}
                    {link ? (
                      <a
                        className={classes.link}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => trackTpLink(link)}
                        href={switchLink(link) || link}>
                        <Link className={classes.linkIcon} />
                        Link
                      </a>
                    ) : null}
                  </div>
                </Box>
              </Grid>
              <Grid item xs={3} className={classes.imageContainer}>
                <PlacesImage
                  fallbackImage="/images/hotel.svg"
                  mapPin={hotel?.mapPin}
                  className={classes.image}
                  fallbackStyle={{
                    objectFit: 'contain',
                  }}
                />
              </Grid>
            </Grid>
            {mobileSingleClick && (
              <DeleteOutlineRounded
                className={classes.actionIcon}
                onClick={() =>
                  handleDeleteAccomodation(hotel.id, hotel.mapPin, hotel.files)
                }
              />
            )}
          </Grid>
        </Collapse>
      </Grid>
      {activeAccommodation === hotel.id && (
        <div
          className={classes.bladeContainer}
          onMouseOver={() => {
            if (isMobile) setHovered(true);
          }}
          onMouseLeave={() => {
            if (isMobile) setHovered(false);
          }}>
          <InlineBlade
            key={activeAccommodation}
            id={hotel.id}
            name={hotel.name}
            hasDate={false}
            hasLink
            hasCost
            hasLocation
            address={hotel.streetAddress}
            notes={hotel.notes}
            open={activeAccommodation === hotel.id}
            setOpen={(open) => {
              setActiveAccommodation(open);
              if (isMobile) setHovered(open);
            }}
            locationBias={locationBias}
            isAccomodation
            hasCustomCalendar
            linkVal={hotel.links}
            mapPinId={hotel?.mapPin}
            updateLocationCallback={(locationAttributes) => {
              const { mapPin = null, ...location } = locationAttributes;
              updateAccomodationAttributes({
                ...location,
                ...(mapPin ? { mapPin: mapPin?.id, mapPinObj: mapPin } : {}),
              });
            }}
            updateLinkCallback={(updatedLink) =>
              updateAccomodationAttributes({ links: updatedLink })
            }
            updateNotesCallback={(notes) =>
              updateAccomodationAttributes({ notes })
            }
            costVal={{
              amount: isBooking
                ? parseFloat(booking?.bookingDetails?.price?.total)
                : hotel.cost,
              per: isBooking ? 1 : hotel.costPer,
              currency: isBooking
                ? booking?.bookingDetails?.price?.currency
                : hotel.currency,
            }}
            updateCostCallback={({ amount, currency, per }) =>
              updateAccomodationAttributes({
                cost: parseFloat(amount),
                costPer: parseInt(per, 10),
                currency,
              })
            }
            updateNameCallback={(name) =>
              updateAccomodationAttributes({ name })
            }
            accomodationProps={{
              checkinDate: hotel.checkInDate,
              checkoutDate: hotel.checkOutDate,
              isBooking,
              bookingStatus: booking?.status,
              bookingId: booking?.id,
              updateCheckinDate: (date) =>
                updateAccomodationAttributes({
                  checkInDate: removeTimezoneOffset(date)?.toISOString(),
                }),
              updateCheckoutDate: (date) =>
                updateAccomodationAttributes({
                  checkOutDate: removeTimezoneOffset(date)?.toISOString(),
                }),
              handleOpenBookingsModal: openHotelBookingsModal,
            }}
            updateAccommodation={(accommodation) =>
              updateAccomodationAttributes(accommodation)
            }
            fileAttachmentModalProps={{
              attachedToID: hotelId,
              attachedToType: 'Accommodation',
              attachedFiles: hotel?.files,
              attachedToText: hotel?.name,
              attachFunc: (newFiles, tripId) => {
                return new Promise((resolve, reject) => {
                  dispatch(
                    updateAccommodation({
                      variables: {
                        id: hotelId,
                        files: newFiles,
                      },
                      attachedToType: 'Accommodation',
                      tripId,
                    })
                  )
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
                });
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

export default AccommodationBlock;
