import { useState, useEffect } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';

const useCityPlaceId = () => {
  const [placeId, setPlaceId] = useState(null);
  const [cityName, setCityName] = useState('');

  const reset = () => {
    setPlaceId(null);
    setCityName('');
  };

  const {
    ready,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });

  useEffect(() => {
    if (cityName && ready) {
      setValue(cityName, true); // Fetch data immediately
    }
  }, [cityName, ready]);

  useEffect(() => {
    if (status === 'OK' && data.length > 0) {
      setPlaceId(data[0].place_id);
    }
  }, [status, data]);

  return { placeId, cityName, setCityName, reset };
};

export default useCityPlaceId;
