import { HotelRounded, SellOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { LightGreyButton } from '../../../atoms/Button/index';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import { getStaysSearchConfig } from '../Stays/bookingsUtils';

import actions from '../../../../redux/actions';
import classList from '../../../classList';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  spacing: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 0px',
  },
  addAccommodationButton: {
    fontSize: 14,
    width: '100%',
    whiteSpace: 'nowrap',
    [breakpoints.down('md')]: {
      flexBasis: '100%',
    },
    '&:hover': {
      backgroundColor: palette.primary.extraLight,
      color: palette.primary.main,
    },
  },

  viewStaysButtonContainer: {
    margin: '0% 0% 0px calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      margin: '0% 0% 0px calc(3.6rem + 15px + 12px)',
    },
  },
  draggedStyle: {
    margin: '0px 0px 0px 0px',
  },
}));

function AccommodationAddModule({ parentId, fullWidth }) {
  const classes = useStyles();
  const { slug: tripId } = useParams();
  const dispatch = useDispatch();
  const parent = useSelector((state) => state.Item.items[parentId]);
  const parentMapPin = useSelector(
    (state) => parent?.mapPin && state.MapV2.mapPins[tripId]?.[parent?.mapPin]
  );

  const navigate = useNavigate();

  const handlSearchDealButtonClick = () => {
    phTrackEvent({ event: EVENTS.PLAN_STAY.TRIP_LINK_CLICK });
    const { cityLocationDetails = null, date = null } = getStaysSearchConfig({
      fromDate: parent?.startDate || parent?.content?.startDate,
      toDate: parent?.endDate || parent?.content?.endDate,
      mapPin: parentMapPin,
      title: parent?.title,
    });
    dispatch(
      actions.Recommendations.setCity({
        city: {
          coordinates: {
            latitude: cityLocationDetails?.latitude,
            longitude: cityLocationDetails?.longitude,
          },
          title: cityLocationDetails?.title,
          placeId: cityLocationDetails?.placeId,
          redirect: true,
        },
        tripId,
      })
    );
    dispatch(
      actions.Bookings.triggerSearch({
        tripId,
        cityLocationDetails,
        date,
      })
    );
    navigate(`/trips/${tripId}/explore?focus=stays`);
  };

  const handleAddAccommodationButtonClick = () => {
    dispatch(actions.Item.setOpenItemSearchbar({ id: parentId }));
  };

  return (
    <div
      className={
        fullWidth ? classes.draggedStyle : classes.viewStaysButtonContainer
      }>
      <div
        style={{
          display: 'flex',
          columnGap: 10,
          rowGap: 4,
          margin: '8px 0px',
          flexWrap: 'wrap',
        }}>
        <LightGreyButton
          onClick={handleAddAccommodationButtonClick}
          className={`${classes.addAccommodationButton} ${classList.addAccommodationButton}`}
          sx={{
            flex: 1,
            border: `1px solid ${fullWidth ? '#D9D9D9' : 'transparent'}`,
          }}
          startIcon={<HotelRounded />}>
          Add Accommodations
        </LightGreyButton>

        <LightGreyButton
          onClick={handlSearchDealButtonClick}
          className={`${classes.addAccommodationButton} ${classList.hotelBookingsButton}`}
          sx={{
            flex: 1,
            border: `1px solid ${fullWidth ? '#D9D9D9' : 'transparent'}`,
            position: 'relative',
          }}
          startIcon={<SellOutlined sx={{ transform: 'rotate(90deg)' }} />}>
          Search Deals
        </LightGreyButton>
      </div>
    </div>
  );
}

export default AccommodationAddModule;
