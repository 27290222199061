import { gql } from '@apollo/client';

/**
 * This files describes the different queries defined to
 * interact with the graphql server.
 *
 * To use graphql in your components, checkout sample implementation
 * of Home.js (or) check out
 *
 * https://www.apollographql.com/docs/tutorial/queries/#fetching-a-list
 */

const GET_USER = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      handle
      handleChangeDate
      profilePicture
      coverImage
      location
      aboutMe
      email
      phone
      is24HourTime
      showPilotLabsPrompt
      disableSounds
      welcomeScreen
      trips {
        id
      }
      friends {
        id
      }
      notifications
      labDisabled
      disableTravelStats
      forwardedFiles {
        id
        name
        url
        size
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
        import {
          id
          items {
            attachedToItem
            type
            placeId
            accommodation {
              id
              name
              checkInDate
              checkOutDate
              streetAddress
              city
              state
              country
              zipCode
              cost
              currency
              costPer
              notes
              isBooking
              bookingId
            }
            activity {
              id
              title
              description
              cost
              currency
              costPer
              activityTime
              streetAddress
              city
              state
              country
              zipCode
            }
            flight {
              id
              flightNumber
              fromAirport
              toAirport
              fromDate
              fromTime
              toDate
              toTime
              flightTime
              cost
              currency
              costPer
              notes
            }
            transport {
              id
              type
              flightNumber
              fromAirport
              toAirport
              fromDate
              fromTime
              toDate
              toTime
              flightTime
              cost
              currency
              costPer
              notes
            }
          }
          status
          error
        }
      }
    }
  }
`;

const GET_USER_FORWARDED_FILES = gql`
  query getUserForwardedFiles($id: ID!) {
    getUser(id: $id) {
      forwardedFiles {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
        import {
          id
          updatedAt
          items {
            placeId
            attachedToItem
            type
            accommodation {
              id
              name
              checkInDate
              checkOutDate
              streetAddress
              city
              state
              country
              zipCode
              cost
              currency
              costPer
              notes
              isBooking
              bookingId
            }
            activity {
              id
              title
              description
              cost
              currency
              costPer
              activityTime
              streetAddress
              city
              state
              country
              zipCode
            }
            flight {
              id
              flightNumber
              fromAirport
              toAirport
              fromDate
              fromTime
              toDate
              toTime
              flightTime
              cost
              currency
              costPer
              notes
            }
            transport {
              id
              type
              flightNumber
              fromAirport
              toAirport
              fromDate
              fromTime
              toDate
              toTime
              flightTime
              cost
              currency
              costPer
              notes
            }
          }
          status
          error
        }
      }
    }
  }
`;

const CREATE_USER = gql`
  mutation (
    $id: ID!
    $firstName: String!
    $lastName: String
    $email: String!
    $welcomeScreen: Boolean!
  ) {
    createUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      welcomeScreen: $welcomeScreen
    ) {
      id
      firstName
    }
  }
`;

const UPDATE_USER = gql`
  mutation (
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $profilePicture: String
    $coverImage: String
    $handle: String
    $handleChangeDate: Date
    $location: String
    $aboutMe: String
    $is24HourTime: Boolean
    $showPilotLabsPrompt: Boolean
    $disableSounds: Boolean
    $welcomeScreen: Boolean
    $trips: [ID!]
    $friends: [ID!]
    $notifications: [ID!]
    $labDisabled: Boolean
    $disableTravelStats: Boolean
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      profilePicture: $profilePicture
      coverImage: $coverImage
      handle: $handle
      handleChangeDate: $handleChangeDate
      location: $location
      aboutMe: $aboutMe
      is24HourTime: $is24HourTime
      showPilotLabsPrompt: $showPilotLabsPrompt
      disableSounds: $disableSounds
      welcomeScreen: $welcomeScreen
      trips: $trips
      friends: $friends
      notifications: $notifications
      labDisabled: $labDisabled
      disableTravelStats: $disableTravelStats
    ) {
      id
      firstName
      welcomeScreen
      coverImage
    }
  }
`;

const UPDATE_WELCOME_SCREEN = gql`
  mutation ($id: ID!, $welcomeScreen: Boolean!) {
    updateWelcomeScreen(id: $id, welcomeScreen: $welcomeScreen) {
      id
      welcomeScreen
    }
  }
`;

const ADD_EXAMPLE_TRIP = gql`
  mutation ($userId: ID!) {
    addExampleTrip(userId: $userId)
  }
`;

const DELETE_USER = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

const GET_USER_HANDLE = gql`
  query getUserHandle($handleName: String!) {
    getUserHandle(handleName: $handleName) {
      userId {
        id
      }
    }
  }
`;

const CREATE_USER_HANDLE = gql`
  mutation ($handleName: String!, $userId: ID!) {
    createUserHandle(handleName: $handleName, userId: $userId) {
      userId {
        id
      }
    }
  }
`;

const UPDATE_USER_HANDLE = gql`
  mutation ($oldHandleName: String!, $newHandleName: String!) {
    updateUserHandle(
      oldHandleName: $oldHandleName
      newHandleName: $newHandleName
    ) {
      userId {
        id
      }
    }
  }
`;

const DELETE_USER_HANDLE = gql`
  mutation ($handleName: String!) {
    deleteUserHandle(handleName: $handleName)
  }
`;

const GET_SHARED_TRIPS = gql`
  query getSharedTrips($userId: ID!) {
    getSharedTrips(userId: $userId) {
      id
      title
      coverImage
      startDate
      endDate
      owner {
        id
        firstName
        lastName
        profilePicture
        email
      }
      sharedUsers {
        id
        firstName
        lastName
        profilePicture
      }
      invitedUsers {
        id
        email
        createdDate
      }
    }
  }
`;

const GET_ALL_TRIPS = gql`
  query getAllTrips($userId: ID!) {
    getAllTrips(userId: $userId) {
      id
      title
      coverImage
      startDate
      endDate
      owner {
        id
        firstName
        lastName
        profilePicture
      }
      sharedUsers {
        id
        firstName
        lastName
        profilePicture
      }
      defaultCurrency
      invitedUsers {
        id
        email
        createdDate
      }
    }
  }
`;

const GET_TRIP = gql`
  query getTrip($id: ID!) {
    getTrip(id: $id) {
      id
      title
      version
      owner {
        id
        firstName
        lastName
        profilePicture
        email
      }
      mapPins {
        id
        lat
        long
        pinData
        type
        placeId
        hotelId
        categoryId
        pinColor
      }
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
        import {
          id
          updatedAt
          items {
            placeId
            attachedToItem
            type
            accommodation {
              id
              name
              checkInDate
              checkOutDate
              streetAddress
              city
              state
              country
              zipCode
              cost
              currency
              costPer
              notes
              isBooking
              bookingId
            }
            activity {
              id
              title
              description
              cost
              currency
              costPer
              activityTime
              streetAddress
              city
              state
              country
              zipCode
            }
            flight {
              id
              flightNumber
              fromAirport
              toAirport
              fromDate
              fromTime
              toDate
              toTime
              flightTime
              cost
              currency
              costPer
              notes
            }
            transport {
              id
              type
              flightNumber
              fromAirport
              toAirport
              fromDate
              fromTime
              toDate
              toTime
              flightTime
              cost
              currency
              costPer
              notes
            }
          }
          status
          error
        }
      }
      importedFiles {
        id
        name
        url
        size
        createdDate
        type
        attachedToType
        attachedToID
      }
      saved {
        id
        thumbnail
        longitude
        latitude
        title
        referenceId
        priceTier
        savedData
        type
      }
      items {
        __typename
        ... on Location {
          tripID
          id
          name
          version
          mapPin {
            id
            lat
            long
            pinData
            type
            placeId
            hotelId
            categoryId
            pinColor
          }
          hotels {
            id
            type
            name
            checkInDate
            checkOutDate
            streetAddress
            city
            state
            country
            zipCode
            cost
            currency
            links
            notes
            costPer
            bookingId
            isBooking
            mapPin {
              id
              lat
              long
              pinData
              type
              placeId
              hotelId
              categoryId
              pinColor
            }
            files {
              id
              name
              url
              size
              createdDate
              createdBy {
                id
                firstName
                lastName
              }
              type
              attachedToType
              attachedToID
              import {
                id
                items {
                  attachedToItem
                  type
                  placeId
                  accommodation {
                    id
                    name
                    checkInDate
                    checkOutDate
                    streetAddress
                    city
                    state
                    country
                    zipCode
                    cost
                    currency
                    costPer
                    notes
                    isBooking
                    bookingId
                  }
                  activity {
                    id
                    title
                    description
                    cost
                    currency
                    costPer
                    activityTime
                    streetAddress
                    city
                    state
                    country
                    zipCode
                  }
                  flight {
                    id
                    flightNumber
                    fromAirport
                    toAirport
                    fromDate
                    fromTime
                    toDate
                    toTime
                    flightTime
                    cost
                    currency
                    costPer
                    notes
                  }
                  transport {
                    id
                    type
                    flightNumber
                    fromAirport
                    toAirport
                    fromDate
                    fromTime
                    toDate
                    toTime
                    flightTime
                    cost
                    currency
                    costPer
                    notes
                  }
                }
                status
                error
              }
            }
          }
          thingsToDo {
            id
            name
            version
            startDate
            endDate
            todos {
              id
              title
              description
              cost
              currency
              costPer
              links
              streetAddress
              city
              state
              country
              zipCode
              likes {
                id
              }
              activityTime
              locations
              version
              mapPin {
                id
                lat
                long
                pinData
                type
                placeId
                hotelId
                categoryId
                pinColor
              }
              files {
                id
                name
                url
                size
                createdDate
                createdBy {
                  id
                  firstName
                  lastName
                }
                type
                attachedToType
                attachedToID
                import {
                  id
                  items {
                    attachedToItem
                    type
                    placeId
                    accommodation {
                      id
                      name
                      checkInDate
                      checkOutDate
                      streetAddress
                      city
                      state
                      country
                      zipCode
                      cost
                      currency
                      costPer
                      notes
                      isBooking
                      bookingId
                    }
                    activity {
                      id
                      title
                      description
                      cost
                      currency
                      costPer
                      activityTime
                      streetAddress
                      city
                      state
                      country
                      zipCode
                    }
                    flight {
                      id
                      flightNumber
                      fromAirport
                      toAirport
                      fromDate
                      fromTime
                      toDate
                      toTime
                      flightTime
                      cost
                      currency
                      costPer
                      notes
                    }
                    transport {
                      id
                      type
                      flightNumber
                      fromAirport
                      toAirport
                      fromDate
                      fromTime
                      toDate
                      toTime
                      flightTime
                      cost
                      currency
                      costPer
                      notes
                    }
                  }
                  status
                  error
                }
              }
            }
          }
          description
          links
          arrivalDate
          departureDate
          locations
        }
        ... on Transportation {
          id
          trip
          type
          title
          notes
          date
          users {
            user
            option
          }
          details {
            id
            flightNumber
            fromAirport
            toAirport
            fromDate
            version
            fromTime
            toDate
            toTime
            flightTime
            cost
            costPer
            currency
            link
            notes
            files {
              id
              name
              url
              size
              createdDate
              createdBy {
                id
                firstName
                lastName
              }
              type
              attachedToType
              attachedToID
              import {
                id
                items {
                  attachedToItem
                  type
                  placeId
                  accommodation {
                    id
                    name
                    checkInDate
                    checkOutDate
                    streetAddress
                    city
                    state
                    country
                    zipCode
                    cost
                    currency
                    costPer
                    notes
                    isBooking
                    bookingId
                  }
                  activity {
                    id
                    title
                    description
                    cost
                    currency
                    costPer
                    activityTime
                    streetAddress
                    city
                    state
                    country
                    zipCode
                  }
                  flight {
                    id
                    flightNumber
                    fromAirport
                    toAirport
                    fromDate
                    fromTime
                    toDate
                    toTime
                    flightTime
                    cost
                    currency
                    costPer
                    notes
                  }
                  transport {
                    id
                    type
                    flightNumber
                    fromAirport
                    toAirport
                    fromDate
                    fromTime
                    toDate
                    toTime
                    flightTime
                    cost
                    currency
                    costPer
                    notes
                  }
                }
                status
                error
              }
            }
          }
          cost
          currency
          link
          files {
            id
            name
            url
            size
            createdDate
            createdBy {
              id
              firstName
              lastName
            }
            type
            attachedToType
            attachedToID
            import {
              id
              items {
                attachedToItem
                type
                placeId
                accommodation {
                  id
                  name
                  checkInDate
                  checkOutDate
                  streetAddress
                  city
                  state
                  country
                  zipCode
                  cost
                  currency
                  costPer
                  notes
                  isBooking
                  bookingId
                }
                activity {
                  id
                  title
                  description
                  cost
                  currency
                  costPer
                  activityTime
                  streetAddress
                  city
                  state
                  country
                  zipCode
                }
                flight {
                  id
                  flightNumber
                  fromAirport
                  toAirport
                  fromDate
                  fromTime
                  toDate
                  toTime
                  flightTime
                  cost
                  currency
                  costPer
                  notes
                }
                transport {
                  id
                  type
                  flightNumber
                  fromAirport
                  toAirport
                  fromDate
                  fromTime
                  toDate
                  toTime
                  flightTime
                  cost
                  currency
                  costPer
                  notes
                }
              }
              status
              error
            }
          }
        }
      }
      sharedUsers {
        id
        firstName
        lastName
        profilePicture
      }
      invitedUsers {
        id
        email
        createdDate
      }
      bookings {
        id
        packageId
        reference
        orderId
        segmentId
        status
        bookingDetails {
          hotelId
          checkInDate
          checkOutDate
          latitude
          longitude
          name
          image
          address
          city
          state
          zipCode
          country
          roomsCount
          users {
            id
            firstName
            lastName
            type
          }
          rooms {
            roomId
            type
            name
            bed
            basis
            price
            currency
            amenities
            images
          }
          cxlPolicy {
            isRefundable
            refundableUntil
            policies {
              fromDate
              toDate
              cancellationFee
              currency
            }
            remarks
          }
          price {
            total
            taxes
            currency
          }
        }
      }
      coverImage
      startDate
      endDate
      notes
      defaultCurrency
      displayTimezoneFixPopup
    }
  }
`;

const GET_TRIP_BASIC = gql`
  query getTripBasic($id: ID!) {
    getTripMeta(id: $id) {
      id
      title
      firstLocation {
        name
      }
      owner {
        id
        firstName
        lastName
        profilePicture
      }
      sharedUsers {
        id
        firstName
        lastName
        profilePicture
      }
      coverImage
      startDate
      endDate
    }
  }
`;

const CREATE_TRIP = gql`
  mutation (
    $title: String!
    $coverImage: String!
    $owner: ID!
    $startDate: Date
    $endDate: Date
    $defaultCurrency: String
  ) {
    createTrip(
      title: $title
      coverImage: $coverImage
      owner: $owner
      startDate: $startDate
      endDate: $endDate
      defaultCurrency: $defaultCurrency
    ) {
      id
    }
  }
`;

const UPDATE_TRIP = gql`
  mutation (
    $id: ID!
    $items: [ItemInput!]
    $title: String
    $owner: ID
    $sharedUsers: [ID!]
    $invitedUsers: [ID!]
    $coverImage: String
    $locations: [ID!]
    $startDate: Date
    $endDate: Date
    $notes: [ID!]
    $files: [ID!]
    $defaultCurrency: String
  ) {
    updateTrip(
      id: $id
      title: $title
      owner: $owner
      items: $items
      sharedUsers: $sharedUsers
      invitedUsers: $invitedUsers
      coverImage: $coverImage
      locations: $locations
      startDate: $startDate
      endDate: $endDate
      notes: $notes
      files: $files
      defaultCurrency: $defaultCurrency
    ) {
      id
      coverImage
      startDate
      version
      endDate
      items {
        __typename
        ... on Location {
          tripID
          id
          name
          version
          hotels {
            id
            type
            name
            checkInDate
            checkOutDate
            streetAddress
            bookingId
            isBooking
            city
            state
            country
            zipCode
            cost
            currency
            links
            notes
            costPer
            mapPin {
              id
              lat
              long
              pinData
              type
              placeId
              hotelId
              categoryId
              pinColor
            }
          }
          thingsToDo {
            id
            name
            version
            startDate
            endDate
            todos {
              id
              title
              description
              cost
              currency
              costPer
              links
              likes {
                id
              }
              activityTime
              locations
              streetAddress
              city
              state
              country
              zipCode
              version
            }
          }
          description
          links
          arrivalDate
          departureDate
          locations
        }
        ... on Transportation {
          id
          trip
          type
          date
          notes
          title
          users {
            user
            option
          }
          details {
            id
            flightNumber
            fromAirport
            toAirport
            fromDate
            version
            fromTime
            toDate
            toTime
          }
          cost
          currency
          link
        }
      }
      sharedUsers {
        id
      }
      invitedUsers {
        id
        email
        createdDate
      }
      notes
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
      }
      defaultCurrency
      displayTimezoneFixPopup
    }
  }
`;

const UPDATE_TRIP_NAME = gql`
  mutation ($id: ID!, $title: String!) {
    updateTripName(id: $id, title: $title) {
      id
      title
    }
  }
`;

const DELETE_TRIP = gql`
  mutation ($id: ID!) {
    deleteTrip(id: $id)
  }
`;

const GET_LOCATION = gql`
  query getLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      description
      hotels {
        id
        type
        name
        checkInDate
        checkOutDate
        streetAddress
        bookingId
        isBooking
        city
        state
        country
        zipCode
        cost
        currency
        links
        notes
        costPer
      }
      thingsToDo {
        id
      }
      links
      arrivalDate
      departureDate
      locations
      tripID
      version
    }
  }
`;

const CREATE_LOCATION = gql`
  mutation createLocation(
    $name: String!
    $description: String
    $arrivalDate: Date
    $departureDate: Date
    $links: [String!]
    $locations: [String!]
    $tripID: ID!
    $mapPin: ID
    $index: Int
  ) {
    createLocation(
      name: $name
      description: $description
      arrivalDate: $arrivalDate
      departureDate: $departureDate
      links: $links
      locations: $locations
      tripID: $tripID
      mapPin: $mapPin
      index: $index
    ) {
      id
      version
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation (
    $id: ID!
    $name: String
    $description: String
    $arrivalDate: Date
    $departureDate: Date
    $links: [String!]
    $locations: [String!]
    $thingsToDo: [ID!]
    $hotels: [ID!]
    $mapPin: ID
    $oldMapPin: ID
  ) {
    updateLocation(
      id: $id
      name: $name
      description: $description
      arrivalDate: $arrivalDate
      departureDate: $departureDate
      links: $links
      locations: $locations
      thingsToDo: $thingsToDo
      hotels: $hotels
      mapPin: $mapPin
      oldMapPin: $oldMapPin
    ) {
      id
      name
      description
      arrivalDate
      departureDate
      links
      locations
      hotels {
        id
      }
      thingsToDo {
        id
      }
      mapPin {
        id
        lat
        long
        pinData
        type
        placeId
        hotelId
        categoryId
        pinColor
      }
      version
    }
  }
`;

const DELETE_LOCATION = gql`
  mutation deleteLocation($id: ID!, $tripId: ID!) {
    deleteLocation(id: $id, tripId: $tripId)
  }
`;

const GET_TRANSPORTATION = gql`
  query getTransportation($id: ID!) {
    getTransportation(id: $id) {
      id
      trip
      type
      title
      notes
      date
      users {
        user
        option
      }
      details {
        id
        flightNumber
        fromAirport
        toAirport
        fromDate
        version
        fromTime
        toDate
        toTime
        flightTime
        cost
        costPer
        currency
        link
        notes
        files {
          id
          name
          url
          size
          createdDate
          createdBy {
            id
            firstName
            lastName
          }
          type
          attachedToType
          attachedToID
        }
      }
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
      }
    }
  }
`;

const GET_TRANSPORTATION_FOR_TRIP = gql`
  query ($tripId: ID!) {
    getTransportationForTrip(tripId: $tripId) {
      id
      trip
      type
      users {
        user
        option
      }
      details {
        id
        flightNumber
        fromAirport
        toAirport
        fromDate
        version
        fromTime
        toDate
        toTime
        flightTime
        cost
        costPer
        currency
        link
        notes
      }
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
      }
    }
  }
`;

const CREATE_TRANSPORTATION = gql`
  mutation createTransportation(
    $users: [UserAttendanceInput!]
    $trip: ID
    $type: String!
    $details: [ID!]
    $cost: Float
    $currency: String
    $title: String
    $notes: String
    $date: Date
    $link: String
    $costPer: Int
    $files: [ID!]
    $index: Int
  ) {
    createTransportation(
      users: $users
      trip: $trip
      type: $type
      details: $details
      cost: $cost
      currency: $currency
      notes: $notes
      title: $title
      date: $date
      link: $link
      costPer: $costPer
      files: $files
      index: $index
    ) {
      id
      type
      details {
        id
        flightNumber
        fromAirport
        toAirport
        fromDate
        version
        fromTime
        toDate
        toTime
        flightTime
        cost
        costPer
        currency
        link
        notes
      }
    }
  }
`;

const UPDATE_TRANSPORTATION = gql`
  mutation (
    $id: ID!
    $users: [UserAttendanceInput!]
    $trip: ID
    $details: [ID!]
    $cost: Float
    $currency: String
    $title: String
    $notes: String
    $date: Date
    $link: String
    $costPer: Int
    $files: [ID!]
  ) {
    updateTransportation(
      id: $id
      users: $users
      trip: $trip
      details: $details
      cost: $cost
      currency: $currency
      link: $link
      notes: $notes
      title: $title
      date: $date
      costPer: $costPer
      files: $files
    ) {
      id
      users {
        user
        option
      }
      trip
      details {
        id
        flightNumber
        fromAirport
        toAirport
        fromDate
        version
        fromTime
        toDate
        toTime
        flightTime
        cost
        costPer
        currency
        link
        notes
        files {
          id
          name
          url
          size
          createdDate
          createdBy {
            id
            firstName
            lastName
          }
          type
          attachedToType
          attachedToID
        }
      }
      cost
      currency
      notes
      link
      date
      costPer
      title
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
      }
    }
  }
`;

const DELETE_TRANSPORTATION = gql`
  mutation deleteTransportation($id: ID!, $filesToBeAdded: [ID], $tripId: ID) {
    deleteTransportation(
      id: $id
      filesToBeAdded: $filesToBeAdded
      tripId: $tripId
    )
  }
`;

const CREATE_FLIGHT = gql`
  mutation createFlight(
    $flightNumber: String
    $fromAirport: String
    $toAirport: String
    $fromDate: Date
    $fromTime: String
    $toDate: Date
    $toTime: String
    $flightTime: String
    $cost: Float
    $currency: String
    $notes: String
    $link: String
    $costPer: Int
    $files: [ID!]
    $transportId: ID
  ) {
    createFlight(
      flightNumber: $flightNumber
      fromAirport: $fromAirport
      toAirport: $toAirport
      fromDate: $fromDate
      fromTime: $fromTime
      toDate: $toDate
      toTime: $toTime
      flightTime: $flightTime
      cost: $cost
      currency: $currency
      link: $link
      notes: $notes
      costPer: $costPer
      files: $files
      transportId: $transportId
    ) {
      id
      flightNumber
      fromAirport
      toAirport
      fromDate
      version
      fromTime
      toDate
      toTime
      flightTime
      cost
      currency
      link
      notes
      costPer
      files {
        id
      }
    }
  }
`;

const UPDATE_FLIGHT = gql`
  mutation updateFlight(
    $id: ID!
    $flightNumber: String
    $fromAirport: String
    $toAirport: String
    $fromDate: Date
    $fromTime: String
    $toDate: Date
    $toTime: String
    $flightTime: String
    $cost: Float
    $currency: String
    $notes: String
    $link: String
    $costPer: Int
    $files: [ID!]
  ) {
    updateFlight(
      id: $id
      flightNumber: $flightNumber
      fromAirport: $fromAirport
      toAirport: $toAirport
      fromDate: $fromDate
      fromTime: $fromTime
      toDate: $toDate
      toTime: $toTime
      flightTime: $flightTime
      cost: $cost
      currency: $currency
      link: $link
      notes: $notes
      costPer: $costPer
      files: $files
    ) {
      id
      flightNumber
      fromAirport
      toAirport
      fromDate
      version
      fromTime
      toDate
      toTime
      flightTime
      cost
      currency
      link
      notes
      costPer
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
      }
    }
  }
`;

const DELETE_FLIGHT = gql`
  mutation deleteFlight($id: ID!, $transportId: ID) {
    deleteFlight(id: $id, transportId: $transportId)
  }
`;

const GET_ACCOMMODATION = gql`
  query getAccommodation($id: ID!) {
    getAccommodation(id: $id) {
      id
      user {
        id
      }
      trip {
        id
      }
      type
      name
      checkInDate
      checkOutDate
      streetAddress
      bookingId
      isBooking
      city
      state
      country
      zipCode
      cost
      currency
      links
      notes
      costPer
      mapPin {
        id
        lat
        long
        pinData
        type
        placeId
        hotelId
        categoryId
        pinColor
      }
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
      }
    }
  }
`;

const GET_ACCOMMODATION_FOR_TRIP = gql`
  query getAccommodationForTrip($tripId: ID!) {
    getAccommodationForTrip(tripId: tripId) {
      user {
        id
      }
      trip {
        id
      }
      type
      name
      checkInDate
      checkOutDate
      streetAddress
      city
      state
      country
      zipCode
      cost
      currency
      links
      notes
      costPer
      bookingId
      isBooking
      mapPin {
        id
        lat
        long
        pinData
        type
        placeId
        hotelId
        categoryId
        pinColor
      }
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
      }
    }
  }
`;

const CREATE_ACCOMMODATION = gql`
  mutation createAccommodation(
    $user: [UserAttendanceInput!]
    $trip: ID
    $type: Int
    $name: String
    $checkInDate: Date
    $checkOutDate: Date
    $streetAddress: String
    $city: String
    $state: String
    $country: String
    $zipCode: String
    $cost: Float
    $currency: String
    $links: String
    $notes: String
    $costPer: Int
    $mapPin: String
    $files: [ID!]
    $index: Int
    $locationId: ID
    $bookingId: ID
    $isBooking: Boolean
  ) {
    createAccommodation(
      user: $user
      trip: $trip
      type: $type
      name: $name
      checkInDate: $checkInDate
      checkOutDate: $checkOutDate
      streetAddress: $streetAddress
      city: $city
      state: $state
      country: $country
      zipCode: $zipCode
      cost: $cost
      currency: $currency
      links: $links
      notes: $notes
      costPer: $costPer
      mapPin: $mapPin
      files: $files
      locationId: $locationId
      index: $index
      bookingId: $bookingId
      isBooking: $isBooking
    ) {
      id
      type
      name
      checkInDate
      checkOutDate
      streetAddress
      city
      state
      country
      zipCode
      cost
      currency
      links
      notes
      costPer
      bookingId
      isBooking
      mapPin {
        id
        lat
        long
        pinData
        type
        placeId
        hotelId
        categoryId
        pinColor
      }
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
      }
    }
  }
`;

const UPDATE_ACCOMMODATION = gql`
  mutation (
    $id: ID!
    $user: [UserAttendanceInput!]
    $trip: ID
    $type: Int
    $name: String
    $checkInDate: Date
    $checkOutDate: Date
    $streetAddress: String
    $city: String
    $state: String
    $country: String
    $zipCode: String
    $cost: Float
    $currency: String
    $links: String
    $notes: String
    $costPer: Int
    $mapPin: String
    $oldMapPin: String
    $files: [ID!]
  ) {
    updateAccommodation(
      id: $id
      user: $user
      trip: $trip
      type: $type
      name: $name
      checkInDate: $checkInDate
      checkOutDate: $checkOutDate
      streetAddress: $streetAddress
      city: $city
      state: $state
      country: $country
      zipCode: $zipCode
      cost: $cost
      currency: $currency
      links: $links
      notes: $notes
      costPer: $costPer
      mapPin: $mapPin
      oldMapPin: $oldMapPin
      files: $files
    ) {
      id
      type
      name
      checkInDate
      checkOutDate
      streetAddress
      city
      state
      country
      zipCode
      cost
      currency
      links
      notes
      costPer
      bookingId
      isBooking
      mapPin {
        id
        lat
        long
        pinData
        type
        placeId
        hotelId
        categoryId
        pinColor
      }
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
      }
    }
  }
`;

const DELETE_ACCOMMODATION = gql`
  mutation deleteAccommodation(
    $id: ID!
    $mapPin: ID
    $filesToBeAdded: [ID]
    $tripId: ID
    $locationId: ID
  ) {
    deleteAccommodation(
      id: $id
      mapPin: $mapPin
      filesToBeAdded: $filesToBeAdded
      tripId: $tripId
      locationId: $locationId
    )
  }
`;

const GET_THINGS_TODO = gql`
  query getThingsTodo($id: ID!) {
    getThingsToDo(id: $id) {
      id
      name
      version
      startDate
      endDate
      todos {
        id
        title
        description
        cost
        currency
        costPer
        links
        likes {
          id
        }
        activityTime
        locations
        streetAddress
        city
        state
        country
        zipCode
        version
      }
    }
  }
`;

const CREATE_THINGS_TODO = gql`
  mutation createThingsToDo(
    $locationId: ID!
    $name: String!
    $startDate: Date
    $endDate: Date
    $todos: [ID!]
    $index: Int
  ) {
    createThingsToDo(
      locationId: $locationId
      name: $name
      startDate: $startDate
      endDate: $endDate
      todos: $todos
      index: $index
    ) {
      id
      version
    }
  }
`;

const UPDATE_THINGS_TODO = gql`
  mutation updateThingsToDo(
    $id: ID!
    $name: String
    $startDate: Date
    $endDate: Date
    $todos: [ID!]
  ) {
    updateThingsToDo(
      id: $id
      name: $name
      startDate: $startDate
      endDate: $endDate
      todos: $todos
    ) {
      id
      version
      todos {
        id
        mapPin {
          id
        }
      }
    }
  }
`;

const DELETE_THINGS_TODO = gql`
  mutation deleteThingsToDo($locationId: ID!, $id: ID!) {
    deleteThingsToDo(locationId: $locationId, id: $id)
  }
`;

const CREATE_TODO = gql`
  mutation (
    $thingsToDoId: ID
    $title: String!
    $description: String
    $cost: Float
    $currency: String
    $costPer: Int
    $links: String
    $likes: [ID!]
    $activityTime: Date
    $mapPin: ID
    $files: [ID!]
    $streetAddress: String
    $city: String
    $state: String
    $country: String
    $zipCode: String
    $index: Int
  ) {
    createTodo(
      thingsToDoId: $thingsToDoId
      title: $title
      description: $description
      cost: $cost
      currency: $currency
      costPer: $costPer
      links: $links
      likes: $likes
      activityTime: $activityTime
      mapPin: $mapPin
      files: $files
      streetAddress: $streetAddress
      city: $city
      state: $state
      country: $country
      zipCode: $zipCode
      index: $index
    ) {
      id
      version
      streetAddress
      mapPin {
        id
        lat
        long
        pinData
        type
        placeId
        hotelId
        categoryId
        pinColor
      }
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
      }
    }
  }
`;

const UPDATE_TODO = gql`
  mutation (
    $id: ID!
    $thingsToDoId: ID
    $title: String
    $description: String
    $cost: Float
    $currency: String
    $costPer: Int
    $links: String
    $likes: [ID!]
    $activityTime: Date
    $locations: String
    $mapPin: ID
    $files: [ID!]
    $streetAddress: String
    $city: String
    $state: String
    $country: String
    $zipCode: String
  ) {
    updateTodo(
      id: $id
      thingsToDoId: $thingsToDoId
      title: $title
      description: $description
      cost: $cost
      currency: $currency
      costPer: $costPer
      links: $links
      likes: $likes
      activityTime: $activityTime
      locations: $locations
      mapPin: $mapPin
      files: $files
      streetAddress: $streetAddress
      city: $city
      state: $state
      country: $country
      zipCode: $zipCode
    ) {
      id
      title
      description
      cost
      currency
      costPer
      links
      version
      likes {
        id
      }
      activityTime
      mapPin {
        id
        lat
        long
        pinData
        type
        placeId
        hotelId
        categoryId
        pinColor
      }
      locations
      streetAddress
      city
      state
      country
      zipCode
      files {
        id
        name
        url
        size
        createdDate
        createdBy {
          id
          firstName
          lastName
        }
        type
        attachedToType
        attachedToID
      }
    }
  }
`;

const DELETE_TODO = gql`
  mutation ($id: ID!, $filesToBeAdded: [ID], $tripId: ID, $thingsToDoId: ID) {
    deleteTodo(
      id: $id
      filesToBeAdded: $filesToBeAdded
      tripId: $tripId
      thingsToDoId: $thingsToDoId
    )
  }
`;

const GET_FRIENDS = gql`
  query getFriends($friendOf: ID!) {
    getFriends(friendOf: $friendOf) {
      user {
        id
      }
      firstName
      lastName
      profilePicture
      privacy
    }
  }
`;
const GET_FRIENDS_FOR_USER = gql`
  query getFriendsForUser($id: ID!) {
    getUser(id: $id) {
      friends {
        user {
          id
          firstName
          lastName
          location
        }
      }
    }
  }
`;

/* WISHLIST */

const CREATE_WISHLIST = gql`
  mutation (
    $title: String!
    $owner: ID
    $coverImage: String
    $savedData: String
    $type: String
    $relationId: String
  ) {
    createWishlist(
      title: $title
      owner: $owner
      coverImage: $coverImage
      savedData: $savedData
      type: $type
      relationId: $relationId
    ) {
      id
    }
  }
`;

const UPDATE_WISHLIST = gql`
  mutation (
    $id: ID!
    $title: String
    $owner: ID
    $coverImage: String
    $likes: [ID!]
    $saves: [ID!]
    $privacy: String
  ) {
    updateWishlist(
      id: $id
      title: $title
      owner: $owner
      coverImage: $coverImage
      likes: $likes
      saves: $saves
      privacy: $privacy
    ) {
      id
    }
  }
`;

const DELETE_WISHLIST = gql`
  mutation ($id: ID!) {
    deleteWishlist(id: $id)
  }
`;

const GET_WISHLISTS = gql`
  query ($userId: ID!) {
    getWishlists(userId: $userId) {
      id
      title
      coverImage
      likes {
        id
        firstName
      }
      saves {
        id
        firstName
      }
      privacy
      type
      relationId
      savedData
    }
  }
`;

const GET_WISHLIST = gql`
  query ($id: ID!) {
    getWishlist(id: $id) {
      likes {
        id
        firstName
      }
      saves {
        id
        firstName
      }
    }
  }
`;

const SEARCH_QUERY = gql`
  query ($searchQuery: String) {
    userSearch(searchQuery: $searchQuery) {
      id
      firstName
      lastName
    }
  }
`;

const GET_USERID_FROM_HANDLE = gql`
  query getUserHandle($userHandle: String!) {
    getUserHandle(handleName: $userHandle) {
      userId {
        id
      }
    }
  }
`;

const CREATE_TRIP_MAP_PIN = gql`
  mutation createTripMapPin(
    $tripId: ID!
    $lat: Float
    $long: Float
    $pinData: String
    $type: String
    $placeId: String
    $categoryId: String
    $pinColor: String
  ) {
    createTripMapPin(
      tripId: $tripId
      lat: $lat
      long: $long
      pinData: $pinData
      type: $type
      placeId: $placeId
      categoryId: $categoryId
      pinColor: $pinColor
    ) {
      id
      lat
      long
      pinData
      type
      tripId
      placeId
      hotelId
      categoryId
      pinColor
    }
  }
`;

const CREATE_MAP_PIN = gql`
  mutation createMapPin(
    $lat: Float
    $long: Float
    $pinData: String
    $type: String
    $placeId: String
    $hotelId: String
    $categoryId: String
    $pinColor: String
    $tripId: ID
  ) {
    createMapPin(
      lat: $lat
      long: $long
      pinData: $pinData
      type: $type
      placeId: $placeId
      hotelId: $hotelId
      categoryId: $categoryId
      pinColor: $pinColor
      tripId: $tripId
    ) {
      id
      lat
      long
      pinData
      type
      placeId
      hotelId
      categoryId
      pinColor
      tripId
    }
  }
`;

const UPDATE_MAP_PIN = gql`
  mutation updateMapPin(
    $id: ID!
    $lat: Float
    $long: Float
    $pinData: String
    $type: String
    $placeId: String
    $categoryId: String
    $pinColor: String
  ) {
    updateMapPin(
      id: $id
      lat: $lat
      long: $long
      pinData: $pinData
      type: $type
      placeId: $placeId
      categoryId: $categoryId
      pinColor: $pinColor
    ) {
      id
      type
      pinData
      lat
      long
      placeId
      hotelId
      categoryId
      pinColor
    }
  }
`;

const DELETE_TRIP_MAP_PIN = gql`
  mutation deleteTripMapPin($tripId: ID!, $id: ID!) {
    deleteTripMapPin(tripId: $tripId, id: $id) {
      id
      mapPins {
        id
      }
    }
  }
`;

const DELETE_MAP_PIN = gql`
  mutation deleteMapPin($id: ID!, $itemId: ID, $itemType: String) {
    deleteMapPin(id: $id, itemId: $itemId, itemType: $itemType)
  }
`;

const GET_MAP_PIN = gql`
  query getMapPin($id: ID!) {
    getMapPin(id: $id) {
      id
      type
      pinData
      lat
      long
      placeId
      hotelId
      categoryId
      pinColor
    }
  }
`;

const GET_FILE = gql`
  query getFile($id: ID!) {
    getFile(id: $id) {
      id
      name
      url
      size
      createdDate
      createdBy {
        id
        firstName
        lastName
      }
      type
      attachedToType
      attachedToID
      import {
        id
        items {
          attachedToItem
          type
          placeId
          accommodation {
            id
            name
            checkInDate
            checkOutDate
            streetAddress
            city
            state
            country
            zipCode
            cost
            currency
            costPer
            notes
            isBooking
            bookingId
          }
          activity {
            id
            title
            description
            cost
            currency
            costPer
            activityTime
            streetAddress
            city
            state
            country
            zipCode
          }
          flight {
            id
            flightNumber
            fromAirport
            toAirport
            fromDate
            fromTime
            toDate
            toTime
            flightTime
            cost
            currency
            costPer
            notes
          }
          transport {
            id
            type
            flightNumber
            fromAirport
            toAirport
            fromDate
            fromTime
            toDate
            toTime
            flightTime
            cost
            currency
            costPer
            notes
          }
          status
          error
        }
      }
    }
  }
`;

const CREATE_FILE = gql`
  mutation createFile(
    $name: String!
    $url: String
    $size: Int!
    $createdDate: Date!
    $type: String!
    $attachedToType: String
    $attachedToID: String
    $mimeType: String
    $createdBy: ID
  ) {
    createFile(
      name: $name
      url: $url
      size: $size
      createdDate: $createdDate
      type: $type
      attachedToType: $attachedToType
      attachedToID: $attachedToID
      mimeType: $mimeType
      createdBy: $createdBy
    ) {
      id
      name
      url
      size
      createdDate
      createdBy {
        id
        firstName
        lastName
      }
      type
      attachedToType
      attachedToID
      mimeType
    }
  }
`;

const UPDATE_FILE = gql`
  mutation updateFile(
    $id: ID!
    $name: String
    $url: String
    $storagePath: String
    $attachedToType: String
    $attachedToID: String
    $mimeType: String
  ) {
    updateFile(
      id: $id
      name: $name
      url: $url
      storagePath: $storagePath
      attachedToID: $attachedToID
      attachedToType: $attachedToType
      mimeType: $mimeType
    ) {
      id
      name
      url
      size
      createdDate
      createdBy {
        id
        firstName
        lastName
      }
      type
      attachedToType
      attachedToID
      mimeType
    }
  }
`;

const DELETE_FILE = gql`
  mutation deleteFile(
    $id: ID!
    $fileRelation: [FileRelationInput]
    $isImported: Boolean
  ) {
    deleteFile(id: $id, fileRelation: $fileRelation, isImported: $isImported)
  }
`;

const ATTACH_FILE_TO_ITEMS = gql`
  mutation attachFiletoItems(
    $fileId: ID!
    $items: [FileAttachmentInput]
    $itemsToRemove: [FileAttachmentInput]
    $addToTrip: Boolean
    $tripId: ID
    $isImported: Boolean
  ) {
    attachFiletoItems(
      fileId: $fileId
      items: $items
      itemsToRemove: $itemsToRemove
      addToTrip: $addToTrip
      tripId: $tripId
      isImported: $isImported
    )
  }
`;

const CREATE_INVITATION = gql`
  mutation createInvitation($tripId: ID!, $userEmail: String!) {
    createInvitation(tripId: $tripId, userEmail: $userEmail) {
      id
      invitedUsers {
        id
        email
        createdDate
      }
    }
  }
`;

const DELETE_INVITATION = gql`
  mutation deleteInvitation(
    $id: ID!
    $tripId: ID!
    $joinTrip: Boolean
    $userId: ID
  ) {
    deleteInvitation(
      id: $id
      tripId: $tripId
      joinTrip: $joinTrip
      userId: $userId
    ) {
      id
      invitedUsers {
        id
        email
        createdDate
      }
      sharedUsers {
        id
        firstName
        lastName
        profilePicture
      }
    }
  }
`;

const GET_INVITATIONS = gql`
  query getInvitations {
    getInvitations {
      id
      tripId
    }
  }
`;

const ADD_SAVED_ITEM = gql`
  mutation addSavedItem(
    $tripId: ID!
    $referenceId: String
    $savedData: String
    $type: String
  ) {
    addSavedItem(
      tripId: $tripId
      referenceId: $referenceId
      savedData: $savedData
      type: $type
    ) {
      id
      thumbnail
      longitude
      latitude
      title
      referenceId
      priceTier
      savedData
      type
    }
  }
`;

const DELETE_SAVED_ITEM = gql`
  mutation deleteSavedItem($id: ID!) {
    deleteSavedItem(id: $id)
  }
`;

// DND mutations
const UPDATE_TRIP_ITEMS_DND = gql`
  mutation updateTripItemsDnd($tripId: ID!, $itemId: ID, $targetIndex: Int) {
    updateTripItemsDnd(
      tripId: $tripId
      itemId: $itemId
      targetIndex: $targetIndex
    )
  }
`;

const UPDATE_LOCATION_ITEMS_DND = gql`
  mutation updateLocationItemsDnd(
    $sourceId: ID!
    $targetId: ID
    $itemId: ID!
    $itemType: String!
    $targetIndex: Int
  ) {
    updateLocationItemsDnd(
      sourceId: $sourceId
      targetId: $targetId
      targetIndex: $targetIndex
      itemId: $itemId
      itemType: $itemType
    )
  }
`;

const UPDATE_TODOS_DND = gql`
  mutation updateTodosDnd(
    $sourceId: ID!
    $targetId: ID
    $itemId: ID!
    $targetIndex: Int
  ) {
    updateTodosDnd(
      sourceId: $sourceId
      targetId: $targetId
      targetIndex: $targetIndex
      itemId: $itemId
    )
  }
`;

const PATCH_TZ_FOR_TRIP = gql`
  mutation patchTzForTrip($tripId: ID!, $timezone: String!) {
    patchTzForTrip(tripId: $tripId, timezone: $timezone)
  }
`;

const GET_TRAVEL_STATS = gql`
  query getTravelStats($userId: ID!) {
    getTravelStats(userId: $userId) {
      friendsInvited
      tripsCount
      activityCount
      shouldShowTravelStats
    }
  }
`;

const REMOVE_USER_FROM_TRIP = gql`
  mutation removeUserFromTrip($userId: ID, $tripId: ID) {
    removeUserFromTrip(userId: $userId, tripId: $tripId)
  }
`;

const ADD_USER_TO_TRIP = gql`
  mutation addUserToTrip($userId: ID, $tripId: ID) {
    addUserToTrip(userId: $userId, tripId: $tripId) {
      tripExists
      userAddedToTrip
    }
  }
`;

const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession($input: CheckoutSessionInput!) {
    createCheckoutSession(input: $input)
  }
`;

const GET_CHECKOUT_SESSION = gql`
  query getCheckoutSession($id: ID!) {
    getCheckoutSession(id: $id) {
      id
      tripId
      status
      price {
        total
        taxes
        currency
      }
      checkoutData {
        hotelId
        checkInDate
        checkOutDate
        sessionId
        packageId
        roomsCount
        users {
          id
          firstName
          lastName
          namePrefix
          email
          phoneNumber
          assignedRoomId
          type
          age
          isLeadUser
        }
        rooms {
          roomId
          type
          name
          bed
          basis
          price
          currency
          amenities
          images
        }
        cxlPolicy {
          isRefundable
          refundableUntil
          policies {
            fromDate
            toDate
            cancellationFee
            currency
          }
          remarks
        }
      }
    }
  }
`;

const UPDATE_CHECKOUT_SESSION_STATUS = gql`
  mutation updateCheckoutSessionStatus(
    $id: ID!
    $status: CheckoutSessionStatus!
  ) {
    updateCheckoutSessionStatus(id: $id, status: $status)
  }
`;

const CREATE_BOOKING = gql`
  mutation createBooking($input: BookingInput!) {
    createBooking(input: $input)
  }
`;

const GET_BOOKING_FROM_ID = gql`
  query getBooking($id: ID!) {
    getBooking(id: $id) {
      id
      status
      tripId
      segmentId
    }
  }
`;

const INITIATE_USER_SESSION = gql`
  mutation initiateUserSession($userId: ID!, $sessionId: String!) {
    initiateUserSession(userId: $userId, sessionId: $sessionId) {
      sessionCount
      newSession
    }
  }
`;

const CREATE_IMPORT = gql`
  mutation createImport($import: ImportInput!) {
    createImport(import: $import) {
      id
      status
      error
    }
  }
`;

const UPDATE_IMPORT = gql`
  mutation updateImport($importId: ID!, $import: ImportInput!) {
    updateImport(importId: $importId, import: $import) {
      id
      importId
      userId
      name
      tripId
      source
      fileId
      items {
        type
        accommodation {
          id
          name
        }
        activity {
          id
          title
        }
        flight {
          id
          flightNumber
        }
        transport {
          id
          type
        }
      }
      status
      error
    }
  }
`;

const UPDATE_IMPORT_ATTACHED_TO = gql`
  mutation updateImportAttachedTo(
    $importId: ID!
    $itemIndex: Int!
    $attachedToItem: String
  ) {
    updateImportItemAttachedTo(
      importId: $importId
      itemIndex: $itemIndex
      attachedToItem: $attachedToItem
    ) {
      fileId
      items {
        attachedToItem
      }
    }
  }
`;

const DELETE_IMPORT_ITEM = gql`
  mutation deleteImportItem($importId: ID!, $itemIndex: Int!) {
    deleteImportItem(importId: $importId, itemIndex: $itemIndex) {
      fileId
      status
    }
  }
`;

const GET_IMPORT = gql`
  query getImport($importId: ID!) {
    getImport(importId: $importId) {
      id
      importId
      userId
      name
      tripId
      source
      fileId
      items {
        type
        accommodation {
          id
          name
        }
        activity {
          id
          title
        }
        flight {
          id
          flightNumber
        }
        transport {
          id
          type
        }
      }
      status
      error
    }
  }
`;

export default {
  CREATE_USER,
  GET_USER,
  UPDATE_USER,
  UPDATE_WELCOME_SCREEN,
  ADD_EXAMPLE_TRIP,
  DELETE_USER,

  CREATE_USER_HANDLE,
  GET_USER_HANDLE,
  UPDATE_USER_HANDLE,
  DELETE_USER_HANDLE,

  GET_SHARED_TRIPS,
  GET_ALL_TRIPS,
  GET_TRIP,
  GET_TRIP_BASIC,
  CREATE_TRIP,
  UPDATE_TRIP,
  UPDATE_TRIP_NAME,
  DELETE_TRIP,
  PATCH_TZ_FOR_TRIP,
  REMOVE_USER_FROM_TRIP,
  ADD_USER_TO_TRIP,

  GET_LOCATION,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,

  GET_TRANSPORTATION,
  GET_TRANSPORTATION_FOR_TRIP,
  CREATE_TRANSPORTATION,
  DELETE_TRANSPORTATION,
  UPDATE_TRANSPORTATION,

  CREATE_FLIGHT,
  UPDATE_FLIGHT,
  DELETE_FLIGHT,

  GET_ACCOMMODATION,
  GET_ACCOMMODATION_FOR_TRIP,
  CREATE_ACCOMMODATION,
  UPDATE_ACCOMMODATION,
  DELETE_ACCOMMODATION,

  GET_THINGS_TODO,
  CREATE_THINGS_TODO,
  UPDATE_THINGS_TODO,
  DELETE_THINGS_TODO,

  CREATE_TODO,
  UPDATE_TODO,
  DELETE_TODO,

  GET_FRIENDS,
  GET_FRIENDS_FOR_USER,

  CREATE_WISHLIST,
  UPDATE_WISHLIST,
  DELETE_WISHLIST,
  GET_WISHLISTS,
  GET_WISHLIST,

  SEARCH_QUERY,
  GET_USERID_FROM_HANDLE,

  GET_MAP_PIN,
  UPDATE_MAP_PIN,
  DELETE_MAP_PIN,
  CREATE_MAP_PIN,
  CREATE_TRIP_MAP_PIN,
  DELETE_TRIP_MAP_PIN,

  GET_FILE,
  CREATE_FILE,
  UPDATE_FILE,
  DELETE_FILE,
  ATTACH_FILE_TO_ITEMS,

  CREATE_INVITATION,
  DELETE_INVITATION,
  GET_INVITATIONS,

  ADD_SAVED_ITEM,
  DELETE_SAVED_ITEM,

  UPDATE_TRIP_ITEMS_DND,
  UPDATE_LOCATION_ITEMS_DND,
  UPDATE_TODOS_DND,

  GET_TRAVEL_STATS,
  CREATE_CHECKOUT_SESSION,
  GET_CHECKOUT_SESSION,
  UPDATE_CHECKOUT_SESSION_STATUS,
  CREATE_BOOKING,
  GET_BOOKING_FROM_ID,

  INITIATE_USER_SESSION,

  CREATE_IMPORT,
  UPDATE_IMPORT,
  GET_IMPORT,
  UPDATE_IMPORT_ATTACHED_TO,
  GET_USER_FORWARDED_FILES,
  DELETE_IMPORT_ITEM,
};
