import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Typography,
  InputBase,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { MoreHorizRounded } from '@mui/icons-material';
import { getFileExtention, getFileIcon, getFileName } from '../../../../utils';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import actions from '../../../../redux/actions';
import { updateFile } from '../../../../redux/slices/FilesV2';
import OptionsMenu from '../../../molecules/Menu/OptionsMenu';
import { DeleteModal } from '../../../molecules/Modal';
import UserAvatar from '../../../molecules/UserAvatar';
import ItemAttachment from './ItemAttachment';

const filesListStyles = makeStyles(() => ({
  headerText: {
    fontSize: 12,
    color: '#4E4E4E',
    textTransform: 'uppercase',
    padding: '0 4px',
  },
  mainText: {
    fontSize: 14,
    color: '#000',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  secondaryText: {
    fontSize: 14,
    color: '#8A8A8A',
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  headerContainer: {
    borderBottom: '1px solid #DEDDDD',
    padding: '6px 12px',
  },
  nameRow: {
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  nameRowFocused: {
    background: '#FFFFFF',
    boxShadow: 'inset 0px 0px 0px 2px #F4F4F4',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  filesListContainer: {
    // 100vh - (static space above the files list + navbar height + static padding below the files list).
    maxHeight: 'calc(100vh - 234px - 60px)',
    overflowY: 'auto',
  },
  fileContainer: {
    borderBottom: '1px solid #DEDDDD',
    padding: '4px 0px',
  },
  fileWrapper: {
    borderRadius: 4,
    padding: '0 12px',
  },
  flexRow: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '8px 4px',
  },
  moreVertButton: {
    padding: 2,
  },
  moreVertIcon: {
    width: 16,
    height: 16,
  },
  fileLogo: {
    marginRight: 9,
    height: 20,
    width: 20,
    viewBox: '0 0 20 20',
    minWidth: 20,
  },
  fileAttachTrigger: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '0 4px',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  renameInput: {
    padding: 0,
    fontSize: 14,
  },
}));

function FileRow({ file, handleTriggerDeleteFile, tripId, tripFiles }) {
  const classes = filesListStyles();
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [optionsMenuAnchor, setOptionsMenuAnchor] = useState(false);
  const [isTextFocused, setIsTextFocused] = useState(false);
  const [fileName, setFileName] = useState(file.name?.split('.')[0]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // remove the parameters from the file link url and extract the file extension
  const url = file.url.split('?')[0];
  const extension = url.split('.').pop();

  const Logo = getFileIcon(extension);

  const handleNameSubmit = () => {
    const fileExtenstion = getFileExtention(file.name);
    if (file.name !== `${fileName}.${fileExtenstion}`) {
      dispatch(
        updateFile({
          variables: {
            id: file.id,
            file: {
              name: `${fileName}.${fileExtenstion}`,
            },
          },
        })
      );
      phTrackEvent({ event: EVENTS.TRIP_FILES.FILE_NAME_UPDATE });
    }
  };

  return (
    <Grid
      item
      container
      className={classes.fileContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Grid container className={classes.fileWrapper}>
        <Grid
          item
          xs={4}
          className={`${classes.flexRow} ${classes.nameRow} ${
            (isTextFocused && classes.nameRowFocused) || ''
          }`}
          {...(!isTextFocused
            ? {
                onClick: () => {
                  dispatch(
                    actions.FilesV2.setFilePreview({
                      extension,
                      ...file,
                    })
                  );
                },
              }
            : {})}>
          <Logo className={classes.fileLogo} />
          {!isTextFocused ? (
            <Typography className={classes.mainText}>{file.name}</Typography>
          ) : (
            <ClickAwayListener
              onClickAway={() => {
                handleNameSubmit();
                setIsTextFocused(false);
              }}>
              <form
                onSubmit={() => {
                  handleNameSubmit();
                  setIsTextFocused(false);
                }}>
                <InputBase
                  value={getFileName(fileName)}
                  classes={{
                    input: classes.renameInput,
                  }}
                  inputRef={(input) => input && input.focus()}
                  inputProps={{ 'aria-label': 'Rename File' }}
                  onChange={(e) => {
                    setFileName(e.target.value);
                  }}
                />
              </form>
            </ClickAwayListener>
          )}
        </Grid>
        <Grid item xs={4} className={classes.fileAttachTrigger}>
          {/* <Typography className={classes.secondaryText}>
            {file.attachedToText}
          </Typography> */}
          <ItemAttachment
            tripId={tripId}
            fileId={file?.id}
            tripFiles={tripFiles}
          />
        </Grid>
        <Grid item xs={2} className={classes.flexRow}>
          {file.createdBy && (
            <UserAvatar
              user={file.createdBy}
              index={0}
              avatarProps={{
                style: {
                  marginRight: 8,
                  height: 16,
                  width: 16,
                  fontSize: 8,
                },
              }}
            />
          )}
          <Typography className={classes.secondaryText}>
            {`${file?.createdBy?.firstName || ''} ${
              file?.createdBy?.lastName || ''
            }`}
          </Typography>
          {isHovered ? (
            <IconButton
              className={classes.moreVertButton}
              onClick={(e) => setOptionsMenuAnchor(e.currentTarget)}>
              <MoreHorizRounded
                className={classes.moreVertIcon}
                aria-controls={`${file.id}-file-menu`}
                aria-haspopup="true"
              />
            </IconButton>
          ) : null}
          <OptionsMenu
            id={`${file.id}-file-menu`}
            state={optionsMenuAnchor}
            setState={setOptionsMenuAnchor}
            callbacks={[
              () => {
                setIsTextFocused(true);
              },
              () => setDeleteModalOpen(true),
            ]}
            options={['Rename', 'Delete']}
            menuItemProps={{
              style: {
                fontSize: 12,
                padding: '4px 16px',
              },
            }}
            menuProps={{
              MenuListProps: {
                style: {
                  padding: '4px 0',
                },
              },
            }}
          />
          <DeleteModal
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            executeFunc={() => {
              handleTriggerDeleteFile(file);
              setDeleteModalOpen(false);
            }}
            alertText="Are you sure?"
            descriptionText="This action will detach your file from all itinerary items and remove your file from Pilot permanently."
            confirmText="Delete"
            cancelText="Cancel"
            emoji="⚠️"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FileRow;
