import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { createItem } from '../../../../redux/slices/Item';
import ITEM_TYPES from '../../../../const';
import { isLocalItem } from '../../../../utils';
import { updateFile } from '../../../../redux/slices/FilesV2';

function useTransporationsCreate({ tripId, id = '' }) {
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const handleCreateConnection = async (
    flightInfo = {},
    additionalProps = {}
  ) =>
    dispatch(
      createItem({
        variables: {
          tripId,
          parentId: id,
          type: ITEM_TYPES.TRANSPORT,
          ignoreParentUpdate: true,
          ...flightInfo,
          index: flightInfo?.index || additionalProps?.index,
        },
        tripId,
        parentId: id,
        ...additionalProps,
      })
    );

  const handleCreateTransportation = async (
    flightList,
    parentId,
    index,
    transportType
  ) => {
    try {
      setIsCreating(true);
      // create flights for each stop created
      const connectionIds = await Promise.all(
        flightList.map(async (item) => {
          const { flight, additionalProps } = item;
          if (
            isLocalItem(flight?.id, ITEM_TYPES.TRANSPORT) ||
            !flight?.id ||
            flight?.id?.startsWith('local')
          ) {
            const newCreatedFlight = await handleCreateConnection(flight, {
              localId: flight?.id,
              ...additionalProps,
              transportType,
            });
            if (flight?.files) {
              flight?.files?.forEach((file) =>
                dispatch(
                  updateFile({
                    variables: {
                      id: file,
                      attachedToType: 'flight',
                      attachedToID: newCreatedFlight?.payload?.createFlight?.id,
                    },
                  })
                )
              );
            }
            return newCreatedFlight?.payload?.createItem?.id;
          }
          return flight?.id;
        })
      );
      const transportation = await new Promise((resolve, reject) => {
        dispatch(
          createItem({
            variables: {
              tripId,
              parentId,
              index,
              type: ITEM_TYPES.TRANSPORTATION,
              content: {
                transportType,
                connections: connectionIds,
              },
            },
            parentId,
            tripId,
            index,
          })
        )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
      return {
        connectionIds,
        transportation,
      };
    } catch (err) {
      return err;
    } finally {
      setIsCreating(false);
    }
  };

  return {
    handleCreateTransportation,
    isCreating,
  };
}

export default useTransporationsCreate;
