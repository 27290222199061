import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
  AttachFile,
  Close,
} from '@mui/icons-material';
import {
  Box,
  Chip,
  Typography,
  Popper,
  ClickAwayListener,
  Autocomplete,
  Checkbox,
  InputBase,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/actions';
import { updateAccommodation } from '../../../redux/slices/Accommodation';
import { updateActivity } from '../../../redux/slices/Activity';
import { attachFiletoItems } from '../../../redux/slices/Files';
import { updateFlight } from '../../../redux/slices/Transportation';
import { updateTrip } from '../../../redux/slices/Trips';
import { ButtonDefault } from '../../atoms/Button/index';

const attachmentPopperStyles = makeStyles((theme) => ({
  attachPopper: {
    borderRadius: 4,
    width: '280px',
    backgroundColor: '#FFF',
    zIndex: 1100,
  },
  attachmentOption: {
    padding: '2px 0',
    borderBottom: '1px solid #DEDDDD',
    cursor: 'pointer',
    '&:hover': {
      background: '#F4F4F4',
    },
  },
  AutoCompleteListbox: {
    background: '#FFFFFF',
    boxShadow: 'none',
    padding: 0,
  },
  searchInput: {
    boxShadow: '0 0 0 1px #7E7E7E',
    padding: '4px 14px',
    background: '#F4F4F4',
    borderRadius: 4,
    width: '100%',
  },
  AutoCompleteListboxPopper: {
    padding: 14,
    boxShadow: '0 0 0 1px #7E7E7E',
    borderRadius: 4,
    // width: '100%',
    // minWidth: '200px',
  },
  sectionLabel: {
    fontSize: '12px',
    color: '#4E4E4E',
    fontWeight: 'bold',
    borderBottom: '1px solid #DEDDDD',
    paddingBottom: '8px',
    padding: '8px 0',
    textTransform: 'uppercase',
    lineHeight: 1.5,
  },
  sectionUl: {
    marginBottom: '8px',
  },
  attachItemsButton: {
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 4,
  },
  chip: {
    borderRadius: 4,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: 12,
    padding: '13px 4px',
    '& .MuiChip-deleteIcon': {
      color: theme.palette.primary.main,
    },
  },
  attachButton: {
    marginTop: 8,
  },
  emptyAttachmentText: {
    color: 'transparent',
    '&:hover': {
      color: '#BCBCBC',
    },
    padding: '8px 4px',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

function ItemAttachment({ fileId, tripId, tripFiles }) {
  const classes = attachmentPopperStyles();
  const [anchorElm, setAnchorEl] = useState(null);
  const [openPopper, setOpen] = useState(false);
  const id = openPopper ? 'attachment-popper' : undefined;
  const dispatch = useDispatch();

  const tripItems = useSelector((state) => state.Trips.trips[tripId].items);
  const activities = useSelector((state) => state.Activity.todos);
  const accommodation = useSelector((state) => state.Accommodation.hotels);
  const locations = useSelector((state) => state.Location.locations);
  const sections = useSelector((state) => state.Section.sections);
  const transportation = useSelector(
    (state) => state.Transportation.transports
  );
  const fileRelations = useSelector(
    (state) => state.Files.fileRelations[tripId]
  );

  const getName = (type, elemId) => {
    if (type === 'Activity') {
      return activities[elemId].title;
    }
    if (type === 'Accommodation') {
      return accommodation[elemId].name;
    }
    if (type === 'Transportation') {
      const transportObj = Object.values(transportation).find((transport) =>
        transport?.details?.map((detail) => detail.id)?.includes(elemId)
      );
      return transportObj?.details?.find((detail) => detail.id === elemId)
        ?.flightNumber;
    }
    return '';
  };

  const attachedItems = fileRelations
    ?.filter(
      (relation) =>
        relation.fileId === fileId && relation.attachedToType !== 'Trip'
    )
    .map((relation) => {
      return {
        id: relation.attachedToId,
        title: getName(relation.attachedToType, relation.attachedToId),
        type: relation.attachedToType,
      };
    });

  const tripItemsList = useMemo(() => {
    const activitesList = [];
    const accommodationList = [];
    const transportationList = [];

    tripItems?.forEach((item) => {
      if (item?.transportation) {
        transportation[item?.transportation]?.details?.forEach((detail) => {
          transportationList.push({
            id: detail.id,
            title: detail.flightNumber,
            type: 'Transportation',
          });
        });
      }
      if (item?.location) {
        locations[item.location]?.hotels?.forEach((hotel) => {
          accommodationList.push({
            id: hotel,
            title: accommodation[hotel].name,
            type: 'Accommodation',
          });
        });
        locations[item.location]?.thingsToDo?.forEach((section) => {
          sections[section]?.todos.forEach((todo) => {
            if (!todo?.includes('local-todo') && activities[todo]) {
              activitesList.push({
                id: todo,
                title: activities[todo].title,
                type: 'Activity',
              });
            }
          });
        });
      }
    });

    return [...transportationList, ...accommodationList, ...activitesList];
  }, [
    tripItems,
    locations,
    sections,
    accommodation,
    activities,
    transportation,
  ]);

  const [val, setValue] = useState([...attachedItems]);

  const arrCompare = (arr1, arr2) => {
    let equal = false;
    if (arr1.length === arr2.length) {
      equal = true;
      arr1.forEach((element) => {
        if (!arr2.includes(element)) equal = false;
      });
    }
    return equal;
  };

  const itemsArr = val?.map((item) => item.id);
  const attachedItemsArr = attachedItems?.map((item) => item.id);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    if (anchorElm) {
      anchorElm.focus();
    }
    setOpen(false);
    setAnchorEl(null);
  };

  const handleAttach = async () => {
    if (!arrCompare(itemsArr, attachedItemsArr)) {
      const attachmentItems = val
        ?.filter((item) => !attachedItemsArr.includes(item.id))
        .map((item) => {
          return {
            id: item.id,
            type: item.type,
          };
        });
      const detachedItems = attachedItems
        ?.filter((item) => !itemsArr.includes(item.id))
        .map((item) => {
          return {
            id: item.id,
            type: item.type,
          };
        });
      const toBeAdded = !fileRelations?.some(
        (relation) =>
          relation.fileId === fileId &&
          !detachedItems?.map((item) => item.id).includes(relation.attachedToId)
      );

      await new Promise(() => {
        dispatch(
          attachFiletoItems({
            variables: {
              fileId,
              tripId,
              items: attachmentItems,
              itemsToRemove: detachedItems,
              addToTrip: toBeAdded,
            },
          })
        ).then(() => {
          if (toBeAdded) {
            dispatch(actions.Trips.addTripFile({ tripId, fileId }));
          }
          const getBladeItems = (items, type) => {
            return items?.filter((item) => item.type === type);
          };
          dispatch(
            actions.Activity.handleActivityFiles({
              fileId,
              itemsToAttach: getBladeItems(attachmentItems, 'Activity'),
              itemsToDetach: getBladeItems(detachedItems, 'Activity'),
            })
          );
          dispatch(
            actions.Accommodation.handleAccommodationFiles({
              fileId,
              itemsToAttach: getBladeItems(attachmentItems, 'Accommodation'),
              itemsToDetach: getBladeItems(detachedItems, 'Accommodation'),
            })
          );
          dispatch(
            actions.Transportation.handleTransportationFiles({
              fileId,
              itemsToAttach: getBladeItems(attachmentItems, 'Transportation'),
              itemsToDetach: getBladeItems(detachedItems, 'Transportation'),
            })
          );
          handleClose();
        });
      });
    }
  };

  const handleDelete = async (itemId, type) => {
    await new Promise(() => {
      (() => {
        if (type === 'Activity') {
          return dispatch(
            updateActivity({
              variables: {
                id: itemId,
                files: activities[itemId]?.files?.filter(
                  (file) => file !== fileId
                ),
              },
              attachedToType: 'Activity',
              tripId,
            })
          );
        }
        if (type === 'Accommodation') {
          return dispatch(
            updateAccommodation({
              variables: {
                id: itemId,
                files: accommodation[itemId]?.files?.filter(
                  (file) => file !== fileId
                ),
              },
              attachedToType: 'Accommodation',
              tripId,
            })
          );
        }
        if (type === 'Transportation') {
          const transportId = Object.values(transportation).find((transport) =>
            transport?.details?.map((detail) => detail.id)?.includes(itemId)
          )?.id;
          return dispatch(
            updateFlight({
              variables: {
                id: itemId,
                files: transportation[transportId]?.details
                  ?.find((flight) => flight.id === itemId)
                  ?.files?.filter((file) => file !== fileId),
              },
              extra: { transportId },
              attachedToType: 'Transportation',
              tripId,
            })
          );
        }
        return null;
      })().then(() => {
        setValue((prevVal) => prevVal.filter((item) => item.id !== itemId));
        if (
          attachedItemsArr?.length === 0 &&
          fileRelations?.filter((relation) => relation.attachedToId === itemId)
            .length === 0
        ) {
          dispatch(
            updateTrip({
              variables: {
                id: tripId,
                files: [...(tripFiles || []), fileId],
              },
            })
          );
        }
      });
    });
  };

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return (
      <div {...other} className={classes.AutoCompleteListboxPopper}>
        {props.children}
        {arrCompare(itemsArr, attachedItemsArr) ? (
          <ButtonDefault
            startIcon={<AttachFile />}
            className={classes.attachButton}
            disabled>
            Attach file
          </ButtonDefault>
        ) : (
          <ButtonDefault
            startIcon={<AttachFile />}
            className={classes.attachButton}
            onClick={() => handleAttach()}>
            Attach file
          </ButtonDefault>
        )}
      </div>
    );
  }

  return (
    <>
      <Box onClick={handleClick} className={classes.attachItemsButton}>
        {attachedItems.length > 0 ? (
          attachedItems.map((item) => {
            return (
              <Chip
                key={item.id}
                size="small"
                label={item.title}
                className={classes.chip}
                variant="outlined"
                onDelete={() => handleDelete(item.id, item.type)}
                deleteIcon={<Close />}
              />
            );
          })
        ) : (
          <Typography className={classes.emptyAttachmentText}>
            Click to search activity
          </Typography>
        )}
      </Box>
      <Popper
        id={id}
        open={openPopper}
        anchorEl={anchorElm}
        className={classes.attachPopper}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -39],
            },
          },
        ]}>
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              onClose={(event, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              disableCloseOnSelect
              value={val}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  event.key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setValue(newValue);
              }}
              PopperComponent={PopperComponent}
              options={tripItemsList}
              getOptionLabel={(option) => option.title}
              noOptionsText="No items to attach"
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  className={classes.attachmentOption}
                  key={option.id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              groupBy={(option) => option.type}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <InputBase
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  placeholder="Search activities..."
                  className={classes.searchInput}
                  sx={{ width: `${anchorElm?.offsetWidth}px` }}
                />
              )}
              ListboxProps={{
                sx: { padding: 0 },
              }}
              classes={{
                paper: classes.AutoCompleteListbox,
                groupLabel: classes.sectionLabel,
                groupUl: classes.sectionUl,
              }}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default ItemAttachment;
