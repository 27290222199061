import { useState } from 'react';
import { Box, Chip, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useDispatch, useSelector } from 'react-redux';
import { Close, DeleteOutlineRounded } from '@mui/icons-material';
import { capitalize } from 'lodash';
import { SecondaryOutlinedButton } from '../../../atoms/Button/index';
import TransportationBlockImport from './ItineraryComponents/TransportationBlockImport';
import AccommodationBlockImport from './ItineraryComponents/AccommodationBlockImport';
import TodoItemImport from './ItineraryComponents/TodoItemImport';

// import { , updateFile } from '../../../../redux/slices/Files';
import {
  updateImportAttachedTo,
  updateFile as updateFileV2,
  attachFiletoItems,
  deleteImportItem,
} from '../../../../redux/slices/FilesV2';
import actions from '../../../../redux/actions';

import AddToTripV from './AddToTrip';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import useTransporationsCreate from '../../organisms/Transportation/useTransporationsCreate';
import ITEM_TYPES, { COST_TYPES } from '../../../../const';
import { extractKeysFromObject, getParentId } from '../../../../utils';
import { useMapUtils } from '../../organisms/Map';
import {
  createItem,
  deleteItem,
  updateItem,
} from '../../../../redux/slices/Item';

function SmartImportV2({
  fileId,
  item: initialItem,
  tripId,
  itemIndex,
  importId,
  attachedToItem,
  width = '300px',
  flexDirection,
  isForwardedFile,
  isModalView,
  showAsAddedonAdded,
  fileIndex,
}) {
  const attachmentPopperStyles = makeStyles((theme) => ({
    attachItemsButton: {
      height: '100%',
      width: '100%',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 4,
    },
    chip: {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      fontSize: 12,
      maxWidth: '120px',
      padding: '13px 4px',
      '& .MuiChip-deleteIcon': {
        color: theme.palette.primary.main,
      },
    },
    attachButton: {
      marginTop: 8,
    },
  }));

  const classes = attachmentPopperStyles();
  const dispatch = useDispatch();
  const tripItems = useSelector((state) => state.Item?.items);
  const [openBlade, setOpenBlade] = useState(false);
  const [locationText, setLocationText] = useState('');
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const { handleCreateTransportation } = useTransporationsCreate({
    tripId,
  });

  const [item, setItem] = useState(initialItem);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const trip = useSelector((state) => state.TripsV2.tripsAtc[tripId]);

  const {
    createMapPinForPlaceId,
    getPlaceDetails,
    createMapPinForPlace,
    extractAddressComponents,
  } = useMapUtils();

  const getMapPinForActivity = async () => {
    if (initialItem?.placeId) {
      try {
        const {
          title,
          photo,
          rating,
          website,
          maps,
          ratingCount,
          long,
          lat,
          types,
          categoryId,
          openingHoursObj,
          addressComponents,
          formattedAddress,
          formattedPhoneNumber,
        } = await getPlaceDetails(initialItem?.placeId, 'ACTIVITY');
        const address = extractAddressComponents(addressComponents);
        const mapPin = await createMapPinForPlace(
          {
            title,
            photo,
            rating,
            website,
            maps,
            ratingCount,
            long,
            lat,
            types,
            placeId: initialItem?.placeId,
            categoryId,
          },
          'ACTIVITY',
          {
            openingHoursObj,
            addressComponents,
            formattedAddress,
            formattedPhoneNumber,
            maps,
            website,
          }
        );
        return {
          mapPin,
          address,
        };
      } catch (error) {
        return {
          mapPin: null,
          address: null,
        };
      }
    }
    return {
      mapPin: null,
      address: null,
    };
  };

  const getMapPinForAccommodation = async () => {
    if (initialItem?.placeId) {
      const mapPinDetails = await createMapPinForPlaceId(
        initialItem?.placeId,
        'ACCOMMODATION'
      );
      return mapPinDetails;
    }
    return null;
  };

  const attachFileToItem = async (itemId) => {
    await dispatch(
      attachFiletoItems({
        variables: {
          id: fileId,
          addToItems: [itemId],
          removeFromItems: [],
        },
      })
    );
    await dispatch(
      updateItem({
        variables: {
          id: itemId,
          files: [fileId],
        },
      })
    );
  };

  const attachToItem = async (itemId) => {
    await dispatch(
      updateImportAttachedTo({
        variables: { importId, itemIndex, attachedToItem: itemId },
      })
    );
    attachFileToItem(itemId);
    if (isForwardedFile) {
      dispatch(
        actions.Auth.updateForwardedFileImportItemAttachedToItemId({
          index: fileIndex,
          fileId,
          itemIndex,
          attachedToItemId: itemId,
        })
      );
      await dispatch(
        updateFileV2({
          variables: {
            id: fileId,
            file: {
              attachedToId: itemId ? tripId : '',
              tripId,
              attachedToType: itemId ? 'Trip' : '',
            },
          },
        })
      );
    }
  };
  const detachItem = async () => {
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.PLAN_IMPORT_HISTORY_BLADE_REMOVE,
    });
    await attachToItem(null);
    const attachedItem = tripItems[attachedToItem];
    dispatch(
      deleteItem({
        variables: {
          id: attachedToItem,
        },
        parentId: getParentId(attachedToItem),
        type: tripItems[attachedToItem]?.type,
        tripId,
      })
    );
    if (
      getParentId(attachedToItem) !== tripId &&
      attachedItem?.type === ITEM_TYPES.TRANSPORT
    ) {
      const transportation = Object.values(tripItems).find((_item) =>
        _item?.content?.connections?.includes(attachedToItem)
      );
      if (transportation?.content?.connections?.length === 1) {
        dispatch(
          deleteItem({
            variables: {
              id: transportation?.id,
            },
            parentId: getParentId(transportation?.id),
          })
        );
      }
    }
  };
  const getAttachedItemName = () => {
    if (['bus', 'train', 'other', 'flight'].includes(item?.type)) {
      return tripItems[attachedToItem]?.content?.flightNumber;
    }
    return (
      tripItems[attachedToItem]?.name ||
      tripItems[attachedToItem]?.title ||
      null
    );
  };

  const attachedItemExists = () => {
    if (!attachedToItem || !item?.type) return false;
    return Boolean(tripItems[attachedToItem]);
  };
  const onAddToTrip = async ({ locationId = null, sectionId = null } = {}) => {
    const tripLocationChildIndex =
      trip?.children?.findIndex((childId) => childId === locationId) > -1
        ? trip?.children?.findIndex((childId) => childId === locationId)
        : 0;
    const transportationIndex =
      tripLocationChildIndex === 0 ? 0 : tripLocationChildIndex;
    if (item?.type === 'flight') {
      // create flights for each stop created

      const { connectionIds } = await handleCreateTransportation(
        item.details.map((flight) => ({
          flight: {
            // ...flight,
            index: transportationIndex,
            content: {
              // ...flight,
              transportType: 'flight',
              expense: {
                amount: flight?.cost,
                currency: flight?.currency,
                costType: COST_TYPES[flight.costPer],
              },
              ...extractKeysFromObject(flight, [
                'flightNumber',
                'fromAirport',
                'toAirport',
              ]),
              description: flight?.notes,
              startDate: flight?.fromDate,
              endDate: flight?.toDate,
            },
            parentId: tripId,
          },
          additionalProps: {
            files: [fileId],
            attachedToType: 'Transportation',
            tripId,
            type: 'flight',
            index: transportationIndex,
            parentId: tripId,
          },
        })),
        tripId,
        transportationIndex,
        'flight'
      );
      const element = document.getElementById(
        `scrollable-container-${locationId}`
      );
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      await attachToItem(connectionIds[0]);
    }

    if (['bus', 'train', 'other'].includes(item?.type)) {
      const { connectionIds } = await handleCreateTransportation(
        item.details.map((flight) => ({
          flight: {
            // ...flight,
            index: transportationIndex,
            content: {
              // ...flight,
              transportType: item?.type,
              expense: {
                amount: flight?.cost,
                currency: flight?.currency,
                costType: COST_TYPES[flight.costPer],
              },
              ...extractKeysFromObject(flight, [
                'flightNumber',
                'fromAirport',
                'toAirport',
              ]),
              description: flight?.notes,
              startDate: flight?.fromDate,
              endDate: flight?.toDate,
            },
            parentId: tripId,
          },
          additionalProps: {
            files: [fileId],
            attachedToType: 'Transportation',
            tripId,
            type: item?.type,
            index: transportationIndex,
            parentId: tripId,
          },
        })),
        tripId,
        transportationIndex,
        item?.type
      );
      await attachToItem(connectionIds[0]);
    }

    if (item?.type === 'activity') {
      try {
        const newActivity = {
          ...item,
          thingsToDoId: sectionId,
          index: tripItems[sectionId]?.children?.length || 0,
          files: [fileId],
        };
        const { mapPin } = await getMapPinForActivity();
        if (mapPin) {
          newActivity.mapPin = mapPin.id;
        }
        const activityVariables = {
          type: ITEM_TYPES.ACTIVITY,
          ...extractKeysFromObject(newActivity, ['title']),
          content: {
            expense: {
              amount: item?.cost,
              currency: item?.currency,
              costType: COST_TYPES[item?.costPer],
            },
            startDate: item?.activityTime,
            description: `${item?.description || ''}\n${item?.notes || ''}`,
            address: extractKeysFromObject(newActivity, [
              'country',
              'city',
              'streetAddress',
              'zipCode',
              'state',
            ]),
          },
          tripId,
          children: [],
          index: !Number.isNaN(tripItems[sectionId]?.children?.length)
            ? tripItems[sectionId]?.children?.length || 0
            : 0,
        };
        const activity = await dispatch(
          createItem({
            variables: activityVariables,
            shouldAppendItem: true,
            tripId,
            parentId: sectionId,
            index: !Number.isNaN(tripItems[sectionId]?.children?.length)
              ? tripItems[sectionId]?.children?.length
              : 0,
          })
        ).unwrap();
        await attachToItem(activity?.createItem?.id);
        const element = document.getElementById(`todo-header-${sectionId}`);
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } catch (error) {
        console.log('error', error);
      }
    }
    if (item?.type === 'accommodation') {
      const mapPinDetails = await getMapPinForAccommodation();

      try {
        const accommodation = await dispatch(
          createItem({
            variables: {
              type: ITEM_TYPES.ACCOMMODATION,
              tripId,
              index: 0,
              title: item?.name,
              content: {
                description: item?.notes || '',
                expense: {
                  costType: COST_TYPES[item?.costPer],
                  amount: item?.cost,
                  currency: item?.currency,
                },
                address: extractKeysFromObject(item, [
                  'country',
                  'city',
                  'streetAddress',
                  'zipCode',
                  'state',
                ]),
                checkInDate: item?.checkInDate,
                checkOutDate: item?.checkOutDate,
              },
              mapPin: mapPinDetails?.id,
              parentId: locationId,
            },
            tripId,
            parentId: locationId,
            index: 0,
          })
        ).unwrap();
        await attachToItem(accommodation?.createItem?.id);

        const element = document.getElementById(
          `scrollable-container-${locationId}`
        );
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } catch (error) {
        console.log('error', error);
      }
    }

    // once the item is added to the trip, change the tab
    if (isForwardedFile) {
      dispatch(actions.View.setForwardedFilesModalTabValue(1));
    }
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.PLAN_IMPORT_HISTORY_BLADE_ADD,
    });
  };

  const handleAddLocation = async (location) => {
    const mapPinId = await createMapPinForPlaceId(
      location?.place_id,
      'LOCATION'
    );
    const res = await dispatch(
      createItem({
        variables: {
          type: ITEM_TYPES.DESTINATION,
          tripId,
          index: 0,
          title: location?.structured_formatting.main_text,
          mapPin: mapPinId,
          parentId: tripId,
        },
        tripId,
        parentId: tripId,
        index: 0,
      })
    ).unwrap();
    console.log('res', res);
    if (item?.type === 'activity') {
      // const section = await dispatch(
      //   createSection({
      //     variables: {
      //       trip: tripId,
      //       locationId: res.payload.createLocation.id,
      //       name: '',
      //       startDate: null,
      //       endDate: null,
      //       todos: [],
      //     },
      //     index: 0,
      //     locationId: res.payload.createLocation.id,
      //   })
      // );
      onAddToTrip({
        sectionId: res.createItem.id,
      });
    } else {
      onAddToTrip({
        locationId: res.createItem.id,
        locationIndex: 0,
      });
    }

    // setOpenSearchbar(false);
  };

  return (
    <Grid
      width="100%"
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
      container
      direction={flexDirection}
      position="relative"
      gap={isModalView && openBlade ? 2 : 0}>
      {showDeleteIcon && (
        <DeleteOutlineRounded
          sx={{
            position: 'absolute',
            top: 20,
            left: 0,
            cursor: 'pointer',
            fontSize: '1.2rem',
            color: '#8A8A8A',
            '&:hover': {
              cursor: 'pointer',
              color: '#474747',
            },
          }}
          onClick={() => {
            phTrackEvent({
              event: EVENTS.SMART_IMPORT.PLAN_IMPORT_BLADE_DELETE,
            });
            dispatch(
              deleteImportItem({
                variables: { importId, itemIndex },
              })
            );
          }}
        />
      )}
      <Grid item xs={9} display="flex">
        <Box
          sx={{
            p: isModalView ? 0 : 2,
            pl: {
              xs: 0,
              sm: 4,
            },
            width: '100%',
            minWidth: {
              xs: '100%',
              sm: width,
            },
          }}>
          {/* <Heading {...headingProps} /> */}

          {item?.__typename === 'Accommodation' ? (
            <AccommodationBlockImport
              item={item}
              setItem={setItem}
              openBlade={openBlade}
              setOpenBlade={setOpenBlade}
            />
          ) : item?.__typename === 'Todo' ? (
            <TodoItemImport
              todo={item}
              setItem={setItem}
              openBlade={openBlade}
              setOpenBlade={setOpenBlade}
            />
          ) : (
            <TransportationBlockImport
              item={item}
              setItem={setItem}
              openBlade={openBlade}
              setOpenBlade={setOpenBlade}
            />
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 2,
            }}>
            {openBlade && (
              <SecondaryOutlinedButton
                sx={{
                  height: '30px',
                }}
                onClick={() => setOpenBlade(false)}>
                Close
              </SecondaryOutlinedButton>
            )}
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        xs={3}
        display="flex"
        justifyContent="flex-end"
        alignItems="center">
        {attachedItemExists() && !showAsAddedonAdded ? (
          <Chip
            size="small"
            label={getAttachedItemName()}
            className={classes.chip}
            variant="outlined"
            onDelete={detachItem}
            deleteIcon={<Close />}
          />
        ) : (
          <AddToTripV
            searchBarProps={{
              autoSearch: true,
              openOnFocus: true,
              isCitySearch: true,
              showBackdrop: true,
              enterToSelect: true,
              tripLocation: false,
              disableLocationBias: true,
              placeholder: 'Name your Destination...',
              popperPlacement: 'bottom',
              locationBias: null,
              handleSelect: (selectedLocation) => {
                handleAddLocation(selectedLocation);
              },
              value: locationText,
              controlledValue: locationText,
              onChange: setLocationText,
            }}
            showHeading={item?.type === 'activity'}
            isAdded={attachedItemExists()}
            handleSelectHeading={
              item?.type === 'activity'
                ? (sectionId) => {
                    onAddToTrip({
                      sectionId,
                    });
                  }
                : undefined
            }
            handleSelectLocation={async (locationId, index) => {
              if (item?.type === 'activity') {
                // temporarily disabled because it's being triggered along with handleSelectHeading, causing the section to be created twice
                // const section = await dispatch(
                //   createSection({
                //     variables: {
                //       trip: tripId,
                //       locationId,
                //       name: '',
                //       startDate: null,
                //       endDate: null,
                //       todos: [],
                //     },
                //     index: 0,
                //     locationId,
                //   })
                // );
                // onAddToTrip({
                //   sectionId: section.payload.createThingsToDo.id,
                // });
              } else {
                onAddToTrip({ locationId, locationIndex: index });
              }
            }}
            popoverTitle={
              ['bus', 'train', 'other', 'flight', 'accommodation'].includes(
                item?.type
              )
                ? `${
                    item?.type === 'other'
                      ? 'Transport'
                      : capitalize(item?.type)
                  } Destination: `
                : null
            }
            searchBarOpen={searchBarOpen}
            setSearchBarOpen={setSearchBarOpen}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default SmartImportV2;
