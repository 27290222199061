import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import InlineBlade from '../../InlineBlade';

const useStyles = makeStyles(() => ({
  bladeContainer: {
    marginBottom: 4,
    display: 'flex',
    flexDirection: 'row',
  },
  deleteIconContainer: {
    display: 'flex',
    marginRight: 8,
    marginTop: 16,
    minWidth: 20,
  },
  deleteIcon: {
    cursor: 'pointer',
    fontSize: 20,
    color: 'rgba(138, 138, 138, 1)',
    '&:hover': {
      color: '#474747',
    },
  },
  deleteIconHide: {
    display: 'none',
  },
}));

function TransportationBladeModifier({
  open,
  setOpen,
  newFlight,
  defaultFlightProps = {},
  id,
  //   transportId,
  index,
  type = 'flight',
  loading,
  handleUpdateTransportationBlade = () => {},
  newFlightIds = [],
  setNewFlightIds = () => {},
}) {
  const classes = useStyles();
  const [flightProps, setFlightProps] = useState({
    content: {
      flightNumber: defaultFlightProps?.flightNumber || '',
      fromAirport: defaultFlightProps?.fromAirport || '',
      toAirport: defaultFlightProps?.toAirport || '',
      startDate: defaultFlightProps?.fromDate || null,
      endDate: defaultFlightProps?.toDate || null,
      links: defaultFlightProps?.link || [],
      cost: null,
    },
    notes: '',
    files: null,
    ...defaultFlightProps,
  });
  const checkNewFlightId = flightProps?.id || (newFlight && 'new-flight');
  const handleUpdateFlight = async (data) => {
    if (
      newFlightIds.includes(checkNewFlightId) &&
      !Object.prototype.hasOwnProperty.call(data, 'automated')
    ) {
      setNewFlightIds(
        newFlightIds.filter((flightId) => flightId !== checkNewFlightId)
      );
    }

    setFlightProps({
      ...flightProps,
      ...data,
    });
    handleUpdateTransportationBlade({ ...flightProps, ...data }, index);
  };

  const namePlaceholder = () => {
    let res;
    switch (type) {
      case 'train':
      case 'bus':
        res = 'ticket number, company, link';
        break;
      case 'other':
        res = 'title, link, transportation type';
        break;
      default:
        res = 'flight number, airline, link';
    }
    return res;
  };

  return (
    <div className={classes.bladeContainer}>
      <InlineBlade
        key={id}
        id={id}
        isFlight
        flightProps={{
          ...flightProps,
          handleUpdateFlight,
          //   setOpenAutomatedFlightBlade,
          isNewFlight: newFlightIds.includes(checkNewFlightId),
        }}
        isLoading={loading}
        name={flightProps?.flightNumber}
        notes={flightProps?.notes}
        placeholder={namePlaceholder()}
        costVal={{
          amount: flightProps?.cost,
          currency: flightProps?.currency,
          per: flightProps?.costPer,
        }}
        linkVal={flightProps?.link}
        hasLink
        hasCost
        open={open}
        transportationType={type}
        setOpen={setOpen}
        updateLinkCallback={(link) =>
          handleUpdateFlight({
            link,
          })
        }
        updateNotesCallback={(notes) =>
          handleUpdateFlight({
            notes,
          })
        }
        updateCostCallback={({ amount, currency, per }) =>
          handleUpdateFlight({
            currency,
            cost: parseFloat(amount),
            costPer: per,
          })
        }
        updateNameCallback={(title) =>
          handleUpdateFlight({
            flightNumber: title,
          })
        }
        disableTransition
        fileAttachmentModalProps={{
          attachedToText: flightProps?.flightNumber,
          attachedToID: '',
          attachedToType: 'Transportation',
          transportType: type,
          attachedFiles: flightProps?.files || [],
          attachFunc: (newFiles, tripId) => {
            handleUpdateFlight(
              {
                files: newFiles,
              },
              {
                attachedToType: 'Transportation',
                tripId,
              }
            );
          },
        }}
      />
    </div>
  );
}

export default TransportationBladeModifier;
