import React, { useState } from 'react';
import {
  Button,
  Typography,
  useMediaQuery,
  SwipeableDrawer,
  Box,
  CircularProgress,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { getCompleteTrip, patchTzForTrip } from '../../../redux/slices/Trips';
import { isTripV2 } from '../../../utils';
import { getCompleteTripUsingAtc } from '../../../redux/slices/TripV2';

const useStyles = makeStyles(({ palette }) => ({
  box: {
    width: '400px',
    height: '290px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    color: '#8A8A8A',
    padding: '20px',
    position: 'relative',
    margin: '40px auto',
  },
  arrowLeft: {
    content: '""',
    position: 'absolute',
    left: '-14px',
    top: '30px',
    borderLeft: 'none',
    borderRight: '15px solid white',
    borderTop: '15px solid transparent',
    borderBottom: '15px solid transparent',
    transform: 'translateY(-50%)',
  },
  button: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #D9D9D9',
    width: '100%',
    '&:hover': {
      borderColor: palette.primary.main,
      backgroundColor: 'white',
      color: '#000',
    },
  },
  customDrawer: {
    '& .MuiDrawer-paper': {
      height: `fit-content`,
      overflow: 'visible',
      paddingBottom: '10px',
    },
  },
  puller: {
    width: '30px',
    height: '3px',
    backgroundColor: '#D9D9D9',
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
  },
}));

function TimezoneFixPopup({ tripId }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const tzPopupCopy =
    'We deployed an update to improve accuracy when traveling acrosstimezones. We ask that you confirm that your travel times are displaying accurately after this patch before departing on your travels.';

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(true);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const onConfirm = async () => {
    setLoading(true);

    // get current browser timezone
    const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

    const result = await dispatch(
      patchTzForTrip({
        variables: {
          tripId,
          timezone,
        },
      })
    );

    if (result) {
      if (isTripV2(tripId)) {
        dispatch(getCompleteTripUsingAtc({ tripId }));
      } else {
        dispatch(getCompleteTrip({ tripId }));
      }
    }
    setLoading(false);
  };

  const PopupBody = (
    <>
      <Typography variant="body2" style={{ marginBottom: '20px' }}>
        {tzPopupCopy}
      </Typography>
      <Typography variant="body2" style={{ marginBottom: '20px' }}>
        If you encounter any issues please{' '}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSev_ej9SSSJtS1Jgy_gT7tB8hvdPin1AkW7QB9tp_y38t6Q3w/viewform"
          target="_blank"
          rel="noreferrer"
          style={{ color: '#1877F2' }}>
          contact support
        </a>{' '}
        and we can assist or facilitate a fix for you.
      </Typography>
      <Button className={classes.button} disabled={loading} onClick={onConfirm}>
        {loading ? <CircularProgress size={16} /> : 'Confirm'}
      </Button>
    </>
  );

  if (!isMobile) {
    return (
      <div className={classes.box}>
        <div className={classes.arrowLeft} />
        <Typography
          variant="h6"
          style={{ marginBottom: '10px', color: '#000' }}>
          Recent Patch
        </Typography>
        {PopupBody}
      </div>
    );
  }
  return (
    <SwipeableDrawer
      container={window.document.body}
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      swipeAreaWidth={60}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      className={classes.customDrawer}>
      <Box
        sx={{
          position: 'absolute',
          top: -60,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: 'visible',
          right: 0,
          left: 0,
          backgroundColor: '#fff',
        }}>
        <div className={classes.puller} />
        <Typography
          variant="h6"
          style={{
            padding: '20px 20px 5px 20px',
            marginBottom: '10px',
            color: '#000',
            boxShadow: '0px -2px 3px 0px rgba(0, 0, 0, 0.25)',
          }}>
          Recent Patch
        </Typography>
      </Box>
      <div style={{ marginTop: '5px', padding: '0 20px', color: '#8A8A8A' }}>
        {PopupBody}
      </div>
    </SwipeableDrawer>
  );
}

export default TimezoneFixPopup;
