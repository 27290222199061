import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HomeOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { OrangeOutlinedButton } from '../../../atoms/Button/index';
import actions from '../../../../redux/actions';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import AccommodationBlock from './AccommodationBlock';
import { getStaysSearchConfig } from '../Stays/bookingsUtils';
import AccommodationSearchBar from './SearchBar';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  spacing: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0% 0% 0% 0%',
  },
  addAccommodationButton: {
    fontSize: 14,
    width: '100%',
    whiteSpace: 'nowrap',
    [breakpoints.down('md')]: {
      flexBasis: '100%',
    },
    '&:hover': {
      backgroundColor: palette.primary.extraLight,
      color: palette.primary.main,
    },
  },

  viewStaysButtonContainer: {
    margin: '0% 0% 0px calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      margin: '0% 0% 0px calc(3.6rem + 15px + 12px)',
    },
  },

  addAccommodationButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: (props) =>
      props.isDragging || props.isSectionHighlight ? 0 : 'calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      paddingLeft: (props) =>
        props.isDragging || props.isSectionHighlight
          ? 0
          : 'calc(3.6rem + 15px)',
      marginLeft: 12,
    },
  },

  hotelBookingsButton: {
    marginLeft: (props) =>
      props.isDragging || props.isSectionHighlight
        ? 12
        : 'calc(3.6rem + 15px + 12px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 27px)',
    },
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid #FFA766',
    },
  },
}));

// Component containing all the accomodation attributes.
function Accommodation({
  hotelId,
  parentId,
  index,
  isDragging,
  isSectionHighlight,
  locationBias,
  dragHandleProps,
  isParentDragging,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug: tripId } = useParams();
  const commandBarState = useSelector((state) => state.View.commandBar);
  const openAccommodationSearchbar = useSelector(
    (state) => state.Item.openItemSearchbar === hotelId
  );
  const setOpenAccommodationSearchbar = (shouldOpen) =>
    dispatch(
      actions.Item.setOpenItemSearchbar(shouldOpen ? { id: hotelId } : null)
    );

  const parent = useSelector((state) =>
    parentId === tripId
      ? state.tripsAtc.tripsAtc[tripId]
      : state.items.items[parentId]
  );
  const parentMapPin = useSelector(
    (state) => state.MapV2.mapPins[tripId]?.[parent?.mapPin]
  );
  const classes = useStyles({ isDragging, isSectionHighlight });
  // state to reference the active accommodation id
  const [activeAccommodation, setActiveAccommodation] = useState(null);

  // function to handle adding accommodation
  const openAccommodationSearchBarUsingCommandBar =
    (commandBarState?.openAccommodationSearchbar &&
      parentId === commandBarState?.activeLocationId) ||
    parentId === commandBarState?.accommodationDroppableSectionId;

  useEffect(() => {
    if (openAccommodationSearchBarUsingCommandBar) {
      setOpenAccommodationSearchbar(openAccommodationSearchBarUsingCommandBar);
    }
  }, [openAccommodationSearchBarUsingCommandBar]);

  useEffect(() => {
    if (!openAccommodationSearchbar) {
      dispatch(
        actions.View.setCommandBar({
          openAccommodationSearchbar: false,
          accommodationDroppableSectionId: null,
        })
      );
    }
  }, [openAccommodationSearchbar]);

  const handlSearchDealButtonClick = () => {
    phTrackEvent({ event: EVENTS.PLAN_STAY.TRIP_LINK_CLICK });
    const { cityLocationDetails = null, date = null } = getStaysSearchConfig({
      fromDate: parent?.content?.startDate,
      toDate: parent?.content?.endDate,
      mapPin: parentMapPin,
      title: parent?.title,
    });
    dispatch(
      actions.Recommendations.setCity({
        city: {
          coordinates: {
            latitude: cityLocationDetails?.latitude,
            longitude: cityLocationDetails?.longitude,
          },
          title: cityLocationDetails?.title,
          placeId: cityLocationDetails?.placeId,
          redirect: true,
        },
        tripId,
      })
    );
    dispatch(
      actions.Bookings.triggerSearch({
        tripId,
        cityLocationDetails,
        date,
      })
    );
    navigate(`/trips/${tripId}/explore?focus=stays`);
  };

  return (
    <Grid item className={classes.spacing}>
      <Grid item xs={12}>
        {/* {section?.hotels?.length > 0 ? (
          <TravelRestrictionsButton
            className={classes.hotelBookingsButton}
            onClick={handlSearchDealButtonClick}
            startIcon={
              <SellOutlined
                sx={{
                  transform: 'rotate(90deg)',
                  width: '14px',
                  height: '14px',
                  marginRight: '2px',
                }}
              />
            }>
            Search Deals
          </TravelRestrictionsButton>
        ) : null} */}
      </Grid>
      <AccommodationBlock
        activeAccommodation={activeAccommodation}
        setActiveAccommodation={setActiveAccommodation}
        update
        hotelId={hotelId}
        isParentDragging={isParentDragging}
        // hotels={section.hotels}
        // locationId={section.id}
        tripId={tripId}
        isDragging={isDragging}
        isSectionHighlight={isSectionHighlight}
        setOpenAccomodationPanel={setOpenAccommodationSearchbar}
        locationBias={locationBias}
        dragHandleProps={dragHandleProps}
      />
      {openAccommodationSearchbar && (
        <div style={{ marginTop: 8, marginBottom: 8 }}>
          <div
            className={classes.viewStaysButtonContainer}
            style={{ marginBottom: 8 }}>
            <OrangeOutlinedButton
              onClick={handlSearchDealButtonClick}
              startIcon={<HomeOutlined sx={{ height: 15, width: 15 }} />}
              sx={{
                padding: '2px 8px',
                fontSize: 12,
                borderRadius: '20px',
              }}>
              View Stays
            </OrangeOutlinedButton>
          </div>

          <AccommodationSearchBar
            setActiveAccommodation={setActiveAccommodation}
            setOpen={(openVal) =>
              dispatch(actions.Item.setOpenItemSearchbar({ id: openVal }))
            }
            locationBias={locationBias}
            index={index + 1}
            parentId={parentId}
            tripId={tripId}
          />
        </div>
      )}
    </Grid>
  );
}

export default Accommodation;
