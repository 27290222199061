import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  BedOutlinedIcon,
  MinusButtonIcon,
  PeopleOutlined,
  PlusButtonIcon,
  StandingPeopleIcon,
  VerticalDividerIcon,
} from '../../../atoms/Icon';

const useStyles = makeStyles(() => ({
  iconButton: {
    color: '#8A8A8A',
    '&:hover': {
      color: '#4E4E4E',
    },
    '.Mui-disabled': {
      color: 'red',
    },
  },
}));

function RoomAndGuestButton({
  anchorEl = null,
  setAnchorEl = () => {},
  guestCount = { adults: 2, children: 0, rooms: 1, total: 2 },
  setGuestCount = () => {},
  containerProps = {},
}) {
  const classes = useStyles();

  const [adults, setAdults] = useState(guestCount?.adults || 2);
  const [rooms, setRooms] = useState(guestCount?.rooms || 1);

  const [children, setChildren] = useState(guestCount?.children || 0);
  // eslint-disable-next-line no-unused-vars
  const [childAges, setChildAges] = useState(guestCount?.childAges || []);
  const boxRef = useRef(null);

  const increaseAdults = () => {
    const newAdults = adults + 1;

    setAdults(newAdults);
  };

  // Function to handle decreasing the  of adults
  const decreaseAdults = () => {
    if (adults > 1) {
      const newAdults = Math.max(1, adults - 1);

      setAdults(newAdults);
    }
  };

  // Function to handle increasing the  of children
  const increaseChildren = () => {
    const newChildren = children + 1;
    const newTotalPeople = adults + newChildren;
    const newRooms = Math.ceil(newTotalPeople / 4);
    const extraRoomsNeededForAdults = Math.max(0, newRooms - adults);

    setChildren(newChildren);
    setChildAges(childAges.slice(0, newChildren));
    setRooms(newRooms);
    setAdults(adults + extraRoomsNeededForAdults);
  };

  // Function to handle decreasing the  of children
  const decreaseChildren = () => {
    if (children > 0) {
      const newChildren = children - 1;
      const newTotalPeople = adults + newChildren;
      const newRooms = Math.ceil(newTotalPeople / 4);

      setChildren(newChildren);
      setChildAges(childAges.slice(0, newChildren));
      setRooms(newRooms);
    }
  };

  // Function to handle increasing the  of rooms
  const increaseRooms = () => {
    const newRooms = rooms + 1;
    if (adults < newRooms) {
      setAdults(newRooms);
    }
    setRooms(newRooms);
  };

  // Function to handle decreasing the  of rooms
  const decreaseRooms = () => {
    if (rooms > 1) {
      const newRooms = rooms - 1;
      const newTotalPeople = adults + children;

      if (newTotalPeople > newRooms * 4) {
        const newAdults = newRooms * 4 - children;
        setAdults(newAdults);
      }

      setRooms(newRooms);
    }
  };

  const handleCountUpdateClick = () => {
    setGuestCount({
      adults,
      children,
      rooms,
      total: adults + children,
      childAges,
    });
    setAnchorEl(null);
  };

  const openRoomGuestBox = Boolean(anchorEl);
  const suggesationBoxId = anchorEl ? 'suggesation-popper' : undefined;

  return (
    <>
      <Box
        ref={boxRef}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
          backgroundColor: '#FFFFFF',
          border: '1px solid #FFA766',
          borderRadius: '24px',
          height: '40px',
          width: '200px',
          zIndex: 1320,
        }}
        {...containerProps}>
        <PeopleOutlined />
        {adults}
        <VerticalDividerIcon />
        <StandingPeopleIcon />
        {children}
        <VerticalDividerIcon />
        <BedOutlinedIcon />
        {rooms}
      </Box>
      <Popper
        id={suggesationBoxId ? 'suggesation-popper' : null}
        open={openRoomGuestBox}
        anchorEl={boxRef.current}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ]}
        sx={{
          backgroundColor: '#FFFFFF',
          padding: 2,
          borderRadius: '4px',
          width: '260px',
          zIndex: 1321,
        }}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h4">Adult</Typography>
              <Typography
                variant="h4"
                sx={{ color: '#8A8A8A', fontSize: '12px' }}>
                Ages 13 or above
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton
                aria-label="minus-adult-count"
                className={classes.iconButton}
                onClick={decreaseAdults}
                disabled={adults === 1}>
                <MinusButtonIcon
                  style={{ color: adults === 1 ? '#D9D9D9' : null }}
                />
              </IconButton>
              <Typography>{adults}</Typography>
              <IconButton
                aria-label="plus-adult-count"
                className={classes.iconButton}
                onClick={increaseAdults}>
                <PlusButtonIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h4">Children</Typography>
              <Typography
                variant="h4"
                sx={{ color: '#8A8A8A', fontSize: '12px' }}>
                Ages 0 to 12
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton
                aria-label="minus-children-count"
                className={classes.iconButton}
                onClick={decreaseChildren}
                disabled={children === 0}>
                <MinusButtonIcon
                  style={{ color: children === 0 ? '#D9D9D9' : null }}
                />
              </IconButton>
              <Typography>{children}</Typography>
              <IconButton
                aria-label="plus-children-count"
                className={classes.iconButton}
                onClick={increaseChildren}>
                <PlusButtonIcon />
              </IconButton>
            </Box>
          </Box>
          {children > 0 &&
            new Array(children).fill(null).map((child, index) => (
              <FormControl fullWidth>
                <InputLabel id="age-select-label">
                  Child {index + 1} Age
                </InputLabel>
                <Select
                  autoFocus
                  labelId="age-select-label"
                  id="age-select"
                  value={childAges?.length > index ? childAges[index] : 0}
                  label={`Child ${index + 1} Age`}
                  MenuProps={{
                    sx: {
                      zIndex: 1321,
                      height: 250,
                    },
                  }}
                  onChange={(e) => {
                    const newChildAges = [...childAges];
                    newChildAges[index] = e.target.value;
                    setChildAges(newChildAges);
                  }}>
                  {new Array(12).fill(null).map((_, age) => (
                    <MenuItem value={age + 1}>{age + 1}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h4">Rooms</Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton
                aria-label="minus-rooms-count"
                className={classes.iconButton}
                onClick={decreaseRooms}
                disabled={rooms === 0}>
                <MinusButtonIcon
                  style={{ color: rooms === 1 ? '#D9D9D9' : null }}
                />
              </IconButton>
              <Typography>{rooms}</Typography>
              <IconButton
                aria-label="plus-rooms-count"
                className={classes.iconButton}
                onClick={increaseRooms}>
                <PlusButtonIcon />
              </IconButton>
            </Box>
          </Box>
          <Button
            disabled={childAges?.filter((a) => a !== null)?.length !== children}
            onClick={handleCountUpdateClick}>
            Update
          </Button>
        </Box>
      </Popper>
    </>
  );
}

export default RoomAndGuestButton;
