import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { format, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { MonthlyCalendar } from '../../../../../../atoms/Icon';
import DateRangePicker from '../../../../../../molecules/DatePickers/DateRangePicker';
import actions from '../../../../../../../redux/actions';

import { getNumberOfNights } from '../../utils';

function HotelPageDatePicker() {
  const [calendarAnchor, setCalendarAnchor] = useState(null);
  const dispatch = useDispatch();

  const { checkInDate = null, checkOutDate = null } = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.searchProps || {}
  );
  const fromDate = parse(checkInDate, 'yyyy-MM-dd', new Date());
  const toDate = parse(checkOutDate, 'yyyy-MM-dd', new Date());

  const getFormattedDateString = () => {
    let newDateString = '';
    try {
      const isSameMonth = format(fromDate, 'MMM') === format(toDate, 'MMM');
      if (fromDate && toDate) {
        newDateString = `${format(fromDate, 'MMM do')} - ${format(
          toDate,
          isSameMonth ? 'do' : 'MMM do'
        )}`;
      }
    } catch (err) {
      // error
    }
    return newDateString;
  };

  const onDateUpdate = ({ from, to }) => {
    if (from && to) {
      dispatch(
        actions.Bookings.setHotelDetailsSearchProps({
          checkInDate: from && format(from, 'yyyy-MM-dd'),
          checkOutDate: to && format(to, 'yyyy-MM-dd'),
        })
      );
      dispatch(
        actions.Bookings.setHotelDetails({
          numberOfNights: getNumberOfNights(from, to),
        })
      );
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={(e) => setCalendarAnchor(e.currentTarget)}>
        <MonthlyCalendar />
        <Typography
          noWrap
          variant="h5"
          color="text.primary"
          marginLeft="4px"
          sx={{
            textDecoration: 'underline',
          }}>
          {getFormattedDateString()}
        </Typography>
      </div>
      <DateRangePicker
        open={Boolean(calendarAnchor)}
        anchorEl={calendarAnchor}
        handleClose={() => setCalendarAnchor(null)}
        defaultRange={{
          from: fromDate,
          to: toDate,
        }}
        placement="bottom"
        numberOfMonths={2}
        isBookings
        onDateUpdate={onDateUpdate}
      />
    </div>
  );
}

export default HotelPageDatePicker;
