import { WishlistReducer } from './slices/Wishlist';
import { AuthReducer } from './slices/Auth';
import { TripsReducer } from './slices/Trips';
import { LocationReducer } from './slices/Location';
import { TransportationReducer } from './slices/Transportation';
import { AccommodationReducer } from './slices/Accommodation';
import { SectionReducer } from './slices/Section';
import { ActivityReducer } from './slices/Activity';
import { MapReducer } from './slices/Map';
import { FilesReducer } from './slices/Files';
import { ErrorReducer } from './slices/Error';
import { ViewReducer } from './slices/View';
import { RecommendationsReducer } from './slices/Recommendations';
import { DiscoverReducer } from './slices/Discover';
import { BookingsReducer } from './slices/Bookings';
import { ItemReducer } from './slices/Item';
import { TripAtcReducer } from './slices/TripV2';
import { SysInfoReducer } from './slices/SysInfo';
import { MapV2Reducer } from './slices/MapV2';
import { FilesV2Reducer } from './slices/FilesV2';

const reducers = {
  Wishlist: WishlistReducer,
  Auth: AuthReducer,
  Trips: TripsReducer,
  Location: LocationReducer,
  Transportation: TransportationReducer,
  Accommodation: AccommodationReducer,
  Section: SectionReducer,
  Activity: ActivityReducer,
  Map: MapReducer,
  Files: FilesReducer,
  Error: ErrorReducer,
  View: ViewReducer,
  Recommendations: RecommendationsReducer,
  Discover: DiscoverReducer,
  Bookings: BookingsReducer,
  // TODO: remove this
  items: ItemReducer,
  Item: ItemReducer,
  tripsAtc: TripAtcReducer,
  SysInfo: SysInfoReducer,
  TripsV2: TripAtcReducer,
  MapV2: MapV2Reducer,
  FilesV2: FilesV2Reducer,
};

export default reducers;
