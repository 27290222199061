/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Grid,
  Button,
  Chip,
  Divider,
  IconButton,
  InputBase,
  Typography,
  Backdrop,
  ClickAwayListener,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles, useTheme } from '@mui/styles';
import { useParams, useLocation } from 'react-router-dom';
import { PlacesSearchBar } from '../../../molecules/SearchBar';
import {
  FilterListIcon,
  BookingErrorIcon,
  RefreshIcon,
  PeopleOutlined,
  NoResultFoundIcon,
  LocationMapPinIcon,
} from '../../../../atoms/Icon';
import HotelDetailsCard from '../HotelDetailsCard';
import BookingsFilterMenu from './BookingsFilterMenu';
import HotelDetailsCardSkeleton from '../HotelDetailsCardSkeleton';
import actions from '../../../../../redux/actions';
import { useMapUtils } from '../../Map';
import recommendationCategories from '../../../../../assets/newRecommendationCategories.json';
import RoomAndGuestButton from '../RoomAndPeopleButton';
import { getStaysSearchConfig, stringToGuestV2 } from '../bookingsUtils';
import HotelsSaveListContainer from '../HotelsSaveListContainer';
import CheckInCheckOutButton from '../CheckInCheckOutButton';
import classList from '../../../../classList';
import { SOUNDS, useSounds } from '../../../../../sounds';
import { phTrackEvent, EVENTS } from '../../../../../analytics';
import { getHotelSearchResults } from './api';
import { getFormattedNightlyPrice } from '../HotelDetails/utils';
import ITEM_TYPES from '../../../../../const';

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: '10px',
    maxWidth: '474px',
    height: '39px',
  },
  suggestionChip: {
    padding: '6px 12px',
    color: 'text.secondary',
    borderRadius: 24,
    height: '100%',
    '& > svg': {
      marginLeft: '-4px !important',
    },
    '& > .MuiChip-label': {
      fontSize: 14,
      paddingLeft: 8,
      paddingRight: 0,
      color: '#4E4E4E',
    },
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  hotelsContainer: {
    margin: '10px 10px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '15px',
  },
  iconDateContainer: {
    margin: 4,
    fontWeight: 400,
    height: '42px',
    backgroundColor: '#FFFFFF',
    color: '#4E4E4E',
    borderRadius: 24,
    border: `1px solid #D9D9D9`,
    // zIndex: theme.zIndex.drawer + 1,
    '& .MuiButton-startIcon': {
      '& svg': {
        transition: '0.25s',
      },
    },
    '&:hover': {
      color: '#4E4E4E',
      backgroundColor: '#F4F4F4',
      '& .MuiButton-startIcon': {
        '& svg': {
          color: theme.palette.primary.light,
        },
      },
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
    flexShrink: 1,
  },
  icon: {
    fontSize: '14px',
    color: '#4E4E4E',
  },
  peopleCountMenuItem: {
    display: 'flex',
    justifyContent: 'center',
    width: '63px',
    color: '#4E4E4E',
    '&:hover': {
      color: '#4E4E4E',
    },
  },
  citySearchBar: {
    fontSize: 16,
    backgroundColor: '#F4F4F4',
    borderRadius: 4,
    padding: '12px 8px',
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid #8A8A8A',
    },
  },
  focusedCitySearchbar: {
    fontSize: 16,
    backgroundColor: '#FFF',
    borderRadius: 4,
    padding: '12px 8px',
    border: '1px solid #FFA766',
    '&:hover': {
      border: '1px solid #FFA766',
    },
  },
  backDrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 1122,
  },
  searchOptionsButton: {
    color: '#4E4E4E',
    backgroundColor: '#FFF',
    border: '1px solid #D9D9D9',
    borderRadius: '24px',
    maxHeight: '40px',
    margin: '4px',
    '&:hover': {
      border: '1px solid #D9D9D9',
      backgroundColor: '#F4F4F4',
      color: '#4E4E4E',
    },
  },
  searchbarPopperContainer: {
    backgroundColor: '#FFFFFF',
    padding: 12,
    borderRadius: '4px',
    zIndex: 1122,
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}));

function LocationChipContainer({ handleChipClick = () => {} }) {
  const classes = useStyles();

  const { slug: tripId } = useParams();
  const mapPins = useSelector((state) => state.MapV2.mapPins[tripId] || {});

  const searchTags = useSelector((state) =>
    Array.from(
      new Set(
        Object.values(state.Item.items || {})
          ?.filter(
            (item) =>
              item.type === ITEM_TYPES.DESTINATION &&
              item.tripId === tripId &&
              item.title !== '' &&
              item.mapPin
          )
          ?.map((loc) => {
            const mapPin = mapPins[loc?.mapPin] || {};
            if (!mapPin) return null;
            return {
              title: loc.title,
              latitude: mapPin?.lat,
              longitude: mapPin?.long,
            };
          })
          ?.filter((tag) => Boolean(tag))
      )
    )
  );

  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        width: '100%',
        flexWrap: 'wrap',
      }}>
      {searchTags?.map((tag) => (
        <Chip
          className={classes.suggestionChip}
          icon={
            <LocationOnIcon
              sx={{
                height: 16,
                width: 16,
              }}
            />
          }
          onMouseDown={() => handleChipClick(tag)}
          label={tag?.title}
          variant="outlined"
        />
      ))}
    </div>
  );
}

function SearchbarContainer({
  inputRef,
  validLocations,
  date,
  updateDate,
  overlayOpen,
  setOverlayOpen,
  anchorGuestEl,
  setAnchorGuestEl,
  guestCount,
  updateGuestCount,
  filterOpen,
  setFilterOpen,
  fLabels,
  hotelList,
  isSaveListVisible,
  fetchFlip,
  setfetchFlip,
  setCityDetails,
  noResultFound,
  resetSearch = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { slug: tripId } = useParams();
  const cityLocationDetails = useSelector(
    (state) => state.Bookings[tripId]?.cityLocationDetails || {}
  );

  const [searchInput, setSearchInput] = useState('');

  const [focus, setFocus] = useState(false);

  const { playSound } = useSounds();
  const { getPlaceDetails } = useMapUtils();

  useEffect(
    () => setSearchInput(cityLocationDetails?.title),
    [cityLocationDetails?.title]
  );

  // utility functions

  // When user selects a location from the search dropdown
  const handleLocationSelect = async (locationResult) => {
    const placeId = locationResult?.place_id;
    const details = await getPlaceDetails(placeId, 'DIRECTIONS_PIN', true);
    setCityDetails({
      placeId,
      latitude: details?.lat,
      longitude: details?.long,
    });
    dispatch(
      actions.Bookings.setCityLocationDetails({
        tripId,
        cityLocationDetails: {
          placeId,
          latitude: details?.lat,
          longitude: details?.long,
          title: details?.title,
        },
      })
    );
    setfetchFlip(!fetchFlip);
  };

  // When user selects one of the chips under the searchbar
  const handleChipSelect = ({ title, latitude, longitude }) => {
    window?.heap?.track('stays_search_suggestion_click', {
      title,
    });
    setCityDetails({
      title,
      latitude,
      longitude,
    });
    dispatch(
      actions.Bookings.setCityLocationDetails({
        tripId,
        cityLocationDetails: {
          title,
          latitude,
          longitude,
        },
      })
    );
    setfetchFlip(!fetchFlip);
  };

  function EndIcon() {
    if (searchInput.length > 0) {
      return null;
    }
    return (
      <IconButton onClick={resetSearch}>
        <CloseIcon />
      </IconButton>
    );
  }

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className={classList.staysSearchbarContainer}>
        {focus && <div className={classes.backDrop} />}
        <PlacesSearchBar
          fullWidth
          id="search-input"
          onChange={(searchResult) => {
            setSearchInput(searchResult);
          }}
          enterToSelect
          autoSearch
          openOnFocus
          showBackdrop
          inputRef={inputRef}
          tripLocation={false}
          handleSelect={(option) => {
            playSound(SOUNDS.softPop);
            handleLocationSelect(option);
            setFocus(false);
          }}
          popperPlacement="bottom"
          CustomSearchBar={InputBase}
          value={searchInput}
          controlledValue={searchInput}
          defaultPopperComponent={
            focus &&
            validLocations?.length > 0 && (
              <div
                className={classes.searchbarPopperContainer}
                style={{
                  minWidth: inputRef?.current?.offsetWidth,
                }}>
                <Grid item xs={12}>
                  <Typography variant="h4" color="#4E4E4E">
                    From your trip
                  </Typography>
                </Grid>
                <Grid item xs={12} mt="14px">
                  <LocationChipContainer handleChipClick={handleChipSelect} />
                </Grid>
              </div>
            )
          }
          searchBarProps={{
            variant: 'standard',
            fullWidth: true,
            inputProps: {
              style: {
                padding: 0,
              },
            },
            placeholder: 'Search for a destination to get started',
            classes: {
              root: classes.citySearchBar,
              focused: classes.focusedCitySearchbar,
            },
            sx: {
              zIndex: focus ? 1123 : 0,
            },
            startIcon: (
              <LocationMapPinIcon
                style={{
                  marginRight: 8,
                  marginLeft: 4,
                }}
              />
            ),
            endIcon: EndIcon,
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                inputRef?.current?.blur();
                setFocus(false);
              }
            },
            onFocus: () => {
              setFocus(true);
            },
            onBlur: () => {
              setFocus(false);
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ paddingTop: isSaveListVisible ? 0 : 2 }}>
        {!isSaveListVisible && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <CheckInCheckOutButton
              date={date}
              updateDate={updateDate}
              overlayOpen={overlayOpen}
              setOverlayOpen={setOverlayOpen}
              setfetchFlip={setfetchFlip}
            />

            <Backdrop
              sx={{
                color: '#fff',
                zIndex: anchorGuestEl ? 1220 : 1099,
              }}
              open={overlayOpen}
              onClick={() => setAnchorGuestEl(null)}
            />

            {!anchorGuestEl ? (
              <Button
                startIcon={
                  <PeopleOutlined sx={{ color: '#1D1D1D', fontSize: '30px' }} />
                }
                className={classes.searchOptionsButton}
                sx={{
                  backgroundColor: anchorGuestEl ? '#D9D9D9' : '#FFFFFF',
                }}
                onClick={(e) => {
                  setAnchorGuestEl(e.currentTarget);
                }}>
                {guestCount?.total}
              </Button>
            ) : (
              <RoomAndGuestButton
                anchorEl={anchorGuestEl}
                setAnchorEl={setAnchorGuestEl}
                guestCount={guestCount}
                setGuestCount={updateGuestCount}
              />
            )}

            {(hotelList?.length !== 0 || noResultFound) && (
              <Button
                startIcon={
                  <FilterListIcon sx={{ color: '#1D1D1D', fontSize: '30px' }} />
                }
                className={classes.searchOptionsButton}
                sx={{
                  backgroundColor: filterOpen ? '#D9D9D9' : '#FFFFFF',
                }}
                onClick={() =>
                  setFilterOpen(
                    filterOpen
                      ? null
                      : document.getElementById('categories-reference')
                  )
                }>
                {`Filters ${fLabels?.length > 0 ? `(${fLabels?.length})` : ''}`}
              </Button>
            )}
          </Box>
        )}
        <Box sx={{ paddingTop: isSaveListVisible ? 0 : 1 }}>
          <Divider />
        </Box>
      </Grid>
    </>
  );
}

function SearchResults() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const { slug: tripId } = useParams();

  const {
    filterLabels: fLabels,
    hotelList,
    otherDetails,
    clickedCardIndex,
    cityLocationDetails,
    search: { date = null, guestCount = null } = {},
    numberOfNights,
    isSaveListVisible,
  } = useSelector((state) => state.Bookings[tripId] || {});
  const { focusPlace } = useMapUtils();
  const city = useSelector((state) => state.View.exploreCitySearch);
  const setCity = (value) => dispatch(actions.View.setExploreCitySearch(value));
  const [cityDetails, setCityDetails] = useState({
    latitude: cityLocationDetails?.latitude,
    longitude: cityLocationDetails?.longitude,
  });
  const { city: citySearchDetails } = useSelector(
    (state) => state.Recommendations.recommendations[tripId] || {}
  );

  const mapPins = useSelector((state) => state.MapV2.mapPins[tripId] || {});
  const items = useSelector((state) => state.Item.items);

  const cardRefs = Array.from({ length: 200 }, () => useRef(null));

  const validLocations = useMemo(() => {
    return Object.values(items || {})
      ?.filter(
        (loc) =>
          loc.tripId === tripId &&
          loc.title !== '' &&
          loc?.type === ITEM_TYPES.DESTINATION
      )
      ?.map((loc) => {
        const mapPin = loc.mapPin ? mapPins[loc.mapPin] : null;
        return {
          id: loc.id,
          title: loc.title,
          mapPin,
          hotelsCount:
            loc.children?.filter(
              (childId) => items[childId]?.type === ITEM_TYPES.HOTEL
            )?.length || 0,
          fromDate: loc.arrivalDate,
          toDate: loc.departureDate,
        };
      })
      .filter((tag) => Boolean(tag));
  }, [items, tripId]);

  useEffect(() => {
    if (!cityLocationDetails && validLocations?.length > 0) {
      try {
        const defaultLocationToSearch = (validLocations || []).sort(
          (a, b) => a.hotelsCount - b.hotelsCount
        )[0];
        // if defaultLocationToSearch fromDate and toDate are null then take the main trip date
        if (
          !defaultLocationToSearch.fromDate &&
          !defaultLocationToSearch.toDate
        ) {
          defaultLocationToSearch.fromDate = date?.from;
          defaultLocationToSearch.toDate = date?.to;
        }

        const {
          date: searchDate = null,
          cityLocationDetails: searchDetails = null,
        } = getStaysSearchConfig({
          ...defaultLocationToSearch,
        });
        if (searchDetails && searchDate) {
          dispatch(
            actions.Bookings.triggerSearch({
              tripId,
              cityLocationDetails: searchDetails,
              date: searchDate,
            })
          );
        }
      } catch (err) {
        // error
        // console.log(err);
      }
    }
  }, [validLocations]);

  const staysHotelKeys = useMemo(
    () =>
      Array.from(
        new Set(
          Object.values(mapPins || {})?.map((mapPin) => mapPin?.hotelId || null)
        )
      ),
    [mapPins]
  );

  const { features, propertyTypes, sortField, sortFieldName } =
    recommendationCategories.BookingOptions;

  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isError, setIsError] = useState(false);
  const [noResultFound, setNoResultFound] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [anchorGuestEl, setAnchorGuestEl] = useState(null);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState(0);
  const [fetchFlip, setfetchFlip] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const searchFilters = useSelector(
    (state) => state.Bookings[tripId]?.filterLabels
  );

  const shouldTriggerSearch = useSelector(
    (state) => state.Bookings[tripId]?.searchTrigger
  );

  const searchLocale = useSelector((state) => state.Bookings?.searchLocale);

  const updateCityLocationDetails = (newCityLocationDetails) => {
    dispatch(
      actions.Bookings.setCityLocationDetails({
        cityLocationDetails: {
          ...cityLocationDetails,
          ...newCityLocationDetails,
        },
        tripId,
      })
    );
  };

  const isValidSearchData = useMemo(() => {
    return Boolean(
      cityLocationDetails?.latitude &&
        cityLocationDetails?.longitude &&
        cityLocationDetails?.title
    );
  }, [cityLocationDetails]);

  const initateHotelSearch = async ({ isPaginatedSearch = false }) => {
    const loadSetter = isPaginatedSearch ? setLoadingButton : setLoading;
    const handleSearchError = () => {
      if (isPaginatedSearch) return;
      setIsError(true);
      dispatch(actions.Bookings.setHotelList({ hotelList: [], tripId }));
    };

    setIsError(false);
    loadSetter(true);
    let response = null;
    try {
      response = await getHotelSearchResults({
        handleError: handleSearchError,
        ...cityLocationDetails,
        date,
        numberOfNights,
        guestCount,
        filters: searchFilters,
        pageIndex,
        isPaginatedSearch,
      });
    } catch (err) {
      // error
    }
    loadSetter(false);

    if (response && response?.result?.length > 0) {
      return {
        hotels: response?.result,
        totalPages: response?.totalPages,
        ...(!loadingButton && {
          filters: response?.filters,
          locale: response?.locale,
        }),
      };
    }
    if (!loadingButton) setNoResultFound(true);
    return null;
  };

  // eslint-disable-next-line consistent-return

  const loadData = async () => {
    if (isValidSearchData) {
      const response = await initateHotelSearch({
        isPaginatedSearch: loadingButton,
        pageIndex,
      });
      if (!response && !loadingButton) {
        dispatch(
          actions.Bookings.setHotelList({
            hotelList: [],
            tripId,
          })
        );
        return null;
      }
      if (!response) return null;

      const {
        hotels = null,
        filters = null,
        locale = null,
        totalPages = 1,
      } = response;
      if (locale) dispatch(actions.Bookings.setSearchLocale({ locale }));
      if (hotels?.length > 0) {
        const currencyFormatter = new Intl.NumberFormat(undefined, {
          currency: locale?.currencyCode || searchLocale?.currencyCode,
          style: 'currency',
          maximumFractionDigits: 0,
        });
        const transformedHotels = hotels?.map((hotel) => {
          return {
            id: hotel.id,
            hotelKey: hotel.id,
            hotelName: hotel.displayName,
            starRating: hotel.starRating,
            imageLink: hotel.coverImage,
            rates: [
              {
                totalRate: hotel.price,
              },
            ],
            providers: [],
            currencyCode: locale?.currencyCode,
            latitude: hotel.coordinates.latitude,
            longitude: hotel.coordinates.longitude,
            price: currencyFormatter.format(
              getFormattedNightlyPrice(hotel?.price, numberOfNights) *
                numberOfNights
            ),
            nightlyPrice: currencyFormatter.format(
              getFormattedNightlyPrice(hotel?.price, numberOfNights)
            ),
            referenceId: hotel.id?.toString(),
            images:
              hotel.photos?.length > 0
                ? hotel.photos?.map((p) => ({ small: p }))
                : [{ small: hotel.coverImage }],
            propertyType: hotel?.type,
            address: hotel.address,
          };
        });

        if (loadingButton) {
          dispatch(
            actions.Bookings.setHotelList({
              hotelList: [...hotelList, ...transformedHotels],
              tripId,
            })
          );
        } else {
          dispatch(
            actions.Bookings.setHotelList({
              hotelList: transformedHotels,
              tripId,
            })
          );

          const parsedRegionData = {
            currencyCode: 'USD',
            lowestTotalRate: filters?.minPrice,
            highestTotalRate: filters?.maxPrice,
            starRating: filters?.starRating,
            amenities: filters?.amenities,
            totalPages,
          };

          dispatch(
            actions.Bookings.setOtherDetails({
              parsedRegionData,
              tripId,
            })
          );

          focusPlace(
            {
              latitude: cityLocationDetails?.latitude,
              longitude: cityLocationDetails?.longitude,
              zoom: 10,
            },
            false
          );
        }
      }
    }
    return null;
  };

  const inputRef = useRef();

  useEffect(() => {
    if (shouldTriggerSearch) {
      dispatch(
        actions.Bookings.triggerSearch({ tripId, cityLocationDetails, date })
      );
      if (cityLocationDetails?.longitude && cityLocationDetails?.latitude) {
        updateCityLocationDetails({
          title: cityLocationDetails?.title,
          latitude: cityLocationDetails?.latitude,
          longitude: cityLocationDetails?.longitude,
        });
        setfetchFlip(!fetchFlip);
      } else if (cityLocationDetails?.title) {
        // workaround to render focus after page is rendered to prevent popper scroll
        setTimeout(() => inputRef?.current?.focus(), 400);
      }
    }
  }, [shouldTriggerSearch]);

  const updateGuestCount = (newGuestCount) => {
    dispatch(
      actions.Bookings.setGuestCount({ tripId, guestCount: newGuestCount })
    );
  };

  const updateDate = (newDate) => {
    dispatch(actions.Bookings.setSearchDate({ tripId, date: newDate }));
  };

  const handleRefreshButtonClick = () => {
    setIsError(false);
    setfetchFlip(!fetchFlip);
  };

  const handleShowMoreClick = () => {
    setLoadingButton(true);
    setPageIndex((x) => x + 1);
    setfetchFlip(!fetchFlip);
  };

  const handleMenuItemClick = (idx) => {
    phTrackEvent({
      event: EVENTS.EXPLORE_STAYS.SORT_UPDATE,
    });
    if (!Number.isNaN(+idx)) setSelectedSortOption(+idx);
  };

  useEffect(() => {
    setfetchFlip(!fetchFlip);
  }, [guestCount, selectedSortOption]);

  useEffect(() => {
    if (anchorGuestEl) {
      setOverlayOpen(true);
    } else {
      setOverlayOpen(false);
    }
  }, [anchorGuestEl]);

  const handleClickAway = () => {
    if (!isMobile && clickedCardIndex) {
      dispatch(
        actions.Bookings.setClickedCardIndex({ cardIndex: null, tripId })
      );
    }
  };

  const handleSearchError = () => {
    setIsError(true);
    dispatch(
      actions.Bookings.setHotelList({
        hotelList: [],
        tripId,
      })
    );
  };

  const getPropsFromUrl = () => {
    const urlParams = new URLSearchParams(location.search);
    const searchProps = {};
    const allProps = [
      'checkInDate',
      'checkOutDate',
      'rooms',
      'll',
      'p',
      'sortOption',
      'filters',
    ];
    const requiredSearchProps = [
      'checkInDate',
      'checkOutDate',
      'rooms',
      'll',
      'p',
    ];

    allProps.forEach((prop) => {
      if (urlParams.get(prop)) {
        searchProps[prop] = urlParams.get(prop) || null;
      }
    });

    // remove null values
    Object.keys(searchProps)?.forEach(
      (prop) => !searchProps[prop] && delete searchProps[prop]
    );

    if (
      (Object.keys(searchProps) || []).filter((prop) =>
        requiredSearchProps.includes(prop)
      ).length === requiredSearchProps.length
    ) {
      // TODO: Additional validation for each prop
      return {
        title: searchProps?.p,
        latitude: searchProps?.ll?.split(',')[0],
        longitude: searchProps?.ll?.split(',')[1],
        checkInDate:
          new Date(searchProps?.checkInDate) ||
          new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
        checkOutDate:
          new Date(searchProps?.checkOutDate) ||
          new Date(Date.now() + 4 * 24 * 60 * 60 * 1000),
        rooms: stringToGuestV2(searchProps?.rooms || '2'),
        selectedSortOption: searchProps?.sortOption || 0,
        filters: searchProps?.filters?.split('+') || [],
      };
    }
    return null;
  };

  const searchWithUrlParams = (searchProps) => {
    dispatch(
      actions.Bookings.setSearchDate({
        tripId,
        date: {
          from: searchProps?.checkInDate,
          to: searchProps?.checkOutDate,
        },
      })
    );
    dispatch(
      actions.Bookings.setFilter({
        tripId,
        filterItems: searchProps?.filters || [],
      })
    );
    setSelectedSortOption(searchProps?.selectedSortOption || 0);
    updateCityLocationDetails({
      title: searchProps?.title,
      latitude: searchProps?.latitude,
      longitude: searchProps?.longitude,
    });
    setCity(searchProps?.title);
    updateGuestCount(searchProps?.rooms);
    setfetchFlip(!fetchFlip);
  };

  useEffect(() => {
    if (
      cityLocationDetails?.latitude &&
      cityLocationDetails?.longitude &&
      hotelList?.length > 0
    )
      return;

    const searchProps = getPropsFromUrl();
    if (searchProps) {
      searchWithUrlParams(searchProps);
    } else if (citySearchDetails) {
      setCity(citySearchDetails?.title);
      setCityDetails({
        latitude: citySearchDetails?.coordinates?.latitude,
        longitude: citySearchDetails?.coordinates?.longitude,
      });
      setfetchFlip(!fetchFlip);
    } else if (validLocations?.length > 0) {
      try {
        const defaultLocationToSearch = (validLocations || []).sort(
          (a, b) => a.hotelsCount - b.hotelsCount
        )[0];
        // if defaultLocationToSearch fromDate and toDate are null then take the main trip date
        if (
          !defaultLocationToSearch.fromDate &&
          !defaultLocationToSearch.toDate
        ) {
          defaultLocationToSearch.fromDate = date?.from;
          defaultLocationToSearch.toDate = date?.to;
        }

        const {
          date: searchDate = null,
          cityLocationDetails: searchDetails = null,
        } = getStaysSearchConfig({
          ...defaultLocationToSearch,
        });
        if (searchDetails && searchDate) {
          setCityDetails({
            title: searchDetails?.title,
            latitude: searchDetails?.latitude,
            longitude: searchDetails?.longitude,
          });
          dispatch(actions.View.setExploreCitySearch(searchDetails?.title));
          dispatch(
            actions.Bookings.triggerSearch({
              tripId,
              cityLocationDetails: searchDetails,
              date: searchDate,
            })
          );
          setfetchFlip(!fetchFlip);
        }
      } catch (err) {
        // error
        // console.log(err);
      }
    }
  }, []);

  const resetSearch = (e) => {
    e.preventDefault();
    setFilterOpen(false);
    setLoading(false);
    setIsError(false);
    setPageIndex(0);
    dispatch(actions.Bookings.setHotelList({ hotelList: [], tripId }));
    dispatch(
      actions.Bookings.setCityLocationDetails({
        setCityLocationDetails: null,
        tripId,
      })
    );
    dispatch(
      actions.Bookings.setFilter({
        filterItems: [],
        tripId,
      })
    );
  };

  useEffect(() => {
    loadData();
  }, [fLabels, fetchFlip]);

  return (
    <Grid container style={{ marginTop: 20 }}>
      <BookingsFilterMenu
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
      />
      {!isSaveListVisible && (
        <SearchbarContainer
          inputRef={inputRef}
          validLocations={validLocations}
          date={date}
          updateDate={updateDate}
          overlayOpen={overlayOpen}
          setOverlayOpen={setOverlayOpen}
          anchorGuestEl={anchorGuestEl}
          setAnchorGuestEl={setAnchorGuestEl}
          guestCount={guestCount}
          updateGuestCount={updateGuestCount}
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen}
          fLabels={fLabels}
          hotelList={hotelList}
          isSaveListVisible={isSaveListVisible}
          setfetchFlip={setfetchFlip}
          setCityDetails={updateCityLocationDetails}
          fetchFlip={fetchFlip}
          resetSearch={resetSearch}
          noResultFound={noResultFound}
        />
      )}
      {loading && (
        <Grid container columnSpacing="12px" rowSpacing="2px" mt="16px">
          {[...Array(10).keys()]?.map((idx) => (
            <Grid item key={idx} xs={12} md={6}>
              <HotelDetailsCardSkeleton />
            </Grid>
          ))}
        </Grid>
      )}

      {!loading &&
        hotelList?.length > 0 &&
        cityLocationDetails?.longitude &&
        cityLocationDetails?.latitude &&
        !isSaveListVisible && (
          <Box sx={{ width: '100%' }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              mt="16px">
              <Typography
                noWrap
                variant="h4"
                sx={{ fontSize: '1rem', fontWeight: '600' }}>
                Search result
              </Typography>
            </Box>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              onClickAway={handleClickAway}>
              <Grid container columnSpacing="12px" rowSpacing="2px" mt="16px">
                {hotelList?.map(
                  (
                    {
                      id,
                      hotelKey,
                      hotelName,
                      starRating,
                      guestRating,
                      numberOfReviews,
                      guestRatingSentiment,
                      providers,
                      rates,
                      imageLink,
                      images,
                      currencyCode,
                      propertyType,
                      latitude,
                      longitude,
                      address,
                    },
                    index
                  ) => (
                    <Grid item xs={12} md={6}>
                      <HotelDetailsCard
                        key={id}
                        hotelKey={hotelKey}
                        index={index}
                        hotelName={hotelName}
                        starRating={starRating}
                        guestRating={guestRating}
                        numberOfReviews={numberOfReviews}
                        guestRatingSentiment={guestRatingSentiment}
                        imageLink={imageLink}
                        rates={rates}
                        providers={providers}
                        currencyCode={currencyCode}
                        referenceId={id?.toString()}
                        images={images}
                        city={cityLocationDetails?.title}
                        cardRef={cardRefs[index]}
                        numberOfNights={numberOfNights}
                        propertyType={propertyType}
                        locationsList={validLocations}
                        accomodationKeyId={staysHotelKeys}
                        latlong={{ latitude, longitude }}
                        date={date}
                        disableProviders
                        address={address}
                        forwardUrlParams={{
                          hotelName,
                          starRating,
                        }}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </ClickAwayListener>
            <Box display="flex" justifyContent="center">
              <LoadingButton
                loading={loadingButton}
                disabled={otherDetails?.totalPages <= pageIndex}
                onClick={handleShowMoreClick}
                sx={{
                  backgroundColor: '#FFFFFF',
                  color: '#ED702E',
                  width: '50%',
                  border:
                    otherDetails?.totalPages <= pageIndex
                      ? '1px solid #8A8A8A'
                      : '1px solid #ED702E',
                  '&:hover': {
                    backgroundColor: '#FFF1E0',
                    color: '#ED702E',
                  },
                  ':active': {
                    backgroundColor: '#FFA766',
                    color: '#FFFFFF',
                  },
                }}>
                {otherDetails?.totalPages <= pageIndex && !loadingButton
                  ? 'No More Deals'
                  : 'Load more deals'}
              </LoadingButton>
            </Box>
          </Box>
        )}

      {isError && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '10%',
            width: '100%',
          }}>
          <BookingErrorIcon />
          <Typography
            sx={{
              color: '#8A8A8A',
              fontWeight: '400',
              fontSize: '24px',
              marginTop: '5%',
              marginBottom: '5%',
            }}>
            Some Error Occured
          </Typography>
          <Button
            startIcon={<RefreshIcon />}
            onClick={handleRefreshButtonClick}>
            Refresh Your Search
          </Button>
        </Box>
      )}

      {isSaveListVisible && !isError && (
        <HotelsSaveListContainer
          locationsList={validLocations}
          accomodationKeyId={staysHotelKeys}
        />
      )}

      {hotelList?.length === 0 && noResultFound && !isError && (
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '30%',
          }}>
          <NoResultFoundIcon />
          <Typography
            sx={{
              fontFamily: 'Inter',
              color: '#000000',
              fontWeight: '500',
              fontSize: '16px',
              marginTop: '5%',
            }}>
            No exact match found.
          </Typography>
        </Box>
      )}
    </Grid>
  );
}

export default SearchResults;
