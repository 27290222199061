import {
  KeyboardCommandKey,
  DeleteForeverRounded,
  FavoriteOutlined,
  ChatBubble,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { PostHogFeature } from 'posthog-js/react';

import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ShoppingListIcon } from '../../../../atoms/Icon';
import FLAGS from '../../../../../featureFlags';
import { ViewActions } from '../../../../../redux/slices/View';
import UserAvatar from '../../../../molecules/UserAvatar';

export default function Menu({
  isOwner,
  onChatClick,
  onDeleteClick,
  onKeyboardShortcutClick,
  onProfileClick,
}) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const handlePwywClick = () => {
    dispatch(
      ViewActions.setContribute({ action: 1, source: 'dashboard-menu' })
    );
  };

  const { user } = useSelector((state) => state.Auth.userData);

  return (
    <Box
      sx={{
        width: 280,
        borderRadius: 3,
        p: 2,
      }}>
      <ListItemButton sx={{ mb: 2 }} onClick={onProfileClick} xˀ>
        <UserAvatar fontSize="16px" iconSize="48px" user={user} />
        <Typography ml="18px" variant="h6">
          {user?.firstName} {user?.lastName}
        </Typography>
      </ListItemButton>
      <Divider sx={{ my: 1 }} />

      <List sx={{ pl: 1, '& .MuiListItem-root': { py: 1.5, px: 2 } }}>
        <PostHogFeature flag={FLAGS.UNIVERSAL_ADD_BAR_KEYBOARDSHORTCUTS}>
          <ListItemButton onClick={onKeyboardShortcutClick}>
            <ListItemIcon>
              <KeyboardCommandKey />
            </ListItemIcon>
            <ListItemText primary="Keyboard Shortcuts" />
          </ListItemButton>
        </PostHogFeature>

        {isTablet && (
          <ListItemButton onClick={onChatClick}>
            <ListItemIcon>
              <ChatBubble />
            </ListItemIcon>
            <ListItemText primary="Chat" />
          </ListItemButton>
        )}

        <PostHogFeature flag={FLAGS.PWYW}>
          <>
            <Divider sx={{ my: 1 }} />
            <ListItemButton onClick={handlePwywClick}>
              <ListItemIcon>
                <FavoriteOutlined />
              </ListItemIcon>
              <ListItemText primary="Help Pilot Grow" />
            </ListItemButton>
          </>
        </PostHogFeature>
        <ListItemButton
          onClick={() =>
            window.open('https://www.pilotplans.com/roadmap', '_blank')
          }>
          <ListItemIcon sx={{ pl: '5px' }}>
            <ShoppingListIcon />
          </ListItemIcon>
          <ListItemText primary="Product Requests" />
        </ListItemButton>

        <Divider sx={{ my: 1 }} />

        <ListItemButton onClick={onDeleteClick}>
          <ListItemIcon>
            <DeleteForeverRounded />
          </ListItemIcon>
          <ListItemText primary={isOwner ? 'Delete Trip' : 'Leave'} Trip />
        </ListItemButton>
      </List>
    </Box>
  );
}
